import { Card, Icon, OutlinedInput } from "@mui/material";
import MDButton from "components/MDButton";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { useEffect, useMemo, useRef, useState } from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";

const autocompleteService = { current: null };

function DecisionForm({ onClickCloseDecisionFormBtn }) {
  const locationRef = useRef();
  const employmentRef = useRef();

  function onClickCloseForm() {
    onClickCloseDecisionFormBtn();
  }

  function onClickSubmitBtn() {
    const locationValue = locationRef.current.querySelector("input").value;
    const employmentValue = employmentRef.current.querySelector("input").value;
    console.log("Location value:", locationValue);
    console.log("Employment value:", employmentValue);

    if (locationValue == "" || employmentValue == "") {
    } else {
      window.location.href = `/ai?location=${locationValue}&employment=${employmentValue}`;
    }
  }

  function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {"Copyright © "}
        <Link color="inherit" href="https://blue-gap.org/">
          BlueGAP
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }

  const jobs = ["Farmer", "Hydrolics Engineer", "Marine", "Nitrogen Expert"];

  const [personName, setPersonName] = useState("");
  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setPersonName(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };

  const [age, setAge] = useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const loaded = useRef(false);

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Card>
      <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="success"
        mx={2}
        mt={-3}
        p={3}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          BlueGAP Profile
        </MDTypography>
        <MDTypography display="block" variant="button" color="white" my={1}>
          Enter your desired location and employment background
        </MDTypography>
      </MDBox>
      <MDBox pt={4} pb={3} px={3}>
        <MDBox component="form" role="form">
          <MDBox mb={2}>
            {/* <TextField
              required
              ref={locationRef}
              label="Location of Interest"
              variant="outlined"
              fullWidth
            /> */}
            <Autocomplete
              ref={locationRef}
              id="google-map-demo"
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.description
              }
              filterOptions={(x) => x}
              options={options}
              autoComplete
              includeInputInList
              filterSelectedOptions
              value={value}
              noOptionsText="No locations"
              onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderInput={(params) => <TextField {...params} label="Add a location" fullWidth />}
              renderOption={(props, option) => {
                const matches = option.structured_formatting.main_text_matched_substrings || [];

                const parts = parse(
                  option.structured_formatting.main_text,
                  matches.map((match) => [match.offset, match.offset + match.length])
                );

                return (
                  <li {...props}>
                    <Grid container alignItems="center">
                      <Grid item sx={{ display: "flex", width: 44 }}>
                        <LocationOnIcon sx={{ color: "text.secondary" }} />
                      </Grid>
                      <Grid item sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}>
                        {parts.map((part, index) => (
                          <Box
                            key={index}
                            component="span"
                            sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                          >
                            {part.text}
                          </Box>
                        ))}
                        <Typography variant="body2" color="text.secondary">
                          {option.structured_formatting.secondary_text}
                        </Typography>
                      </Grid>
                    </Grid>
                  </li>
                );
              }}
            />
          </MDBox>
          <MDBox
            mb={2}
            sx={{
              "& .MuiOutlinedInput-root": { height: "45px" },
            }}
          >
            <TextField
              select
              label="Select"
              defaultValue="None"
              helperText="Please select your employment"
              fullWidth
              ref={employmentRef}
            >
              {jobs.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </MDBox>

          {/* <MDBox mb={2}>
            <MDInput type="password" label="Password" variant="standard" fullWidth />
          </MDBox> */}
          <MDBox mt={2} mb={1}>
            <MDButton variant="gradient" color="info" fullWidth onClick={onClickSubmitBtn}>
              submit
            </MDButton>
          </MDBox>
          <MDBox mt={2} mb={1}>
            <MDButton variant="gradient" color="error" fullWidth onClick={onClickCloseForm}>
              cancel
            </MDButton>
          </MDBox>
          <MDBox mt={3} mb={1} textAlign="center">
            <Copyright sx={{ mt: 1, mb: 1 }} />
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default DecisionForm;
