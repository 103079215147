import { Box, Card } from "@mui/material";
import MDBox from "components/MDBox";
import pattern from "assets/images/illustrations/pattern-tree.svg";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

function ChampionCard({
  image,
  expertise,
  name,
  site,
  setAutoplay,
  setCurrentData,
  mapGlobal,
  user,
  getCurrentZoom,
  currentData,
  setYouTubePlaylist,
}) {
  return (
    <Card sx={{ backgroundColor: "black !important", margin: "8px 8px 8px 8px" }}>
      <MDBox
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height="100%"
        opacity={0.2}
        sx={{
          backgroundImage: `url(${pattern})`,
          backgroundSize: "cover",
        }}
      />
      <Box display="flex" alignItems="center">
        <Box width="85px" ml={2} mt={1} mb={1}>
          <img
            style={{
              width: "85px",
              height: "85px",
              borderRadius: "50%",
              border: "4px solid",
              borderColor: currentData
                ? currentData.type === "blue"
                  ? "#0288d1"
                  : currentData.type === "orange"
                  ? "#ff9800"
                  : "#558b2f"
                : user?.type === "blue"
                ? "#0288d1"
                : user?.type === "orange"
                ? "#ff9800"
                : "#558b2f",
            }}
            src={image}
            alt="image"
          />
        </Box>
        <Box width="40%" ml={2} mt={1} mb={1}>
          <MDTypography
            variant="h5"
            color="white"
            fontWeight="medium"
            sx={{ fontFamily: "PFDinTextCompProMedium", fontSize: "25px" }}
          >
            {name}
          </MDTypography>
          <MDTypography
            variant="h6"
            color="white"
            fontWeight="light"
            textTransform="capitalize"
            sx={{ fontFamily: "PFDinTextCompProMedium", fontSize: "21px" }}
          >
            {expertise}
          </MDTypography>
        </Box>
        <Box ml={2} mt={1} mb={3}>
          {site.length >= 15 ? (
            <>
              <MDTypography
                variant="button"
                color="white"
                fontWeight="regular"
                opacity={0.8}
                sx={{
                  fontFamily: "PFDinTextCompProMedium",
                  fontSize: "19px",
                  display: "block",
                }}
              >
                Location :
              </MDTypography>
              <MDTypography
                variant="button"
                color="white"
                fontWeight="regular"
                opacity={0.8}
                sx={{
                  fontFamily: "PFDinTextCompProMedium",
                  fontSize: "19px",
                }}
              >
                {site}
              </MDTypography>
            </>
          ) : (
            <MDTypography
              variant="button"
              color="white"
              fontWeight="regular"
              opacity={0.8}
              sx={{
                fontFamily: "PFDinTextCompProMedium",
                fontSize: "19px",
              }}
            >
              Location : {site}
            </MDTypography>
          )}

          <MDButton
            color="info"
            size="small"
            style={{
              margin: "10px 0px 0px 0px",
              color: "#fff",
              visibility: currentData ? "hidden" : "visible",
            }}
            variant="contained"
            onClick={() => {
              if (user.data.embedId) {
                fetch(
                  `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=${user.data.embedId}&key=AIzaSyBgvH0dBGFTYNKfBXMRcQ9ms9RKPqF-dqc`
                )
                  .then((res) => res.json())
                  .then((data) => {
                    setAutoplay(0);
                    //console.log("youtube", data.items);
                    setYouTubePlaylist(data);
                  });
              }
              setYouTubePlaylist(null);
              setCurrentData(user);
              mapGlobal.setCenter(user.position);
              mapGlobal.setZoom(getCurrentZoom(user.data.state));
            }}
          >
            Read Story
          </MDButton>
        </Box>
      </Box>
      {/* <MDTypography
                variant="button"
                color="white"
                fontWeight="regular"
                opacity={0.8}
                sx={{ fontFamily: "PFDinTextCompProMedium", fontSize: "21px" }}
              >
                Location : {site}
              </MDTypography>
              <MDButton
                color="info"
                size="small"
                style={{
                  margin: "10px 0px 0px 0px",
                  color: "#fff"
                }}
                variant="contained"
                onClick={() => {
                  setAutoplay(0);
                  setCurrentData(user);
                  mapGlobal.setCenter(user.position);
                  mapGlobal.setZoom(getCurrentZoom(user.data.state));
                }}
              >
                Read Story
              </MDButton> */}
    </Card>
  );
}

export default ChampionCard;
