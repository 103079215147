import { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { useMaterialUIController } from "context";

function PlotlyObservationChart({
  data,
  provider,
  startDate,
  endDate,
  selectedVariableAbbr,
  selectedVariableName,
  setLoadingIndicator,
  siteData,
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { sensorDataBox } = controller;
  const [stateData, setSetData] = useState({
    selectedVariableName: "Select a variable",
    data: [{ x: null, y: null }],
    startDate: new Date(),
    endDate: new Date(),
  });

  useEffect(() => {
    // console.log(data);
    //console.log(provider);
    // console.log(startDate);
    // console.log(endDate);
    //console.log(selectedVariableAbbr);
    //console.log(selectedVariableName);
    // if (startDate == endDate) {
    //   console.log("here");
    //   let endDateObj = new Date(endDate);
    //   const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // 24 hours * 60 minutes * 60 seconds * 1000 milliseconds

    //   // Add one day to the original date
    //   const newEndDate = new Date(endDateObj.getTime() + oneDayInMilliseconds);
    //   endDate = newEndDate.toISOString();
    //   console.log(endDate); // Outputs: "2016-08-16T05:00:00.000Z"
    // }
    setSetData({
      data: formatObservations(data, provider),
      selectedVariableAbbr: selectedVariableAbbr,
      selectedVariableName: selectedVariableName,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
    });
  }, [data]);

  function formatObservations(observations, provider) {
    //console.log("objeara", observations);
    // debugger;
    if (observations != null) {
      // Make sure data is actual observation and not noise.
      const helper_isLegitData = (data) => {
        if (data !== null && data !== "null" && data !== "None") {
          if (parseFloat(data) >= 0) {
            return true;
          }
        }
        return false;
      };

      const helper_nullifyMissingData = (data, doesRequireParseFloat = false) => {
        if (data === null || data === "null" || data === "None") {
          return null;
        }
        let data_numeric = doesRequireParseFloat ? parseFloat(data) : data;
        if (data_numeric < 0) {
          return null;
        }
        return data_numeric;
      };

      // -----------------

      let labeledData = [];

      // OBSERVATION RESPONSE FORMAT #1
      // NWIS IV - A list of objects with metadata and timerange
      if (Array.isArray(observations) && Array.isArray(observations[0])) {
        observations[0].forEach(function (observation) {
          let dataRaw =
            observation?.[selectedVariableAbbr] == null ? 0.0 : observation?.[selectedVariableAbbr];
          let dateRaw = observation?.["dt"];
          if (helper_isLegitData(dataRaw)) {
            let dateInstance = new Date(dateRaw);
            let dataProcessed = helper_nullifyMissingData(dataRaw, true);
            labeledData.push({ x: dateInstance, y: dataProcessed });
          }
        });
      }
      // OBSERVATION RESPONSE FORMAT #2
      // A list of objects with key as data and value as observation
      else if (observations) {
        observations.forEach(function (observation) {
          if (provider === "IWQIS") {
            let dataRaw = observation["data"]; //24 values representing 24 hours of the day
            let dateRaw = observation["day"] ? observation["day"] : observation["year"];
            for (var unit = 0; unit < dataRaw.length; unit++) {
              // if(helper_isLegitData(dataRaw[unit])) {
              let dateInstance = new Date(dateRaw.toString());
              dateInstance = observation["day"]
                ? dateInstance.setHours(unit)
                : dateInstance.setDate(unit);
              labeledData.push({ x: dateInstance, y: helper_nullifyMissingData(dataRaw[unit]) });
              // }
            }
          } else {
            let observationAsArray = Object.entries(observation)[0];
            // if(helper_isLegitData(observationAsArray[1])){
            let dateInstance = new Date(observationAsArray[0]);
            let dataProcessed = helper_nullifyMissingData(observationAsArray[1], true);
            labeledData.push({ x: dateInstance, y: dataProcessed });
            // }
          }
        });
      }
      // // todo: if no data is available, show notification
      // if (labeledData.length < 1) {
      //     showNotification("Data is not available.");
      //     return [];
      // }
      // else if (labeledData.length < 2) {
      //     labeledData.push({ "x": labeledData[0].x, "y": 0 });
      //     showNotification("This is a singular data point for " + labeledData[0].x.toLocaleString([], { dateStyle: 'long' }));
      // }
      //console.log(labeledData);
      return labeledData;
    }
    return null;
  }

  return (
    <>
      {data !== null ? (
        <>
          <Plot
            data={[
              {
                x: stateData.data.map((obj) => {
                  return obj.x;
                }),
                y: stateData.data.map((obj) => {
                  return obj.y;
                }),
                type: provider == "IWQIS" ? "scatter" : "bar",
                mode: "lines",
                marker: { color: "#2989E3" },
                line: { color: "#2989E3" },
              },
            ]}
            onUpdate={() => {
              setLoadingIndicator(false);
            }}
            layout={{
              autosize: true,
              margin: {
                l: 70,
                r: 40,
                b: 40,
                t: 40,
                pad: 10,
              },
              // width: 350,
              // height: 200,
              // title: 'A Fancy Plot',
              xaxis: {
                autorange:
                  stateData.startDate.toLocaleDateString() ===
                  stateData.endDate.toLocaleDateString()
                    ? true
                    : true,
                range:
                  stateData.startDate.toLocaleDateString() ===
                  stateData.endDate.toLocaleDateString()
                    ? false
                    : [stateData.startDate, stateData.endDate],
                rangeselector: {
                  buttons: [
                    {
                      count: 12,
                      label: "1yr",
                      step: "month",
                      stepmode: "backward",
                    },
                    {
                      count: 24,
                      label: "2yr",
                      step: "month",
                      stepmode: "backward",
                    },
                    { step: "all" },
                  ],
                },
                rangeslider: { range: [stateData.startDate, stateData.endDate] },
                type: "date",
                tickformat:
                  stateData.startDate.toLocaleDateString() ===
                  stateData.endDate.toLocaleDateString()
                    ? "%Y-%m-%d %H:%M"
                    : null,
                tickvals:
                  stateData.startDate.toLocaleDateString() ===
                  stateData.endDate.toLocaleDateString()
                    ? [stateData.startDate]
                    : null,
              },
              yaxis: {
                autorange: true,
                range: [0, 100],
                type: "linear",
                rangemode: "tozero",
                title: {
                  text: selectedVariableName,
                  font: {
                    family: "Arial, sans-serif",
                    size: 12,
                    color: "#000",
                  },
                },
              },
            }}
            style={{ width: "100%", height: "100%", overflow: "hidden" }}
            config={{
              responsive: true,
              displaylogo: false,
              toImageButtonOptions: {
                format: "png",
                filename:
                  (siteData.agency == "NWIS" ? siteData.agency : siteData.provider) +
                  " - " +
                  siteData.siteid,
                height: 600,
                width: 1200,
                scale: 1,
              },
              modeBarButtonsToRemove: [
                "zoom2d",
                "pan2d",
                "select2d",
                "lasso2d",
                "zoomIn2d",
                "zoomOut2d",
                "autoScale2d",
              ],
            }}
          />
        </>
      ) : null}
    </>
  );
}

export default PlotlyObservationChart;
