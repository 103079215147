import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { makeStyles } from "@mui/styles";
import Plot from "react-plotly.js";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import "../styles/SummaryBox.css";
import Chip from "@mui/material/Chip";
import SummaryBoxStyle from "../styles/SummaryBoxStyle";
import GaugeChart from "react-gauge-chart";
// import Chart from "chart.js/auto";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  Switch,
} from "@mui/material";
import { storetVariables } from "constants";
import AnalyteSelector from "./AnalyteSelector";
import { calculateAverage } from "../helpers/summaryAndStyleHelper";
import { mainURL } from "constants";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Plotly from "plotly.js/lib/core";
import html2canvas from "html2canvas";

const useStyles = makeStyles(SummaryBoxStyle);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function SummaryBox({
  geoType,
  summaryObject,
  breadCrumbsList,
  handleBreadcrumbClick,
  value,
  setTabValue,
  chartValue,
  setShowDataReport,
  demographicObject,
  setShowTakeAction,
  parsedJSONData,
  handleCheckbox,
  state,
  setState,
  dataSourceState,
  dataSourceLoaded,
  boundState,
  showNotification,
  searchParams,
  mapGlobal,
}) {
  const classes = useStyles();

  // const [value, setTabValue] = useState(0);

  const [siteLabel, setSiteLabel] = useState("");
  const [advancedToggle, setAdvancedToggle] = useState(false);

  ///////////////////

  const handleChange = (event, newValue) => {
    setAdvancedToggle(false);
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function getDeltaText(value) {
    // //console.log("geom", summaryObject);
    if (value > 0 && value <= 25) {
      return "Poor";
    } else if (value > 25 && value < 75) {
      return "Average";
    } else {
      return "Good";
    }
  }

  // useEffect(() => {
  //     getLabelName();
  // }, [geomObject])

  // function getLabelName() {
  //     switch (geoType) {
  //         case 'County':
  //             setSiteLabel(geomObject?.features[0].properties.name + ', ' + geomObject?.features[0].properties.state_name);
  //             break;
  //         case 'City':
  //             setSiteLabel(geomObject?.features[0].properties.ua_label);
  //             break;
  //         case 'ZipCode':
  //             setSiteLabel(geomObject?.features[0].properties.po_name + ', ' + geomObject?.features[0].properties.state);
  //             break;
  //         default:
  //             setSiteLabel(geomObject?.features[0].properties.name + ', ' + geomObject?.features[0].properties.st);
  //     }
  // }

  // const [state, setState] = useState({
  //   zip_code: true,
  //   census: false,
  // });

  const handleChangeCheckBox = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });

    handleCheckbox(event.target.name, event.target.checked);
  };

  const handleChangeAdvancedToggle = () => {
    setAnalyte(Object.keys(summaryObject.grouped_analytes_data)[0]);
    setWaterSource(Object.keys(summaryObject.data_source)[0]);
    setAdvancedToggle(!advancedToggle);
  };

  const [waterQualityData, setWaterQualityData] = useState(null);
  // const { zip_code, census } = state;
  const [analyte, setAnalyte] = useState("");
  const [open, setOpen] = useState(false);

  const handleChangeSelect = (event) => {
    setAnalyte(event.target.value);
  };

  const [waterSource, setWaterSource] = useState("");
  const [openWaterSource, setOpenWaterSource] = useState(false);

  const handleChangeSelectWaterSource = (event) => {
    setWaterSource(event.target.value);
  };

  useEffect(() => {
    if (summaryObject?.analytes_list && summaryObject?.data_source) {
      setAnalyte(Object.keys(summaryObject.grouped_analytes_data)[0]);
      setWaterSource(Object.keys(summaryObject.data_source)[0]);
    }
  }, []);

  useEffect(() => {
    // console.log(summaryObject.analytes_list[analyte]);
    // console.log(summaryObject.grouped_analytes_data);
    if (summaryObject?.grouped_analytes_data) {
      const minVar = waterSource + "_m";
      const maxVar = waterSource + "_mx";
      const avgVar = waterSource + "_a";
      // let tempWaterQualityData = {
      //   drinkingWaterQualitySummaryMin:
      //     (minVar == "s_m" || minVar == "pws_m") && summaryObject?.analytes_list[analyte]
      //       ? summaryObject.analytes_list[analyte][minVar]
      //         ? parseFloat(summaryObject.analytes_list[analyte][minVar]).toFixed(1)
      //         : 0.0
      //       : 0.0,
      //   drinkingWaterQualitySummaryMax:
      //     maxVar == "s_mx" || (maxVar == "pws_mx" && summaryObject?.analytes_list[analyte])
      //       ? summaryObject.analytes_list[analyte][maxVar]
      //         ? parseFloat(summaryObject.analytes_list[analyte][maxVar]).toFixed(1)
      //         : 0.0
      //       : 0.0,
      //   drinkingWaterQualitySummaryAvg:
      //     avgVar == "s_a" || (avgVar == "pws_a" && summaryObject?.analytes_list[analyte])
      //       ? summaryObject.analytes_list[analyte][avgVar]
      //         ? parseFloat(summaryObject.analytes_list[analyte][avgVar]).toFixed(1)
      //         : 0.0
      //       : 0.0,
      //   waterCommunityQualitySummaryMin:
      //     minVar == "st_m" || minVar == "i_m" || minVar == "n_m" || minVar == "iw_m"
      //       ? summaryObject.analytes_list[analyte][minVar]
      //         ? parseFloat(summaryObject.analytes_list[analyte][minVar]).toFixed(1)
      //         : 0.0
      //       : 0.0,
      //   waterCommunityQualitySummaryMax:
      //     maxVar == "st_mx" || maxVar == "i_mx" || maxVar == "n_mx" || maxVar == "iw_mx"
      //       ? summaryObject.analytes_list[analyte][maxVar]
      //         ? parseFloat(summaryObject.analytes_list[analyte][maxVar]).toFixed(1)
      //         : 0.0
      //       : 0.0,
      //   waterCommunityQualitySummaryAvg:
      //     avgVar == "st_a" || avgVar == "i_a" || avgVar == "n_a" || avgVar == "iw_a"
      //       ? summaryObject.analytes_list[analyte][avgVar]
      //         ? parseFloat(summaryObject.analytes_list[analyte][avgVar]).toFixed(1)
      //         : 0.0
      //       : 0.0,
      //   groundWaterQualitySummaryMin:
      //     (minVar == "tbw_m" || minVar == "p_m") && summaryObject?.analytes_list[analyte]
      //       ? summaryObject.analytes_list[analyte][minVar]
      //         ? parseFloat(summaryObject.analytes_list[analyte][minVar]).toFixed(1)
      //         : 0.0
      //       : 0.0,
      //   groundWaterQualitySummaryMax:
      //     (maxVar == "tbw_mx" || maxVar == "p_mx") && summaryObject?.analytes_list[analyte]
      //       ? summaryObject.analytes_list[analyte][maxVar]
      //         ? parseFloat(summaryObject.analytes_list[analyte][maxVar]).toFixed(1)
      //         : 0.0
      //       : 0.0,
      //   groundWaterQualitySummaryAvg:
      //     (avgVar == "tbw_a" || avgVar == "p_a") && summaryObject?.analytes_list[analyte]
      //       ? summaryObject.analytes_list[analyte][avgVar]
      //         ? parseFloat(summaryObject.analytes_list[analyte][avgVar]).toFixed(1)
      //         : 0.0
      //       : 0.0,
      // };
      // setWaterQualityData(tempWaterQualityData);
      // console.log(summaryObject.grouped_analytes_data[analyte]);
      let tempWaterData = {};
      tempWaterData = {
        drinkingWaterQualitySummaryMax: 0.0,
        drinkingWaterQualitySummaryMin: 0.0,
        drinkingWaterQualitySummaryAvg: 0.0,
        groundWaterQualitySummaryMax: 0.0,
        groundWaterQualitySummaryMin: 0.0,
        groundWaterQualitySummaryAvg: 0.0,
        recreationalWaterQualitySummaryMax: 0.0,
        recreationalWaterQualitySummaryMin: 0.0,
        recreationalWaterQualitySummaryAvg: 0.0,
        waterCommunityQualitySummaryMax: 0.0,
        waterCommunityQualitySummaryMin: 0.0,
        waterCommunityQualitySummaryAvg: 0.0,
      };
      let min_analytes_list = [];
      let max_analytes_list = [];
      let avg_analytes_list = [];
      for (const key in summaryObject.grouped_analytes_data[analyte]) {
        if (key.startsWith("s_") || key.startsWith("pws_")) {
          if (key.endsWith("mx")) {
            tempWaterData.drinkingWaterQualitySummaryMax = summaryObject.grouped_analytes_data[
              analyte
            ][key]
              ? parseFloat(summaryObject.grouped_analytes_data[analyte][key]).toFixed(1)
              : 0.0;
          } else if (key.endsWith("m")) {
            tempWaterData.drinkingWaterQualitySummaryMin = summaryObject.grouped_analytes_data[
              analyte
            ][key]
              ? parseFloat(summaryObject.grouped_analytes_data[analyte][key]).toFixed(1)
              : 0.0;
          } else {
            tempWaterData.drinkingWaterQualitySummaryAvg = summaryObject.grouped_analytes_data[
              analyte
            ][key]
              ? parseFloat(summaryObject.grouped_analytes_data[analyte][key]).toFixed(1)
              : 0.0;
          }
        } else if (key.startsWith("p_") || key.startsWith("tbw_")) {
          if (key.endsWith("mx")) {
            tempWaterData.groundWaterQualitySummaryMax = parseFloat(
              summaryObject.grouped_analytes_data[analyte][key]
            ).toFixed(1);
          } else if (key.endsWith("m")) {
            tempWaterData.groundWaterQualitySummaryMin = parseFloat(
              summaryObject.grouped_analytes_data[analyte][key]
            ).toFixed(1);
          } else {
            tempWaterData.groundWaterQualitySummaryAvg = parseFloat(
              summaryObject.grouped_analytes_data[analyte][key]
            ).toFixed(1);
          }
        } else if (key.startsWith("tbb_") || key.startsWith("vi_")) {
          if (key.endsWith("mx")) {
            tempWaterData.recreationalWaterQualitySummaryMax = parseFloat(
              summaryObject.grouped_analytes_data[analyte][key]
            ).toFixed(1);
          } else if (key.endsWith("m")) {
            tempWaterData.recreationalWaterQualitySummaryMin = parseFloat(
              summaryObject.grouped_analytes_data[analyte][key]
            ).toFixed(1);
          } else {
            tempWaterData.recreationalWaterQualitySummaryAvg = parseFloat(
              summaryObject.grouped_analytes_data[analyte][key]
            ).toFixed(1);
          }
        } else {
          if (key.endsWith("_mx")) {
            max_analytes_list.push(parseFloat(summaryObject.grouped_analytes_data[analyte][key]));
          } else if (key.endsWith("_m")) {
            min_analytes_list.push(parseFloat(summaryObject.grouped_analytes_data[analyte][key]));
          } else if (
            key.endsWith("_a") &&
            typeof summaryObject.grouped_analytes_data[analyte][key] === "number"
          ) {
            // console.log("values", parseFloat(summaryObject.grouped_analytes_data[analyte][key]))
            if (parseFloat(summaryObject.grouped_analytes_data[analyte][key]) != 0) {
              avg_analytes_list.push(parseFloat(summaryObject.grouped_analytes_data[analyte][key]));
            }
          }
        }
      }

      // console.log("avg", avg_analytes_list);

      tempWaterData.waterCommunityQualitySummaryMin =
        min_analytes_list.length === 0
          ? 0.0
          : parseFloat(Math.min(...min_analytes_list)).toFixed(1);
      tempWaterData.waterCommunityQualitySummaryMax =
        min_analytes_list.length === 0
          ? 0.0
          : parseFloat(Math.max(...max_analytes_list)).toFixed(1);
      tempWaterData.waterCommunityQualitySummaryAvg =
        avg_analytes_list.length === 0
          ? 0.0
          : parseFloat(calculateAverage(avg_analytes_list)).toFixed(1);

      // console.log(tempWaterData);
      setWaterQualityData(tempWaterData);
    }
  }, [analyte, waterSource]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseWaterSource = () => {
    setOpenWaterSource(false);
  };

  const handleOpenWaterSource = () => {
    setOpenWaterSource(true);
  };

  async function generateImageFromComponent(component) {
    const canvas = await html2canvas(document.getElementById(component));
    return canvas.toDataURL("image/png");
  }

  // Function to convert image path to data URL
  function getImageDataFromPath(path) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0, img.width, img.height);
        resolve(canvas.toDataURL("image/png"));
      };
      img.onerror = function () {
        reject(new Error("Failed to load image at path: " + path));
      };
      img.src = path;
    });
  }

  // const generateGaugeImage = (value) => {
  //   return new Promise((resolve, reject) => {
  //     const canvas = document.createElement("canvas");
  //     canvas.width = 200;
  //     canvas.height = 200;

  //     const ctx = canvas.getContext("2d");

  //     const config = {
  //       type: "gauge",
  //       data: {
  //         datasets: [
  //           {
  //             data: [25, 50, 25],
  //             value: value,
  //             backgroundColor: ["red", "yellow", "green"],
  //             borderWidth: 2,
  //           },
  //         ],
  //       },
  //       options: {
  //         responsive: true,
  //         plugins: {
  //           legend: { display: false },
  //           tooltip: { enabled: false },
  //         },
  //         title: {
  //           display: true,
  //           text: "Gauge Chart",
  //         },
  //         layout: {
  //           padding: {
  //             bottom: 30,
  //           },
  //         },
  //         needle: {
  //           radiusPercentage: 4,
  //           widthPercentage: 3.2,
  //           lengthPercentage: 80,
  //           color: "rgba(0, 0, 0, 1)",
  //         },
  //         valueLabel: {
  //           formatter: (value) => `${value} mg/l`,
  //         },
  //       },
  //     };

  //     new Chart(ctx, config);
  //     resolve(canvas.toDataURL("image/png"));
  //   });
  // };

  async function handleFetchPDFBtn() {
    // console.log(summaryObject);
    // console.log(breadCrumbsList);
    // console.log(mapGlobal.getZoom());

    try {
      const pdf = new jsPDF();
      pdf.setFontSize(16);
      let currentYValue = 5;
      pdf.setFont("helvetica", "bold");

      const imagePath = require("assets/images/logos/bluegaplogo.png"); // Replace with the actual path
      const imgData = await getImageDataFromPath(imagePath);
      const mapPath = require(`assets/images/maps/${summaryObject.id}.png`); // Replace with the actual path
      const mapData = await getImageDataFromPath(mapPath);

      // Add the image to the PDF
      pdf.addImage(imgData, "PNG", 10, currentYValue, 60, 20); // Adjust position and size as needed
      currentYValue += 25;
      const borderWidth = 1; // Adjust the border width as needed

      // Draw a rectangle as a border with black color
      pdf.setDrawColor(0); // Set the border color to black
      pdf.setLineWidth(borderWidth); // Set the border width
      pdf.rect(120 - borderWidth, 10 - borderWidth, 80 + 2 * borderWidth, 80 + 2 * borderWidth);
      pdf.addImage(mapData, "PNG", 120, 10, 80, 80);
      pdf.text(`BlueGAP ${geoType} Report`, 15, currentYValue);
      pdf.setFont("helvetica", "normal");
      currentYValue += 10;

      pdf.setFontSize(12);
      pdf.setFont("helvetica", "bold");
      pdf.text(`County: `, 15, currentYValue);
      pdf.setFont("helvetica", "normal");
      pdf.text(
        `${
          geoType == "Zip Code"
            ? breadCrumbsList[1]
              ? breadCrumbsList[1].summaryObject.name
              : summaryObject.name
            : summaryObject.name
        }`,
        34,
        currentYValue
      );
      currentYValue += 10;
      if (geoType == "Zip Code") {
        pdf.setFont("helvetica", "bold");
        pdf.text(`Zip Code: `, 15, currentYValue);
        pdf.setFont("helvetica", "normal");
        pdf
          .text(`${summaryObject.name}, ${summaryObject.po_name}`, 35, currentYValue)
          .setFont("helvetica", "normal");
        currentYValue += 10;
      }

      pdf.setFont("helvetica", "bold");
      pdf.text(`Data Availability Score: `, 15, currentYValue);
      currentYValue += 5;
      const imageDataUrl = await generateImageFromComponent("gauge-chart1");
      pdf.addImage(imageDataUrl, "PNG", 15, currentYValue, 70, 35);
      currentYValue += geoType == "Zip Code" ? 45 : 55;
      pdf.setFont("helvetica", "normal");
      let longText =
        "What is a Data Availability Score? Your data score is a measure of how much publicly accessible state and federal level data for the occurrence of nitrate in water resources in your area is available. A high data score means that more data is publicly available, while a low data score means that relative to other areas in your region, there is less publicly available water quality data. To generate your score";

      if (summaryObject.chartValue >= 75) {
        longText =
          "Good news! More data is publicly available in this region than in most locations. Generally, this means there is more water quality monitoring going on in your area, which will help local leaders make more informed decisions about protection of water resources that are vital for drinking water and recreational activities.";
      } else if (summaryObject.chartValue > 25 && summaryObject.chartValue < 75) {
        longText =
          "Average data is publicly available in this region than in most locations. Generally, this means there is average water quality monitoring going on in your area, which will help local leaders make more informed decisions about protection of water resources that are vital for drinking water and recreational activities.";
      } else {
        longText =
          "Bad news! Less data is publicly available in this region than in most locations. Generally, this means there is very poor water quality monitoring going on in your area, which will prevent local leaders to make more informed decisions about protection of water resources that are vital for drinking water and recreational activities.";
      }

      // Define the maximum width for text to prevent it from overflowing
      let maxWidth = 180; // Adjust the width based on your page layout

      // Split text into lines
      let splitText = pdf.splitTextToSize(longText, maxWidth);

      // Add split text to PDF and adjust Y position accordingly
      splitText.forEach(function (line) {
        pdf.text(line, 15, currentYValue);
        currentYValue += 7; // Adjust line height as needed
      });
      currentYValue += 2;
      pdf.setFont("helvetica", "bold");
      pdf.text(`What is Data Availability Score?`, 15, currentYValue);
      currentYValue += 7;
      pdf.setFont("helvetica", "normal");
      let longText2 =
        "Your data score is a measure of how much publicly accessible state and federal level data for the occurrence of nitrate in water resources in your area is available. A high data score means that more data is publicly available, while a low data score means that relative to other areas in your region, there is less publicly available water quality data. A score is considered low if there are fewer than 10,000 records; average if there are between 10,000 and 100,000 records; and high if there are more than 100,000 records.";

      // Define the maximum width for text to prevent it from overflowing
      let maxWidth2 = 180; // Adjust the width based on your page layout

      // Split text into lines
      let splitText2 = pdf.splitTextToSize(longText2, maxWidth2);

      // Add split text to PDF and adjust Y position accordingly
      splitText2.forEach(function (line) {
        pdf.text(line, 15, currentYValue);
        currentYValue += 7; // Adjust line height as needed
      });
      currentYValue += 5;
      pdf.setFont("helvetica", "bold");
      pdf.text(`Data Summary:`, 15, currentYValue);
      currentYValue += 5;

      const headers = ["Data Source", "Sites", "Record", "Date Range"];
      let rows = [];
      if (parsedJSONData.state == "IA") {
        rows = [
          [
            "Recreational Water (Stream)",
            summaryObject.waterCommunity.toLocaleString(),
            summaryObject.waterCommunityObsCount.toLocaleString(),
            summaryObject.dateRangeWC,
          ],
          [
            "Drinking Water (Public)",
            summaryObject.drinkingWater.toLocaleString(),
            summaryObject.drinkingWaterObsCount.toLocaleString(),
            summaryObject.dateRangeDW,
          ],
          [
            "Drinking Water (Private)",
            summaryObject.groundWater.toLocaleString(),
            summaryObject.groundWaterObsCount.toLocaleString(),
            summaryObject.dateRangeGW,
          ],
        ];
      } else if (parsedJSONData.state == "FL") {
        rows = [
          [
            "Recreational Water (Stream)",
            summaryObject.waterCommunity.toLocaleString(),
            summaryObject.waterCommunityObsCount.toLocaleString(),
            summaryObject.dateRangeWC,
          ],
          [
            "Recreational Water (Ocean)",
            summaryObject.recreationalWater.toLocaleString(),
            summaryObject.recreationalWaterObsCount.toLocaleString(),
            summaryObject.dateRangeRW,
          ],
          [
            "Drinking Water (Public)",
            summaryObject.drinkingWater.toLocaleString(),
            summaryObject.drinkingWaterObsCount.toLocaleString(),
            summaryObject.dateRangeDW,
          ],
          [
            "Drinking Water (Private)",
            summaryObject.groundWater.toLocaleString(),
            summaryObject.groundWaterObsCount.toLocaleString(),
            summaryObject.dateRangeGW,
          ],
        ];
      } else if (parsedJSONData.state == "VI") {
        rows = [
          [
            "Recreational Water (Ocean)",
            summaryObject.recreationalWater.toLocaleString(),
            summaryObject.recreationalWaterObsCount.toLocaleString(),
            summaryObject.dateRangeRW,
          ],
        ];
      }
      const columnStyles = {
        1: { align: "right" }, // Align the second column (index 1) to the right
        2: { align: "right" }, // Align the third column (index 2) to the right
      };

      pdf.autoTable({
        startY: currentYValue,
        head: [headers],
        body: rows,
        columnStyles: columnStyles,
      });
      parsedJSONData.state == "IA" || parsedJSONData.state == "FL"
        ? (currentYValue += 45)
        : (currentYValue += 25);

      pdf.setFont("helvetica", "bold");
      pdf.text(`About this data:`, 15, currentYValue);
      currentYValue += 7;
      pdf.setFont("helvetica", "normal");
      let longText3 =
        "Recreational water is…., with sources X, Y, and Z. Drinking water (public) is…., with sources X, Y, Z. Drinking water (private) is…. with sources X, Y, Z.";

      // Define the maximum width for text to prevent it from overflowing
      let maxWidth3 = 95;

      // Split text into lines
      let splitText3 = pdf.splitTextToSize(longText3, maxWidth3);

      // Add split text to PDF and adjust Y position accordingly
      splitText3.forEach(function (line) {
        pdf.text(line, 15, currentYValue);
        currentYValue += 7; // Adjust line height as needed
      });
      currentYValue += 5;

      pdf.addPage();

      currentYValue = 15;

      if (geoType == "Zip Code" || geoType == "County") {
        pdf.setFont("helvetica", "bold");
        pdf.text(`Water Quality Summary:`, 15, currentYValue);
        currentYValue += 5;
        const headers2 = ["Data Source - Nitrate (mg/l)", "Min", "Max", "Avg"];
        let rows2 = [];

        if (parsedJSONData.state == "IA") {
          rows2 = [
            [
              "Recreational Water (Stream)",
              summaryObject.waterCommunityQualitySummaryMin,
              summaryObject.waterCommunityQualitySummaryMax,
              summaryObject.waterCommunityQualitySummaryAvg,
            ],
            [
              "Drinking Water (Public)",
              summaryObject.drinkingWaterQualitySummaryMin,
              summaryObject.drinkingWaterQualitySummaryMax,
              summaryObject.drinkingWaterQualitySummaryAvg,
            ],
            [
              "Drinking Water (Private)",
              summaryObject.groundWaterQualitySummaryMin,
              summaryObject.groundWaterQualitySummaryMax,
              summaryObject.groundWaterQualitySummaryAvg,
            ],
          ];
        } else if (parsedJSONData.state == "FL") {
          rows2 = [
            [
              "Recreational Water (Stream)",
              summaryObject.waterCommunityQualitySummaryMin,
              summaryObject.waterCommunityQualitySummaryMax,
              summaryObject.waterCommunityQualitySummaryAvg,
            ],
            [
              "Recreational Water (Ocean)",
              summaryObject.recreationalWaterQualitySummaryMin,
              summaryObject.recreationalWaterQualitySummaryMax,
              summaryObject.recreationalWaterQualitySummaryAvg,
            ],
            [
              "Drinking Water (Public)",
              summaryObject.drinkingWaterQualitySummaryMin,
              summaryObject.drinkingWaterQualitySummaryMax,
              summaryObject.drinkingWaterQualitySummaryAvg,
            ],
            [
              "Drinking Water (Private)",
              summaryObject.groundWaterQualitySummaryMin,
              summaryObject.groundWaterQualitySummaryMax,
              summaryObject.groundWaterQualitySummaryAvg,
            ],
          ];
        } else if (parsedJSONData.state == "VI") {
          rows2 = [
            [
              "Recreational Water (Ocean)",
              summaryObject.recreationalWaterQualitySummaryMin,
              summaryObject.recreationalWaterQualitySummaryMax,
              summaryObject.recreationalWaterQualitySummaryAvg,
            ],
          ];
        }

        const columnStyles2 = {
          1: { align: "right" }, // Align the second column (index 1) to the right
          2: { align: "right" }, // Align the third column (index 2) to the right
          3: { align: "right" }, // Align the third column (index 2) to the right
        };

        pdf.autoTable({
          startY: currentYValue,
          head: [headers2],
          body: rows2,
          columnStyles: columnStyles2,
        });
        parsedJSONData.state == "IA" || parsedJSONData.state == "FL"
          ? (currentYValue += 45)
          : (currentYValue += 25);
      }
      let tempY = currentYValue;
      try {
        if (parsedJSONData.state == "IA" || parsedJSONData.state == "FL") {
          const imageDataUrl2 = await generateImageFromComponent("gauge-chart2");
          pdf.setFont("helvetica", "normal");
          pdf.text(`Drinking Water (private)`, 15, tempY - 3);
          pdf.addImage(imageDataUrl2, "PNG", 15, tempY, 70, 35);
          tempY += 40;
          pdf.setFont("helvetica", "normal");
          let longText4 = `The average nitrate concentration is ${summaryObject?.groundWaterQualitySummaryAvg} mg/l, which is around the ${summaryObject?.groundWaterQualitySummaryPercentile}th percetile for all ground water sites in ${parsedJSONData.state}. This means that around ${summaryObject?.groundWaterQualitySummaryPercentile}% of all other sites have nitrate concentration less than those here. See what this means below.`;

          // Define the maximum width for text to prevent it from overflowing
          let maxWidth4 = 85;

          // Split text into lines
          let splitText4 = pdf.splitTextToSize(longText4, maxWidth4);

          // Add split text to PDF and adjust Y position accordingly
          splitText4.forEach(function (line) {
            pdf.text(line, 15, tempY);
            tempY += 7; // Adjust line height as needed
          });
        }

        //
        const imageDataUrl3 = await generateImageFromComponent("gauge-chart3");
        pdf.setFont("helvetica", "normal");
        pdf.text(
          `Recreational Water (stream)`,
          parsedJSONData.state == "IA" || parsedJSONData.state == "FL" ? 115 : 15,
          currentYValue - 3
        );
        pdf.addImage(
          imageDataUrl3,
          "PNG",
          parsedJSONData.state == "IA" || parsedJSONData.state == "FL" ? 115 : 15,
          currentYValue,
          70,
          35
        );
        currentYValue += 40;
        pdf.setFont("helvetica", "normal");
        let longText5 = `The average nitrate concentration is ${summaryObject?.waterCommunityQualitySummaryAvg} mg/l, which is around the ${summaryObject?.waterCommunityQualitySummaryPercentile}th percetile for all recreational water sites in ${parsedJSONData.state}. This means that around ${summaryObject?.waterCommunityQualitySummaryPercentile}% of all other sites have nitrate concentration less than those here. See what this means below.`;

        // Define the maximum width for text to prevent it from overflowing
        let maxWidth5 = 85;

        // Split text into lines
        let splitText5 = pdf.splitTextToSize(longText5, maxWidth5);

        // Add split text to PDF and adjust Y position accordingly
        splitText5.forEach(function (line) {
          pdf.text(
            line,
            parsedJSONData.state == "IA" || parsedJSONData.state == "FL" ? 115 : 15,
            currentYValue
          );
          currentYValue += 7; // Adjust line height as needed
        });
      } catch (error) {
        console.error("Error generating gauge image:", error);
      }
      currentYValue = tempY >= currentYValue ? tempY : currentYValue;
      currentYValue += 5;
      let tempY2 = currentYValue;
      try {
        if (parsedJSONData.state != "VI") {
          const imageDataUrl2 = await generateImageFromComponent("gauge-chart4");
          pdf.setFont("helvetica", "normal");
          pdf.text(`Drinking Water (public)`, 15, tempY2 - 3);
          pdf.addImage(imageDataUrl2, "PNG", 15, tempY2, 70, 35);
          tempY2 += 40;
          pdf.setFont("helvetica", "normal");
          let longText4 = `The average nitrate concentration is ${summaryObject?.drinkingWaterQualitySummaryAvg} mg/l, which is around the ${summaryObject?.drinkingWaterQualitySummaryPercentile}th percetile for all drinking water sites in ${parsedJSONData.state}. This means that around ${summaryObject?.drinkingWaterQualitySummaryPercentile}% of all other sites have nitrate concentration less than those here. See what this means below.`;

          // Define the maximum width for text to prevent it from overflowing
          let maxWidth4 = 85;

          // Split text into lines
          let splitText4 = pdf.splitTextToSize(longText4, maxWidth4);

          // Add split text to PDF and adjust Y position accordingly
          splitText4.forEach(function (line) {
            pdf.text(line, 15, tempY2);
            tempY2 += 7; // Adjust line height as needed
          });
        }

        //
        if (parsedJSONData.state != "IA") {
          const imageDataUrl3 = await generateImageFromComponent("gauge-chart5");
          pdf.setFont("helvetica", "normal");
          pdf.text(
            `Recreational Water (ocean)`,
            parsedJSONData.state == "IA" || parsedJSONData.state == "FL" ? 115 : 15,
            currentYValue - 3
          );
          pdf.addImage(
            imageDataUrl3,
            "PNG",
            parsedJSONData.state == "IA" || parsedJSONData.state == "FL" ? 115 : 15,
            currentYValue,
            70,
            35
          );
          currentYValue += 40;
          pdf.setFont("helvetica", "normal");
          let longText5 = `The average nitrate concentration is ${summaryObject?.recreationalWaterQualitySummaryAvg} mg/l, which is around the ${summaryObject?.recreationalWaterQualitySummaryPercentile}th percetile for all ocean water sites in ${parsedJSONData.state}. This means that around ${summaryObject?.recreationalWaterQualitySummaryPercentile}% of all other sites have nitrate concentration less than those here. See what this means below.`;

          // Define the maximum width for text to prevent it from overflowing
          let maxWidth5 = 85;

          // Split text into lines
          let splitText5 = pdf.splitTextToSize(longText5, maxWidth5);

          // Add split text to PDF and adjust Y position accordingly
          splitText5.forEach(function (line) {
            pdf.text(
              line,
              parsedJSONData.state == "IA" || parsedJSONData.state == "FL" ? 115 : 15,
              currentYValue
            );
            currentYValue += 7; // Adjust line height as needed
          });
        }
      } catch (error) {
        console.error("Error generating gauge image:", error);
      }
      currentYValue = tempY2 >= currentYValue ? tempY2 : currentYValue;
      currentYValue += 5;
      pdf.setFont("helvetica", "bold");
      pdf.text(`What Do These Water Quality Data Mean?`, 15, currentYValue);
      currentYValue += 7;
      pdf.setFont("helvetica", "normal");
      let waterQualityText =
        "Drinking Water: Nitrate is regulated in drinking water by the US EPA at 10 mg-N/L. On average, the public drinking water systems in your region provide water that is (above/near/below/well below) this safety standard. Consumers relying on private wells in your area typically have….(X).  Nitrate is regulated in drinking water for its risks to public health. Infants below the age of six months who drink water containing nitrate in excess of 10 mg-N/L could become seriously ill and, if untreated, may die. Symptoms include shortness of breath and blue-baby syndrome. More recent studies have suggested that nitrate in drinking water may be linked to other.";

      // Define the maximum width for text to prevent it from overflowing
      let maxWidthWQ = 180; // Adjust the width based on your page layout

      // Split text into lines
      let splitTextWQ = pdf.splitTextToSize(waterQualityText, maxWidthWQ);

      // Add split text to PDF and adjust Y position accordingly
      splitTextWQ.forEach(function (line) {
        pdf.text(line, 15, currentYValue);
        currentYValue += 7; // Adjust line height as needed
      });

      pdf.addPage();
      currentYValue = 15;
      pdf.setFont("helvetica", "bold");
      pdf.text(`Demographics Summary:`, 15, currentYValue);
      currentYValue += 5;
      const headers2 = ["Socioeconomic Indicators", "Value"];
      const rows2 = [
        ["Over Age 64", demographicObject.over64.toLocaleString()],
        ["Under Age 5", demographicObject.under5.toLocaleString()],
        ["Umemployment Rate", demographicObject.unemployed + " %"],
        ["Low Income", demographicObject.lowincpct + " %"],
        ["People of Color", demographicObject.peopcolorp + " %"],
        ["Less than High School Education", demographicObject.lesshspct + " %"],
        ["Limited English Speaking", demographicObject.lingisopct + " %"],
      ];

      pdf.autoTable({
        startY: currentYValue,
        head: [headers2],
        body: rows2,
      });

      // const apiKey = "AIzaSyAqJnFXGfEMyxIlezvCWTYJS6k7doTUjRU";

      // const fillColor = "0xff0000"; // Replace with the desired fill color in hexadecimal format
      // const mapImageUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${
      //   demographicObject.lat
      // },${
      //   demographicObject.lng
      // }&zoom=${mapGlobal.getZoom()}&size=400x400&path=fillcolor:${fillColor}|enc:${
      //   demographicObject.lat
      // },${demographicObject.lng}&key=${apiKey}`;
      // const borderWidth = 1; // Adjust the border width as needed

      // // Draw a rectangle as a border with black color
      // pdf.setDrawColor(0); // Set the border color to black
      // pdf.setLineWidth(borderWidth); // Set the border width
      // pdf.rect(120 - borderWidth, 10 - borderWidth, 80 + 2 * borderWidth, 80 + 2 * borderWidth);
      // pdf.addImage(mapData, "PNG", 120, 10, 80, 80);
      // Now you can use mapImageUrl for displaying the map
      // console.log("Map Image URL:", mapImageUrl);
      pdf.save("report.pdf");
    } catch (error) {
      showNotification("An error occurred while generating PDF. Please try again later.", "error");
    }
  }

  function handleSnapshotClickBtn() {
    // console.log(geoType);
    // console.log(breadCrumbsList);
    // console.log(dataSourceState);
    if (geoType == "Zip Code") {
      let tempURL = `${window.location.origin}${
        window.location.pathname
      }?geotype=${encodeURIComponent(geoType)}&value=${breadCrumbsList[0].id}&url=${
        parsedJSONData
          ? encodeURIComponent(parsedJSONData.boundaryURL)
          : encodeURIComponent(searchParams.get("url"))
      }&lat=${parsedJSONData ? parsedJSONData.latitude : searchParams.get("lat")}&lng=${
        parsedJSONData ? parsedJSONData.longitude : searchParams.get("lng")
      }&state=${
        parsedJSONData ? parsedJSONData.state : searchParams.get("state")
      }&bounds=${encodeURIComponent(boundState)}&dataSource=${encodeURIComponent(
        JSON.stringify(dataSourceState)
      )}&zoom=${
        parsedJSONData ? parsedJSONData.zoomValue : searchParams.get("zoom")
      }&zip=true&zip_code=${breadCrumbsList[1].id}&breadcrumb=${encodeURIComponent(
        JSON.stringify(breadCrumbsList)
      )}`;
      console.log(tempURL);
      navigator.clipboard.writeText(tempURL);
    } else {
      let tempUrl = `${window.location.origin}${
        window.location.pathname
      }?geotype=${geoType}&value=${breadCrumbsList[breadCrumbsList.length - 1].id}&url=${
        parsedJSONData
          ? encodeURIComponent(parsedJSONData.boundaryURL)
          : encodeURIComponent(searchParams.get("url"))
      }&lat=${parsedJSONData ? parsedJSONData.latitude : searchParams.get("lat")}&lng=${
        parsedJSONData ? parsedJSONData.longitude : searchParams.get("lng")
      }&zoom=${parsedJSONData ? parsedJSONData.zoomValue : searchParams.get("zoom")}&state=${
        parsedJSONData ? parsedJSONData.state : searchParams.get("state")
      }&bounds=${encodeURIComponent(boundState)}&dataSource=${encodeURIComponent(
        JSON.stringify(dataSourceState)
      )}&dataSourceLoaded=${encodeURIComponent(JSON.stringify(dataSourceLoaded))}`;
      console.log(tempUrl, encodeURIComponent(JSON.stringify(dataSourceLoaded)));
      navigator.clipboard.writeText(tempUrl);
    }

    showNotification("Snapshot URL has been copied to clipboard.", "info");
  }

  return (
    <Card
      style={{
        position: "absolute",
        top: 10,
        right: 10,
        width: "20%",
        maxWidth: "400px",
        minWidth: "360px",
        contain: "content",
        padding: "4px",
      }}
    >
      <MDTypography
        style={{
          fontFamily: "PFDinTextCompProMedium",
          marginLeft: "10px",
          color: "black",
          fontWeight: "500",
          fontSize: "23px",
        }}
      >
        {geoType ? geoType : "Census"} Summary
      </MDTypography>

      {breadCrumbsList.length > 0 ? (
        <div
          style={
            breadCrumbsList.length === 3
              ? { paddingLeft: 10, display: "flex", gap: "5px" }
              : {
                  position: "absolute",
                  color: "black",
                  top: "0%",
                  right: "5px",
                  zIndex: "1",
                  textAlign: "right",
                }
          }
        >
          {breadCrumbsList?.map((data, index) => (
            <div key={index}>
              {/* <p onClick={() => { handleBreadcrumbClick(data) }}
                                style={{ fontSize: "0.9rem", padding: "5px 5px 5px 5px" }}>{data.name}</p> */}
              <Chip
                label={data.name}
                onClick={() => {
                  handleBreadcrumbClick(data);
                }}
                onDelete={() => {
                  handleBreadcrumbClick(data);
                }}
                size="small"
                sx={{
                  background: "#E8E9EB",
                  fontFamily: "PFDinTextCompProMedium",
                  fontSize: "17px",
                }}
              />
            </div>
          ))}
        </div>
      ) : (
        <MDTypography
          style={{
            position: "absolute",
            color: "black",
            top: "0%",
            right: "3%",
            backgroundColor: "rgb(210, 221, 228)",
            fontFamily: "PFDinTextCompProMedium",
            fontSize: "20px",
          }}
        >
          <span
            className={classes.dataTitle}
            style={{
              fontSize: "1.1rem",
              padding: "5px 5px 5px 5px",
            }}
          >
            {summaryObject?.name}
          </span>
        </MDTypography>
      )}

      {/* <MDTypography className={classes.demographicText}>The trend shows exponential increase in record numbers.</MDTypography> */}

      <>
        <FormControl sx={{ ml: 2 }} component="fieldset" variant="standard">
          <FormGroup>
            <Grid container spacing={2}>
              {geoType == "County" ? (
                demographicObject ? (
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={state.zip_code}
                          onChange={handleChangeCheckBox}
                          name="zip_code"
                          disabled={sessionStorage.getItem("old") === "yes"}
                        />
                      }
                      label={
                        <p
                          style={{
                            fontFamily: "PFDinTextCompProMedium",
                            fontSize: "1.1rem",
                            fontWeight: "normal",
                            color: "black",
                          }}
                        >
                          Zip Code
                        </p>
                      }
                    />
                  </Grid>
                ) : null
              ) : null}

              {geoType == "Zip Code" ? (
                demographicObject ? (
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={state.census}
                          onChange={handleChangeCheckBox}
                          name="census"
                          disabled={sessionStorage.getItem("old") === "yes"}
                        />
                      }
                      label={
                        <p
                          style={{
                            fontFamily: "PFDinTextCompProMedium",
                            fontSize: "1.1rem",
                            fontWeight: "normal",
                            color: "black",
                          }}
                        >
                          Census Tracts
                        </p>
                      }
                    />
                  </Grid>
                ) : null
              ) : null}
            </Grid>
          </FormGroup>
        </FormControl>
        <Box sx={{ width: "100%", marginTop: geoType == "Zip Code" ? "0%" : "0%" }}>
          <Box style={{ paddingLeft: "7px", paddingRight: "10px" }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              {demographicObject ? (
                <Tab
                  label="Demographics"
                  style={{
                    margin: "0px 5px 0px 0px",
                    fontFamily: "PFDinTextCompProMedium",
                    fontSize: "18px",
                    backgroundColor: "#e5e5ea",
                  }}
                  {...a11yProps(0)}
                />
              ) : null}

              {/* {(geoType == "County" || geoType == "Zip Code") && (
                  <Tab
                    label="Data Status"
                    style={{
                      margin: "0px 0px 0px 0px",
                      fontFamily: "PFDinTextCompProMedium",
                      fontSize: "18px",
                      backgroundColor: "#e5e5ea",
                    }}
                    {...a11yProps(1)}
                  />
                )} */}
              <Tab
                label="Data Status"
                style={{
                  margin: "0px 0px 0px 0px",
                  fontFamily: "PFDinTextCompProMedium",
                  fontSize: "18px",
                  backgroundColor: "#e5e5ea",
                }}
                {...a11yProps(1)}
                disabled={geoType == "Census Tract"}
              />

              {geoType == "Zip Code" && (
                <Tab
                  style={{
                    margin: "0px 5px 0px 5px",
                    fontFamily: "PFDinTextCompProMedium",
                    fontSize: "18px",
                    backgroundColor: "#e5e5ea",
                  }}
                  label="Water Quality"
                  {...a11yProps(2)}
                />
              )}
              {/* <Tab
              label="Demographic Summary"
              style={{
                margin: "0px 5px 0px 5px",
                fontFamily: "PFDinTextCompProMedium",
                fontSize: "18px",
              }}
              {...a11yProps(2)}
            /> */}
            </Tabs>
          </Box>
          {demographicObject ? (
            <TabPanel style={{ padding: "7px 7px 0px 7px" }} value={value} index={0}>
              <div className={classes.tableResponsive}>
                <Table className={classes.table}>
                  <TableBody>
                    <TableRow className={classes.tableBodyRow}>
                      <TableCell className={classes.tableSummaryCell}>
                        <span style={{ color: `black`, fontWeight: `bold` }}>
                          Socioeconomic Indicators
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCell}
                        style={{ width: "60px", textAlign: "right" }}
                      >
                        <span style={{ color: `black`, fontWeight: `bold` }}>Value</span>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  <TableBody>
                    <TableRow className={classes.tableBodyRow}>
                      <TableCell className={classes.tableSummaryCell}>
                        <span className={classes.dataTitleDemographic}>Over Age 64</span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCell}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitleDemographic}>
                          {demographicObject?.over64.toLocaleString()}
                        </span>
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableBodyRow}>
                      <TableCell className={classes.tableSummaryCell}>
                        <span className={classes.dataTitleDemographic}>Under Age 5</span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCell}
                        style={{ textAlign: "right" }}
                      >
                        <span
                          style={{
                            color: `black`,
                            fontSize: "14px",
                          }}
                          className={classes.dataTitle}
                        >
                          {demographicObject?.under5.toLocaleString()}
                        </span>
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableBodyRow}>
                      <TableCell className={classes.tableSummaryCell}>
                        <span className={classes.dataTitleDemographic}>Unemployment Rate</span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCell}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitleDemographic}>
                          {demographicObject?.unemployed}%
                        </span>
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableBodyRow}>
                      <TableCell className={classes.tableSummaryCell}>
                        <span className={classes.dataTitleDemographic}>Low Income</span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCell}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitleDemographic}>
                          {demographicObject?.lowincpct}%
                        </span>
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableBodyRow}>
                      <TableCell className={classes.tableSummaryCell}>
                        <span className={classes.dataTitleDemographic}>People of Color</span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCell}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitleDemographic}>
                          {demographicObject?.peopcolorp}%
                        </span>
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableBodyRow}>
                      <TableCell className={classes.tableSummaryCell}>
                        <span className={classes.dataTitleDemographic}>
                          Less than High School Education
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCell}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitleDemographic}>
                          {demographicObject?.lesshspct}%
                        </span>
                      </TableCell>
                    </TableRow>
                    <TableRow className={classes.tableBodyRow}>
                      <TableCell className={classes.tableSummaryCell}>
                        <span className={classes.dataTitleDemographic}>
                          Limited English Speaking
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCell}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitleDemographic}>
                          {demographicObject?.lingisopct}%
                        </span>
                      </TableCell>
                    </TableRow>
                    {breadCrumbsList.length == 3 ? (
                      <TableRow className={classes.tableBodyRow}>
                        <TableCell className={classes.tableSummaryCell}>
                          <span className={classes.dataTitleDemographic}>Demographic Index</span>
                        </TableCell>
                        <TableCell
                          className={classes.tableSummaryCell}
                          style={{ textAlign: "right" }}
                        >
                          <span className={classes.dataTitleDemographic}>
                            {demographicObject?.demoindex}%
                          </span>
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </Table>
              </div>
            </TabPanel>
          ) : null}

          <TabPanel value={value} index={demographicObject ? 1 : 0}>
            <>
              <MDTypography
                style={{
                  fontFamily: "PFDinTextCompProMedium",
                  marginLeft: "10px",
                  color: "black",
                  fontWeight: "500",
                  fontSize: "15px",
                  position: "absolute",
                  top: demographicObject ? "30%" : "24%",
                  left: "0%",
                  zIndex: 1,
                  width: "110px",
                }}
              >
                Data Availability Score
              </MDTypography>
              {/* <MDTypography className={classes.statusText}>
                  {getDeltaText(summaryObject?.chartValue)}
                </MDTypography>
                <Plot
                  config={{ displayModeBar: false }}
                  className="my-plot"
                  data={[
                    {
                      type: "indicator",
                      mode: "delta+number+gauge",
                      value: summaryObject?.chartValue,
                      delta: {
                        suffix: getDeltaText(summaryObject?.chartValue),
                        reference: 25,
                        text: "Poor",
                      },
                      gauge: {
                        axis: { range: [null, 100], tickwidth: 1, tickcolor: "darkblue" },
                        bar: { color: "#B1A9A9" },
                        bgcolor: "green",
                        borderwidth: 2,
                        bordercolor: "gray",
                        steps: [
                          { range: [0, 25], color: "#FA5B3D" },
                          { range: [25, 75], color: "#f4ff1f" },
                        ],
                      },
                    },
                  ]}
                  layout={{
                    width: 343,
                    height: 150,
                    margin: { t: 25, r: 25, l: 25, b: 25 },
                    paper_bgcolor: "white",
                    font: { color: "darkblue", family: "Arial" },
                  }}
                ></Plot> */}
              <GaugeChart
                id="gauge-chart1"
                colors={["#FA5B3D", "yellow", "green"]}
                percent={summaryObject?.chartValue / 100}
                textColor="black"
                animate={false}
                arcsLength={[25, 50, 25]}
              />

              {summaryObject?.drinkingWaterQualitySummaryPercentile ? (
                <div style={{ zIndex: -99999, position: "absolute", top: "-100%" }}>
                  <GaugeChart
                    id="gauge-chart4"
                    colors={["#FA5B3D", "yellow", "green"]}
                    percent={summaryObject?.drinkingWaterQualitySummaryPercentile / 100}
                    textColor="black"
                    animate={false}
                    arcsLength={[25, 50, 25]}
                  />
                </div>
              ) : null}

              {summaryObject?.waterCommunityQualitySummaryPercentile ? (
                <div style={{ zIndex: -99999, position: "absolute", top: "-100%" }}>
                  <GaugeChart
                    id="gauge-chart3"
                    colors={["#FA5B3D", "yellow", "green"]}
                    percent={summaryObject?.waterCommunityQualitySummaryPercentile / 100}
                    textColor="black"
                    animate={false}
                    arcsLength={[25, 50, 25]}
                  />
                </div>
              ) : null}

              {summaryObject?.groundWaterQualitySummaryPercentile ? (
                <div style={{ zIndex: -99999, position: "absolute", top: "-100%" }}>
                  <GaugeChart
                    id="gauge-chart2"
                    colors={["#FA5B3D", "yellow", "green"]}
                    percent={summaryObject?.groundWaterQualitySummaryPercentile / 100}
                    textColor="black"
                    animate={false}
                    arcsLength={[25, 50, 25]}
                  />
                </div>
              ) : null}

              {summaryObject?.recreationalWaterQualitySummaryPercentile ? (
                <div style={{ zIndex: -99999, position: "absolute", top: "-100%" }}>
                  <GaugeChart
                    id="gauge-chart5"
                    colors={["#FA5B3D", "yellow", "green"]}
                    percent={summaryObject?.recreationalWaterQualitySummaryPercentile / 100}
                    textColor="black"
                    animate={false}
                    arcsLength={[25, 50, 25]}
                  />
                </div>
              ) : null}
            </>
            <div className={classes.tableResponsive}>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow className={classes.tableBodyRow}>
                    <TableCell className={classes.tableSummaryCell}>
                      <span style={{ color: `blue` }} className={classes.dataTitle}>
                        Data Source
                      </span>
                    </TableCell>
                    <TableCell className={classes.tableSummaryCell} style={{ textAlign: "right" }}>
                      <span style={{ color: `blue` }} className={classes.dataTitle}>
                        Sites
                      </span>
                    </TableCell>
                    <TableCell className={classes.tableSummaryCell} style={{ textAlign: "right" }}>
                      <span style={{ color: `blue` }} className={classes.dataTitle}>
                        Record &nbsp;
                      </span>
                    </TableCell>
                    <TableCell className={classes.tableSummaryCell} style={{ textAlign: "right" }}>
                      <span style={{ color: `blue` }} className={classes.dataTitle}>
                        Date Range &nbsp;
                      </span>
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableBody>
                  {(parsedJSONData
                    ? parsedJSONData.state == "IA"
                    : searchParams.get("state") == "IA") ||
                  (parsedJSONData
                    ? parsedJSONData.state == "FL"
                    : searchParams.get("state") == "FL") ? (
                    <TableRow className={classes.tableBodyRow}>
                      <TableCell className={classes.tableSummaryCell}>
                        <span
                          style={{
                            color: `black`,
                            fontFamily: "PFDinTextCompProMedium",
                            fontSize: "16px",
                          }}
                          className={classes.dataTitle}
                        >
                          Recreational (stream)
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCell}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitle}>
                          {summaryObject?.waterCommunity.toLocaleString()}
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCell}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitle}>
                          {summaryObject?.waterCommunityObsCount.toLocaleString()} &nbsp;
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCell}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitle}>
                          {summaryObject?.dateRangeWC} &nbsp;
                        </span>
                        {/* <span className={classes.dataTitle}>2013-2023 &nbsp;</span> */}
                      </TableCell>
                    </TableRow>
                  ) : null}

                  {(parsedJSONData
                    ? parsedJSONData.state == "VI"
                    : searchParams.get("state") == "VI") ||
                  (parsedJSONData
                    ? parsedJSONData.state == "FL"
                    : searchParams.get("state") == "FL") ? (
                    <TableRow className={classes.tableBodyRow}>
                      <TableCell className={classes.tableSummaryCell}>
                        <span
                          style={{
                            color: `black`,
                            fontFamily: "PFDinTextCompProMedium",
                            fontSize: "16px",
                          }}
                          className={classes.dataTitle}
                        >
                          Recreational (ocean)
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCell}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitle}>
                          {summaryObject?.recreationalWater}
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCell}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitle}>
                          {summaryObject?.recreationalWaterObsCount} &nbsp;
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCell}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitle}>
                          {summaryObject?.dateRangeRW} &nbsp;
                        </span>
                        {/* <span className={classes.dataTitle}>2013-2023 &nbsp;</span> */}
                      </TableCell>
                    </TableRow>
                  ) : null}

                  {(parsedJSONData
                    ? parsedJSONData.state == "IA"
                    : searchParams.get("state") == "IA") ||
                  (parsedJSONData
                    ? parsedJSONData.state == "FL"
                    : searchParams.get("state") == "FL") ? (
                    <TableRow className={classes.tableBodyRow}>
                      <TableCell className={classes.tableSummaryCell}>
                        <span
                          style={{
                            color: `black`,
                            fontFamily: "PFDinTextCompProMedium",
                            fontSize: "16px",
                          }}
                          className={classes.dataTitle}
                        >
                          Drinking Water (public)
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCell}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitle}>
                          {summaryObject?.drinkingWater.toLocaleString()}
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCell}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitle}>
                          {summaryObject?.drinkingWaterObsCount.toLocaleString()} &nbsp;
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCell}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitle}>
                          {summaryObject?.dateRangeDW} &nbsp;
                        </span>
                        {/* <span className={classes.dataTitle}>2013-2023 &nbsp;</span> */}
                      </TableCell>
                    </TableRow>
                  ) : null}

                  {(parsedJSONData
                    ? parsedJSONData.state == "IA"
                    : searchParams.get("state") == "IA") ||
                  (parsedJSONData
                    ? parsedJSONData.state == "FL"
                    : searchParams.get("state") == "FL") ? (
                    <TableRow className={classes.tableBodyRow}>
                      <TableCell className={classes.tableSummaryCell}>
                        <span
                          style={{
                            color: `black`,
                            fontFamily: "PFDinTextCompProMedium",
                            fontSize: "16px",
                          }}
                          className={classes.dataTitle}
                        >
                          Drinking Water (private)
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCell}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitle}>{summaryObject?.groundWater}</span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCell}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitle}>
                          {summaryObject?.groundWaterObsCount} &nbsp;
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCell}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitle}>
                          {summaryObject?.dateRangeGW} &nbsp;
                        </span>
                        {/* <span className={classes.dataTitle}>2013-2023 &nbsp;</span> */}
                      </TableCell>
                    </TableRow>
                  ) : null}

                  {/* <TableRow className={classes.tableBodyRow}>
                                    <TableCell className={classes.tableCell}>
                                        <span style={{ color: `red` }} className={classes.dataTitle}>Ground Water</span>
                                    </TableCell>
                                    <TableCell className={classes.tableCell} style={{textAlign:"right"}}>
                                        <span className={classes.dataTitle}>116 &nbsp;</span>
                                    </TableCell>
                                    <TableCell className={classes.tableCell} style={{textAlign:"right"}}>
                                        <span className={classes.dataTitle}>14005 &nbsp;</span>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <span className={classes.dataTitle}>2010-2023 &nbsp;</span>
                                    </TableCell>
                                </TableRow> */}
                </TableBody>
              </Table>
            </div>
          </TabPanel>
          <TabPanel value={value} index={demographicObject ? 2 : 1}>
            {/* <>
                <MDTypography
                  style={{
                    fontFamily: "PFDinTextCompProMedium",
                    marginLeft: "10px",
                    color: "black",
                    fontWeight: "500",
                    fontSize: "15px",
                    position: "absolute",
                    top: "30%",
                    left: "0%",
                    zIndex: 1,
                    width: "80px",
                  }}
                >
                  Water Quality Score
                </MDTypography>
                <GaugeChart
                  id="gauge-chart1"
                  colors={["#FA5B3D", "yellow", "green"]}
                  percent={summaryObject?.chartValue / 100}
                  textColor="black"
                  animate={false}
                  arcsLength={[25,50,25]}
                />
              </> */}
            <div className={classes.tableResponsive}>
              {/* <span
                  style={{
                    color: `black`,
                    fontFamily: "PFDinTextCompProMedium",
                    fontSize: "18px",
                    marginLeft:"5px"
                  }}
                  className={classes.dataTitle}
                >
                  Amount of Nitrate
                </span> */}
              <Grid item>
                {/* <FormControlLabel
                  sx={{ marginLeft: "5px" }}
                  control={
                    <Switch
                      checked={advancedToggle}
                      onChange={handleChangeAdvancedToggle}
                      name="advanced"
                    />
                  }
                  label={
                    <p
                      style={{
                        fontFamily: "PFDinTextCompProMedium",
                        fontSize: "1.1rem",
                        fontWeight: "normal",
                        color: "black",
                      }}
                    >
                      Customize Parameters (Advanced)
                    </p>
                  }
                /> */}
                {/* <div style={{ float: "right", margin:"10px 5px 10px 10px" }}>
                  <p
                    style={{
                      // fontFamily: "PFDinTextCompProMedium",
                      fontSize: "0.85rem",
                      fontWeight: "200",
                      color: "#FF7518",
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontStretch: "condensed"
                    }}
                    onClick={handleChangeAdvancedToggle}
                  >
                    Customize Parameters (Advanced)
                  </p>
                </div> */}
              </Grid>

              <Table className={classes.table}>
                <TableBody>
                  <TableRow className={classes.tableBodyRow}>
                    <TableCell className={classes.tableSummaryCellWQ} style={{ width: "115px" }}>
                      {advancedToggle ? (
                        <span style={{ color: `blue` }} className={classes.dataTitle}>
                          Data Source
                        </span>
                      ) : (
                        <span style={{ color: `blue` }} className={classes.dataTitle}>
                          Data Source - Nitrate (mg/l)
                        </span>
                      )}
                    </TableCell>
                    <TableCell
                      className={classes.tableSummaryCellWQ}
                      style={{ textAlign: "right" }}
                    >
                      <span style={{ color: `blue` }} className={classes.dataTitle}>
                        Min
                      </span>
                    </TableCell>
                    <TableCell
                      className={classes.tableSummaryCellWQ}
                      style={{ textAlign: "right" }}
                    >
                      <span style={{ color: `blue` }} className={classes.dataTitle}>
                        Max &nbsp;
                      </span>
                    </TableCell>
                    <TableCell
                      className={classes.tableSummaryCellWQ}
                      style={{ textAlign: "right" }}
                    >
                      <span style={{ color: `blue` }} className={classes.dataTitle}>
                        Avg &nbsp;
                      </span>
                    </TableCell>
                    {/* <TableCell
                        className={classes.tableSummaryCellWQ}
                        style={{ textAlign: "right", width: "45px" }}
                      >
                        <span style={{ color: `blue` }} className={classes.dataTitle}>
                          S.D &nbsp;
                        </span>
                      </TableCell> */}
                  </TableRow>
                </TableBody>
                <TableBody>
                  {(parsedJSONData
                    ? parsedJSONData.state == "IA"
                    : searchParams.get("state") == "IA") ||
                  (parsedJSONData
                    ? parsedJSONData.state == "FL"
                    : searchParams.get("state") == "FL") ? (
                    <TableRow className={classes.tableBodyRow}>
                      <TableCell className={classes.tableSummaryCellWQ}>
                        <span
                          style={{
                            color: `black`,
                            fontFamily: "PFDinTextCompProMedium",
                            fontSize: "16px",
                          }}
                          className={classes.dataTitle}
                        >
                          Recreational (stream)
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCellWQ}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitle}>
                          {advancedToggle
                            ? waterQualityData?.waterCommunityQualitySummaryMin
                            : summaryObject?.waterCommunityQualitySummaryMin != null
                            ? summaryObject?.waterCommunityQualitySummaryMin
                            : 0.0}
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCellWQ}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitle}>
                          {advancedToggle
                            ? waterQualityData?.waterCommunityQualitySummaryMax
                            : summaryObject?.waterCommunityQualitySummaryMax != null
                            ? summaryObject?.waterCommunityQualitySummaryMax
                            : 0.0}{" "}
                          &nbsp;
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCellWQ}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitle}>
                          {advancedToggle
                            ? waterQualityData?.waterCommunityQualitySummaryAvg
                            : summaryObject?.waterCommunityQualitySummaryAvg != null
                            ? summaryObject?.waterCommunityQualitySummaryAvg
                            : 0.0}{" "}
                          &nbsp;
                        </span>
                      </TableCell>
                      {/* <TableCell
                        className={classes.tableSummaryCellWQ}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitle}>0 &nbsp;</span>
                      </TableCell> */}
                    </TableRow>
                  ) : null}

                  {(parsedJSONData
                    ? parsedJSONData.state == "VI"
                    : searchParams.get("state") == "VI") ||
                  (parsedJSONData
                    ? parsedJSONData.state == "FL"
                    : searchParams.get("state") == "FL") ? (
                    <TableRow className={classes.tableBodyRow}>
                      <TableCell className={classes.tableSummaryCellWQ}>
                        <span
                          style={{
                            color: `black`,
                            fontFamily: "PFDinTextCompProMedium",
                            fontSize: "16px",
                          }}
                          className={classes.dataTitle}
                        >
                          Recreational (ocean)
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCellWQ}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitle}>
                          {advancedToggle
                            ? waterQualityData?.recreationalWaterQualitySummaryMin
                            : summaryObject?.recreationalWaterQualitySummaryMin != null
                            ? summaryObject?.recreationalWaterQualitySummaryMin
                            : 0.0}
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCellWQ}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitle}>
                          {advancedToggle
                            ? waterQualityData?.recreationalWaterQualitySummaryMax
                            : summaryObject?.recreationalWaterQualitySummaryMax != null
                            ? summaryObject?.recreationalWaterQualitySummaryMax
                            : 0.0}{" "}
                          &nbsp;
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCellWQ}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitle}>
                          {advancedToggle
                            ? waterQualityData?.recreationalWaterQualitySummaryAvg
                            : summaryObject?.recreationalWaterQualitySummaryAvg != null
                            ? summaryObject?.recreationalWaterQualitySummaryAvg
                            : 0.0}{" "}
                          &nbsp;
                        </span>
                      </TableCell>
                      {/* <TableCell
                        className={classes.tableSummaryCellWQ}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitle}>0 &nbsp;</span>
                      </TableCell> */}
                    </TableRow>
                  ) : null}

                  {(parsedJSONData
                    ? parsedJSONData.state == "IA"
                    : searchParams.get("state") == "IA") ||
                  (parsedJSONData
                    ? parsedJSONData.state == "FL"
                    : searchParams.get("state") == "FL") ? (
                    <TableRow className={classes.tableBodyRow}>
                      <TableCell className={classes.tableSummaryCellWQ}>
                        <span
                          style={{
                            color: `black`,
                            fontFamily: "PFDinTextCompProMedium",
                            fontSize: "16px",
                          }}
                          className={classes.dataTitle}
                        >
                          Drinking Water (public)
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCellWQ}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitle}>
                          {advancedToggle
                            ? waterQualityData?.drinkingWaterQualitySummaryMin
                            : summaryObject?.drinkingWaterQualitySummaryMin != null
                            ? summaryObject?.drinkingWaterQualitySummaryMin
                            : 0.0}
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCellWQ}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitle}>
                          {advancedToggle
                            ? waterQualityData?.drinkingWaterQualitySummaryMax
                            : summaryObject?.drinkingWaterQualitySummaryMax != null
                            ? summaryObject?.drinkingWaterQualitySummaryMax
                            : 0.0}{" "}
                          &nbsp;
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCellWQ}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitle}>
                          {advancedToggle
                            ? waterQualityData?.drinkingWaterQualitySummaryAvg
                            : summaryObject?.drinkingWaterQualitySummaryAvg != null
                            ? summaryObject?.drinkingWaterQualitySummaryAvg
                            : 0.0}{" "}
                          &nbsp;
                        </span>
                      </TableCell>
                      {/* <TableCell
                        className={classes.tableSummaryCellWQ}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitle}>
                          {summaryObject?.drinkingWaterQualitySummarySD != null
                            ? summaryObject?.drinkingWaterQualitySummarySD
                            : 0.0}{" "}
                          &nbsp;
                        </span>
                      </TableCell> */}
                    </TableRow>
                  ) : null}

                  {(parsedJSONData
                    ? parsedJSONData.state == "IA"
                    : searchParams.get("state") == "IA") ||
                  (parsedJSONData
                    ? parsedJSONData.state == "FL"
                    : searchParams.get("state") == "FL") ? (
                    <TableRow className={classes.tableBodyRow}>
                      <TableCell className={classes.tableSummaryCellWQ}>
                        <span
                          style={{
                            color: `black`,
                            fontFamily: "PFDinTextCompProMedium",
                            fontSize: "16px",
                          }}
                          className={classes.dataTitle}
                        >
                          Drinking Water (private)
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCellWQ}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitle}>
                          {advancedToggle
                            ? waterQualityData?.groundWaterQualitySummaryMin
                            : summaryObject?.groundWaterQualitySummaryMin != null
                            ? summaryObject?.groundWaterQualitySummaryMin
                            : 0.0}
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCellWQ}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitle}>
                          {advancedToggle
                            ? waterQualityData?.groundWaterQualitySummaryMax
                            : summaryObject?.groundWaterQualitySummaryMax != null
                            ? summaryObject?.groundWaterQualitySummaryMax
                            : 0.0}{" "}
                          &nbsp;
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableSummaryCellWQ}
                        style={{ textAlign: "right" }}
                      >
                        <span className={classes.dataTitle}>
                          {advancedToggle
                            ? waterQualityData?.groundWaterQualitySummaryAvg
                            : summaryObject?.groundWaterQualitySummaryAvg != null
                            ? summaryObject?.groundWaterQualitySummaryAvg
                            : 0.0}{" "}
                          &nbsp;
                        </span>
                      </TableCell>
                      {/* <TableCell
      className={classes.tableSummaryCellWQ}
      style={{ textAlign: "right" }}
    >
      <span className={classes.dataTitle}>0 &nbsp;</span>
    </TableCell> */}
                    </TableRow>
                  ) : null}

                  {/* <TableRow className={classes.tableBodyRow}>
                                    <TableCell className={classes.tableCell}>
                                        <span style={{ color: `red` }} className={classes.dataTitle}>Ground Water</span>
                                    </TableCell>
                                    <TableCell className={classes.tableCell} style={{textAlign:"right"}}>
                                        <span className={classes.dataTitle}>0.7 &nbsp;</span>
                                    </TableCell>
                                    <TableCell className={classes.tableCell} style={{textAlign:"right"}}>
                                        <span className={classes.dataTitle}>19.2 &nbsp;</span>
                                    </TableCell>
                                    <TableCell className={classes.tableCell} style={{textAlign:"right"}}>
                                        <span className={classes.dataTitle}>19.55 &nbsp;</span>
                                    </TableCell>
                                </TableRow> */}
                </TableBody>
              </Table>

              {advancedToggle ? (
                <>
                  {/* <AnalyteSelector
                    width={160}
                    analytes_list={summaryObject.data_source}
                    label="Data Source"
                    open={openWaterSource}
                    handleClose={handleCloseWaterSource}
                    handleOpen={handleOpenWaterSource}
                    analyte={waterSource}
                    handleChangeSelect={handleChangeSelectWaterSource}
                  /> */}

                  <AnalyteSelector
                    width={320}
                    analytes_list={summaryObject.grouped_analytes_data}
                    label="Analyte"
                    open={open}
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    analyte={analyte}
                    handleChangeSelect={handleChangeSelect}
                  />
                </>
              ) : null}
            </div>
          </TabPanel>
        </Box>
      </>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {geoType == "Census Tract" ? null : (
          <>
            <MDButton
              style={{
                margin: "10px 10px 15px 10px",
                padding: "0% 4% 0% 4%",
                backgroundColor: `#d2dde4`,
                color: "#000",
                boxShadow: "0 7px 0 #b2bdc4",
              }}
              variant="contained"
              onClick={() => {
                setShowDataReport(true);
              }}
            >
              Data Summary
            </MDButton>
            <MDButton
              style={{
                margin: "10px 10px 15px 10px",
                padding: "0% 4% 0% 4%",
                backgroundColor: `#d2dde4`,
                color: "#000",
                boxShadow: "0 7px 0 #b2bdc4",
              }}
              variant="contained"
              onClick={() => {
                setShowTakeAction(true);
              }}
            >
              Take Action
            </MDButton>
            {value == (demographicObject ? 1 : 0) ? (
              geoType == "County" ? (
                <MDButton
                  style={{
                    margin: "10px 10px 15px 10px",
                    padding: "0% 4% 0% 4%",
                    backgroundColor: `#d2dde4`,
                    color: "#000",
                    boxShadow: "0 7px 0 #b2bdc4",
                  }}
                  variant="contained"
                  onClick={handleFetchPDFBtn}
                >
                  Report
                </MDButton>
              ) : null
            ) : null}

            {/* <MDButton
              style={{
                margin: "10px 10px 15px 10px",
                padding: "0% 4% 0% 4%",
                backgroundColor: `#d2dde4`,
                color: "#000",
                boxShadow: "0 7px 0 #b2bdc4",
              }}
              variant="contained"
              onClick={handleSnapshotClickBtn}
            >
              Snap
            </MDButton> */}
          </>
        )}

        {geoType == "Zip Code" && value == (demographicObject ? 2 : 1) ? (
          <MDButton
            style={{
              margin: "10px 10px 15px 10px",
              padding: "0% 4% 0% 4%",
              backgroundColor: `#d2dde4`,
              color: "#000",
              boxShadow: "0 7px 0 #b2bdc4",
            }}
            variant="contained"
            onClick={handleChangeAdvancedToggle}
          >
            {advancedToggle ? "-" : "+"}
          </MDButton>
        ) : null}
      </div>
    </Card>
  );
}

export default SummaryBox;
