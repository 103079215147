import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

function AnalyteSelector({width, analytes_list, label, open, handleClose, handleOpen, analyte, handleChangeSelect}) {
  return (
    <FormControl sx={{ m: 1, width: width }}>
      <InputLabel id="demo-controlled-open-select-label">{label}</InputLabel>
      <Select
        labelId="demo-controlled-open-select-label"
        id="demo-controlled-open-select"
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={analyte}
        label={label}
        onChange={handleChangeSelect}
        sx={{ height: "40px" }}
      >
        {analytes_list
          ? Object.entries(analytes_list).map(([key, idx]) => (
              <MenuItem key={key} value={key}>
                {key}
              </MenuItem>
            ))
          : null}
      </Select>
    </FormControl>
  );
}

export default AnalyteSelector;
