const ComparisonTableStyle = {
    table: {
        marginBottom: "0",
        width: "100%",
        minWidth: "100%",
        backgroundColor: "transparent",
        borderSpacing: "0",
        borderCollapse: "collapse",
        '& .MuiTableHead-root': {
            display: "revert !important",
            padding: "0px !important"
        }
    },
    tableHeadCell: {
        padding: "0px 2px 0px 0px !important",
        verticalAlign: "middle",
        fontSize: "13px !important",
        fontWeight: "400 !important"
    },
    tableCell: {
        padding: "0px 2px 0px 0px !important",
        verticalAlign: "middle",
        fontSize: "13px !important",
    },
    tableResponsive: {
        width: "100%",
        marginTop: "1px"
    },
    tableHeadRow: {
        height: "12px",
        color: "inherit",
        display: "table-row",
        outline: "none",
        verticalAlign: "middle"
    },
    tableBodyRow: {
        height: "6px",
        color: "inherit",
        display: "table-row",
        outline: "none",
        verticalAlign: "middle"
    },
    tableActionButton: {
        width: "27px",
        height: "27px",
        padding: "0"
    },
    tableActionButtonIcon: {
        width: "17px",
        height: "17px"
    },
    close: {
        backgroundColor: "transparent",
        color: "#e91e63",
        boxShadow: "none"
    },
    container: {
        width: "20%",
        bottom: "10px",
        right: "30px",
        position: "absolute",
        maxHeight: "50%",
        maxWidth: "500px",
        minWidth: "250px",
        // overflowY: "scroll",
        '&::-webkit-scrollbar': { display: "none" },
        scrollbarWidth: "none",
    },
    cardBody: {
        marginTop: "-24px !important",
    },
    button: {
        width: "100%",
        marginTop: "5px"
    },
    cardCategoryWhite: {
        color: "white",
        margin: "0",
        fontSize: "13px",
        marginTop: "0",
        marginBottom: "0",
        paddingLeft: "4px",
        fontWeight:"300"
    },
    cardTitleWhite: {
        color: "white",
        fontSize:"20px !important",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        transition: "all 150ms ease-in 0s",
        padding: "0px 3px",
        marginLeft: "-3px",
        "& small": {
            color: "gray",
            fontWeight: "400",
            lineHeight: "1"
        },
        "&:hover": {
            // color: grayColor[1],
            transform: "scale(0.97)",
            filter: "drop-shadow(0 0 0.75rem crimson)",
            // boxShadow: "rgb(52, 71, 103 / 15%) 0rem 0.1875rem 0.1875rem 0rem, rgb(52, 71, 103 / 20%) 0rem 0.1875rem 0.0625rem -0.125rem, rgb(52, 71, 103 / 15%) 0rem 0.0625rem 0.3125rem 0rem",
        }
    }
}

export default ComparisonTableStyle;