const interviewDetailBoxStyle = {
    tableTitle: {
        fontSize: "0.9rem !important",
        padding: "0rem 0rem !important",
        borderBottom: "0px !important", color: "black !important",
        fontWeight: "500 !important"
    },
    tableDescription: {
        fontSize: "0.9rem !important",
        padding: "0.25rem 0.9rem !important",
        borderBottom: "0px !important", color: "black !important",
        fontWeight: "300 !important"
    },
    scrollable: {
        overflow: 'scroll',
        scrollbarWidth: 'none',
        '-ms-overflow-style': 'none',
        '&::-webkit-scrollbar': { display: 'none' },
    },
    cardCss: {
        height: "100vh",
        position: "relative !important",
        background: "lightgrey"
    },
    btnDiv: {
        position: "absolute", top: 0, right: 0, zIndex: 1
    },
    mapAndSideCss: {
        display: "flex", flexDirection: "row", height: "100%", width: "100%"
    },
    sideBar: {
        width: "500px",
        background: "lightgrey",
        height: "100%",
        display: "flex", /* add display flex */
        flexDirection: "column" /* change direction to column */,
        overflow: 'scroll',
        scrollbarWidth: 'none',
        '-ms-overflow-style': 'none',
        '&::-webkit-scrollbar': { display: 'none' },
    },
    cardSmallAndBig: {
        display: "flex", flexDirection: "column", borderRadius: "0.75rem", background: "white",
        height:"100%",
        margin: "10px 10px 10px 10px", marginLeft: "10px", boxShadow: '0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(76 175 80 / 40%)'
    },
    cardSmallAndBig2: {
        display: "flex", flexDirection: "column", borderRadius: "0.75rem", background: "white",
        margin: "10px 10px 15px 10px"
    },
    cardImgShortDesc: {
        display: "flex", flexDirection: "row", alignItems: "center"
    },
    bigDesc: {
        flex: 1, /* use flex to take up remaining height */
        display: "flex",
        flexDirection: "column"/* add overflow for scrolling */,
        background:"white",
        borderRadius: "0.75rem",
    },
    mapCss: {
        width: "70%", height: "100%",
        flex: 1
    },
    videoResponsive: {
        overflow: "hidden",
        // padding: "140px",
        minHeight:"400px",
        // maxHeight: "450px",
        margin: "10px 10px 10px 10px",
        overflowX: "hidden", /* Hide horizontal scrollbar */
        overflowY: "auto",
        scrollbarWidth: 'none',
        '-ms-overflow-style': 'none',
        '&::-webkit-scrollbar': { display: 'none' },
    },
    viframe: {
        left: 0,
        top: 0,
        height: "100%",
        width: "100%",
        position: "absolute"
    },
    buttonContainer: {
        position: "absolute",
        bottom: 0,
        right: 0,
    },
    imageSection: {
        flex: "0 0 25%",
        marginLeft: "10px",
        marginRight: "0px"
    },
    imageSection2: {
        flex: "0 0 25%",
        marginRight: "10px"
    },
    image: {
        width: "85px",
        height: "85px",
        borderRadius: "50%"
    }
}

export default interviewDetailBoxStyle;