import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import RadioGroup from "@mui/material/RadioGroup";
import PlotlyObservationChart from "../PlotlyObservationChart";
import { useMaterialUIController } from "context";
import { useEffect, useState, useCallback } from "react";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.css";
import DataResolutionTab from "../DataResolutionTab";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import AccessTime from "@mui/icons-material/AccessTime";
import TodayIcon from "@mui/icons-material/Today";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {
  storetVariables,
  nwisVariables,
  iwqisVariables,
  observationEndpoints,
  observationTimerangeEndpoints,
} from "constants";
import { makeStyles } from "@mui/styles";
import SensorDataObservationStyle from "../styles/SensorDataObservationStyle";
import { Divider } from "@mui/material";
import { iadnrVariables } from "constants";
import { sdwaVariables } from "constants";
import { pwtsVariables } from "constants";
import zIndex from "@mui/material/styles/zIndex";
import { pwsVariables } from "constants";
import { tbwVariables } from "constants";
import { setShowObservationsPanel } from "context";
import { tbbVariables } from "constants";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const useStyles = makeStyles(SensorDataObservationStyle);

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pl: 3, pr: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function SensorDataObservation({
  data,
  setOpenPanel,
  showNotification,
  setLoadingIndicator,
  showCloseBtnStatus,
  setShowDataExpert,
  setExpertURL,
  geoType,
  breadCrumbsList,
  dataSourceState,
  parsedJSONData,
  boundState,
}) {
  const classes = useStyles();
  const [controller, dispatch] = useMaterialUIController();
  const { showObservationsPanel } = controller;
  const [value, setValue] = useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [selectedObservations, setSelectedObservations] = useState(null);

  const HOURLY = "hourly";
  const DAILY = "daily";
  const WEEKLY = "weekly";
  const MONTHLY = "monthly";

  const IV = "IV";
  const DV = "DV";

  const { afterToday, allowedRange } = DateRangePicker;

  function onCloseObservationHandler() {
    setOpenPanel(false);
    setSelectedObservations(null);
  }

  const getDataResolutionArrayForProvider = (aProvider) => {
    if (aProvider === "NWISDV") {
      return [HOURLY, DAILY, WEEKLY, MONTHLY];
    } else if (aProvider === "IWQIS") {
      return [DAILY, HOURLY];
    } else {
      return [HOURLY];
    }
  };

  const DATA_RESOLUTION_ARR = getDataResolutionArrayForProvider(data?.provider);

  // Get available variables for given sensor
  const getAvailableVariables = (dataInstance) => {
    switch (dataInstance.provider) {
      case "IWQIS":
        return iwqisVariables;
      case "STORET":
        return storetVariables;
      case "IADNR":
        return iadnrVariables;
      case "PWS":
        return pwsVariables;
      case "SDWA":
        return sdwaVariables;
      case "PWTS":
        return pwtsVariables;
      case "TBW":
        return tbwVariables;
      case "NWISDV":
        return nwisVariables;
      case "NWISIV":
        return nwisVariables;
      case "TBB":
        return tbbVariables;
      default:
        return [];
    }
  };

  const observationValueCallback = (
    result,
    setSelectedObservations,
    selectedVariable,
    showNotification
  ) => {
    if ("Error" in result) {
      //console.log("Errors: ", result.errors);
      showNotification(`Error: ${result.Error}`, "error");
      return;
    } else if ("errors" in result) {
      showNotification(`Error: ${result.Error}`, "error");
      //console.log("Errors: ", result.errors);
      return;
    } else if ("length" in result && result.length < 1) {
      //console.log("Errors: ", result);
      showNotification(
        `${selectedVariable.name} observations have not been found for given timeframe.`,
        "error"
      );
      return;
    }
    setSelectedObservations(result);

    setLoadingIndicator(false);
    setOpenPanel(true);
  };

  // retrieve observations
  // const [selectedObservations, setSelectedObservations] = useState(null);
  const [dataResolution, setDataResolution] = useState(null);

  const handleDataResolutionChange = (event, value) => {
    setLoadingIndicator(true);
    if (typeof value === "number" && value < DATA_RESOLUTION_ARR.length) {
      setDataResolution(value);
    }
  };

  // A function to retrieve observation data
  // when the user selects an option from the dropdown list.
  //
  // Helper function to pad with zeros
  const leftPad = function (str, length, padString) {
    while (str.length < length) str = padString + str;
    return str;
  };

  // set date range for observations
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateRangeArr, setDateRangeArr] = useState([null, null]);
  const setDateRange = (dateArray) => {
    if (dateArray.length >= 2) {
      setStartDate(dateArray[0].toISOString());
      setEndDate(dateArray[1].toISOString());
      setDateRangeArr(dateArray);
    }
  };
  const clearDateRange = () => {
    setStartDate(null);
    setEndDate(null);
    setDateRangeArr([null, null]);
  };

  const [selectedVariable, setSelectedVariable] = useState(null);
  const [nwisSource, setNwisSource] = useState("DV");

  function setNwisSourceHandler(value) {
    setDataResolution(null);

    setNwisSource(value);
  }

  const setSelectedVariableHandler = (event, selectedVariable, reason) => {
    if (reason === "clear") {
      setSelectedVariable(null);
    }
    if (selectedVariable != null) {
      setSelectedVariable(selectedVariable);
      setDateRange([selectedVariable.min, selectedVariable.max]);

      // this is where we initially set the date range for the selected parameter
      // let's use this chance to also set the default obs. resolution for NWIS
      if (data.provider == "NWISDV") {
        if (nwisSource == DV) {
          setDataResolution(1);
          return;
        }
        // not taking leap years into account
        let diffInYears =
          (selectedVariable.max - selectedVariable.min) / (1000 * 60 * 60 * 24 * 365);
        if (diffInYears > 6) setDataResolution(3);
        else if (diffInYears > 3) setDataResolution(2);

        // if(diffInYears > 1) setIsDateRangeInYearsLarge(true);
      } else {
        setDataResolution(0);
        return;
      }
    }
  };

  // set available parameters for the observation location
  const [availableVariables, setAvailableVariables] = useState([]);
  const setAvailableVariablesHandler = (sensorVariables) => {
    setAvailableVariables(sensorVariables);
    // debugger;
    if (sensorVariables.length > 0) {
      if (data.agency == "IWQIS") {
        const nitrateIndex = sensorVariables.findIndex((item) => item.abbr == "nitrate_con");
        setSelectedVariableHandler(null, sensorVariables[nitrateIndex], null);
      } else {
        setSelectedVariableHandler(null, sensorVariables[0], null);
      }
    } else {
      // no data was found for this sensor.
      setOpenPanel(false);
      showNotification("No observations are available for this location.", "error");
      setLoadingIndicator(false);
      ////console.log("not data fooune");
    }
  };

  const setObservationTimeranges = (dataInstance, apiEndpoint) => {
    //debugger;
    if (apiEndpoint === null) {
      return;
    }

    let siteParam =
      dataInstance.agency == "TBB"
        ? "&site=" + dataInstance.siteid
        : "site=" +
          (dataInstance.provider === "IWQIS"
            ? dataInstance.siteid
            : leftPad(dataInstance.siteid, 7, "0")); // if shorter than length 8, pad w/ 0s
    let variableParam = "";
    let requestURL = apiEndpoint + siteParam;
    // E.g. https://umissis.org/umisapi/nwis_dv_param_timerange?site=05464500&param=allp

    //console.log(requestURL);
    fetch(requestURL)
      .then((res) => res.json())
      .then(
        (result) => {
          observationTimerangesCallback(result);
        },
        (error) => {
          //console.log("There was an error retrieving the data. Please try again later.");
          showNotification(
            "There was an error retrieving the data. Please try again later.",
            "error"
          );
          setLoadingIndicator(false);
        }
      );
  };

  //After show observations click method.
  const observationTimerangesCallback = (results) => {
    //debugger;
    if (results.Error === undefined && results.Notice === undefined && results[0]) {
      //console.log(results);
      let sensorVariables = getAvailableVariables(data);
      // Handling different array depths...
      let timeRanges = results[0][0] ? results[0][0][0] : results[0];
      //console.log(timeRanges);
      sensorVariables.forEach((variable) => {
        let minDate = "";
        let maxDate = "";

        switch (data.provider) {
          case "STORET":
          case "IADNR":
          case "TBB":
            minDate = timeRanges[variable.chartvalue + "_min"];
            maxDate = timeRanges[variable.chartvalue + "_max"];
            break;
          default:
            minDate = timeRanges[variable.abbr + "_min"];
            maxDate = timeRanges[variable.abbr + "_max"];
        }

        if (minDate != null) {
          minDate = new Date(minDate);
        }
        if (maxDate != null) {
          maxDate = new Date(maxDate);
        }
        variable["min"] = minDate;
        variable["max"] = maxDate;
      });
      // Filter out the variables that we don't have information for.
      sensorVariables = sensorVariables
        .filter((variable) => variable.min != null && variable.max != null)
        .sort((firstVar, secondVar) => firstVar.name.localeCompare(secondVar.name));
      // Set state for available variables with min/max dates.
      //console.log(sensorVariables);
      setAvailableVariablesHandler(sensorVariables);
    } else {
      showNotification("No data found for specified sensor point.", "error");
      setLoadingIndicator(false);
      setOpenPanel(false);
      setSelectedObservations(null);
    }
  };

  useEffect(() => {
    // debugger;
    // setLoadingIndicator(true);
    if (data.provider != "NWISDV") return;
    //console.log("getting nwis timerange");
    let timerangeEndpoint =
      nwisSource == IV
        ? observationTimerangeEndpoints.NWISIV
        : observationTimerangeEndpoints.NWISDV;
    setObservationTimeranges(data, timerangeEndpoint);
  }, [nwisSource]);

  // useEffect(() => {
  //   if (selectedVariable != null && dataResolution != undefined && data != null) {
  //     const apiEndpoint = getAPIEndpoint(data, DATA_RESOLUTION_ARR[dataResolution]);
  //     getObservationValues(data, apiEndpoint, selectedVariable, startDate, endDate);
  //   }

  //   return () => {
  //     // Component will unmount
  //     setSelectedObservations(dispatch, null);
  //     // Call any cleanup function here
  //   };
  // }, [data, selectedVariable, startDate, endDate, dataResolution]);

  //This fixes the unnecessary re-render of the component?
  const fetchData = useCallback(() => {
    if (selectedVariable != null && dataResolution != undefined && data != null) {
      // debugger;
      const apiEndpoint = getAPIEndpoint(data, DATA_RESOLUTION_ARR[dataResolution]);
      getObservationValues(data, apiEndpoint, selectedVariable, startDate, endDate);
    }
  }, [data, selectedVariable, startDate, endDate, dataResolution]);

  useEffect(() => {
    fetchData();
    // return () => {
    //   setSelectedObservations(dispatch, null);
    // };
  }, [fetchData]);

  useEffect(() => {
    // First, get which parameters are available for this sensor
    // Works for all STORET, NWIS, and IWQIS sensors.
    // debugger;
    if (
      data.provider === "SDWA" ||
      data.provider === "TBW" ||
      data.provider === "PWS" ||
      data.provider === "PWTS"
    ) {
      setValue(1);
    } else {
      setValue(0);
    }
    let timerangeEndpoint =
      data.stateabbr == "VI"
        ? observationTimerangeEndpoints["USVI"]
        : observationTimerangeEndpoints[data.provider];
    setObservationTimeranges(data, timerangeEndpoint);
  }, []);

  // Get API endpoint for given sensor
  const getAPIEndpoint = (dataInstance, aDataResolution) => {
    // debugger;
    let provider = dataInstance.provider;
    let postTerms = "";
    if (provider in observationEndpoints) {
      // //console.log(provider);
      if (provider == "NWISDV" || provider == "IWQIS") {
        postTerms = "_" + aDataResolution;
      }
      if (dataInstance.stateabbr == "VI") {
        return observationEndpoints["USVI" + postTerms];
      }
      return observationEndpoints[provider + postTerms];
    }
    return null;
  };

  //before chart ko data.
  const getObservationValues = (
    dataInstance,
    apiEndpoint,
    selectedVariable,
    startDate,
    endDate
  ) => {
    setLoadingIndicator(true);
    if (apiEndpoint === null) {
      return;
    }
    // debugger;
    let stateParam = "",
      siteParam = "",
      variableParam = "",
      startDateParam = "",
      endDateParam = "",
      requestURL = "";

    // IWQIS Sensors have a different structure of request URL
    if (dataInstance.provider === "IWQIS") {
      siteParam =
        "site_uid=" +
        (/^\d+$/.test(dataInstance.siteid)
          ? leftPad(dataInstance.siteid, 7, "0")
          : dataInstance.siteid);
      variableParam = "&param_uid=" + selectedVariable.abbr;
      // IWQIS daily endpoint requires dates to be only years..
      if (startDate !== null && endDate !== null) {
        if (DATA_RESOLUTION_ARR[dataResolution] === DAILY) {
          startDate = startDate.substring(0, 4);
          endDate = endDate.substring(0, 4);
        }
        startDateParam = startDate !== null ? "&begin=" + startDate : "";
        endDateParam = endDate !== null ? "&end=" + endDate : "";
      }
      requestURL = apiEndpoint + siteParam + variableParam + startDateParam + endDateParam;
    } else if (dataInstance.provider === "IADNR") {
      stateParam = "extent=" + dataInstance.extentVal;
      siteParam = "&site=" + leftPad(dataInstance.siteid, 8, "0"); // if shorter than length 8, pad w/ 0s
      // variableParam = "&param=" + "discharge";
      // requestURL = "https://umissis.org/umisapi/nwis_dv_obs?state=19&site=05388250&param=discharge&starttime=2006-10-01T05:00:00.000Z&endtime=2023-01-16T06:00:00.000Z";
      // variableParam = "&param=" + selectedVariable.abbr;
      startDateParam = startDate !== null ? "&startdt=" + startDate : "";
      endDateParam = endDate !== null ? "&enddt=" + endDate : "";
      requestURL = apiEndpoint + stateParam + siteParam + startDateParam + endDateParam;
    }
    // For all others, requests are formed as below.
    else {
      let extentVal = "";
      if (dataInstance.stateabbr == "FL") {
        if (dataInstance.agency == "PWS" || dataInstance.agency == "TBW") {
          extentVal = "tb";
        } else {
          extentVal = "031002";
        }
      } else if (dataInstance.stateabbr == null) {
        extentVal = "21020002";
      } else {
        extentVal = dataInstance.stateabbr;
      }
      stateParam = "extent=" + extentVal;
      siteParam =
        dataInstance.agency == "TBB"
          ? "&site=" + dataInstance.siteid
          : "&site=" + leftPad(dataInstance.siteid, 7, "0"); // if shorter than length 8, pad w/ 0s
      // variableParam = "&param=" + "discharge";
      // requestURL = "https://umissis.org/umisapi/nwis_dv_obs?state=19&site=05388250&param=discharge&starttime=2006-10-01T05:00:00.000Z&endtime=2023-01-16T06:00:00.000Z";
      variableParam = "&param=" + selectedVariable.abbr;
      startDateParam =
        startDate !== null
          ? "&startdt=" +
            (dataInstance.provider == "STORET" && dataInstance.stateabbr != "VI"
              ? startDate
              : startDate.substring(0, 10))
          : "";
      endDateParam =
        endDate !== null
          ? "&enddt=" +
            (dataInstance.provider == "STORET" && dataInstance.stateabbr != "VI"
              ? endDate
              : endDate.substring(0, 10))
          : "";
      requestURL = apiEndpoint + stateParam + siteParam + startDateParam + endDateParam;
      //requestURL = "mainURL/nwis_dv_obs?extent=IA&startdt=2022-01-01&enddt=2023-01-01&site=05388250";
    }
    // console.log(requestURL);
    // let endpointURL = Endpoints.storetEndpoints[geoType]+region;
    fetch(requestURL)
      .then((res) => res.json())
      .then(
        (result) => {
          //console.log("api ko result", result[0])
          if (result[0] == null) {
            showNotification("No observation data found!", "error");
            setLoadingIndicator(false);
            setOpenPanel(false);
            setSelectedObservations(null);
            return;
          }
          observationValueCallback(
            result,
            setSelectedObservations,
            selectedVariable,
            showNotification
          );
        },
        (error) => {
          showNotification(
            "There was an error retrieving the data. Please try again later.",
            "error"
          );
          setLoadingIndicator(false);
          setOpenPanel(false);
          setSelectedObservations(null);
          return;
        }
      );
  };

  const getColorForProvider = (dataInstance) => {
    // if (dataInstance.provider === undefined){
    //   return "success";
    // }
    switch (dataInstance.provider) {
      case "IWQIS":
        return "#F9C74F"; // blue
      case "STORET":
        return "#F94144"; // purple
      case "SDWA":
        return "rgb(17, 4, 204)"; // pink
      case "PWTS":
        return "#b97a56"; // orange
      case "IADNR":
        return "#F8961E"; // orange
      default:
        // NWIS
        return "#F3722C"; // green
    }
  };

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
    createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
    createData("Eclair", 262, 16.0, 24, 6.0),
    createData("Cupcake", 305, 3.7, 67, 4.3),
    createData("Gingerbread", 356, 16.0, 49, 3.9),
  ];

  return (
    <div
      style={{
        maxWidth: "550px",
        minWidth: "300px",
        margin: "0 auto",
      }}
    >
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card>
            {selectedObservations !== null && selectedVariable != null && data != null ? (
              <div>
                <MDBox
                  style={{
                    height: "355px",
                    border: `2px solid ${getColorForProvider(data)}`,
                    boxShadow:
                      "0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(76 175 80 / 40%)",
                  }}
                  mt={2}
                  ml={2}
                  mr={2}
                  mb={1.5}
                >
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                      <Tab label="Chart" {...a11yProps(0)} />
                      <Tab label="Table" {...a11yProps(1)} />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={value} index={0}>
                    <MDBox
                      style={{
                        height: "300px",
                      }}
                    >
                      <PlotlyObservationChart
                        siteData={data}
                        provider={data.provider}
                        data={selectedObservations}
                        selectedVariableAbbr={selectedVariable.chartvalue}
                        selectedVariableName={selectedVariable.name}
                        startDate={startDate}
                        endDate={endDate}
                        setLoadingIndicator={setLoadingIndicator}
                      />
                    </MDBox>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <MDBox sx={{ height: "300px", overflowY: "scroll" }}>
                      {data.provider == "IWQIS" ? (
                        <Typography>
                          Data too large to show in table. Please view the chart instead.
                        </Typography>
                      ) : selectedObservations[0].length > 100 ? (
                        <Typography>
                          Data too large to show in table. Please view the chart instead.
                        </Typography>
                      ) : (
                        <Table>
                          <TableBody>
                            <TableRow className={classes.tableBodyRow}>
                              <TableCell className={classes.tableSummaryCell}>
                                <span style={{ color: `blue` }} className={classes.dataTitle}>
                                  Analyte
                                </span>
                              </TableCell>
                              <TableCell
                                className={classes.tableSummaryCell}
                                style={{ textAlign: "right" }}
                              >
                                <span style={{ color: `blue` }} className={classes.dataTitle}>
                                  Value
                                </span>
                              </TableCell>
                              <TableCell
                                className={classes.tableSummaryCell}
                                style={{ textAlign: "center" }}
                              >
                                <span style={{ color: `blue` }} className={classes.dataTitle}>
                                  Date &nbsp;
                                </span>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                          <TableBody>
                            {selectedObservations[0].map((item, index) => (
                              <TableRow key={index} className={classes.tableBodyRow}>
                                <TableCell className={classes.tableSummaryCell}>
                                  <span
                                    style={{
                                      color: `black`,
                                      fontFamily: "PFDinTextCompProMedium",
                                      fontSize: "16px",
                                    }}
                                    className={classes.dataTitle}
                                  >
                                    {selectedVariable.name}
                                  </span>
                                </TableCell>
                                <TableCell
                                  className={classes.tableSummaryCell}
                                  style={{ textAlign: "right" }}
                                >
                                  <span className={classes.dataTitle}>
                                    {item[selectedVariable.chartvalue]
                                      ? parseFloat(item[selectedVariable.chartvalue]).toFixed(3)
                                      : 0}
                                  </span>
                                </TableCell>
                                <TableCell
                                  className={classes.tableSummaryCell}
                                  style={{ textAlign: "center" }}
                                >
                                  <span className={classes.dataTitle}>
                                    {new Date(item.dt).toLocaleDateString()}
                                  </span>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      )}
                    </MDBox>
                  </CustomTabPanel>
                </MDBox>
                {/* {data.provider === "NWISDV" ? <MDBox style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                  <RadioGroup
                    row
                    aria-labelledby="nwis-type"
                    name="nwis-type"
                    defaultValue={nwisSource}
                    onChange={(e, val) => setNwisSourceHandler(val)}
                    style={{margin:"0px !important"}}
                  >
                    <FormControlLabel
                      value="DV"
                      control={<Radio />}
                      label="Daily Values"
                      labelPlacement="start"
                      sx={{margin:"0px !important"}}
                    />
                    <FormControlLabel
                      value="IV"
                      control={<Radio />}
                      label="Instant Values"
                      labelPlacement="start"
                    />
                  </RadioGroup>
                </MDBox> : null} */}
                {data.provider === "SDWA" ||
                data.provider === "TBW" ||
                data.provider === "PWS" ||
                data.provider === "PWTS" ? null : (
                  <>
                    <MDBox
                      px={3}
                      pb={1}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "green",
                        position: "relative",
                        zIndex: 2,
                      }}
                    >
                      <DataResolutionTab
                        style={{ background: "transparent" }}
                        dataResolutionValue={dataResolution}
                        handleDataResolutionChange={handleDataResolutionChange}
                        activeColor={getColorForProvider(data)}
                        tabs={
                          data.provider === "NWISDV"
                            ? [
                                {
                                  tabName: (
                                    <span style={{ fontSize: "14px", marginRight: "5px" }}>
                                      {HOURLY}
                                    </span>
                                  ),
                                  tabIcon: HourglassEmptyIcon,
                                  isDisabled:
                                    (dateRangeArr[1] - dateRangeArr[0]) /
                                      (1000 * 60 * 60 * 24 * 365) >
                                      1 || nwisSource != IV,
                                },
                                {
                                  tabName: (
                                    <span style={{ fontSize: "14px", marginRight: "5px" }}>
                                      {DAILY}
                                    </span>
                                  ),
                                  tabIcon: CalendarTodayIcon,
                                  isDisabled: nwisSource != DV,
                                },
                                {
                                  tabName: (
                                    <span style={{ fontSize: "14px", marginRight: "5px" }}>
                                      {WEEKLY}
                                    </span>
                                  ),
                                  tabIcon: TodayIcon,
                                  isDisabled: nwisSource != IV,
                                },
                                {
                                  tabName: (
                                    <span style={{ fontSize: "14px", marginRight: "5px" }}>
                                      {MONTHLY}
                                    </span>
                                  ),
                                  tabIcon: DateRangeIcon,
                                  isDisabled: nwisSource != IV,
                                },
                              ]
                            : data.provider === "IWQIS"
                            ? [
                                {
                                  tabName: (
                                    <span style={{ fontSize: "14px", marginRight: "5px" }}>
                                      {DAILY}
                                    </span>
                                  ),
                                  tabIcon: CalendarTodayIcon,
                                },
                                {
                                  tabName: (
                                    <span style={{ fontSize: "14px", marginRight: "5px" }}>
                                      {HOURLY}
                                    </span>
                                  ),
                                  tabIcon: HourglassEmptyIcon,
                                },
                              ]
                            : [
                                {
                                  tabName: (
                                    <span style={{ fontSize: "14px", marginRight: "5px" }}>
                                      {HOURLY}
                                    </span>
                                  ),
                                  tabIcon: HourglassEmptyIcon,
                                },
                              ]
                        }
                      />
                    </MDBox>
                    <div
                      style={{
                        display: "flex",
                        position: "relative",
                        zIndex: 1,
                        borderBottom: "0.0625rem solid #f0f2f5",
                        paddingBottom: "7px",
                      }}
                    >
                      <MDBox pt={1} style={{ flexGrow: 1, margin: "0px 10px 0px 10px" }}>
                        <Autocomplete
                          id="variable-selector"
                          size="small"
                          options={availableVariables}
                          value={selectedVariable}
                          getOptionLabel={(option) => option.name}
                          style={{}}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Variable"
                              shrink="false"
                              variant="outlined"
                            />
                          )}
                          onChange={setSelectedVariableHandler}
                        />
                      </MDBox>

                      <MDBox pt={1} style={{ margin: "0px 10px 0px 0px" }}>
                        <DateRangePicker
                          disabled={selectedVariable === null}
                          value={dateRangeArr}
                          onChange={setDateRangeArr}
                          disabledDate={
                            selectedVariable === null
                              ? afterToday()
                              : allowedRange(selectedVariable.min, selectedVariable.max)
                          }
                          placeholder="Select Date Range"
                          appearance="default"
                          placement="topStart"
                          preventOverflow={true}
                          opens="center"
                          block
                          onOk={setDateRange}
                          onClean={clearDateRange}
                        />
                      </MDBox>
                    </div>
                  </>
                )}

                <MDBox pt={1} pb={1} px={1} className={classes.statsBtnContainer}>
                  <div className={classes.stats}>
                    <Tooltip
                      title={
                        selectedVariable === null || startDate === null || endDate === null
                          ? "Define parameters above"
                          : "Displaying " +
                            selectedVariable.name +
                            " data for " +
                            new Date(startDate).toLocaleDateString() +
                            " - " +
                            new Date(endDate).toLocaleDateString()
                      }
                    >
                      <AccessTime />
                    </Tooltip>
                    {(data.agency == "NWIS" ? data.agency : data.provider) + " - " + data.siteid}
                  </div>
                  {showCloseBtnStatus != null ? (
                    <>
                      <MDButton
                        style={{ marginRight: "10px" }}
                        size="small"
                        color="success"
                        onClick={() => {
                          setShowDataExpert(true);
                          // console.log("data", data);
                          let extentVal = "";
                          data.stateabbr == "IA" ? (extentVal = "IA") : (extentVal = "031002");
                          setExpertURL(
                            `/chat/app?expert=dataexpert&site=${data.siteid}&source=${
                              data.provider == "NWISDV" ? "NWIS" : data.provider
                            }&fips=${data.fips}&extent=${extentVal}&name=Sensor%20Data%20Expert`
                          );
                        }}
                      >
                        Sensor Data Expert
                      </MDButton>
                      <MDButton size="small" color="error" onClick={onCloseObservationHandler}>
                        Close
                      </MDButton>
                    </>
                  ) : null}
                </MDBox>
              </div>
            ) : null}
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default SensorDataObservation;
