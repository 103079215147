import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { useState } from "react";
import MDBox from "components/MDBox";
import { useNavigate } from "react-router-dom";

function Header({ setShowTakeAction, setShowVideoOptions }) {
  const pages = ["Products", "Pricing", "Blog"];
  let navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: "#fff", height: "100% !important" }}>
      <Toolbar sx={{ paddingRight: "10px !important" }}>
        <img
          //   onClick={() => {
          //     window.location.replace("/");
          //   }}
          style={{ width: "270px", cursor: "pointer" }}
          src={require("assets/images/logos/bluegaplogo.png")}
          alt="image"
        />

        <Typography
          variant="h6"
          noWrap
          sx={{
            ml: 2,
            display: { xs: "none", md: "flex" },
            fontFamily: "PFDinTextCompProMedium",
            fontSize: "45px",
            color: "#000 !important",
            textDecoration: "none",
          }}
        >
          Information System
        </Typography>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "none", md: "flex" },
            justifyContent: "flex-end", // Align items to the right
          }}
        >
          <MDBox
            id="IA"
            variant="gradient"
            bgColor="success"
            color="white"
            coloredShadow="success"
            borderRadius="md"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="3.2rem"
            m={1}
            p={2}
            style={{
              fontSize: "1.5vw",
              fontFamily: "PFDinTextCompProMedium",
              fontWeight: "normal",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("aboutus");
            }}
          >
            About Us
          </MDBox>
          <MDBox
            id="IA"
            variant="gradient"
            bgColor="success"
            color="white"
            coloredShadow="success"
            borderRadius="md"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="3.2rem"
            m={1}
            p={2}
            style={{
              fontSize: "1.5vw",
              fontFamily: "PFDinTextCompProMedium",
              fontWeight: "normal",
              cursor: "pointer",
            }}
            onClick={() => {
              window.open(
                "https://docs.google.com/forms/d/e/1FAIpQLSeBSL4BpDkZhxXDkftWvMl5N4TtRSKvPgCbrcpT6PGgbyWnVA/viewform",
                "_blank"
              );
            }}
          >
            Feedback
          </MDBox>
          <MDBox
            id="IA"
            variant="gradient"
            bgColor="success"
            color="white"
            coloredShadow="success"
            borderRadius="md"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="3.2rem"
            m={1}
            p={2}
            style={{
              fontSize: "1.5vw",
              fontFamily: "PFDinTextCompProMedium",
              fontWeight: "normal",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowTakeAction(true);
            }}
          >
            AI Expert
          </MDBox>
          <MDBox
            id="IA"
            variant="gradient"
            bgColor="success"
            color="white"
            coloredShadow="success"
            borderRadius="md"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="3.2rem"
            m={1}
            p={2}
            style={{
              fontSize: "1.5vw",
              fontFamily: "PFDinTextCompProMedium",
              fontWeight: "normal",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowVideoOptions(true);
            }}
          >
            Video Tutorial
          </MDBox>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
