/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDSnackbar from "components/MDSnackbar";

import { styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';


// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

// Overview page components
import Header from "layouts/api/components/Header";

// Images
import pattern from "assets/images/illustrations/pattern-tree.svg";
import apiLaunchImage from "assets/images/api-launch.png";

function Overview() {
  const [warningSB, setWarningSB] = useState(true);
  const closeWarningSB = () => setWarningSB(false);

  return (
    <DashboardLayout>
      <Header>
        <MDBox mt={1} mb={1}>
          <Grid container spacing={1}>
            <Grid item xs={18} md={12} xl={6}>
              {/* Vertical Info Block 1 */}
              <Card sx={{ boxShadow: "none" }}>
                <MDBox p={2}>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  {"BlueGap"} API
                  </MDTypography>
                </MDBox>
                <MDBox pt={1} pb={1} px={2} lineHeight={1.25}>
                  <MDBox display="flex" alignItems="center" mb={0.5}>
                    <MDTypography variant="button" fontWeight="regular" color="text" /*textTransform="uppercase"*/>
                      {"BlueGap"} provides a read-only RESTful API to access resources provided as part of the platform's database. 
                      The API provides access to chain-like querying mechanisms to filter and join existing data for use by 
                      community users in their existing ecosystems and data.                    
                    </MDTypography>
                  </MDBox>

                  <MDBox display="flex" alignItems="center" mb={0.5} mt={2}>
                    <MDBox><MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
                      Access and Authentication
                    </MDTypography></MDBox>
                  </MDBox>
                  <MDBox display="flex" alignItems="center" mb={0.5}>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      The API does not require an access token or authorization and can be freely used in a manner that does not 
                      exceed reasonable request volume (i.e. fair usage). 
                    </MDTypography>
                  </MDBox>

                  <MDBox display="flex" alignItems="center" mb={0.5} mt={2}>
                    <MDBox><MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
                      Endpoint
                    </MDTypography></MDBox>
                  </MDBox>
                  <MDBox display="flex" alignItems="center" mb={0.5}>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      The endpoint to access the Data API of {"BlueGap"} can be found below.
                      {"BlueGap"} Data API relies on PostgREST and follows OpenAPI standards. 
                      You can construct requests in the same fashion you would build an SQL query for PostgreSQL.
                      <a href="https://postgrest.org/en/stable/api.html" target="_blank"> For more information on how to build requests, please refer to PostgREST documentation.</a>
                    </MDTypography>
                  </MDBox>

                  <MDBox
                    // component="li"
                    display="flex"
                    justifyContent="center"
                    alignItems="flex-start"
                    bgColor={"grey-100"}
                    borderRadius="lg"
                    p={3}
                    mb={1}
                    mt={2}
                  >
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      <a href="/" target="_blank">https://{"BlueGap"}.org/platform/api/postgrest/&#123;your-query-here&#125;</a>
                    </MDTypography>
                  </MDBox>

                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={18} md={12} xl={6} sx={{ display: "flex" }}>
              {/* <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} /> */}
              <Divider orientation="vertical" sx={{ mx: 0 }} />
              {/* Vertical Info Block 2 */}
              <Card sx={{ boxShadow: "none" }}>
                <MDBox p={2}>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  {"BlueGap"} API - GUI
                  </MDTypography>
                </MDBox>
                <MDBox pt={1} pb={1} px={2} lineHeight={1.25}>
                  <MDBox display="flex" alignItems="center" mb={2.5}>
                    <MDTypography variant="button" fontWeight="regular" color="text" /*textTransform="uppercase"*/>
                    {"BlueGap"} data resources can also be explored, filtered, analyzed, and downloaded via an interactive user interface. 
                    </MDTypography>
                  </MDBox>
                  <MDBox display="flex" alignItems="center" mb={2.5}>
                    <MDTypography variant="button" fontWeight="regular" color="text" /*textTransform="uppercase"*/>
                      The {"BlueGap"} API interface can support bathymetry-related platforms and research by providing an intuitive, accessible, 
                      and flexible API to discover data and download it in desired file formats (e.g. CSV, XML, JSON).
                    </MDTypography>
                  </MDBox>

                  {/* Button to open the UI for API */}
                  {/* <MDBox display="flex" alignItems="center" mb={0.5}> */}
                    <Card
                      sx={({ palette: { gradients }, functions: { linearGradient }, boxShadows: { xl } }) => ({
                        background: gradients["dark"]
                          ? linearGradient(gradients["dark"].main, gradients["dark"].state)
                          : linearGradient(gradients.dark.main, gradients.dark.state),
                        boxShadow: xl,
                        position: "relative",
                      })}
                    >
                      {/* <MDBox
                        position="absolute"
                        top={0}
                        left={0}
                        width="100%"
                        height="100%"
                        opacity={0.2}
                        sx={{
                          backgroundImage: `url(${apiLaunchImage})`,
                          backgroundSize: "cover",
                        }}
                      /> */}
                      <MDBox position="relative" zIndex={2} p={2}>
                        <ImageButton
                          focusRipple
                          key="api-launch"
                          style={{
                            width: "100%",
                          }}
                          href="api-gui"
                        >
                          <ImageSrc style={{ backgroundImage: `url(${apiLaunchImage})` }} />
                          <ImageBackdrop className="MuiImageBackdrop-root" />
                          <Image>
                            <Typography
                              className="MuiImageTypography-root"
                              component="span"
                              variant="h3"
                              color="inherit"
                              sx={{
                                position: 'relative',
                                p: 4,
                                pt: 2,
                                pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                              }}
                            >
                              Launch API Interface
                              <ImageMarked className="MuiImageMarked-root" />
                            </Typography>
                          </Image>
                        </ImageButton>
                      </MDBox>
                    </Card>
                </MDBox>
              </Card>

            </Grid>
          </Grid>
          <MDSnackbar
            color="warning"
            icon="star"
            title="BlueGap Database Notice"
            content="Database population is ongoing; data may not readily be available at all tables."
            dateTime={"As of "+new Date().toDateString()}
            open={warningSB}
            onClose={closeWarningSB}
            close={closeWarningSB}
            bgWhite
          />
        </MDBox>
      </Header>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Overview;

// Helper definitions

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  height: 200,
  [theme.breakpoints.down('sm')]: {
    width: '100% !important', // Overrides inline-style
    height: 100,
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageTypography-root': {
      color: "#1a73e8",
    },
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center 40%',
});

const Image = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: 'absolute',
  bottom: -2,
  left: 'calc(50% - 9px)',
  transition: theme.transitions.create('opacity'),
}));

