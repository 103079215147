// const PLATFORM_PATH = `/lab/bluegap/is`;
const PLATFORM_PATH = `/`;

const DOMAINROOT = window.location.origin.includes(`localhost`)
  ? window.location.origin
  : `https://uihilabwebci.org`; //TODO: Replace with actual domain

const HOMEPAGE = DOMAINROOT + PLATFORM_PATH;
//console.log(process.env.NODE_ENV);
const mainURL =
  process.env.NODE_ENV == "development"
    ? `https://umissis.org/bluegap`
    : "https://umissis.org/bluegap";

//aba next eta hai
const observationTimerangeEndpoints = {
  IWQIS: `https://umissis.org/umisapi/iwqis_param_timerange?&param=allp&`,
  STORET: `${mainURL}/storet_timeranges?`,
  NWISDV: `${mainURL}/nwis_timeranges?`,
  PWS: `${mainURL}/pws_timeranges?`,
  PWTS: `${mainURL}/pwts_timeranges?`,
  TBW: `${mainURL}/tbw_timeranges?`,
  SDWA: `${mainURL}/sdwa_timeranges?`,
  IADNR: `${mainURL}/iadnr_timeranges?`,
  NWISIV: `https://umissis.org/umisapi/iv_range?`,
  TBB: `${mainURL}/tbb_storet_timeranges?`,
  USVI: `${mainURL}/vi_storet_timeranges?`,
};

// const observationEndpoints = {
//     `IWQIS`: `https://api2.iwqis.iowawis.org/v1/hourly?`,
//     `STORET`: `https://umissis.org/umisapi/storet_obs?`,
//     `NWISDV`: `https://umissis.org/umisapi/nwis_dv_obs?`,
//     `NWISIV`: `https://umissis.org/umisapi/nwis_dv_obs?`,

//     // For the sake of brevity and backward-compatibility,
//     // NWISIV data resolution endpoints are provided as follows.
//     // Note: requirements changed since the start of the project;
//     // that is NWISIV are no longer treated as seperate sensors.
//     `NWISDV_hourly`: `https://umissis.org/umisapi/nwis_iv_hourly?`,
//     `NWISDV_daily`: `https://umissis.org/umisapi/nwis_dv_obs?`,
//     `NWISDV_weekly`: `https://umissis.org/umisapi/nwis_iv_weekly?`,
//     `NWISDV_monthly`: `https://umissis.org/umisapi/nwis_iv_monthly?`,

//     `IWQIS_hourly`: `https://api2.iwqis.iowawis.org/v1/hourly?`,
//     `IWQIS_daily`: `https://api2.iwqis.iowawis.org/v1/daily?`,
// }

const observationEndpoints = {
  IWQIS: `https://api2.iwqis.iowawis.org/v1/hourly?`,
  STORET: `${mainURL}/storet_obs?`,
  NWISDV: `${mainURL}/nwis_obs?`,
  NWISIV: `${mainURL}/nwis_obs?`,
  PWS: `${mainURL}/pws_obs?`,
  PWTS: `${mainURL}/pwts_obs?`,
  TBW: `${mainURL}/tbw_obs?`,
  SDWA: `${mainURL}/sdwa_obs?`,
  IADNR: `${mainURL}/iadnr_obs?`,
  TBB: `${mainURL}/tbb_obs?`,
  USVI: `${mainURL}/vi_obs?`,

  // For the sake of brevity and backward-compatibility,
  // NWISIV data resolution endpoints are provided as follows.
  // Note: requirements changed since the start of the project;
  // that is NWISIV are no longer treated as seperate sensors.
  NWISDV_hourly: `https://umissis.org/umisapi/nwis_iv_hourly?`,
  NWISDV_daily: `${mainURL}/nwis_obs?`,
  NWISDV_weekly: `https://umissis.org/umisapi/nwis_iv_weekly?`,
  NWISDV_monthly: `https://umissis.org/umisapi/nwis_iv_monthly?`,

  IWQIS_hourly: `https://api2.iwqis.iowawis.org/v1/hourly?`,
  IWQIS_daily: `https://api2.iwqis.iowawis.org/v1/daily?`,
};

const iwqisVariables = [
  { name: `Water Temperature (°c)`, abbr: `temp_water` },
  { name: `Discharge (FT3/S) cfs`, abbr: `discharge` },
  { name: `Yield`, abbr: `yield` },
  { name: `Load`, abbr: `load` },
  { name: `Specific Conductance (µS/cm)`, abbr: `spec_cond` },
  { name: `Dissolved Oxygen (mg/l)`, abbr: `diss_oxy_con` },
  { name: `pH`, abbr: `ph` },
  { name: `Turbidity (NTU)`, abbr: `turbi_mean` },
  { name: `Nitrate (mg/l)`, abbr: `nitrate_con` },

  { name: `Water Temperature (°c) w/ DS5Xv2`, abbr: `temp_water2` },
  { name: `Discharge w/ DS5Xv2`, abbr: `discharge2` },
  { name: `Yield w/ DS5Xv2`, abbr: `yield2` },
  { name: `Load w/ DS5Xv2`, abbr: `load2` },
  { name: `Specific Conductance w/ DS5Xv2 (µS/cm)`, abbr: `spec_cond2` },
  { name: `Dissolved Oxygen w/ DS5Xv2 (mg/l)`, abbr: `diss_oxy_con2` },
  { name: `pH w/ DS5Xv2`, abbr: `ph2` },
  { name: `Turbidity w/ DS5Xv2`, abbr: `turbi_mean2` },

  { name: `Turbidity w/ DTS12`, abbr: `turbi_mean3` },
];

const nwisVariables = [
  { name: `Discharge (FT3/S) (cfs)`, abbr: `discharge`, chartvalue: `p00060` },
  { name: `Nitrate (mg/l)`, abbr: `nitrate`, chartvalue: `p99133` },
];

const sdwaVariables = [{ name: `Nitrate (mg/l)`, abbr: `nitrate`, chartvalue: `nitrate` }];

const pwtsVariables = [
  { name: `Nitrite (mg/l)`, abbr: `nitrite`, chartvalue: `nitrite` },
  { name: `Nitrate (mg/l)`, abbr: `nitrate`, chartvalue: `nitrate` },
];

const pwsVariables = [
  { name: `Nitrite (mg/l)`, abbr: `no2`, chartvalue: `no2` },
  { name: `Nitrate (mg/l)`, abbr: `no3`, chartvalue: `no3` },
];

const tbwVariables = [
  { name: `Nitrite (mg/l)`, abbr: `no2`, chartvalue: `no2` },
  { name: `Nitrate (mg/l)`, abbr: `no3`, chartvalue: `no3` },
];

// const storetVariables = [
//     { name: 'Water Temperature (F)', abbr: `temp_f`, chartvalue: `p0002` },
//     { name: 'Air Temperature (F)', abbr: `temp_air_f`, chartvalue: `p0002` },
//     { name: 'Specific Conductance (MICROMHO)', abbr: `spec_cond`, chartvalue: `p0002` },
//     { name: `Phosphorus (mg/l)`, abbr: `phosphorus`, chartvalue: `p0002` },
//     { name: 'pH', abbr: `ph`, chartvalue: `p0002` },
//     { name: 'Turbidity (NTU)', abbr: `turbi`, chartvalue: `p0002` },
//     { name: 'Nitrate (mg/l)', abbr: `nitrate`, chartvalue: `p0002` },
// ]

const storetVariables = [
  { name: `Nitrate (mg/l)`, abbr: `nitrate`, chartvalue: `p0001` },
  { name: `Nitrate as N (mg/l)`, abbr: `nitrate_n`, chartvalue: `p0002` },
  { name: `Nitrate + Nitrite (mg/l)`, abbr: `n+n`, chartvalue: `p0003` },
  { name: `Inorganic nitrogen (nitrate and nitrite) (mg/l)`, abbr: `inorg_nitro`, chartvalue: `p0004` },
  {
    name: `Inorganic nitrogen (nitrate and nitrite) as N (mg/l)`,
    abbr: `inrog_nitro_n`,
    chartvalue: `p0005`,
  },
  {
    name: `Inorganic nitrogen (nitrate and nitrite and ammoni) (mg/l)`,
    abbr: `inorg_nitro_n_n_a`,
    chartvalue: `p0006`,
  },
  { name: `Nitrogen (mg/l)`, abbr: `nitro`, chartvalue: `p0007` },
  { name: `Nitrogen-15 (mg/l)`, abbr: `nitro_15`, chartvalue: `p0008` },
  { name: `Ammonia-nitrogen (mg/l)`, abbr: `ammonia_nitro`, chartvalue: `p0009` },
  { name: `Organic Nitrogen (mg/l)`, abbr: `org_nitro`, chartvalue: `p0010` },
  { name: `Kjeldahl nitrogen (mg/l)`, abbr: `kjeldahl_nitro`, chartvalue: `p0011` },
  { name: `Nutrient-nitrogen (mg/l)`, abbr: `nutri_nitro`, chartvalue: `p0012` },
  { name: `Ammonia-nitrogen as N (mg/l)`, abbr: `ammonia_nitro_N`, chartvalue: `p0013` },
  { name: `Total Kjeldahl nitrogen (mg/l)`, abbr: `total_kjel_nitro`, chartvalue: `p0014` },
  { name: `Total Particulate Nitrogen (mg/l)`, abbr: `total_parti_nitro`, chartvalue: `p0015` },
  { name: `Carbon/Nitrogen molar ratio (c/n ratio)`, abbr: `c/n_molar_ratio`, chartvalue: `p0016` },
];

const tbbVariables = [
  { name: `Nitrate (mg/l)`, abbr: `nitrate`, chartvalue: `p10001` },
  { name: `Nitrate Nitrite (mg/l)`, abbr: `nitrate_nitrite`, chartvalue: `p10002` },
  { name: `Organic Nitrogen (mg/l)`, abbr: `org_nitrogen`, chartvalue: `p10003` },
  { name: `Kjeldahl Nitrogen (mg/l)`, abbr: `kjeldahl_nitrogen`, chartvalue: `p10004` },
  {
    name: `Total Nitrogen (mg/l)`,
    abbr: `total_nitrogen`,
    chartvalue: `p10005`,
  },
];

const iadnrVariables = [
  { name: `Nitrate (mg/l)`, abbr: `nitrate`, chartvalue: `p30` },
  { name: `Nitrite (mg/l)`, abbr: `nitrite`, chartvalue: `p31` },
  { name: `Nitrite as N (mg/l)`, abbr: `nitrite_n`, chartvalue: `p26` },
  { name: `Nitrate as N (mg/l)`, abbr: `nitrate_n`, chartvalue: `p22` },
  { name: `Nitrate as NO3 (mg/l)`, abbr: `nitrate_no3`, chartvalue: `p23` },
  { name: `Organic nitrogen as N (mg/l)`, abbr: `org_nitro_n`, chartvalue: `p24` },
  { name: `Organic nitrogen (mg/l)`, abbr: `org_nitro`, chartvalue: `p25` },
  {
    name: `Inorganic nitrogen as N (mg/l)`,
    abbr: `inorg_nitro_n`,
    chartvalue: `p17`,
  },
  { name: `Inorganic nitrogen (mg/l)`, abbr: `inorg_nitro`, chartvalue: `p32` },
  { name: `Ammonia as N (mg/l)`, abbr: `ammonia_n`, chartvalue: `p21` },
  { name: `Ammonia as NH3 (mg/l)`, abbr: `ammonia_nh3`, chartvalue: `p28` },
  { name: `Ammonia (mg/l)`, abbr: `ammonia`, chartvalue: `p29` },
  { name: `Kjeldahl nitrogen (mg/l)`, abbr: `kjeldahl_nitro`, chartvalue: `p19` },
  { name: `Kjeldahl nitrogen as N (mg/l)`, abbr: `kjeldahl_nitro_n_mg_l`, chartvalue: `p18` },
  { name: `Ammonia-nitrogen as N (mg/l)`, abbr: `ammonia_nitro_N`, chartvalue: `p20` },
  { name: `Kjeldahl nitrogen as N (mg/kg)`, abbr: `kjeldahl_nitro_n_mg_kg`, chartvalue: `p27` },
];

const analytesList = [
  { nitrate: "Nitrate SDWA/PWTS" },
  { no3: "Nitrate PWS/TBW" },
  { nitrite: "Nitrite SDWA/PWTS" },
  { no2: "Nitrite PWS/TBW" },
  { p30: `Nitrate (mg/l) IADNR` },
  { p31: `Nitrite IADNR` },
  { p26: `Nitrite as N IADNR` },
  { p22: `Nitrate as N IADNR` },
  { p23: `Nitrate as NO3 IADNR` },
  { p24: `Organic nitrogen as N IADNR` },
  { p25: `Organic nitrogen IADNR` },
  {
    p17: `Inorganic nitrogen (nitrate and nitrite) as N IADNR`,
  },
  { p32: `Inorganic nitrogen (nitrate and nitrite) IADNR` },
  { p21: `Ammonia as N IADNR` },
  { p28: `Ammonia as NH3 IADNR` },
  { p29: `Ammonia IADNR` },
  { p19: `Kjeldahl nitrogen IADNR` },
  { p28: `Kjeldahl nitrogen as N mg/l IADNR` },
  { p20: `Ammonia-nitrogen as N IADNR` },
  { p27: `Kjeldahl nitrogen as N mg/kg IADNR` },
  { p0001: `Nitrate (mg/l) STORET` },
  { p0002: `Nitrate as N STORET` },
  { p0003: `Nitrate + Nitrite STORET` },
  { p0004: `Inorganic nitrogen (nitrate and nitrite) STORET` },
  {
    p0005: `Inorganic nitrogen (nitrate and nitrite) as N STORET`,
  },
  {
    p0006: `Inorganic nitrogen (nitrate and nitrite and ammoni) STORET`,
  },
  { p0007: `Nitrogen STORET` },
  { p0008: `Nitrogen-15 STORET` },
  { p0009: `Ammonia-nitrogen STORET` },
  { p0010: `Organic Nitrogen STORET` },
  { p0011: `Kjeldahl nitrogen STORET` },
  { p0012: `Nutrient-nitrogen STORET` },
  { p0013: `Ammonia-nitrogen as N STORET` },
  { p0014: `Total Kjeldahl nitrogen STORET` },
  { p0015: `Total Particulate Nitrogen STORET` },
  { p0016: `Carbon/Nitrogen molar ratio STORET` },
  { p00060: `Discharge (FT3/S) NWIS` },
  { p99133: `Nitrate (mg/l) NWIS` },
  { ncon: "Nitrate concentration IWQIS" },
  { ph: "pH IWQIS" },
  { spc: "Specific Conductance IWQIS" },
  { chcon: "Chlorophyill concentration IWQIS" },
  { docon: "Dissolved Oxygen Conc. IWQIS" },
  { dosat: "Dissolved Oxygen Sat. IWQIS" },
  { cv: "Chlorophyll V IWQIS" },
  { twv2: "Water temperature ds5x v2 IWQIS" },
  { phv2: "pH ds5x v2 IWQIS" },
  { spcv2: "Specific conductance  ds5x v2 IWQIS" },
  { doconv2: "Dissolved oxygen concentration ds5x v2 IWQIS" },
  { dosatv2: "Dissolved oxygen saturation ds5x v2 IWQIS" },
  { pcon: "Phosphorus concentration IWQIS" },
];

const dataSourceList = [
  { st: "Recreational Water STORET" },
  { n: "Recreational Water NWIS" },
  { i: "Recreational Water IADNR" },
  { iw: "Recreational Water IWQIS" },
];

// const storetEndpoints = {
//     State: `https://umissis.org/umisapi/storet_sites?param=state_fips&value=`,
//     County: `https://umissis.org/umisapi/storet_sites?param=fips&value=`,
//     City: `https://umissis.org/umisapi/storet_sites?param=ua_id&value=`,
//     Watershed: `https://umissis.org/umisapi/storet_sites?param=`,//huc4&value=1111
// }

const storetEndpoints = {
  State: `${mainURL}/sites?agency=storet&field=state_fips&value=`,
  County: `${mainURL}/sites?agency=storet&field=fips&value=`,
  City: `${mainURL}/sites?agency=storet&field=ua_label&value=`,
  "Zip Code": `${mainURL}/sites?agency=storet&field=zip_code&value=`,
  Place: `${mainURL}/sites?agency=storet&field=placename&value=`,
  Watershed: `${mainURL}/sites?agency=storet&field=`,
};

// const iwqisEndpoints = {
//     State: `https://umissis.org/umisapi/iwqissites?param=state_fips&value=`,
//     County: `https://umissis.org/umisapi/iwqissites?param=fips&value=`,
//     City: `https://umissis.org/umisapi/iwqissites?param=ua_id&value=`,
//     Watershed: `https://umissis.org/umisapi/iwqissites?param=`,
// }

const iwqisEndpoints = {
  State: `${mainURL}/sites?agency=iwqis&field=state_fips&value=`,
  County: `${mainURL}/sites?agency=iwqis&field=fips&value=`,
  City: `${mainURL}/sites?agency=iwqis&field=ua_label&value=`,
  "Zip Code": `${mainURL}/sites?agency=iwqis&field=zip_code&value=`,
  Place: `${mainURL}/sites?agency=iwqis&field=placename&value=`,
  Watershed: `${mainURL}/sites?agency=iwqis&field=`,
};

// const nwisdvEndpoints = {
//     State: `https://umissis.org/umisapi/nwis_sites?param=state_fips&value=`,
//     County: `https://umissis.org/umisapi/nwis_sites?param=fips&value=`,
//     City: `https://umissis.org/umisapi/nwis_sites?param=ua_id&value=`,
//     Watershed: `https://umissis.org/umisapi/nwis_sites?param=`,
// }

const nwisdvEndpoints = {
  State: `${mainURL}/sites?agency=nwis&field=state_fips&value=`,
  County: `${mainURL}/sites?agency=nwis&field=fips&value=`,
  City: `${mainURL}/sites?agency=nwis&field=ua_label&value=`,
  Watershed: `${mainURL}/sites?agency=nwis&field=`,
  "Zip Code": `${mainURL}/sites?agency=nwis&field=zip_code&value=`,
  Place: `${mainURL}/sites?agency=nwis&field=placename&value=`,
};

const waterCommunityEndpoints = {
  State: `${mainURL}/sites?agency=nwis&field=state_fips&value=`,
  County: `${mainURL}/wic_sites?extent=county&value=`,
  City: `${mainURL}/sites?agency=nwis&field=ua_label&value=`,
  Watershed: `${mainURL}/sites?agency=nwis&field=`,
  "Zip Code": `${mainURL}/wic_sites?extent=zip_code&value=`,
  Place: `${mainURL}/sites?agency=nwis&field=placename&value=`,
};

const recreationalWaterOceanEndpoints = {
  State: `${mainURL}/sites?agency=nwis&field=state_fips&value=`,
  County: `${mainURL}/rw_sites?extent=county&value=`,
  City: `${mainURL}/sites?agency=nwis&field=ua_label&value=`,
  Watershed: `${mainURL}/sites?agency=nwis&field=`,
  "Zip Code": `${mainURL}/rw_sites?extent=zip_code&value=`,
  Place: `${mainURL}/sites?agency=nwis&field=placename&value=`,
};

const pwtsEndpoints = {
  State: `${mainURL}/sites?agency=pwts&field=state_fips&value=`,
  County: `${mainURL}/gw_sites?extent=county&value=`,
  City: `${mainURL}/sites?agency=pwts&field=ua_label&value=`,
  "Zip Code": `${mainURL}/gw_sites?extent=zip_code&value=`,
  Watershed: `${mainURL}/sites?agency=pwts&field=`,
  Place: `${mainURL}/sites?agency=pwts&field=placename&value=`,
};

const sdwaEndpoints = {
  State: `${mainURL}/sites?agency=sdwa&field=state_fips&value=`,
  County: `${mainURL}/dw_sites?extent=county&value=`,
  City: `${mainURL}/sites?agency=sdwa&field=ua_label&value=`,
  "Zip Code": `${mainURL}/dw_sites?extent=zip_code&value=`,
  Watershed: `${mainURL}/sites?agency=sdwa&field=`,
  Place: `${mainURL}/sites?agency=sdwa&field=placename&value=`,
};

const iadnrSNAPSTEndpoints = {
  State: `${mainURL}/sites?agency=iasnapst&field=state_fips&value=`,
  County: `${mainURL}/sites?agency=iasnapst&field=fips&value=`,
  City: `${mainURL}/sites?agency=iasnapst&field=ua_label&value=`,
  "Zip Code": `${mainURL}/sites?agency=iasnapst&field=zip_code&value=`,
  Watershed: `${mainURL}/sites?agency=iasnapst&field=`,
  Place: `${mainURL}/sites?agency=iasnapst&field=placename&value=`,
};

const iadnr21IOWAEndpoints = {
  State: `${mainURL}/sites?agency=21iowa&field=state_fips&value=`,
  County: `${mainURL}/sites?agency=21iowa&field=fips&value=`,
  City: `${mainURL}/sites?agency=21iowa&field=ua_label&value=`,
  "Zip Code": `${mainURL}/sites?agency=21iowa&field=zip_code&value=`,
  Watershed: `${mainURL}/sites?agency=21iowa&field=`,
  Place: `${mainURL}/sites?agency=21iowa&field=placename&value=`,
};

const iadnrIOWAGWEndpoints = {
  State: `${mainURL}/sites?agency=iowagw&field=state_fips&value=`,
  County: `${mainURL}/sites?agency=iowagw&field=fips&value=`,
  City: `${mainURL}/sites?agency=iowagw&field=ua_label&value=`,
  "Zip Code": `${mainURL}/sites?agency=iowagw&field=zip_code&value=`,
  Watershed: `${mainURL}/sites?agency=iowagw&field=`,
  Place: `${mainURL}/sites?agency=iowagw&field=placename&value=`,
};

const iadnrNONDNREndpoints = {
  State: `${mainURL}/sites?agency=nondnr&field=state_fips&value=`,
  County: `${mainURL}/sites?agency=nondnr&field=fips&value=`,
  City: `${mainURL}/sites?agency=nondnr&field=ua_label&value=`,
  "Zip Code": `${mainURL}/sites?agency=nondnr&field=zip_code&value=`,
  Watershed: `${mainURL}/sites?agency=nondnr&field=`,
  Place: `${mainURL}/sites?agency=nondnr&field=placename&value=`,
};

const mapStyle = [
  {
    featureType: `landscape`,
    elementType: `geometry.fill`,
    stylers: [
      {
        color: `#7CA82C`,
      },
    ],
  },
  {
    featureType: `poi`,
    elementType: `geometry.fill`,
    stylers: [
      {
        color: `#7CA82C`,
      },
    ],
  },
  {
    featureType: `poi.park`,
    elementType: `geometry.fill`,
    stylers: [
      {
        color: `#7CA82C`,
      },
    ],
  },
  {
    featureType: `road`,
    elementType: `geometry.fill`,
    stylers: [
      {
        visibility: `off`,
      },
    ],
  },
  {
    featureType: `road.highway`,
    elementType: `geometry.stroke`,
    stylers: [
      {
        color: `#D3D3D3`,
      },
    ],
  },
  {
    featureType: `water`,
    elementType: `geometry.fill`,
    stylers: [
      {
        color: `#118285`,
      },
    ],
  },
  {
    featureType: `poi`,
    elementType: `labels`,
    stylers: [{ visibility: `off` }],
  },
  {
    featureType: `road`,
    elementType: `labels`,
    stylers: [{ visibility: `off` }],
  },
  {
    featureType: `transit`,
    elementType: `labels`,
    stylers: [{ visibility: `off` }],
  },
  {
    featureType: `administrative`,
    elementType: `geometry.fill`,
    stylers: [
      {
        color: `#7CA82C`,
      },
    ],
  },
  {
    featureType: `administrative.province`,
    elementType: `geometry.fill`,
    stylers: [
      {
        color: `#7CA82C`,
      },
    ],
  },
];

const analytesDetails = [
  {
    "Nitrate (mg/l)": [
      "s_nitrate",
      "p_nitrate",
      "pws_no3",
      "tbw_no3",
      "i_p30",
      "st_p0001",
      "vi_p0001",
      "tbb_p10001",
      "iw_ncon",
      "n_p99133",
    ],
  },
  { "Nitrite (mg/l)": ["s_nitrite", "p_nitrite", "pws_no2", "tbw_no2", "i_p31"] },
  { "Nitrate as N (mg/l)": ["st_p0002", "vi_p0002", "i_p22"] },
  { "Nitrite as N (mg/l)": ["i_p26"] },
  { "Nitrite as N03 (mg/l)": ["i_p23"] },
  { "Organic Nitrogen as N (mg/l)": ["i_p24"] },
  { "Organic Nitrogen (mg/l)": ["i_p25", "tbb_p10003"] },
  { "Nitrogen (mg/l)": ["st_p0007", "vi_p0007"] },
  { "Inorganic Nitrogen as N (mg/l)": ["st_p0005", "vi_p0005", "i_p17"] },
  { "Inorganic Nitrogen (Nitrate, Nitrite and Ammoni) (mg/l)": ["st_p0006", "vi_p0006"] },
  { "Ammonia as NH3 (mg/l)": ["i_p28"] },
  { "Total Nitrogen (mg/l)": ["tbb_p10005"] },
  { "Nitrate + Nitrite (mg/l)": ["st_p0003", "vi_p0003", "tbb_p10002"] },
  { "Inorganic Nitrogen (mg/l)": ["st_p0004", "vi_p0004", "i_p32"] },
  { "Kjeldahl Nitrogen (mg/l)": ["st_p0011", "vi_p0011", "i_p19", "tbb_p10004"] },
  { "Ammonia-nitrogen as N (mg/l)": ["st_p0013", "vi_p0013", "i_p20"] },
  { "Ammonia-nitrogen (mg/l)": ["st_p0009", "vi_p0009"] },
];

export {
  DOMAINROOT,
  PLATFORM_PATH,
  HOMEPAGE,
  storetVariables,
  nwisVariables,
  iwqisVariables,
  observationEndpoints,
  observationTimerangeEndpoints,
  storetEndpoints,
  iwqisEndpoints,
  nwisdvEndpoints,
  pwtsEndpoints,
  sdwaEndpoints,
  iadnrSNAPSTEndpoints,
  iadnr21IOWAEndpoints,
  iadnrIOWAGWEndpoints,
  iadnrNONDNREndpoints,
  iadnrVariables,
  sdwaVariables,
  pwtsVariables,
  mapStyle,
  mainURL,
  waterCommunityEndpoints,
  pwsVariables,
  tbwVariables,
  analytesList,
  dataSourceList,
  analytesDetails,
  recreationalWaterOceanEndpoints,
  tbbVariables,
};
