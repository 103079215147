import {
  Autocomplete,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import PlotlyObservationChart from "../PlotlyObservationChart";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import { DateRangePicker } from "rsuite";
import DataResolutionTab from "../DataResolutionTab";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { makeStyles } from "@mui/styles";
import DataReportBoxStyle from "../styles/DataReportBoxStyle";
import HistogramChart from "./Charts/HistogramChart";
import {
  calculateAverage,
  filterDataWithNullValues,
  getCountyObsData,
  getDataList,
  getSummaryObject,
  getZipCodeObsData,
  groupDataByAnalytes,
  groupKeysByAnalyte,
} from "../helpers/summaryAndStyleHelper";
import { analytesList } from "constants";
import { dataSourceList } from "constants";
import AnalyteSelector from "./AnalyteSelector";
// import countyGeoJSON3Year from "../../../assets/obs_Stats_County_3Year.json";
// import zipCodesGeoJSON3Year from "../../../assets/obs_Stats_Zip_3Year.json";
import { analytesDetails } from "constants";

const useStyles = makeStyles(DataReportBoxStyle);

function DataReportBox({
  countyObsData3Year,
  zipCodeObsData3Year,
  setShowDataReport,
  geoType,
  parsedJSONData,
  summaryObject,
  geoValue,
  state,
  showNotification,
  searchParams,
}) {
  const classes = useStyles();
  const [dataResolution, setDataResolution] = useState(0);
  // const countyObsData3Year = countyGeoJSON3Year;
  // const zipCodeObsData3Year = zipCodesGeoJSON3Year;

  const { afterToday, allowedRange } = DateRangePicker;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateRangeArr, setDateRangeArr] = useState([null, null]);
  const [obsData, setObsData] = useState([]);

  const setDateRange = (dateArray) => {
    // debugger;
    if (dateArray.length >= 2) {
      setStartDate(dateArray[0].toISOString());
      setEndDate(dateArray[1].toISOString());
      setDateRangeArr(dateArray);
    }
  };
  const clearDateRange = () => {
    setStartDate(null);
    setEndDate(null);
    setDateRangeArr([null, null]);
  };

  const [selectedVariable, setSelectedVariable] = useState(null);
  const setSelectedVariableHandler = (event, selectedVariable, reason) => {
    if (reason === "clear") {
      setSelectedVariable(null);
    }
    if (selectedVariable != null) {
      // console.log(selectedVariable);
      setSelectedVariable(selectedVariable);
    }
  };
  // set available parameters for the observation location
  const [availableVariables, setAvailableVariables] = useState([]);
  const setAvailableVariablesHandler = (sensorVariables) => {
    setAvailableVariables(sensorVariables);
    // debugger;
    if (sensorVariables.length > 0) {
      setSelectedVariableHandler(null, sensorVariables[0], null);
    } else {
      // no data was found for this sensor.
      setOpenPanel(false);
      showNotification("No observations are available for this location.", "error");
      setLoadingIndicator(false);
      ////console.log("not data fooune");
    }
  };

  const [tableSummary, setTableSummary] = useState(null);

  const handleDataResolutionChange = (event, value) => {
    setShowAdvanced(false);
    if (typeof value === "number") {
      setDataResolution(value);
      setSelectedVariable(availableVariables[1]);
      if (value == 0) {
        setMultiplier(3);
        let endpointURL = "";
        if (
          summaryObject.id == "12999" ||
          summaryObject.id == "33996" ||
          summaryObject.id == "33997" ||
          summaryObject.id == "33998" ||
          summaryObject.id == "33999" ||
          summaryObject.id == "33333" ||
          state == "VI"
        ) {
          if (geoType == "County") {
            state != "VI"
              ? (endpointURL = `https://umissis.org/bluegap/mos?geomtype=bay&startyear=2020&value=${geoValue}`)
              : (endpointURL = `https://umissis.org/bluegap/mos?geomtype=district&startyear=2020&value=${geoValue}`);
          } else {
            state != "VI"
              ? (endpointURL = `https://umissis.org/bluegap/mos?geomtype=bay_zip_code&startyear=2020&value=${geoValue}`)
              : (endpointURL = `https://umissis.org/bluegap/mos?geomtype=district_zip_code&startyear=2020&value=${geoValue}`);
          }
        } else {
          geoType == "County"
            ? (endpointURL = `https://umissis.org/bluegap/mos?geomtype=county&startyear=2020&value=${geoValue}`)
            : (endpointURL = `https://umissis.org/bluegap/mos?geomtype=zip_code&startyear=2020&value=${geoValue}`);
        }
        fetchObservationData(endpointURL)
          .then((obsData) => {
            let tempObsData = formatObservationData(obsData, value);
            setTableSummary(tempObsData);
            setObsData(tempObsData.data);
            setSelectedVariable(availableVariables[0]);
            setDateRange([
              new Date(tempObsData.data[0].dt),
              new Date(tempObsData.data[tempObsData.data.length - 1].dt),
            ]);
          })
          .catch((error) => {
            // Handle any errors that occurred during the data retrieval
            console.error(error);
          });
      } else {
        setMultiplier(23);
        let endpointURL = "";
        if (
          summaryObject.id == "12999" ||
          summaryObject.id == "33996" ||
          summaryObject.id == "33997" ||
          summaryObject.id == "33998" ||
          summaryObject.id == "33999" ||
          summaryObject.id == "33333" ||
          state == "VI"
        ) {
          if (geoType == "County") {
            state != "VI"
              ? (endpointURL = `https://umissis.org/bluegap/mos?geomtype=bay&startyear=2013&value=${geoValue}`)
              : (endpointURL = `https://umissis.org/bluegap/mos?geomtype=district&startyear=2013&value=${geoValue}`);
          } else {
            state != "VI"
              ? (endpointURL = `https://umissis.org/bluegap/mos?geomtype=bay_zip_code&startyear=2013&value=${geoValue}`)
              : (endpointURL = `https://umissis.org/bluegap/mos?geomtype=district_zip_code&startyear=2013&value=${geoValue}`);
          }
        } else {
          geoType == "County"
            ? (endpointURL = `https://umissis.org/bluegap/mos?geomtype=county&startyear=2013&value=${geoValue}`)
            : (endpointURL = `https://umissis.org/bluegap/mos?geomtype=zip_code&startyear=2013&value=${geoValue}`);
        }
        fetchObservationData(endpointURL)
          .then((obsData) => {
            let tempObsData = formatObservationData(obsData, value);
            setTableSummary(tempObsData);
            setObsData(tempObsData.data);
            setSelectedVariable(availableVariables[0]);
            setDateRange([
              new Date(tempObsData.data[0].dt),
              new Date(tempObsData.data[tempObsData.data.length - 1].dt),
            ]);
          })
          .catch((error) => {
            // Handle any errors that occurred during the data retrieval
            console.error(error);
          });
      }
    }
  };

  function fetchObservationData(endpointURL) {
    return new Promise((resolve, reject) => {
      fetch(endpointURL)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to retrieve observation data");
          }
          return response.json();
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  function formatObservationData(obsData, value) {
    if (obsData.notice) {
      return null;
    }
    // debugger;
    let dataReportObs = {};
    let processedObj = {};
    let summaryObject3Year = {};
    if (value == 0) {
      dataReportObs =
        geoType == "Zip Code"
          ? getZipCodeObsData(zipCodeObsData3Year, geoValue)
          : getCountyObsData(countyObsData3Year, geoValue);

      processedObj = getDataList(dataReportObs);

      summaryObject3Year = getSummaryObject(processedObj, dataReportObs, geoType, state);
    }

    let tempObj = {};
    let tempObsData = [];
    const aggregatedData = {};
    let summary = {};
    let groupedAnalytesData = {};

    if (geoType == "Zip Code") {
      // Iterate through the list of objects
      obsData.forEach((data) => {
        for (const key in data) {
          if (
            data[key] !== null &&
            (key.endsWith("_m") || key.endsWith("_mx") || key.endsWith("_a"))
          ) {
            if (!aggregatedData[key]) {
              aggregatedData[key] = [];
            }
            aggregatedData[key].push(data[key]);
          }
        }
      });
      // console.log("aggre", aggregatedData);

      for (const key in aggregatedData) {
        const value = aggregatedData[key];

        if (key.endsWith("_m")) {
          let tempMin = Math.min(...value);
          aggregatedData[key] = tempMin;
        }

        if (key.endsWith("_mx")) {
          let tempMax = Math.max(...value);
          aggregatedData[key] = tempMax;
        }

        if (key.endsWith("_a")) {
          let tempAvg = calculateAverage(value);
          aggregatedData[key] = tempAvg;
        }
      }

      groupedAnalytesData = groupDataByAnalytes(aggregatedData, analytesDetails);
    }

    obsData.forEach((item) => {
      tempObj = {};
      let sum_wc = 0;
      let sum_dw = 0;
      let sum_gw = 0;
      let sum_rw = 0;
      let sum_sites_wc = 0;

      tempObj.dt = item.date
        ? item.date
        : item.st_datetime
        ? item.st_datetime
        : item.tbb_datetime
        ? item.tbb_datetime
        : item.vi_datetime;
      tempObj.dw_sc =
        typeof item.s_sc == "number"
          ? item.s_sc
          : 0 + typeof item.pws_sc == "number"
          ? item.pws_sc
          : 0;
      tempObj.gw_sc =
        typeof item.tbw_sc == "number"
          ? item.tbw_sc
          : 0 + typeof item.p_sc == "number"
          ? item.p_sc
          : 0;
      tempObj.rw_sc =
        typeof item.tbb_sc == "number"
          ? item.tbb_sc
          : 0 + typeof item.vi_sc == "number"
          ? item.vi_sc
          : 0;

      for (const key in item) {
        const value = item[key];
        if (
          (key.startsWith("tbb_") || key.startsWith("vi_")) &&
          key.endsWith("_c") &&
          typeof value === "number"
        ) {
          sum_rw += value;
        } else if (
          key.endsWith("_c") &&
          key !== "s_nitrate_c" &&
          key !== "p_nitrate_c" &&
          key !== "pws_no3_c" &&
          key !== "tbw_no3_c" &&
          typeof value === "number"
        ) {
          sum_wc += value;
        } else if ((key === "s_nitrate_c" || key === "pws_no3_c") && typeof value === "number") {
          sum_dw += value;
        } else if ((key === "p_nitrate_c" || key === "tbw_no3_c") && typeof value === "number") {
          sum_gw += value;
        } else if (
          key.endsWith("_sc") &&
          key !== "s_sc" &&
          key !== "p_sc" &&
          key !== "pws_sc" &&
          key !== "tbw_sc" &&
          key !== "tbb_sc" &&
          key !== "vi_sc" &&
          typeof value === "number"
        ) {
          sum_sites_wc += value;
        }
      }
      tempObj.obs_count = sum_wc;
      tempObj.obs_count_dw = sum_dw;
      tempObj.obs_count_gw = sum_gw;
      tempObj.obs_count_rw = sum_rw;
      tempObj.wc_sc = sum_sites_wc;
      tempObsData.push(tempObj);
    });

    summary.sites_dw = value == 0 ? summaryObject3Year.drinkingWater : summaryObject.drinkingWater;
    summary.sites_rw =
      value == 0 ? summaryObject3Year.recreationalWater : summaryObject.recreationalWater;
    summary.sites_wc =
      value == 0 ? summaryObject3Year.waterCommunity : summaryObject.waterCommunity;
    summary.sites_gw = value == 0 ? summaryObject3Year.groundWater : summaryObject.groundWater;
    summary.obs_dw =
      value == 0 ? summaryObject3Year.drinkingWaterObsCount : summaryObject.drinkingWaterObsCount;
    summary.obs_rw =
      value == 0
        ? summaryObject3Year.recreationalWaterObsCount
        : summaryObject.recreationalWaterObsCount;
    summary.obs_wc =
      value == 0 ? summaryObject3Year.waterCommunityObsCount : summaryObject.waterCommunityObsCount;
    summary.obs_gw =
      value == 0 ? summaryObject3Year.groundWaterObsCount : summaryObject.groundWaterObsCount;
    //date sort
    tempObsData.sort((a, b) => new Date(a.dt) - new Date(b.dt));

    // console.log(summaryObject);
    // console.log(summaryObject3Year);

    summary.data = tempObsData;
    if (geoType == "Zip Code") {
      summary.min_wc =
        value == 0
          ? summaryObject3Year.waterCommunityQualitySummaryMin
            ? summaryObject3Year.waterCommunityQualitySummaryMin
            : 0.0
          : summaryObject.waterCommunityQualitySummaryMin
          ? summaryObject.waterCommunityQualitySummaryMin
          : 0.0;
      summary.max_wc =
        value == 0
          ? summaryObject3Year.waterCommunityQualitySummaryMax
            ? summaryObject3Year.waterCommunityQualitySummaryMax
            : 0.0
          : summaryObject.waterCommunityQualitySummaryMax
          ? summaryObject.waterCommunityQualitySummaryMax
          : 0.0;
      summary.avg_wc =
        value == 0
          ? summaryObject3Year.waterCommunityQualitySummaryAvg
            ? summaryObject3Year.waterCommunityQualitySummaryAvg
            : 0.0
          : summaryObject.waterCommunityQualitySummaryAvg
          ? summaryObject.waterCommunityQualitySummaryAvg
          : 0.0;

      summary.min_dw =
        value == 0
          ? summaryObject3Year.drinkingWaterQualitySummaryMin
            ? summaryObject3Year.drinkingWaterQualitySummaryMin
            : 0.0
          : summaryObject.drinkingWaterQualitySummaryMin
          ? summaryObject.drinkingWaterQualitySummaryMin
          : 0.0;
      summary.max_dw =
        value == 0
          ? summaryObject3Year.drinkingWaterQualitySummaryMax
            ? summaryObject3Year.drinkingWaterQualitySummaryMax
            : 0.0
          : summaryObject.drinkingWaterQualitySummaryMax
          ? summaryObject.drinkingWaterQualitySummaryMax
          : 0.0;
      summary.avg_dw =
        value == 0
          ? summaryObject3Year.drinkingWaterQualitySummaryAvg
            ? summaryObject3Year.drinkingWaterQualitySummaryAvg
            : 0.0
          : summaryObject.drinkingWaterQualitySummaryAvg
          ? summaryObject.drinkingWaterQualitySummaryAvg
          : 0.0;

      summary.min_gw =
        value == 0
          ? summaryObject3Year.groundWaterQualitySummaryMin
            ? summaryObject3Year.groundWaterQualitySummaryMin
            : 0.0
          : summaryObject.groundWaterQualitySummaryMin
          ? summaryObject.groundWaterQualitySummaryMin
          : 0.0;
      summary.max_gw =
        value == 0
          ? summaryObject3Year.groundWaterQualitySummaryMax
            ? summaryObject3Year.groundWaterQualitySummaryMax
            : 0.0
          : summaryObject.groundWaterQualitySummaryMax
          ? summaryObject.groundWaterQualitySummaryMax
          : 0.0;
      summary.avg_gw =
        value == 0
          ? summaryObject3Year.groundWaterQualitySummaryAvg
            ? summaryObject3Year.groundWaterQualitySummaryAvg
            : 0.0
          : summaryObject.groundWaterQualitySummaryAvg
          ? summaryObject.groundWaterQualitySummaryAvg
          : 0.0;

      summary.min_rw =
        value == 0
          ? summaryObject3Year.recreationalWaterQualitySummaryMin
            ? summaryObject3Year.recreationalWaterQualitySummaryMin
            : 0.0
          : summaryObject.recreationalWaterQualitySummaryMin
          ? summaryObject.recreationalWaterQualitySummaryMin
          : 0.0;
      summary.max_rw =
        value == 0
          ? summaryObject3Year.recreationalWaterQualitySummaryMax
            ? summaryObject3Year.recreationalWaterQualitySummaryMax
            : 0.0
          : summaryObject.recreationalWaterQualitySummaryMax
          ? summaryObject.recreationalWaterQualitySummaryMax
          : 0.0;
      summary.avg_rw =
        value == 0
          ? summaryObject3Year.recreationalWaterQualitySummaryAvg
            ? summaryObject3Year.recreationalWaterQualitySummaryAvg
            : 0.0
          : summaryObject.recreationalWaterQualitySummaryAvg
          ? summaryObject.recreationalWaterQualitySummaryAvg
          : 0.0;
    }

    summary.grouped_analytes_data = groupedAnalytesData;

    return summary;
  }

  useEffect(() => {
    let availableVariables = [];

    if (parsedJSONData ? parsedJSONData.state == "IA" : searchParams.get("state") == "IA") {
      availableVariables = [
        {
          abbr: "wc",
          name: "Recreational Water (stream)",
        },
        {
          abbr: "dw",
          name: "Drinking Water (public)",
        },
        {
          abbr: "gw",
          name: "Drinking Water (private)",
        },
      ];
    } else if (parsedJSONData ? parsedJSONData.state == "FL" : searchParams.get("state") == "FL") {
      availableVariables = [
        {
          abbr: "wc",
          name: "Recreational Water (stream)",
        },
        {
          abbr: "rw",
          name: "Recreational Water (ocean)",
        },
        {
          abbr: "dw",
          name: "Drinking Water (public)",
        },
        {
          abbr: "gw",
          name: "Drinking Water (private)",
        },
      ];
    } else if (parsedJSONData ? parsedJSONData.state == "VI" : searchParams.get("state") == "VI") {
      availableVariables = [
        {
          abbr: "rw",
          name: "Recreational Water (ocean)",
        },
      ];
    }

    let endpointURL = "";
    if (
      summaryObject.id == "12999" ||
      summaryObject.id == "33996" ||
      summaryObject.id == "33997" ||
      summaryObject.id == "33998" ||
      summaryObject.id == "33999" ||
      summaryObject.id == "33333" ||
      state == "VI"
    ) {
      // console.log("here", geoType);
      if (geoType == "County") {
        state != "VI"
          ? (endpointURL = `https://umissis.org/bluegap/mos?geomtype=bay&startyear=2020&value=${geoValue}`)
          : (endpointURL = `https://umissis.org/bluegap/mos?geomtype=district&startyear=2020&value=${geoValue}`);
      } else {
        state != "VI"
          ? (endpointURL = `https://umissis.org/bluegap/mos?geomtype=bay_zip_code&startyear=2020&value=${geoValue}`)
          : (endpointURL = `https://umissis.org/bluegap/mos?geomtype=district_zip_code&startyear=2020&value=${geoValue}`);
      }
    } else {
      geoType == "County"
        ? (endpointURL = `https://umissis.org/bluegap/mos?geomtype=county&startyear=2020&value=${geoValue}`)
        : (endpointURL = `https://umissis.org/bluegap/mos?geomtype=zip_code&startyear=2020&value=${geoValue}`);
    }

    // let obsData = getObservationData(endpointURL);
    fetchObservationData(endpointURL)
      .then((obsData) => {
        let tempObsData = formatObservationData(obsData, 0);
        // console.log(tempObsData);
        if (tempObsData == null) {
          setShowDataReport(false);
          showNotification("No observations are available for this location.", "error");
        } else {
          setTableSummary(tempObsData);
          setObsData(tempObsData.data);
          setAvailableVariables(availableVariables);
          setSelectedVariable(availableVariables[0]);
          setDateRange([
            new Date(tempObsData.data[0].dt),
            new Date(tempObsData.data[tempObsData.data.length - 1].dt),
          ]);
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the data retrieval
        console.error(error);
      });
  }, []);

  useEffect(() => {
    //debugger;
    //console.log("calling the API to get data based upon input.");
  }, [selectedVariable, startDate, endDate, dataResolution]);

  const [multiplier, setMultiplier] = useState(3);

  const [showAdvanced, setShowAdvanced] = useState(false);

  const [analyte, setAnalyte] = useState("");
  const [open, setOpen] = useState(false);

  const handleChangeSelect = (event) => {
    setAnalyte(event.target.value);
  };

  const [waterSource, setWaterSource] = useState("");
  const [openWaterSource, setOpenWaterSource] = useState(false);

  const handleChangeSelectWaterSource = (event) => {
    setWaterSource(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChangeAdvancedToggle = () => {
    // setAnalyte(Object.keys(tableSummary.grouped_analytes_list)[0]);
    setAnalyte(Object.keys(tableSummary.grouped_analytes_data)[0]);
    setShowAdvanced(!showAdvanced);
  };

  const [waterQualityData, setWaterQualityData] = useState(null);

  useEffect(() => {
    // console.log(tableSummary?.grouped_analytes_data);
    if (tableSummary?.grouped_analytes_data[analyte]) {
      let tempWaterData = {};
      let min_analytes_list = [];
      let max_analytes_list = [];
      let avg_analytes_list = [];
      tempWaterData = {
        drinkingWaterQualitySummaryMax: 0.0,
        drinkingWaterQualitySummaryMin: 0.0,
        drinkingWaterQualitySummaryAvg: 0.0,
        groundWaterQualitySummaryMax: 0.0,
        groundWaterQualitySummaryMin: 0.0,
        groundWaterQualitySummaryAvg: 0.0,
        recreationalWaterQualitySummaryMax: 0.0,
        recreationalWaterQualitySummaryMin: 0.0,
        recreationalWaterQualitySummaryAvg: 0.0,
        waterCommunityQualitySummaryMax: 0.0,
        waterCommunityQualitySummaryMin: 0.0,
        waterCommunityQualitySummaryAvg: 0.0,
      };

      for (const key in tableSummary.grouped_analytes_data[analyte]) {
        if (key.startsWith("s_") || key.startsWith("pws_")) {
          if (key.endsWith("mx")) {
            tempWaterData.drinkingWaterQualitySummaryMax = tableSummary.grouped_analytes_data[
              analyte
            ][key]
              ? parseFloat(tableSummary.grouped_analytes_data[analyte][key]).toFixed(1)
              : 0.0;
          } else if (key.endsWith("m")) {
            tempWaterData.drinkingWaterQualitySummaryMin = tableSummary.grouped_analytes_data[
              analyte
            ][key]
              ? parseFloat(tableSummary.grouped_analytes_data[analyte][key]).toFixed(1)
              : 0.0;
          } else {
            tempWaterData.drinkingWaterQualitySummaryAvg = tableSummary.grouped_analytes_data[
              analyte
            ][key]
              ? parseFloat(tableSummary.grouped_analytes_data[analyte][key]).toFixed(1)
              : 0.0;
          }
        } else if (key.startsWith("p_") || key.startsWith("tbw_")) {
          if (key.endsWith("mx")) {
            tempWaterData.groundWaterQualitySummaryMax = parseFloat(
              tableSummary.grouped_analytes_data[analyte][key]
            ).toFixed(1);
          } else if (key.endsWith("m")) {
            tempWaterData.groundWaterQualitySummaryMin = parseFloat(
              tableSummary.grouped_analytes_data[analyte][key]
            ).toFixed(1);
          } else {
            tempWaterData.groundWaterQualitySummaryAvg = parseFloat(
              tableSummary.grouped_analytes_data[analyte][key]
            ).toFixed(1);
          }
        } else if (key.startsWith("tbb_") || key.startsWith("vi_")) {
          if (key.endsWith("mx")) {
            tempWaterData.recreationalWaterQualitySummaryMax = parseFloat(
              tableSummary.grouped_analytes_data[analyte][key]
            ).toFixed(1);
          } else if (key.endsWith("m")) {
            tempWaterData.recreationalWaterQualitySummaryMin = parseFloat(
              tableSummary.grouped_analytes_data[analyte][key]
            ).toFixed(1);
          } else {
            tempWaterData.recreationalWaterQualitySummaryAvg = parseFloat(
              tableSummary.grouped_analytes_data[analyte][key]
            ).toFixed(1);
          }
        } else {
          if (key.endsWith("_mx")) {
            max_analytes_list.push(parseFloat(tableSummary.grouped_analytes_data[analyte][key]));
          } else if (key.endsWith("_m")) {
            min_analytes_list.push(parseFloat(tableSummary.grouped_analytes_data[analyte][key]));
          } else if (
            key.endsWith("_a") &&
            typeof tableSummary.grouped_analytes_data[analyte][key] === "number"
          ) {
            // console.log("values", parseFloat(tableSummary.grouped_analytes_data[analyte][key]));
            if (parseFloat(tableSummary.grouped_analytes_data[analyte][key]) != 0) {
              avg_analytes_list.push(parseFloat(tableSummary.grouped_analytes_data[analyte][key]));
            }
          }
        }
      }

      // console.log("avg", avg_analytes_list);

      tempWaterData.waterCommunityQualitySummaryMin =
        min_analytes_list.length === 0
          ? 0.0
          : parseFloat(Math.min(...min_analytes_list)).toFixed(1);
      tempWaterData.waterCommunityQualitySummaryMax =
        min_analytes_list.length === 0
          ? 0.0
          : parseFloat(Math.max(...max_analytes_list)).toFixed(1);
      tempWaterData.waterCommunityQualitySummaryAvg =
        avg_analytes_list.length === 0
          ? 0.0
          : parseFloat(calculateAverage(avg_analytes_list)).toFixed(1);

      //console.log("remp", tempWaterQualityData);
      setWaterQualityData(tempWaterData);
    }
  }, [analyte, waterSource, tableSummary]);

  return (
    <div
      style={{
        margin: "0 auto",
        paddingTop: "1.3%",
      }}
    >
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card
            sx={{
              maxWidth: "50%",
              "@media (max-width: 1600px)": {
                maxWidth: "55%", // Change the left position for screens with a width greater than or equal to 1920px
              },
            }}
          >
            {obsData.length !== 0 ? (
              <>
                <MDBox
                  style={{
                    height: "240px",
                    border: `2px solid black`,
                    boxShadow:
                      "0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(76 175 80 / 40%)",
                  }}
                  mt={2}
                  ml={2}
                  mr={2}
                  mb={1.5}
                >
                  <HistogramChart
                    provider="none"
                    data={[obsData]}
                    selectedVariableAbbr={selectedVariable?.abbr}
                    selectedVariableName={selectedVariable?.name}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </MDBox>
                <MDBox
                  pb={1}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "green",
                    position: "relative",
                    zIndex: 2,
                  }}
                >
                  <DataResolutionTab
                    style={{ background: "transparent" }}
                    dataResolutionValue={dataResolution}
                    handleDataResolutionChange={handleDataResolutionChange}
                    activeColor={"orange"}
                    tabs={[
                      {
                        tabName: (
                          <span style={{ fontSize: "12px", marginRight: "5px" }}>3-YEAR</span>
                        ),
                        tabIcon: HourglassEmptyIcon,
                      },
                      {
                        tabName: (
                          <span style={{ fontSize: "12px", marginRight: "5px" }}>10-YEAR</span>
                        ),
                        tabIcon: CalendarTodayIcon,
                      },
                    ]}
                  />
                  {showAdvanced ? (
                    <>
                      <AnalyteSelector
                        width={150}
                        analytes_list={tableSummary?.grouped_analytes_data}
                        label="Analyte"
                        open={open}
                        handleClose={handleClose}
                        handleOpen={handleOpen}
                        analyte={analyte}
                        handleChangeSelect={handleChangeSelect}
                      />
                    </>
                  ) : null}
                </MDBox>

                <MDBox style={{ padding: "0px 10px 10px 15px" }}>
                  <Table
                    className={classes.table}
                    sx={{ width: geoType === "Zip Code" ? "100%" : "100%" }}
                  >
                    <TableBody>
                      <TableRow className={classes.tableBodyRow}>
                        {geoType == "Zip Code" ? (
                          <TableCell
                            className={classes.tableSummaryCellWQ}
                            style={{ width: geoType === "Zip Code" ? "30%" : "30%" }}
                          >
                            {showAdvanced ? (
                              <span style={{ color: `blue` }} className={classes.dataTitle}>
                                Data Source
                              </span>
                            ) : (
                              <span style={{ color: `blue` }} className={classes.dataTitle}>
                                Data Source - Nitrate (mg/l)
                              </span>
                            )}
                          </TableCell>
                        ) : (
                          <TableCell
                            className={classes.tableSummaryCellWQ}
                            style={{ width: geoType === "Zip Code" ? "30%" : "30%" }}
                          >
                            <span style={{ color: `blue` }} className={classes.dataTitle}>
                              Data Source
                            </span>
                          </TableCell>
                        )}

                        <TableCell
                          className={classes.tableSummaryCell}
                          style={{
                            textAlign: "right",
                            width: geoType === "Zip Code" ? "10%" : "35%",
                          }}
                        >
                          <span style={{ color: `blue` }} className={classes.dataTitle}>
                            Sites
                          </span>
                        </TableCell>
                        <TableCell
                          className={classes.tableSummaryCell}
                          style={{
                            textAlign: "right",
                            width: geoType === "Zip Code" ? "15%" : "35%",
                          }}
                        >
                          <span style={{ color: `blue` }} className={classes.dataTitle}>
                            Record &nbsp;
                          </span>
                        </TableCell>
                        {geoType === "Zip Code" ? (
                          <>
                            <TableCell
                              className={classes.tableSummaryCellWQ}
                              style={{ textAlign: "right", width: "10%" }}
                            >
                              <span style={{ color: `blue` }} className={classes.dataTitle}>
                                Min
                              </span>
                            </TableCell>
                            <TableCell
                              className={classes.tableSummaryCellWQ}
                              style={{ textAlign: "right", width: "10%" }}
                            >
                              <span style={{ color: `blue` }} className={classes.dataTitle}>
                                Max &nbsp;
                              </span>
                            </TableCell>
                            <TableCell
                              className={classes.tableSummaryCellWQ}
                              style={{ textAlign: "right", width: "10%" }}
                            >
                              <span style={{ color: `blue` }} className={classes.dataTitle}>
                                Avg &nbsp;
                              </span>
                            </TableCell>
                            {/* <TableCell
                              className={classes.tableSummaryCellWQ}
                              style={{ textAlign: "right", width: "10%" }}
                            >
                              <span style={{ color: `blue` }} className={classes.dataTitle}>
                                S.D &nbsp;
                              </span>
                            </TableCell> */}
                          </>
                        ) : null}
                      </TableRow>
                    </TableBody>
                    <TableBody>
                      {(parsedJSONData
                        ? parsedJSONData.state == "IA"
                        : searchParams.get("state") == "IA") ||
                      (parsedJSONData
                        ? parsedJSONData.state == "FL"
                        : searchParams.get("state") == "FL") ? (
                        <TableRow className={classes.tableBodyRow}>
                          <TableCell className={classes.tableSummaryCellWQ}>
                            <span
                              style={{
                                color: `black`,
                                fontFamily: "PFDinTextCompProMedium",
                                fontSize: "16px",
                                width: geoType === "Zip Code" ? "30%" : "30%",
                              }}
                              className={classes.dataTitle}
                            >
                              Recreational Water (stream)
                            </span>
                          </TableCell>
                          <TableCell
                            className={classes.tableSummaryCell}
                            style={{
                              textAlign: "right",
                              width: geoType === "Zip Code" ? "10%" : "35%",
                            }}
                          >
                            <span className={classes.dataTitle}>{tableSummary?.sites_wc}</span>
                          </TableCell>
                          <TableCell
                            className={classes.tableSummaryCell}
                            style={{
                              textAlign: "right",
                              width: geoType === "Zip Code" ? "15%" : "35%",
                            }}
                          >
                            <span className={classes.dataTitle}>
                              {tableSummary?.obs_wc.toLocaleString()} &nbsp;
                            </span>
                          </TableCell>
                          {geoType === "Zip Code" ? (
                            <>
                              <TableCell
                                className={classes.tableSummaryCellWQ}
                                style={{ textAlign: "right", width: "10%" }}
                              >
                                <span className={classes.dataTitle}>
                                  {showAdvanced
                                    ? waterQualityData?.waterCommunityQualitySummaryMin
                                    : tableSummary?.min_wc}
                                </span>
                              </TableCell>
                              <TableCell
                                className={classes.tableSummaryCellWQ}
                                style={{ textAlign: "right", width: "10%" }}
                              >
                                <span className={classes.dataTitle}>
                                  {showAdvanced
                                    ? waterQualityData?.waterCommunityQualitySummaryMax
                                    : tableSummary?.max_wc}{" "}
                                  &nbsp;
                                </span>
                              </TableCell>
                              <TableCell
                                className={classes.tableSummaryCellWQ}
                                style={{ textAlign: "right", width: "10%" }}
                              >
                                <span className={classes.dataTitle}>
                                  {showAdvanced
                                    ? waterQualityData?.waterCommunityQualitySummaryAvg
                                    : tableSummary?.avg_wc}{" "}
                                  &nbsp;
                                </span>
                              </TableCell>
                              {/* <TableCell
                              className={classes.tableSummaryCellWQ}
                              style={{ textAlign: "right", width: "10%" }}
                            >
                              <span className={classes.dataTitle}>0.0 &nbsp;</span>
                            </TableCell> */}
                            </>
                          ) : null}
                        </TableRow>
                      ) : null}

                      {(parsedJSONData
                        ? parsedJSONData.state == "VI"
                        : searchParams.get("state") == "VI") ||
                      (parsedJSONData
                        ? parsedJSONData.state == "FL"
                        : searchParams.get("state") == "FL") ? (
                        <TableRow className={classes.tableBodyRow}>
                          <TableCell className={classes.tableSummaryCellWQ}>
                            <span
                              style={{
                                color: `black`,
                                fontFamily: "PFDinTextCompProMedium",
                                fontSize: "16px",
                                width: geoType === "Zip Code" ? "30%" : "30%",
                              }}
                              className={classes.dataTitle}
                            >
                              Recreational Water (ocean)
                            </span>
                          </TableCell>
                          <TableCell
                            className={classes.tableSummaryCell}
                            style={{
                              textAlign: "right",
                              width: geoType === "Zip Code" ? "10%" : "35%",
                            }}
                          >
                            <span className={classes.dataTitle}>{tableSummary?.sites_rw}</span>
                          </TableCell>
                          <TableCell
                            className={classes.tableSummaryCell}
                            style={{
                              textAlign: "right",
                              width: geoType === "Zip Code" ? "15%" : "35%",
                            }}
                          >
                            <span className={classes.dataTitle}>
                              {tableSummary?.obs_rw.toLocaleString()} &nbsp;
                            </span>
                          </TableCell>
                          {geoType === "Zip Code" ? (
                            <>
                              <TableCell
                                className={classes.tableSummaryCellWQ}
                                style={{ textAlign: "right", width: "10%" }}
                              >
                                <span className={classes.dataTitle}>
                                  {showAdvanced
                                    ? waterQualityData?.recreationalWaterQualitySummaryMin
                                    : tableSummary?.min_rw}
                                </span>
                              </TableCell>
                              <TableCell
                                className={classes.tableSummaryCellWQ}
                                style={{ textAlign: "right", width: "10%" }}
                              >
                                <span className={classes.dataTitle}>
                                  {showAdvanced
                                    ? waterQualityData?.recreationalWaterQualitySummaryMax
                                    : tableSummary?.max_rw}{" "}
                                  &nbsp;
                                </span>
                              </TableCell>
                              <TableCell
                                className={classes.tableSummaryCellWQ}
                                style={{ textAlign: "right", width: "10%" }}
                              >
                                <span className={classes.dataTitle}>
                                  {showAdvanced
                                    ? waterQualityData?.recreationalWaterQualitySummaryAvg
                                    : tableSummary?.avg_rw}{" "}
                                  &nbsp;
                                </span>
                              </TableCell>
                              {/* <TableCell
                            className={classes.tableSummaryCellWQ}
                            style={{ textAlign: "right", width: "10%" }}
                          >
                            <span className={classes.dataTitle}>0.0 &nbsp;</span>
                          </TableCell> */}
                            </>
                          ) : null}
                        </TableRow>
                      ) : null}

                      {(parsedJSONData
                        ? parsedJSONData.state == "IA"
                        : searchParams.get("state") == "IA") ||
                      (parsedJSONData
                        ? parsedJSONData.state == "FL"
                        : searchParams.get("state") == "FL") ? (
                        <TableRow className={classes.tableBodyRow}>
                          <TableCell className={classes.tableSummaryCellWQ}>
                            <span
                              style={{
                                color: `black`,
                                fontFamily: "PFDinTextCompProMedium",
                                fontSize: "16px",
                                width: geoType === "Zip Code" ? "30%" : "30%",
                              }}
                              className={classes.dataTitle}
                            >
                              Drinking Water (public)
                            </span>
                          </TableCell>
                          <TableCell
                            className={classes.tableSummaryCell}
                            style={{
                              textAlign: "right",
                              width: geoType === "Zip Code" ? "10%" : "35%",
                            }}
                          >
                            <span className={classes.dataTitle}>{tableSummary?.sites_dw}</span>
                          </TableCell>
                          <TableCell
                            className={classes.tableSummaryCell}
                            style={{
                              textAlign: "right",
                              width: geoType === "Zip Code" ? "15%" : "35%",
                            }}
                          >
                            <span className={classes.dataTitle}>
                              {tableSummary?.obs_dw.toLocaleString()} &nbsp;
                            </span>
                          </TableCell>
                          {geoType === "Zip Code" ? (
                            <>
                              <TableCell
                                className={classes.tableSummaryCellWQ}
                                style={{ textAlign: "right", width: "10%" }}
                              >
                                <span className={classes.dataTitle}>
                                  {showAdvanced
                                    ? waterQualityData?.drinkingWaterQualitySummaryMin
                                    : tableSummary?.min_dw}
                                </span>
                              </TableCell>
                              <TableCell
                                className={classes.tableSummaryCellWQ}
                                style={{ textAlign: "right", width: "10%" }}
                              >
                                <span className={classes.dataTitle}>
                                  {showAdvanced
                                    ? waterQualityData?.drinkingWaterQualitySummaryMax
                                    : tableSummary?.max_dw}{" "}
                                  &nbsp;
                                </span>
                              </TableCell>
                              <TableCell
                                className={classes.tableSummaryCellWQ}
                                style={{ textAlign: "right", width: "10%" }}
                              >
                                <span className={classes.dataTitle}>
                                  {showAdvanced
                                    ? waterQualityData?.drinkingWaterQualitySummaryAvg
                                    : tableSummary?.avg_dw}{" "}
                                  &nbsp;
                                </span>
                              </TableCell>
                              {/* <TableCell
                              className={classes.tableSummaryCellWQ}
                              style={{ textAlign: "right", width: "10%" }}
                            >
                              <span className={classes.dataTitle}>
                                {summaryObject?.drinkingWaterQualitySummarySD != null
                                  ? summaryObject?.drinkingWaterQualitySummarySD
                                  : 0.0}{" "}
                                &nbsp;
                              </span>
                            </TableCell> */}
                            </>
                          ) : null}
                        </TableRow>
                      ) : null}

                      {(parsedJSONData
                        ? parsedJSONData.state == "IA"
                        : searchParams.get("state") == "IA") ||
                      (parsedJSONData
                        ? parsedJSONData.state == "FL"
                        : searchParams.get("state") == "FL") ? (
                        <TableRow className={classes.tableBodyRow}>
                          <TableCell className={classes.tableSummaryCellWQ}>
                            <span
                              style={{
                                color: `black`,
                                fontFamily: "PFDinTextCompProMedium",
                                fontSize: "16px",
                                width: geoType === "Zip Code" ? "30%" : "30%",
                              }}
                              className={classes.dataTitle}
                            >
                              Drinking Water (private)
                            </span>
                          </TableCell>
                          <TableCell
                            className={classes.tableSummaryCell}
                            style={{
                              textAlign: "right",
                              width: geoType === "Zip Code" ? "10%" : "35%",
                            }}
                          >
                            <span className={classes.dataTitle}>{tableSummary?.sites_gw}</span>
                          </TableCell>
                          <TableCell
                            className={classes.tableSummaryCell}
                            style={{
                              textAlign: "right",
                              width: geoType === "Zip Code" ? "15%" : "35%",
                            }}
                          >
                            <span className={classes.dataTitle}>
                              {tableSummary?.obs_gw.toLocaleString()} &nbsp;
                            </span>
                          </TableCell>
                          {geoType === "Zip Code" ? (
                            <>
                              <TableCell
                                className={classes.tableSummaryCellWQ}
                                style={{ textAlign: "right", width: "10%" }}
                              >
                                <span className={classes.dataTitle}>
                                  {showAdvanced
                                    ? waterQualityData?.groundWaterQualitySummaryMin
                                    : tableSummary?.min_gw}
                                </span>
                              </TableCell>
                              <TableCell
                                className={classes.tableSummaryCellWQ}
                                style={{ textAlign: "right", width: "10%" }}
                              >
                                <span className={classes.dataTitle}>
                                  {showAdvanced
                                    ? waterQualityData?.groundWaterQualitySummaryMax
                                    : tableSummary?.max_gw}{" "}
                                  &nbsp;
                                </span>
                              </TableCell>
                              <TableCell
                                className={classes.tableSummaryCellWQ}
                                style={{ textAlign: "right", width: "10%" }}
                              >
                                <span className={classes.dataTitle}>
                                  {showAdvanced
                                    ? waterQualityData?.groundWaterQualitySummaryAvg
                                    : tableSummary?.avg_gw}{" "}
                                  &nbsp;
                                </span>
                              </TableCell>
                              {/* <TableCell
                              className={classes.tableSummaryCellWQ}
                              style={{ textAlign: "right", width: "10%" }}
                            >
                              <span className={classes.dataTitle}>0.0 &nbsp;</span>
                            </TableCell> */}
                            </>
                          ) : null}
                        </TableRow>
                      ) : null}
                    </TableBody>
                  </Table>
                </MDBox>

                <div
                  style={{
                    display: "flex",
                    position: "relative",
                    borderBottom: "0.0625rem solid #f0f2f5",
                    paddingBottom: "7px",
                  }}
                >
                  <MDBox pt={1} style={{ flexGrow: 1, margin: "0px 10px 0px 10px" }}>
                    <Autocomplete
                      id="variable-selector"
                      size="small"
                      options={availableVariables}
                      value={selectedVariable}
                      getOptionLabel={(option) => option.name}
                      style={{}}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Source"
                          shrink="false"
                          variant="outlined"
                        />
                      )}
                      onChange={setSelectedVariableHandler}
                    />
                  </MDBox>

                  <MDBox pt={1} style={{ margin: "0px 10px 0px 0px" }}>
                    <Typography sx={{ fontWeight: "300", fontSize: "18px" }}>
                      {dateRangeArr[0].toLocaleDateString() +
                        " - " +
                        dateRangeArr[1].toLocaleDateString()}
                    </Typography>
                  </MDBox>
                </div>
                <MDBox pt={1} pb={1} px={1}>
                  <MDButton
                    color="error"
                    onClick={() => {
                      setShowDataReport(false);
                    }}
                    sx={{ float: "right" }}
                  >
                    Close
                  </MDButton>
                  {geoType == "Zip Code" ? (
                    <MDButton
                      style={{
                        backgroundColor: `#d2dde4`,
                        color: "#000",
                        float: "right",
                        marginRight: "10px",
                      }}
                      variant="contained"
                      onClick={handleChangeAdvancedToggle}
                    >
                      {showAdvanced ? "Hide" : "Advanced"}
                    </MDButton>
                  ) : null}
                </MDBox>
              </>
            ) : null}
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default DataReportBox;
