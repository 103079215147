import { analytesDetails } from "constants";
import { dataSourceList } from "constants";
import { analytesList } from "constants";

export function groupDataByAnalytes(apiResponse, analytesDetails) {
  const groupedData = {};

  for (const analyteDetail of analytesDetails) {
    const analyteKey = Object.keys(analyteDetail)[0];
    const analyteAliases = analyteDetail[analyteKey];

    const templist = [];
    const tempObj = {};
    for (const alias of analyteAliases) {
      const keys = ["_m", "_mx", "_a"];
      // const tempObj = {};
      for (const keySuffix of keys) {
        const apiResponseKey = `${alias}${keySuffix}`;
        const apiResponseValue = apiResponse[apiResponseKey];

        if (apiResponseValue !== null && apiResponseValue !== undefined) {
          tempObj[apiResponseKey] = apiResponseValue;
        }
      }
    }
    if (Object.keys(tempObj).length > 0) {
      templist.push(tempObj);
    }
    if (templist.length > 0) {
      groupedData[analyteKey] = tempObj;
    }
  }

  return groupedData;
}

export function getDataList(featureObs) {
  let min_dt_list = [];
  let min_gw_dt_list = [];
  let min_dw_dt_list = [];
  let min_rw_dt_list = [];
  let max_dt_list = [];
  let max_gw_dt_list = [];
  let max_dw_dt_list = [];
  let max_rw_dt_list = [];
  let min_analytes_list = [];
  let min_gw_analytes_list = [];
  let min_dw_analytes_list = [];
  let min_rw_analytes_list = [];
  let max_analytes_list = [];
  let max_gw_analytes_list = [];
  let max_dw_analytes_list = [];
  let max_rw_analytes_list = [];
  let avg_analytes_list = [];
  let avg_gw_analytes_list = [];
  let avg_dw_analytes_list = [];
  let avg_rw_analytes_list = [];
  let sum_obs_wC = 0;
  let sum_sites_wc = 0;
  let sum_sites_gw = 0;
  let sum_obs_gw = 0;
  let sum_sites_dw = 0;
  let sum_obs_dw = 0;
  let sum_sites_rw = 0;
  let sum_obs_rw = 0;
  let groupedAnalytes = {};
  let groupedDataSource = {};
  let groupedAnalytesList = {};
  let groupedAnalytesData = {};

  // console.log(filterDataWithNullValues(featureObs));
  if (featureObs?.zip_code) {
    let filteredNullValues = filterDataWithNullValues(featureObs);
    groupedAnalytes = groupKeysByAnalyte(filteredNullValues, 1);

    for (const item of analytesList) {
      for (const key in item) {
        if (groupedAnalytes[key]) {
          groupedAnalytesList[key] = item[key];
        }
      }
    }

    //console.log(groupedAnalytes);

    let tempGroupedDataSource = groupKeysByAnalyte(filteredNullValues, 0);
    for (const item of dataSourceList) {
      for (const key in item) {
        if (tempGroupedDataSource[key]) {
          groupedDataSource[key] = item[key];
        }
      }
    }
    //console.log(groupedDataSource);

    groupedAnalytesData = groupDataByAnalytes(featureObs, analytesDetails);
  }

  // console.log(featureObs);

  for (const key in featureObs) {
    const value = featureObs[key];
    // debugger;
    if (
      (key.startsWith("tbb_") || key.startsWith("vi_")) &&
      key.endsWith("_c") &&
      typeof value === "number"
    ) {
      sum_obs_rw += value;
    } else if (
      key.endsWith("_c") &&
      key !== "s_nitrate_c" &&
      key !== "p_nitrate_c" &&
      key !== "pws_no3_c" &&
      key !== "tbw_no3_c" &&
      key !== "s_nitrite_c" &&
      key !== "p_nitrite_c" &&
      key !== "pws_no2_c" &&
      key !== "tbw_no2_c" &&
      key !== "tbw_no2_c" &&
      typeof value === "number"
    ) {
      sum_obs_wC += value;
    } else if ((key === "s_sc" || key === "pws_sc") && typeof value === "number") {
      sum_sites_dw += value;
    } else if ((key === "p_sc" || key === "tbw_sc") && typeof value === "number") {
      sum_sites_gw += value;
    } else if ((key === "pws_no3_c" || key === "s_nitrate_c") && typeof value === "number") {
      sum_obs_dw += value;
    } else if ((key === "p_nitrate_c" || key === "tbw_no3_c") && typeof value === "number") {
      sum_obs_gw += value;
    } else if ((key === "tbb_sc" || key === "vi_sc") && typeof value === "number") {
      sum_sites_rw += value;
    } else if (
      key.endsWith("_sc") &&
      key !== "s_sc" &&
      key !== "p_sc" &&
      key !== "pws_sc" &&
      key !== "tbw_sc" &&
      typeof value === "number"
    ) {
      sum_sites_wc += value;
    } else if ((key == "tbb_mdt" || key == "vi_mdt") && value != null) {
      min_rw_dt_list.push(new Date(value).getFullYear());
    } else if ((key == "tbb_mxdt" || key == "vi_mxdt") && value != null) {
      max_rw_dt_list.push(new Date(value).getFullYear());
    } else if (
      key.endsWith("_mdt") &&
      key !== "s_mdt" &&
      key !== "pws_mdt" &&
      key !== "tbw_mdt" &&
      key !== "p_mdt" &&
      value != null
    ) {
      min_dt_list.push(new Date(value).getFullYear());
    } else if (
      key.endsWith("_mxdt") &&
      key !== "s_mxdt" &&
      key !== "pws_mxdt" &&
      key !== "tbw_mxdt" &&
      key !== "p_mxdt" &&
      value != null
    ) {
      max_dt_list.push(new Date(value).getFullYear());
    } else if ((key == "s_mdt" || key == "pws_mdt") && value != null) {
      min_dw_dt_list.push(new Date(value).getFullYear());
    } else if ((key == "s_mxdt" || key == "pws_mxdt") && value != null) {
      max_dw_dt_list.push(new Date(value).getFullYear());
    } else if ((key == "p_mdt" || key == "tbw_mdt") && value != null) {
      min_gw_dt_list.push(new Date(value).getFullYear());
    } else if ((key == "p_mxdt" || key == "tbw_mxdt") && value != null) {
      max_gw_dt_list.push(new Date(value).getFullYear());
    } else if ((key == "s_nitrate_m" || key == "pws_no3_m") && typeof value === "number") {
      min_dw_analytes_list.push(value);
    } else if ((key == "s_nitrate_mx" || key == "pws_no3_mx") && typeof value === "number") {
      max_dw_analytes_list.push(value);
    } else if ((key == "s_nitrate_a" || key == "pws_no3_a") && typeof value === "number") {
      avg_dw_analytes_list.push(value);
    } else if ((key == "p_nitrate_m" || key == "tbw_no3_m") && typeof value === "number") {
      min_gw_analytes_list.push(value);
    } else if ((key == "p_nitrate_mx" || key == "tbw_no3_mx") && typeof value === "number") {
      max_gw_analytes_list.push(value);
    } else if ((key == "p_nitrate_a" || key == "tbw_no3_a") && typeof value === "number") {
      avg_gw_analytes_list.push(value);
    } else if ((key == "tbb_p10001_m" || key == "vi_p0001_m") && typeof value === "number") {
      min_rw_analytes_list.push(value);
    } else if ((key == "tbb_p10001_mx" || key == "vi_p0001_mx") && typeof value === "number") {
      max_rw_analytes_list.push(value);
    } else if ((key == "tbb_p10001_a" || key == "vi_p0001_a") && typeof value === "number") {
      avg_rw_analytes_list.push(value);
    } else if (
      (key == "n_p99133_m" ||
        key == "p_nitrate_m" ||
        key == "st_p0001_m" ||
        key == "i_p30_m" ||
        key == "iw_ncon_m") &&
      key !== "s_nitrate_m" &&
      typeof value === "number"
    ) {
      min_analytes_list.push(value);
    } else if (
      (key == "n_p99133_mx" ||
        key == "p_nitrate_mx" ||
        key == "st_p0001_mx" ||
        key == "i_p30_mx" ||
        key == "iw_ncon_mx") &&
      key !== "s_nitrate_mx" &&
      typeof value === "number"
    ) {
      max_analytes_list.push(value);
    } else if (
      (key == "n_p99133_a" || key == "st_p0001_a" || key == "i_p30_a" || key == "iw_ncon_a") &&
      typeof value === "number"
    ) {
      avg_analytes_list.push(value);
    }
  }

  return {
    min_dt_list: min_dt_list,
    max_dt_list: max_dt_list,
    avg_analytes_list: avg_analytes_list,
    max_analytes_list: max_analytes_list,
    min_analytes_list: min_analytes_list,
    min_gw_dt_list: min_gw_dt_list,
    max_gw_dt_list: max_gw_dt_list,
    min_dw_dt_list: min_dw_dt_list,
    max_dw_dt_list: max_dw_dt_list,
    min_rw_dt_list: min_rw_dt_list,
    max_rw_dt_list: max_rw_dt_list,
    avg_gw_analytes_list: avg_gw_analytes_list,
    max_gw_analytes_list: max_gw_analytes_list,
    min_gw_analytes_list: min_gw_analytes_list,
    avg_dw_analytes_list: avg_dw_analytes_list,
    max_dw_analytes_list: max_dw_analytes_list,
    min_dw_analytes_list: min_dw_analytes_list,
    avg_rw_analytes_list: avg_rw_analytes_list,
    max_rw_analytes_list: max_rw_analytes_list,
    min_rw_analytes_list: min_rw_analytes_list,
    sum_obs_wC: sum_obs_wC,
    sum_sites_wc: sum_sites_wc,
    sum_sites_rw: sum_sites_rw,
    sum_sites_gw: sum_sites_gw,
    sum_obs_gw: sum_obs_gw,
    sum_sites_dw: sum_sites_dw,
    sum_obs_dw: sum_obs_dw,
    sum_obs_rw: sum_obs_rw,
    analytes_list: groupedAnalytes,
    grouped_analytes_list: groupedAnalytesList,
    data_source: groupedDataSource,
    grouped_analytes_data: groupedAnalytesData,
  };
}

// Function to group keys with the same analyte under a common object
export function groupKeysByAnalyte(obj, idx) {
  const groupedObject = {};

  for (const key in obj) {
    // Analyte name is assumed to be the part before the underscore
    if (key.endsWith("a") || key.endsWith("m") || key.endsWith("mx")) {
      const analyteName = key.split("_")[idx];

      if (!groupedObject[analyteName]) {
        groupedObject[analyteName] = {};
      }

      // Remove the analyte name from the key
      const subKey = key.replace(analyteName + "_", "");

      groupedObject[analyteName][subKey] = obj[key];
    }
  }

  return groupedObject;
}

// Function to filter out objects with null values
export function filterDataWithNullValues(obj) {
  const filteredObject = {};
  for (const key in obj) {
    if (obj[key] !== null) {
      filteredObject[key] = obj[key]; // Only include non-null properties
    }
  }
  return filteredObject;
}

export function getZipCodeStyle(zipCodeObsData, zipCode, state) {
  let zipTemp = zipCodeObsData.find(
    (item) =>
      item.i_zip_code === zipCode ||
      item.iw_zip_code === zipCode ||
      item.p_zip_code === zipCode ||
      item.n_zip_code === zipCode ||
      item.st_zip_code === zipCode ||
      item.s_zip_code === zipCode ||
      item.zip_code === zipCode
  );
  let sum_c = 0;

  let processedObj = getDataList(zipTemp);

  sum_c =
    processedObj.sum_obs_wC +
    processedObj.sum_obs_gw +
    processedObj.sum_obs_dw +
    processedObj.sum_obs_rw;

  const color = getColor(sum_c, state, true);

  return {
    strokeWeight: 3,
    fillOpacity: 0.5,
    strokeColor: "red",
    visible: true,
    fillColor: color,
    zIndex: 2,
  };
}

export function getZipCodeObsData(zipCodeObsData, zip_code) {
  let featureObs = zipCodeObsData.find(
    (item) =>
      item.i_zip_code === zip_code ||
      item.iw_zip_code === zip_code ||
      item.p_zip_code === zip_code ||
      item.n_zip_code === zip_code ||
      item.st_zip_code === zip_code ||
      item.s_zip_code === zip_code ||
      item.zip_code === zip_code
  );

  return featureObs;
}

export function getCountyStyle(countyObsData, fips, state) {
  let featureObs = countyObsData.find(
    (item) =>
      item.i_fips === fips ||
      item.iw_fips === fips ||
      item.st_fips === fips ||
      item.n_fips === fips ||
      item.p_fips === fips ||
      item.s_fips === fips ||
      item.fips == fips
  );
  // //console.log("value yei ho?",featureObs.i_sc);
  let sum_c = 0;

  let processedObj = getDataList(featureObs);

  sum_c =
    processedObj.sum_obs_wC +
    processedObj.sum_obs_rw +
    processedObj.sum_obs_gw +
    processedObj.sum_obs_dw;

  // console.log("sum_c", sum_c);
  const color = getColor(sum_c, state, false);
  return {
    fillColor: color, // Set the fill color for the polygons
    fillOpacity: 0.5, // Set the fill opacity
    strokeWeight: 1, // Set the stroke weight
    strokeColor: "black", // Set the stroke color
  };
}

export function getCountyObsData(countyObsData, fips) {
  let featureObs = countyObsData.find(
    (item) =>
      item.i_fips === fips ||
      item.iw_fips === fips ||
      item.st_fips === fips ||
      item.n_fips === fips ||
      item.p_fips === fips ||
      item.s_fips === fips ||
      item.fips == fips
  );

  return featureObs;
}

export function getColor(value, state, isZipCode) {
  // if (state == "IA") {
  //   if (value > 100000) {
  //     return "green";
  //   } else if (value > 10000 && value < 100000) {
  //     return "yellow";
  //   } else {
  //     return "#FA5B3D";
  //   }
  // } else {
  //   if (value > 10000) {
  //     return "green";
  //   } else if (value > 1000 && value < 10000) {
  //     return "yellow";
  //   } else {
  //     return "#FA5B3D";
  //   }
  // }
  let tempValue = 0;

  if (state == "IA") {
    if (!isZipCode) {
      if (value < 10000) {
        tempValue = Math.floor(value / 400);
      } else if (value > 10000 && value < 100000) {
        tempValue =
          (value - 10000) / 1800 + 25 > 100 ? 100 : Math.floor((value - 10000) / 1800 + 25);
      } else {
        tempValue =
          (value - 100000) / 36000 + 75 > 100 ? 100 : Math.floor((value - 100000) / 36000 + 75);
      }
    } else {
      if (value < 1000) {
        tempValue = Math.floor(value / 40);
      } else if (value > 1000 && value < 10000) {
        tempValue = (value - 1000) / 180 + 25 > 100 ? 100 : Math.floor((value - 1000) / 180 + 25);
      } else {
        tempValue =
          (value - 10000) / 3600 + 75 > 100 ? 100 : Math.floor((value - 10000) / 3600 + 75);
      }
    }
  } else {
    if (!isZipCode) {
      if (value < 1000) {
        tempValue = Math.floor(value / 40);
      } else if (value > 1000 && value < 10000) {
        tempValue = (value - 1000) / 180 + 25 > 100 ? 100 : Math.floor((value - 1000) / 180 + 25);
      } else {
        tempValue =
          (value - 10000) / 3600 + 75 > 100 ? 100 : Math.floor((value - 10000) / 3600 + 75);
      }
    } else {
      if (value < 100) {
        tempValue = Math.floor(value / 4);
      } else if (value > 100 && value < 1000) {
        tempValue = (value - 100) / 18 + 25 > 100 ? 100 : Math.floor((value - 100) / 18 + 25);
      } else {
        tempValue = (value - 1000) / 360 + 75 > 100 ? 100 : Math.floor((value - 1000) / 360 + 75);
      }
    }
  }
  // console.log(tempValue);
  if (tempValue >= 75) {
    return "green";
  } else if (tempValue > 25 && tempValue < 75) {
    return "yellow";
  } else {
    return "#FA5B3D";
  }
}

export function getDemoIndexColor(value) {
  //console.log(value);
  if (value < 50) {
    return "rgb(217, 217, 217)";
  } else if (value >= 50 && value < 60) {
    return "rgb(181, 181, 181)";
  } else if (value >= 60 && value < 70) {
    return "rgb(145, 145, 145)";
  } else if (value >= 70 && value < 80) {
    return "rgb(107, 107, 107)";
  } else if (value >= 80 && value < 90) {
    return "rgb(255, 255, 115)";
  } else if (value >= 90 && value < 95) {
    return "rgb(230, 152, 0)";
  } else if (value >= 95 && value < 100) {
    return "rgb(168, 0, 0)";
  } else {
    return "white";
  }
}

export function calculateAverage(numbers) {
  if (numbers.length === 0) {
    return 0; // Return 0 for an empty list or handle the case accordingly
  }

  const sum = numbers.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const average = sum / numbers.length;

  return average;
}

export function getSummaryObject(processedObj, featureObs, geoType, state) {
  let tempObj = {};
  let analytes_list = [];
  // console.log("pobj", processedObj);

  // let minDateDW = new Date(featureObs.s_mdt).getFullYear();
  // let maxDateDW = new Date(featureObs.s_mxdt).getFullYear();

  if (processedObj.min_dw_dt_list.length || processedObj.max_dw_dt_list.length > 0) {
    tempObj.dateRangeDW =
      (Math.min(...processedObj.min_dw_dt_list) == "1969"
        ? ""
        : Math.min(...processedObj.min_dw_dt_list)) +
      " - " +
      (Math.max(...processedObj.max_dw_dt_list) == "1969"
        ? ""
        : Math.max(...processedObj.max_dw_dt_list));
  } else {
    tempObj.dateRangeDW = " " + "-" + " ";
  }

  if (processedObj.min_dt_list.length || processedObj.max_dt_list.length > 0) {
    tempObj.dateRangeWC =
      (Math.min(...processedObj.min_dt_list) == "1969"
        ? ""
        : Math.min(...processedObj.min_dt_list)) +
      " - " +
      (Math.max(...processedObj.max_dt_list) == "1969"
        ? ""
        : Math.max(...processedObj.max_dt_list));
  } else {
    tempObj.dateRangeWC = " " + "-" + " ";
  }

  if (processedObj.min_gw_dt_list.length || processedObj.max_gw_dt_list.length > 0) {
    tempObj.dateRangeGW =
      (Math.min(...processedObj.min_gw_dt_list) == "1969"
        ? ""
        : Math.min(...processedObj.min_gw_dt_list)) +
      " - " +
      (Math.max(...processedObj.max_gw_dt_list) == "1969"
        ? ""
        : Math.max(...processedObj.max_gw_dt_list));
  } else {
    tempObj.dateRangeGW = " " + "-" + " ";
  }

  if (processedObj.min_rw_dt_list.length || processedObj.max_rw_dt_list.length > 0) {
    tempObj.dateRangeRW =
      (Math.min(...processedObj.min_rw_dt_list) == "1969"
        ? ""
        : Math.min(...processedObj.min_rw_dt_list)) +
      " - " +
      (Math.max(...processedObj.max_rw_dt_list) == "1969"
        ? ""
        : Math.max(...processedObj.max_rw_dt_list));
  } else {
    tempObj.dateRangeRW = " " + "-" + " ";
  }

  tempObj.drinkingWater = processedObj.sum_sites_dw;
  tempObj.groundWater = processedObj.sum_sites_gw;
  tempObj.recreationalWater = processedObj.sum_sites_rw;
  tempObj.waterCommunity = processedObj.sum_sites_wc;
  tempObj.waterCommunityObsCount = processedObj.sum_obs_wC;
  tempObj.groundWaterObsCount = processedObj.sum_obs_gw;
  tempObj.drinkingWaterObsCount = processedObj.sum_obs_dw;
  tempObj.recreationalWaterObsCount = processedObj.sum_obs_rw;
  tempObj.analytes_list = processedObj.analytes_list;
  tempObj.grouped_analytes_list = processedObj.grouped_analytes_list;
  tempObj.data_source = processedObj.data_source;
  tempObj.grouped_analytes_data = processedObj.grouped_analytes_data;
  //Yaha ho hai mamms!
  let totalRecords =
    processedObj.sum_obs_wC +
    tempObj.drinkingWaterObsCount +
    tempObj.groundWaterObsCount +
    tempObj.recreationalWaterObsCount;

  if (state == "IA") {
    if (geoType == "County") {
      if (totalRecords < 10000) {
        tempObj.chartValue = Math.floor(totalRecords / 400);
      } else if (totalRecords > 10000 && totalRecords < 100000) {
        tempObj.chartValue =
          (totalRecords - 10000) / 1800 + 25 > 100
            ? 100
            : Math.floor((totalRecords - 10000) / 1800 + 25);
      } else {
        tempObj.chartValue =
          (totalRecords - 100000) / 36000 + 75 > 100
            ? 100
            : Math.floor((totalRecords - 100000) / 36000 + 75);
      }
    } else {
      if (totalRecords < 1000) {
        tempObj.chartValue = Math.floor(totalRecords / 40);
      } else if (totalRecords > 1000 && totalRecords < 10000) {
        tempObj.chartValue =
          (totalRecords - 1000) / 180 + 25 > 100
            ? 100
            : Math.floor((totalRecords - 1000) / 180 + 25);
      } else {
        tempObj.chartValue =
          (totalRecords - 10000) / 3600 + 75 > 100
            ? 100
            : Math.floor((totalRecords - 10000) / 3600 + 75);
      }
    }
  } else {
    if (geoType == "County") {
      if (totalRecords < 1000) {
        tempObj.chartValue = Math.floor(totalRecords / 40);
      } else if (totalRecords > 1000 && totalRecords < 10000) {
        tempObj.chartValue =
          (totalRecords - 1000) / 180 + 25 > 100
            ? 100
            : Math.floor((totalRecords - 1000) / 180 + 25);
      } else {
        tempObj.chartValue =
          (totalRecords - 10000) / 3600 + 75 > 100
            ? 100
            : Math.floor((totalRecords - 10000) / 3600 + 75);
      }
    } else {
      if (totalRecords < 100) {
        tempObj.chartValue = Math.floor(totalRecords / 4);
      } else if (totalRecords > 100 && totalRecords < 1000) {
        tempObj.chartValue =
          (totalRecords - 100) / 18 + 25 > 100 ? 100 : Math.floor((totalRecords - 100) / 18 + 25);
      } else {
        tempObj.chartValue =
          (totalRecords - 1000) / 360 + 75 > 100
            ? 100
            : Math.floor((totalRecords - 1000) / 360 + 75);
      }
    }
  }
  tempObj.geoType = geoType;
  if (geoType == "Zip Code" || geoType == "County") {
    tempObj.drinkingWaterQualitySummaryMin =
      processedObj.min_dw_analytes_list.length === 0
        ? 0.0
        : parseFloat(Math.min(...processedObj.min_dw_analytes_list)).toFixed(1);
    tempObj.drinkingWaterQualitySummaryMax =
      processedObj.max_dw_analytes_list.length === 0
        ? 0.0
        : parseFloat(Math.min(...processedObj.max_dw_analytes_list)).toFixed(1);
    tempObj.drinkingWaterQualitySummaryAvg =
      processedObj.avg_dw_analytes_list.length === 0
        ? 0.0
        : parseFloat(calculateAverage(processedObj.avg_dw_analytes_list)).toFixed(1);
    tempObj.drinkingWaterQualitySummarySD =
      typeof parseFloat(featureObs.s_nitrate_sd).toFixed(1) == "number"
        ? parseFloat(featureObs.s_nitrate_sd).toFixed(1)
        : 0.0;

    tempObj.waterCommunityQualitySummaryMin =
      processedObj.min_analytes_list.length === 0
        ? 0.0
        : parseFloat(Math.min(...processedObj.min_analytes_list)).toFixed(1);
    tempObj.waterCommunityQualitySummaryMax =
      processedObj.max_analytes_list.length === 0
        ? 0.0
        : parseFloat(Math.max(...processedObj.max_analytes_list)).toFixed(1);
    // console.log("avg here", processedObj.avg_analytes_list);
    tempObj.waterCommunityQualitySummaryAvg =
      processedObj.avg_analytes_list.length === 0
        ? 0.0
        : parseFloat(calculateAverage(processedObj.avg_analytes_list)).toFixed(1);

    tempObj.groundWaterQualitySummaryMin =
      processedObj.min_gw_analytes_list.length === 0
        ? 0.0
        : parseFloat(Math.min(...processedObj.min_gw_analytes_list)).toFixed(1);
    tempObj.groundWaterQualitySummaryMax =
      processedObj.max_gw_analytes_list.length === 0
        ? 0.0
        : parseFloat(Math.max(...processedObj.max_gw_analytes_list)).toFixed(1);
    tempObj.groundWaterQualitySummaryAvg =
      processedObj.avg_gw_analytes_list.length === 0
        ? 0.0
        : parseFloat(calculateAverage(processedObj.avg_gw_analytes_list)).toFixed(1);

    tempObj.recreationalWaterQualitySummaryMin =
      processedObj.min_rw_analytes_list.length === 0
        ? 0.0
        : parseFloat(Math.min(...processedObj.min_rw_analytes_list)).toFixed(1);
    tempObj.recreationalWaterQualitySummaryMax =
      processedObj.max_rw_analytes_list.length === 0
        ? 0.0
        : parseFloat(Math.min(...processedObj.max_rw_analytes_list)).toFixed(1);
    tempObj.recreationalWaterQualitySummaryAvg =
      processedObj.avg_rw_analytes_list.length === 0
        ? 0.0
        : parseFloat(calculateAverage(processedObj.avg_rw_analytes_list)).toFixed(1);

    if (state == "IA") {
      if (tempObj.drinkingWaterQualitySummaryAvg <= 0) {
        tempObj.drinkingWaterQualitySummaryPercentile = 25;
      } else if (
        tempObj.drinkingWaterQualitySummaryAvg > 0 &&
        tempObj.drinkingWaterQualitySummaryAvg <= 1.2
      ) {
        // tempObj.drinkingWaterQualitySummaryPercentile = 25 + (((1.2-0)/(drinkingWaterQualitySummaryAvg - 0))*25);
        tempObj.drinkingWaterQualitySummaryPercentile = 50;
      } else {
        tempObj.drinkingWaterQualitySummaryPercentile = 75;
      }

      if (tempObj.waterCommunityQualitySummaryAvg <= 4.19) {
        tempObj.waterCommunityQualitySummaryPercentile = 25;
      } else if (
        tempObj.waterCommunityQualitySummaryAvg > 4.19 &&
        tempObj.waterCommunityQualitySummaryAvg <= 8.23
      ) {
        tempObj.waterCommunityQualitySummaryPercentile = 50;
      } else {
        tempObj.waterCommunityQualitySummaryPercentile = 75;
      }

      if (tempObj.groundWaterQualitySummaryAvg <= 4.19) {
        tempObj.groundWaterQualitySummaryPercentile = 25;
      } else if (
        tempObj.groundWaterQualitySummaryAvg > 4.19 &&
        tempObj.groundWaterQualitySummaryAvg <= 8.23
      ) {
        tempObj.groundWaterQualitySummaryPercentile = 50;
      } else {
        tempObj.groundWaterQualitySummaryPercentile = 75;
      }
    }

    if (state == "FL") {
      if (tempObj.drinkingWaterQualitySummaryAvg <= 0) {
        tempObj.drinkingWaterQualitySummaryPercentile = 25;
      } else if (
        tempObj.drinkingWaterQualitySummaryAvg > 0 &&
        tempObj.drinkingWaterQualitySummaryAvg <= 0.03
      ) {
        tempObj.drinkingWaterQualitySummaryPercentile = 50;
      } else {
        tempObj.drinkingWaterQualitySummaryPercentile = 75;
      }

      if (tempObj.waterCommunityQualitySummaryAvg <= 0.18) {
        tempObj.waterCommunityQualitySummaryPercentile = 25;
      } else if (
        tempObj.waterCommunityQualitySummaryAvg > 0.18 &&
        tempObj.waterCommunityQualitySummaryAvg <= 0.298
      ) {
        tempObj.waterCommunityQualitySummaryPercentile = 50;
      } else {
        tempObj.waterCommunityQualitySummaryPercentile = 75;
      }

      if (tempObj.groundWaterQualitySummaryAvg <= 0.075) {
        tempObj.groundWaterQualitySummaryPercentile = 25;
      } else if (
        tempObj.groundWaterQualitySummaryAvg > 0.075 &&
        tempObj.groundWaterQualitySummaryAvg <= 6.3
      ) {
        tempObj.groundWaterQualitySummaryPercentile = 50;
      } else {
        tempObj.groundWaterQualitySummaryPercentile = 75;
      }

      if (tempObj.recreationalWaterQualitySummaryAvg <= 4.19) {
        tempObj.recreationalWaterQualitySummaryPercentile = 25;
      } else if (
        tempObj.recreationalWaterQualitySummaryAvg > 4.19 &&
        tempObj.recreationalWaterQualitySummaryAvg <= 8.23
      ) {
        tempObj.recreationalWaterQualitySummaryPercentile = 50;
      } else {
        tempObj.recreationalWaterQualitySummaryPercentile = 75;
      }
    }

    if (state == "VI") {
      if (tempObj.waterCommunityQualitySummaryAvg <= 0.3) {
        tempObj.waterCommunityQualitySummaryPercentile = 25;
      } else if (
        tempObj.waterCommunityQualitySummaryAvg > 0.3 &&
        tempObj.waterCommunityQualitySummaryAvg <= 0.38
      ) {
        tempObj.waterCommunityQualitySummaryPercentile = 50;
      } else {
        tempObj.waterCommunityQualitySummaryPercentile = 75;
      }

      if (tempObj.recreationalWaterQualitySummaryAvg <= 0.28) {
        tempObj.recreationalWaterQualitySummaryPercentile = 25;
      } else if (
        tempObj.recreationalWaterQualitySummaryAvg > 0.28 &&
        tempObj.recreationalWaterQualitySummaryAvg <= 0.35
      ) {
        tempObj.recreationalWaterQualitySummaryPercentile = 50;
      } else {
        tempObj.recreationalWaterQualitySummaryPercentile = 75;
      }
    }
  }

  // console.log("tempObj", tempObj);

  return tempObj;
}

export function getDemographicData(geoType, eventType, bounds, fips, gid, demographicData) {
  // var swLatLng = bounds.getSouthWest();
  // var neLatLng = bounds.getNorthEast();

  // var swLat = swLatLng.lat();
  // var swLng = swLatLng.lng();
  // var neLat = neLatLng.lat();
  // var neLng = neLatLng.lng();

  // let urlEx = `https://umissis.org/bluegap/tract_demography?table=county&x1=${swLng}&y1=${swLat}&x2=${neLng}&y2=${neLat}`;
  let urlEx = `https://umissis.org/bluegap/tract_demography2?table=county&value=${fips}`;
  // console.log(demographicData, "url");
  let demoObject = {};
  let over64 = 0;
  let unemployed = 0;
  let under5 = 0;
  let lowincpct = 0;
  let lesshspct = 0;
  let peopcolorp = 0;
  let lingisopct = 0;
  let demoindex = 0;
  let tempDemograph = [];

  if (eventType == "click") {
    fetch(urlEx)
      .then((res) => res.json())
      .then((data) => {
        if (geoType == "County") {
          tempDemograph = data.features.filter((feature) => feature.properties.fips == fips);
        } else if (geoType == "Zip Code") {
          tempDemograph = data.features;
        } else {
          tempDemograph = data.features.filter((feature) => feature.properties.gid == gid);
        }
      });
  } else {
    if (demographicData.length > 0) {
      tempDemograph = demographicData[0][0].features.filter(
        (feature) => feature.properties.fips == fips
      );
    } else {
      demoObject.over64 = 0;
      demoObject.unemployed = 0;
      demoObject.under5 = 0;
      demoObject.lowincpct = 0;
      demoObject.lesshspct = 0;
      demoObject.peopcolorp = 0;
      demoObject.lingisopct = 0;
      demoObject.demoindex = 0;
      return demoObject;
    }
  }

  tempDemograph.forEach((item) => {
    over64 += item.properties.over64;
    unemployed += item.properties.unemppct;
    under5 += item.properties.under5;
    lowincpct += item.properties.lowincpct;
    lesshspct += item.properties.lesshspct;
    peopcolorp += item.properties.peopcolorp;
    lingisopct += item.properties.lingisopct;
    demoindex += item.properties.p_demogidx;
  });

  demoObject.over64 = over64;
  demoObject.unemployed = (Math.round(unemployed * 100) / 100).toFixed(2);
  demoObject.under5 = under5;
  demoObject.lowincpct = (Math.round(lowincpct * 100) / 100).toFixed(2);
  demoObject.lesshspct = (Math.round(lesshspct * 100) / 100).toFixed(2);
  demoObject.peopcolorp = (Math.round(peopcolorp * 100) / 100).toFixed(2);
  demoObject.lingisopct = (Math.round(lingisopct * 100) / 100).toFixed(2);
  demoObject.demoindex = (Math.round(demoindex * 100) / 100).toFixed(2);

  return demoObject;
}
