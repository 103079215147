import advectionManualFiltering from './advection-manual-filtering'
import advection from './advection'
import clear from './clear'
import curl from './curl'
import display from './display'
import divergence from './divergence'
import gradientSubtract from './gradient-subtract'
import pressure from './pressure'
import splat from './splat'
import vert from './vert'
import vorticity from './vorticity'

export default {
  advectionManualFiltering,
  advection,
  clear,
  curl,
  display,
  divergence,
  gradientSubtract,
  pressure,
  splat,
  vert,
  vorticity
}
