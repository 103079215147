import { forwardRef, useEffect, useRef, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import MDSnackbar from "components/MDSnackbar";

import Configurator from "layouts/dashboardMap/components/Configurator";

import {
  useMaterialUIController,
  setDataSourceLoaded,
  setDataSourceState,
  setSensorDataBox,
  setGeomObject,
  setSensorObject,
  setSensorClassName,
} from "context";
import PersonIcon from "@mui/icons-material/Person";
import { blue } from "@mui/material/colors";

import { makeStyles } from "@mui/styles";
import styles from "./styles/dashboardMapStyle";
import SensorDataInfoBox from "./components/SensorDataInfoBox";
import SensorDataObservation from "./components/SensorDataObservation";
import { storetEndpoints, iwqisEndpoints, nwisdvEndpoints } from "constants";
import ComparisonTable from "./components/ComparisonTable";
import { AnimatePresence } from "framer-motion";
import { pwtsEndpoints } from "constants";
import { sdwaEndpoints } from "constants";
import { iadnrSNAPSTEndpoints } from "constants";
import { iadnr21IOWAEndpoints } from "constants";
import { iadnrIOWAGWEndpoints } from "constants";
import { iadnrNONDNREndpoints } from "constants";
import SummaryBox from "./components/SummaryBox";
import { useLocation, useSearchParams } from "react-router-dom";

const useStyles = makeStyles(styles);

// import zipCodesGeoJSON from "../../assets/obs_Stats_Zip.json";
// import placeGeoJSON from "../../assets/obs_Stats_Place.json";
// import countyGeoJSON from "../../assets/obs_Stats_County.json";
// import demographicIowaJSON from "../../assets/demographic_Iowa.json";
// import demographicTampaJSON from "../../assets/demographic_Tampa.json";
import LegendBox from "./components/LegendBox";
import Box from "@mui/material/Box";
import {
  getColor,
  getCountyObsData,
  getCountyStyle,
  getDataList,
  getDemoIndexColor,
  getDemographicData,
  getSummaryObject,
  getZipCodeObsData,
  getZipCodeStyle,
} from "./helpers/summaryAndStyleHelper";
import DataReportBox from "./components/DataReportBox";
import { mainURL } from "constants";
import { waterCommunityEndpoints } from "constants";
import DemographicReportBox from "./components/DemographicReportBox";
import {
  Avatar,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
  Slide,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { setDrikingWater } from "context";
import { setWaterCommunity } from "context";
import { setGroundWater } from "context";
import { setRecreationalWater } from "context";
import { recreationalWaterOceanEndpoints } from "constants";

function DashboardMap() {
  const classes = useStyles();
  const [controller, dispatch] = useMaterialUIController();
  const {
    geomObject,
    sensorObject,
    sensorClassName,
    dataSourceState,
    dataSourceLoaded,
    waterCommunity,
    drinkingWater,
    groundWater,
    recreationalWater,
  } = controller;

  const [tc, setTC] = useState(false);
  const [snackbarErrorMessage, setSnackbarErrorMessage] = useState("Something went wrong.");
  const [snackbarType, setSnackbarType] = useState("success");
  const showNotification = (message, type = "") => {
    if (!tc) {
      setSnackbarErrorMessage(message);
      setSnackbarType(type);
      setTC(true);
      setTimeout(function () {
        setTC(false);
      }, 2000);
    }
  };

  const g_maxNumberOfSensorsOnMap = 2000;

  const [mapGlobal, setMapGlobal] = useState(null);

  const [geoType, setGeoType] = useState("");
  const [boundState, setBoundState] = useState(null);
  const mapRef = useRef(null);

  const [summaryObject, setSummaryObject] = useState(null);
  const [demographicObject, setDemographicObject] = useState(null);

  const location = useLocation();
  const parsedJSONData = JSON.parse(location.state);

  // const zipCodeObsData = zipCodesGeoJSON;
  // const placeObsData = placeGeoJSON;
  // const countyObsData = countyGeoJSON;
  // const iowaDemoData = demographicIowaJSON;
  // const tampaDemoData = demographicTampaJSON;

  const [breadCrumbsList, setBreadCrumbsList] = useState([]);
  const [zipCodeObsData, setZipCodeObsData] = useState([]);
  const [zipCodeObsData3Year, setZipCodeObsData3Year] = useState([]);
  const [countyObsData, setCountyObsData] = useState([]);
  const [countyObsData3Year, setCountyObsData3Year] = useState([]);
  const [activeStep, setActiveStep] = useState(0);

  const [regionInputValue, setRegionInputValue] = useState("");
  const [regionOptions, setRegionOptions] = useState([]);

  const [geoTypeValue, setGeoTypeValue] = useState("");
  const [geoTypeUrl, setGeoTypeUrl] = useState("");
  const [geoTypeOption, setGeoTypeOption] = useState(null);
  const setGeoTypeHandler = (event, selectedGeoType) => {
    setRegionValue("");
    setRegionOptions([]);
    setRegionInputValue("");
    setGeoTypeOption(selectedGeoType);
    // props.setGlobalRegionValue(null);
    //console.log("geotype changed");
    selectedGeoType != null &&
    Object.keys(selectedGeoType).includes("name") &&
    Object.keys(selectedGeoType).includes("api")
      ? (setGeoTypeValue(selectedGeoType.name), setGeoTypeUrl(selectedGeoType.api))
      : (setGeoTypeValue(""), setGeoTypeUrl(""), clearSearch());
  };

  const [regionValue, setRegionValue] = useState("");
  const setRegionValueHandler = (value) => {
    setRegionValue(value);
    //console.log(value);
    if (value == null) {
      //console.log("reset");
      // resetMap();
    } else {
      loadNewMap(value, geoTypeUrl, geoTypeValue);
    }
  };

  // useEffect(() => {
  //   fetch("mainURL/obs_stats?geomtype=zip_code").then(res => res.json()).then((data) => {
  //     zipCodeObsData = data;
  //     //console.log("zip", zipCodeObsData);
  //   });

  //   fetch("mainURL/obs_stats?geomtype=place").then(res => res.json()).then((data) => {
  //     placeObsData = data;
  //     //console.log("place", placeObsData);
  //   });
  // }, []);

  const [geomLoading, setGeomLoading] = useState(false);

  const [geoValue, setGeoValue] = useState(null);

  const [validState, setValidState] = useState(false);

  let isOldFlow = false;

  var lockZipCode = false;
  const [searchParams, setSearchParams] = useSearchParams();

  const [drinkingWaterLoaded, setDrinkingWaterLoaded] = useState(false);
  const [groundWaterLoaded, setGroundWaterLoaded] = useState(false);
  const [waterCommunityLoaded, setWaterCommunityLoaded] = useState(false);
  const [recreationalWaterOceanLoaded, setRecreationalWaterOceanLoaded] = useState(false);
  const isFirstSnapShotRender = useRef(true);

  // async function compressBlob(blob) {
  //   const compressionStream = new CompressionStream("gzip");
  //   const compressedStream = blob.stream().pipeThrough(compressionStream);

  //   const reader = compressedStream.getReader();
  //   let chunks = [];
  //   while (true) {
  //     const { done, value } = await reader.read();
  //     if (done) break;
  //     chunks.push(value);
  //   }

  //   return new Blob(chunks, { type: "application/gzip" });
  // }

  // function downloadBlob(blob, filename) {
  //   const url = URL.createObjectURL(blob);
  //   const a = document.createElement("a");
  //   a.href = url;
  //   a.download = filename;
  //   document.body.appendChild(a);
  //   a.click();
  //   a.remove();
  //   URL.revokeObjectURL(url);
  // }

  // const fetchAndCompressFile = async () => {
  //   const fileUrl = "obs_Stats_Zip.json"; // Replace with your file URL

  //   try {
  //     const response = await fetch(fileUrl);
  //     const blob = await response.blob();

  //     const compressedBlob = await compressBlob(blob);
  //     downloadBlob(compressedBlob, "compressed_file.txt");
  //   } catch (error) {
  //     console.error("Error fetching or compressing file:", error);
  //   }
  // };

  async function fetchAndDecompress(url) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Ensure the stream is locked by the response
      if (!response.body) {
        throw new Error("ReadableStream not yet supported in this browser.");
      }

      // Create a Decompression Stream for 'gzip'
      const decompressionStream = new DecompressionStream("gzip");

      // Use the decompression stream with the response body
      const decompressedStream = response.body.pipeThrough(decompressionStream);

      // Convert the readable stream to text (assuming the content is text)
      const reader = decompressedStream.getReader();
      let chunks = "";
      let result;
      while (!(result = await reader.read()).done) {
        const chunk = new TextDecoder().decode(result.value, { stream: true });
        chunks += chunk;
      }

      // Parse JSON, or handle the text as needed
      return JSON.parse(chunks);
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
      throw error;
    }
  }

  useEffect(() => {
    //console.log("1", iowaCountiesGeoJSON);
    document.title = "BlueGAP Data Platform";
    sessionStorage.setItem("is_old_flow", false);
    sessionStorage.setItem("isLocked", false);
    sessionStorage.setItem("hoverFeature", true);
    let google = window.google;
    let map = mapRef.current;
    let featureObs = {};
    const myLatlng = new google.maps.LatLng(
      parsedJSONData ? parsedJSONData.latitude : searchParams.get("lat"),
      parsedJSONData ? parsedJSONData.longitude : searchParams.get("lng")
    );
    const mapOptions = {
      tilt: 47.5,
      zoom: parsedJSONData ? parsedJSONData.zoomValue : parseInt(searchParams.get("zoom")),
      center: myLatlng,
      mapTypeId: google.maps.MapTypeId.TERRAIN,
      scrollwheel: true,

      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },

      streetViewControl: false,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP,
      },

      mapTypeControl: true,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
      },
      fullscreenControl: false,
    };

    map = new google.maps.Map(map, mapOptions);
    // //console.log("obs data", obsData);
    // setLoadingIndicator(true);
    showNotification("Please wait for the map to load completely.", "info");
    // fetch("https://app.blue-gap.org/obs_Stats_Zip_3Year.txt")
    //   .then((response) => {
    //     response.json().then((zipCodeObsData3Year) => {
    //       setZipCodeObsData3Year(zipCodeObsData3Year);
    //     });
    //   })
    //   .catch((error) => {
    //     showNotification("Server error. Please try again later.", "error");
    //   });

    // fetchAndCompressFile();

    fetchAndDecompress("https://app.blue-gap.org/obs_Stats_Zip_3Year.txt")
      .then((data) => {
        // console.log("adasd", data);
        setZipCodeObsData3Year(data);
      })
      .catch((error) => {
        showNotification("Server error. Please try again later.", "error");
      });

    fetch("https://app.blue-gap.org/obs_Stats_County.json")
      .then((response) => {
        response.json().then((countyObsData) => {
          setCountyObsData(countyObsData);

          fetch("https://app.blue-gap.org/obs_Stats_County_3Year.json")
            .then((response) => {
              response.json().then((countyObsData3Year) => {
                setCountyObsData3Year(countyObsData3Year);
                fetchAndDecompress("https://app.blue-gap.org/obs_Stats_Zip.txt")
                  .then((zipCodeObsData) => {
                    // console.log("zpRes", zipCodeObsData);
                    setZipCodeObsData(zipCodeObsData);
                    fetch(parsedJSONData ? parsedJSONData.boundaryURL : searchParams.get("url"))
                      .then((res) => res.json())
                      .then((result) => {
                        //console.log("result", result);

                        //notepad 1

                        map.data.addGeoJson(result);

                        map.data.setStyle(function (feature) {
                          if (feature.getProperty("fips")) {
                            let countyStyle = getCountyStyle(
                              countyObsData,
                              feature.getProperty("fips"),
                              parsedJSONData ? parsedJSONData.state : searchParams.get("state")
                            );

                            return countyStyle;
                          } else {
                            //USVI case
                            let zipCodeStyle = getZipCodeStyle(
                              zipCodeObsData,
                              feature.getProperty("zip_code"),
                              parsedJSONData ? parsedJSONData.state : searchParams.get("state")
                            );
                            zipCodeStyle.strokeColor = "black";
                            zipCodeStyle.strokeWeight = 1;
                            zipCodeStyle.visible = false;

                            return zipCodeStyle;
                          }
                        });

                        let summaryObjStore = [];
                        // Add a click event listener to the polygons

                        if (searchParams.get("url")) {
                          let breadObj = {};
                          // console.log("search param");
                          setGeomLoading(true);
                          let selectedGeoType =
                            searchParams.get("geotype") == "County"
                              ? {
                                  name: "County",
                                  api: `${mainURL}/geom?table=counties&field=fips&value=`,
                                }
                              : {
                                  name: "Zip Code",
                                  api: `${mainURL}/geom?table=zip_code&field=zip_code&value=`,
                                };
                          ///

                          resetMap();
                          setActiveStep((prevActiveStep) => prevActiveStep + 1);
                          setTabValue(1);
                          setValidState(true);
                          let featureObs = getCountyObsData(
                            countyObsData,
                            searchParams.get("value")
                          );
                          let endpointUrl = `${mainURL}/geom?table=counties&value=${searchParams.get(
                            "value"
                          )}&field=fips`;
                          let bounds = searchParams.get("bounds"); // Get bounds string from URL
                          setBoundState(bounds);
                          bounds = bounds.replace(/\s+/g, ""); // Remove whitespaces in the string
                          bounds = bounds.substring(2, bounds.length - 2); //Remove the double parentheses at both ends

                          let boundsArray = bounds.split("),("); // Split the bounds string into SW and NE coordinate strings

                          // Split SW and NE coordinate string in to their latitudes and longitudes:
                          let SWLatLngArray = boundsArray[0].split(",");
                          let NELatLngArray = boundsArray[1].split(",");

                          // Create LatLng objects for the SW and NE corners:
                          let SWLatLng = new google.maps.LatLng(
                            parseFloat(SWLatLngArray[0]),
                            parseFloat(SWLatLngArray[1])
                          );
                          let NELatLng = new google.maps.LatLng(
                            parseFloat(NELatLngArray[0]),
                            parseFloat(NELatLngArray[1])
                          );

                          let latitude =
                            (parseFloat(SWLatLngArray[0]) + parseFloat(NELatLngArray[0])) / 2;
                          let longitude =
                            (parseFloat(SWLatLngArray[1]) + parseFloat(NELatLngArray[1])) / 2;

                          // Create LatLngBounds object:
                          let latLngBounds = new google.maps.LatLngBounds(SWLatLng, NELatLng);

                          // Fit map to bounds:
                          map.fitBounds(latLngBounds);
                          //console.log("current zoom", map.getZoom());
                          // debugger;

                          //let urlEx = `https://umissis.org/bluegap/tract_demography?table=county&x1=${swLng}&y1=${swLat}&x2=${neLng}&y2=${neLat}`;
                          let urlEx = `https://umissis.org/bluegap/tract_demography2?table=county&value=${searchParams.get(
                            "value"
                          )}`;
                          // console.log(urlEx, "url");
                          let demoObject = {};

                          demoObject.lat = latitude;
                          demoObject.lng = longitude;

                          fetch(urlEx)
                            .then((res) => res.json())
                            .then((data) => {
                              let over64 = 0;
                              let unemployed = 0;
                              let under5 = 0;
                              let lowincpct = 0;
                              let lesshspct = 0;
                              let peopcolorp = 0;
                              let lingisopct = 0;
                              let demoindex = 0;
                              let totalpop = 0;

                              let tempDemograph = data.features.filter(
                                (feature) => feature.properties.fips == searchParams.get("value")
                              );
                              //console.log("demographic", tempDemograph);

                              tempDemograph.forEach((item) => {
                                totalpop += item.properties.acstotpop;
                                over64 += item.properties.over64;
                                unemployed += item.properties.unemployed;
                                under5 += item.properties.under5;
                                lowincpct += item.properties.lowincome;
                                lesshspct += item.properties.lesshs;
                                peopcolorp += item.properties.peopcolor;
                                lingisopct += item.properties.lingiso;
                                demoindex += item.properties.p_demogidx;
                              });

                              unemployed = unemployed / totalpop;
                              peopcolorp = peopcolorp / totalpop;
                              lowincpct = lowincpct / totalpop;
                              lesshspct = lesshspct / totalpop;
                              lingisopct = lingisopct / totalpop;
                              demoindex = demoindex / totalpop;

                              demoObject.over64 = over64;
                              demoObject.unemployed = Math.round(unemployed * 100).toFixed(2);
                              demoObject.under5 = under5;
                              demoObject.lowincpct = Math.round(lowincpct * 100).toFixed(2);
                              demoObject.lesshspct = Math.round(lesshspct * 100).toFixed(2);
                              demoObject.peopcolorp = Math.round(peopcolorp * 100).toFixed(2);
                              demoObject.lingisopct = Math.round(lingisopct * 100).toFixed(2);
                              demoObject.demoindex = Math.round(demoindex * 100).toFixed(2);

                              breadObj.demoObject = demoObject;
                              setDemographicObject(demoObject);
                            });

                          fetch(
                            // `${mainURL}/allgeom?table=zip_code&x1=${swLng}&y1=${swLat}&x2=${neLng}&y2=${neLat}`
                            `${mainURL}/allgeom2?table=zip_code&table2=counties_simplified&poly=fips&value=${searchParams.get(
                              "value"
                            )}`
                          )
                            .then((res) => res.json())
                            .then((data) => {
                              map.data.addGeoJson(data);
                              if (searchParams.get("zip") !== "true") {
                                setDataSourceState(
                                  dispatch,
                                  JSON.parse(decodeURIComponent(searchParams.get("dataSource")))
                                );
                              }
                              if (searchParams.get("zip") !== "true") {
                                // map.data.revertStyle();
                                map.data.setStyle(function (feature) {
                                  if (!feature.getProperty("fips")) {
                                    let zipCodeStyle = getZipCodeStyle(
                                      zipCodeObsData,
                                      feature.getProperty("zip_code"),
                                      parsedJSONData
                                        ? parsedJSONData.state
                                        : searchParams.get("state")
                                    );
                                    zipCodeStyle.strokeColor = "black";
                                    zipCodeStyle.strokeWeight = 1;
                                    return zipCodeStyle;
                                  } else {
                                    if (searchParams.get("value") === feature.getProperty("fips")) {
                                      return {
                                        strokeWeight: 5,
                                        fillOpacity: 0,
                                        strokeColor: "red",
                                        visible: true,
                                        zIndex: 1,
                                      };
                                    } else {
                                      return {
                                        visible: false,
                                      };
                                    }
                                  }
                                });
                              }
                            });
                          summaryObjStore.splice(0, summaryObjStore.length);

                          if (endpointUrl) {
                            fetch(endpointUrl)
                              .then((res) => res.json())
                              .then((data) => {
                                const processedObj = getDataList(featureObs);

                                let summaryObj = getSummaryObject(
                                  processedObj,
                                  featureObs,
                                  geoType,
                                  parsedJSONData ? parsedJSONData.state : searchParams.get("state")
                                );

                                breadObj.id = searchParams.get("value");
                                summaryObj.name =
                                  data.features[0].properties.name +
                                  ", " +
                                  data.features[0].properties.state_name;
                                summaryObj.id = searchParams.get("value");
                                summaryObj.search = data.features[0].properties.zip_code;
                                summaryObj.po_name = data.features[0].properties.po_name;

                                setGeoValue(breadObj.id);
                                breadObj.type = "County";
                                breadObj.name = summaryObj.name;
                                breadObj.summaryObject = summaryObjStore[0];
                                // setBreadCrumbsList((prevList) => [...prevList, breadObj]);

                                setBreadCrumbsList((prevList) => {
                                  const isItemExist = prevList.some(
                                    (bread) => bread.id === breadObj.id
                                  ); // Assuming each bread object has an 'id' property

                                  if (!isItemExist) {
                                    return [...prevList, breadObj];
                                  }

                                  return prevList;
                                });

                                //console.log(summaryObj);
                                setGeoTypeHandler(null, selectedGeoType);
                                setGeoTypeOption(selectedGeoType);
                                setGeoType("County");
                                setSummaryObject(summaryObj);
                                setRegionValue(summaryObj);
                                sessionStorage.setItem("hoverFeature", false);
                                summaryObjStore.push(summaryObj);
                                //sensor data present and on click of geoms didnt remove them so.
                                map.data.forEach((feature) => {
                                  if (feature.getGeometry().getType() === "Point") {
                                    map.data.remove(feature);
                                  }
                                });

                                searchParams.get("zip") == "true"
                                  ? setGeomLoading(true)
                                  : setGeomLoading(false);

                                // searchParams.get("zip") == "true"
                                //   ? null
                                //   : setDataSourceState(
                                //       dispatch,
                                //       JSON.parse(decodeURIComponent(searchParams.get("dataSource")))
                                //     );
                              });
                          }

                          if (searchParams.get("zip") == "true") {
                            // setGeomLoading(true);
                            featureObs = getZipCodeObsData(
                              zipCodeObsData,
                              searchParams.get("zip_code")
                            );

                            //resetMap();
                            setActiveStep((prevActiveStep) => prevActiveStep + 1);
                            // lockZipCode = true;
                            //lockZipCode = false;
                            setTabValue(1);

                            // let urlEx = `https://umissis.org/bluegap/tract_demography?table=county&x1=${swLng}&y1=${swLat}&x2=${neLng}&y2=${neLat}`;
                            let urlEx = `https://umissis.org/bluegap/tract_demography3?table=zip_code&value=${searchParams.get(
                              "zip_code"
                            )}`;
                            //console.log(urlEx, "url");
                            let demoObject = {};

                            demoObject.lat = latitude;
                            demoObject.lng = longitude;

                            fetch(urlEx)
                              .then((res) => res.json())
                              .then((data) => {
                                let over64 = 0;
                                let unemployed = 0;
                                let under5 = 0;
                                let lowincpct = 0;
                                let lesshspct = 0;
                                let peopcolorp = 0;
                                let lingisopct = 0;
                                let demoindex = 0;
                                let totalpop = 0;

                                let tempDemograph = data.features;
                                map.data.addGeoJson(data);
                                //console.log("demographic", tempDemograph);

                                tempDemograph.forEach((item) => {
                                  totalpop += item.properties.acstotpop;
                                  over64 += item.properties.over64;
                                  unemployed += item.properties.unemployed;
                                  under5 += item.properties.under5;
                                  lowincpct += item.properties.lowincome;
                                  lesshspct += item.properties.lesshs;
                                  peopcolorp += item.properties.peopcolor;
                                  lingisopct += item.properties.lingiso;
                                  demoindex += item.properties.p_demogidx;
                                });

                                unemployed = unemployed / totalpop;
                                peopcolorp = peopcolorp / totalpop;
                                lowincpct = lowincpct / totalpop;
                                lesshspct = lesshspct / totalpop;
                                lingisopct = lingisopct / totalpop;
                                demoindex = demoindex / totalpop;

                                demoObject.over64 = over64;
                                demoObject.unemployed = Math.round(unemployed * 100).toFixed(2);
                                demoObject.under5 = under5;
                                demoObject.lowincpct = Math.round(lowincpct * 100).toFixed(2);
                                demoObject.lesshspct = Math.round(lesshspct * 100).toFixed(2);
                                demoObject.peopcolorp = Math.round(peopcolorp * 100).toFixed(2);
                                demoObject.lingisopct = Math.round(lingisopct * 100).toFixed(2);
                                demoObject.demoindex = Math.round(demoindex * 100).toFixed(2);

                                breadObj.demoObject = demoObject;
                                setDemographicObject(demoObject);
                              });

                            setValidState(true);
                            selectedGeoType = {
                              name: "Zip Code",
                              api: `${mainURL}/geom?table=zip_code&field=zip_code&value=`,
                            };
                            setGeoTypeOption(selectedGeoType);
                            setGeoTypeHandler(null, selectedGeoType);

                            endpointUrl = `${mainURL}/geom?table=zip_code&value=${searchParams.get(
                              "zip_code"
                            )}&field=zip_code`;
                            //console.log(zip_code);
                            map.data.setStyle(function (feature) {
                              if (feature.getProperty("zip_code") && !feature.getProperty("gid")) {
                                if (
                                  searchParams.get("zip_code") !== feature.getProperty("zip_code")
                                ) {
                                  return {
                                    visible: false,
                                  };
                                } else {
                                  return {
                                    strokeWeight: 5,
                                    fillOpacity: 0,
                                    strokeColor: "red",
                                    visible: true,
                                    zIndex: 2,
                                  };
                                }
                              } else {
                                return {
                                  visible: false,
                                };
                              }
                            });
                            if (searchParams.get("state") === "VI") {
                              summaryObjStore.splice(0, summaryObjStore.length);
                            }

                            if (endpointUrl) {
                              fetch(endpointUrl)
                                .then((res) => res.json())
                                .then((data) => {
                                  const processedObj = getDataList(featureObs);

                                  let summaryObj = getSummaryObject(
                                    processedObj,
                                    featureObs,
                                    "Zip Code",
                                    parsedJSONData
                                      ? parsedJSONData.state
                                      : searchParams.get("state")
                                  );
                                  breadObj.id = searchParams.get("zip_code");
                                  breadObj.state = parsedJSONData
                                    ? parsedJSONData.state
                                    : searchParams.get("state");
                                  summaryObj.id = searchParams.get("zip_code");
                                  summaryObj.name = data.features[0].properties.zip_code;
                                  summaryObj.search = data.features[0].properties.zip_code;
                                  summaryObj.po_name = data.features[0].properties.po_name;
                                  summaryObj.zip_code = data.features[0].properties.zip_code;

                                  setGeoValue(breadObj.id);
                                  breadObj.type = "Zip Code";
                                  breadObj.name = summaryObj.name;
                                  breadObj.summaryObject = summaryObjStore[0];
                                  // setBreadCrumbsList((prevList) => [...prevList, breadObj]);

                                  setBreadCrumbsList(
                                    JSON.parse(decodeURIComponent(searchParams.get("breadcrumb")))
                                  );

                                  console.log(summaryObj);
                                  setGeoTypeHandler(null, selectedGeoType);
                                  setGeoTypeOption(selectedGeoType);
                                  setGeoType("Zip Code");
                                  setSummaryObject(summaryObj);
                                  setRegionValue(summaryObj);
                                  summaryObjStore.push(summaryObj);
                                  searchParams.get("census") == "true"
                                    ? setGeomLoading(true)
                                    : setGeomLoading(false);
                                  //sensor data present and on click of geoms didnt remove them so.
                                  map.data.forEach((feature) => {
                                    if (feature.getGeometry().getType() === "Point") {
                                      map.data.remove(feature);
                                    }
                                  });

                                  setDataSourceState(
                                    dispatch,
                                    JSON.parse(decodeURIComponent(searchParams.get("dataSource")))
                                  );

                                  // setGeomLoading(false);

                                  if (searchParams.get("census") == "true") {
                                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                                    // lockZipCode = true;
                                    //lockZipCode = false;
                                    setTabValue(0);
                                    let urlEx = `https://umissis.org/bluegap/tract_demography3?table=tract&value=${searchParams.get(
                                      "gid"
                                    )}`;
                                    //console.log(urlEx, "url");
                                    let demoObject = {};

                                    fetch(urlEx)
                                      .then((res) => res.json())
                                      .then((data) => {
                                        demoObject.over64 = data[0].over64;
                                        demoObject.unemployed = (
                                          Math.round(data[0].unemppct * 100) / 100
                                        ).toFixed(2);
                                        demoObject.under5 = data[0].under5;
                                        demoObject.lowincpct = (
                                          Math.round(data[0].lowincpct * 100) / 100
                                        ).toFixed(2);
                                        demoObject.lesshspct = (
                                          Math.round(data[0].lesshspct * 100) / 100
                                        ).toFixed(2);
                                        demoObject.peopcolorp = (
                                          Math.round(data[0].peopcolorp * 100) / 100
                                        ).toFixed(2);
                                        demoObject.lingisopct = (
                                          Math.round(data[0].lingisopct * 100) / 100
                                        ).toFixed(2);
                                        demoObject.demoindex = (
                                          Math.round(data[0].p_demogidx * 100) / 100
                                        ).toFixed(2);
                                        //console.log("demoObject", demoObject);
                                        breadObj.demoObject = demoObject;
                                        setDemographicObject(demoObject);
                                      });

                                    setValidState(true);
                                    selectedGeoType = {
                                      name: "Census Tract",
                                      api: ``,
                                    };
                                    setGeoType("Census Tract");
                                    setGeoTypeOption(selectedGeoType);
                                    setGeoTypeHandler(null, selectedGeoType);
                                    setGeomLoading(false);
                                    //console.log(zip_code);

                                    map.data.setStyle(function (feature) {
                                      if (feature.getProperty("gid")) {
                                        console.log(searchParams.get("gid"));
                                        if (searchParams.get("gid") != feature.getProperty("gid")) {
                                          return {
                                            visible: false,
                                          };
                                        } else {
                                          return {
                                            strokeWeight: 5,
                                            fillOpacity: 0.5,
                                            strokeColor: "red",
                                            visible: true,
                                            fillColor: "lightgray",
                                          };
                                        }
                                      } else {
                                        return {
                                          visible: false,
                                        };
                                      }
                                    });

                                    if (searchParams.get("state") === "VI") {
                                      summaryObjStore.splice(0, summaryObjStore.length);
                                    }
                                  }
                                });
                            }
                          }
                        }

                        //////

                        map.data.addListener("click", function (event) {
                          if (sessionStorage.getItem("is_old_flow") == "false") {
                            sessionStorage.setItem("old", "no");
                            var feature = event.feature;
                            //console.log("clicked feature", feature);
                            if (feature.getGeometry().getType() !== "Point") {
                              let geoType;
                              let endpointUrl = null;
                              let breadObj = {};
                              let selectedGeoType = {};
                              var fips = feature.getProperty("fips");
                              var zip_code = feature.getProperty("zip_code");
                              var gid = feature.getProperty("gid");
                              // console.log(zip_code);
                              var place_fips = feature.getProperty("placefips");

                              if (fips && !gid) {
                                //NOTE: florida one state no data for this particular fips.
                                setGeomLoading(true);
                                sessionStorage.setItem("hoverFeature", false);
                                if (fips == "12049") {
                                  endpointUrl = null;
                                  sessionStorage.setItem("hoverFeature", true);
                                  showNotification("No data found!", "error");
                                } else {
                                  if (sessionStorage.getItem("isLocked") == "false") {
                                    resetMap();
                                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                                    setTabValue(1);
                                    setValidState(true);
                                    selectedGeoType = {
                                      name: "County",
                                      api: `${mainURL}/geom?table=counties&field=fips&value=`,
                                    };
                                    geoType = "County";
                                    setGeoTypeHandler(null, selectedGeoType);
                                    setGeoTypeOption(selectedGeoType);
                                    featureObs = getCountyObsData(countyObsData, fips);
                                    endpointUrl = `${mainURL}/geom?table=counties&value=${fips}&field=fips`;
                                    // Get the geometry of the clicked feature
                                    var geometry = feature.getGeometry();

                                    // Calculate the bounds of the clicked feature
                                    var bounds = new google.maps.LatLngBounds();
                                    geometry.forEachLatLng(function (latlng) {
                                      bounds.extend(latlng);
                                    });

                                    setBoundState(bounds);

                                    // Fit the map to the bounds of the clicked polygon
                                    map.fitBounds(bounds);
                                    //console.log("current zoom", map.getZoom());
                                    // debugger;

                                    // Retrieve the latitude and longitude values from the bounds
                                    var swLatLng = bounds.getSouthWest();
                                    var neLatLng = bounds.getNorthEast();

                                    var swLat = swLatLng.lat();
                                    var swLng = swLatLng.lng();
                                    var neLat = neLatLng.lat();
                                    var neLng = neLatLng.lng();

                                    //let urlEx = `https://umissis.org/bluegap/tract_demography?table=county&x1=${swLng}&y1=${swLat}&x2=${neLng}&y2=${neLat}`;
                                    let urlEx = `https://umissis.org/bluegap/tract_demography2?table=county&value=${fips}`;
                                    // console.log(urlEx, "url");
                                    let demoObject = {};
                                    demoObject.lat = (swLat + neLat) / 2;
                                    demoObject.lng = (swLng + neLng) / 2;

                                    fetch(urlEx)
                                      .then((res) => res.json())
                                      .then((data) => {
                                        let over64 = 0;
                                        let unemployed = 0;
                                        let under5 = 0;
                                        let lowincpct = 0;
                                        let lesshspct = 0;
                                        let peopcolorp = 0;
                                        let lingisopct = 0;
                                        let demoindex = 0;
                                        let totalpop = 0;

                                        let tempDemograph = data.features.filter(
                                          (feature) => feature.properties.fips == fips
                                        );
                                        //console.log("demographic", tempDemograph);

                                        tempDemograph.forEach((item) => {
                                          totalpop += item.properties.acstotpop;
                                          over64 += item.properties.over64;
                                          unemployed += item.properties.unemployed;
                                          under5 += item.properties.under5;
                                          lowincpct += item.properties.lowincome;
                                          lesshspct += item.properties.lesshs;
                                          peopcolorp += item.properties.peopcolor;
                                          lingisopct += item.properties.lingiso;
                                          demoindex += item.properties.p_demogidx;
                                        });
                                        // console.log("total", totalpop);

                                        unemployed = unemployed / totalpop;
                                        peopcolorp = peopcolorp / totalpop;
                                        lowincpct = lowincpct / totalpop;
                                        lesshspct = lesshspct / totalpop;
                                        lingisopct = lingisopct / totalpop;
                                        demoindex = demoindex / totalpop;

                                        demoObject.over64 = over64;
                                        demoObject.unemployed = Math.round(
                                          unemployed * 100
                                        ).toFixed(2);
                                        demoObject.under5 = under5;
                                        demoObject.lowincpct = Math.round(lowincpct * 100).toFixed(
                                          2
                                        );
                                        demoObject.lesshspct = Math.round(lesshspct * 100).toFixed(
                                          2
                                        );
                                        demoObject.peopcolorp = Math.round(
                                          peopcolorp * 100
                                        ).toFixed(2);
                                        demoObject.lingisopct = Math.round(
                                          lingisopct * 100
                                        ).toFixed(2);
                                        demoObject.demoindex = Math.round(demoindex * 100).toFixed(
                                          2
                                        );

                                        breadObj.demoObject = demoObject;
                                        setDemographicObject(demoObject);
                                      });

                                    fetch(
                                      // `${mainURL}/allgeom?table=zip_code&x1=${swLng}&y1=${swLat}&x2=${neLng}&y2=${neLat}`
                                      `${mainURL}/allgeom2?table=zip_code&table2=counties_simplified&poly=fips&value=${fips}`
                                    )
                                      .then((res) => res.json())
                                      .then((data) => {
                                        // //console.log("data", data);
                                        map.data.addGeoJson(data);

                                        // map.data.revertStyle();
                                        map.data.setStyle(function (feature) {
                                          if (
                                            !feature.getProperty("fips") &&
                                            feature.getProperty("zip_code")
                                          ) {
                                            let zipCodeStyle = getZipCodeStyle(
                                              zipCodeObsData,
                                              feature.getProperty("zip_code"),
                                              parsedJSONData
                                                ? parsedJSONData.state
                                                : searchParams.get("state")
                                            );
                                            zipCodeStyle.strokeColor = "black";
                                            zipCodeStyle.strokeWeight = 1;
                                            return zipCodeStyle;
                                          } else {
                                            if (fips === feature.getProperty("fips")) {
                                              return {
                                                strokeWeight: 5,
                                                fillOpacity: 0,
                                                strokeColor: "red",
                                                visible: true,
                                                zIndex: 1,
                                              };
                                            } else {
                                              return {
                                                visible: false,
                                              };
                                            }
                                          }
                                        });
                                      });
                                    summaryObjStore.splice(0, summaryObjStore.length);
                                  }
                                }
                              } else if (zip_code && !feature.getProperty("gid")) {
                                // console.log(sessionStorage.getItem("isLocked"));
                                setGeomLoading(true);
                                featureObs = getZipCodeObsData(zipCodeObsData, zip_code);

                                if (!featureObs) {
                                  showNotification("No data found!!", "error");
                                  return;
                                }
                                if (sessionStorage.getItem("isLocked") == "false") {
                                  resetMap();
                                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                                  // lockZipCode = true;
                                  sessionStorage.setItem("isLocked", true);
                                  //lockZipCode = false;
                                  setTabValue(1);

                                  var geometry = feature.getGeometry();

                                  // Calculate the bounds of the clicked feature
                                  var bounds = new google.maps.LatLngBounds();
                                  geometry.forEachLatLng(function (latlng) {
                                    bounds.extend(latlng);
                                  });

                                  setBoundState(bounds);

                                  // Retrieve the latitude and longitude values from the bounds
                                  var swLatLng = bounds.getSouthWest();
                                  var neLatLng = bounds.getNorthEast();

                                  var swLat = swLatLng.lat();
                                  var swLng = swLatLng.lng();
                                  var neLat = neLatLng.lat();
                                  var neLng = neLatLng.lng();

                                  // let urlEx = `https://umissis.org/bluegap/tract_demography?table=county&x1=${swLng}&y1=${swLat}&x2=${neLng}&y2=${neLat}`;
                                  let urlEx = `https://umissis.org/bluegap/tract_demography3?table=zip_code&value=${zip_code}`;
                                  //console.log(urlEx, "url");
                                  let demoObject = {};

                                  demoObject.lat = (swLat + neLat) / 2;
                                  demoObject.lng = (swLng + neLng) / 2;

                                  fetch(urlEx)
                                    .then((res) => res.json())
                                    .then((data) => {
                                      let over64 = 0;
                                      let unemployed = 0;
                                      let under5 = 0;
                                      let lowincpct = 0;
                                      let lesshspct = 0;
                                      let peopcolorp = 0;
                                      let lingisopct = 0;
                                      let demoindex = 0;
                                      let totalpop = 0;

                                      let tempDemograph = data.features;
                                      map.data.addGeoJson(data);
                                      //console.log("demographic", tempDemograph);

                                      tempDemograph.forEach((item) => {
                                        totalpop += item.properties.acstotpop;
                                        over64 += item.properties.over64;
                                        unemployed += item.properties.unemployed;
                                        under5 += item.properties.under5;
                                        lowincpct += item.properties.lowincome;
                                        lesshspct += item.properties.lesshs;
                                        peopcolorp += item.properties.peopcolor;
                                        lingisopct += item.properties.lingiso;
                                        demoindex += item.properties.p_demogidx;
                                      });

                                      unemployed = unemployed / totalpop;
                                      peopcolorp = peopcolorp / totalpop;
                                      lowincpct = lowincpct / totalpop;
                                      lesshspct = lesshspct / totalpop;
                                      lingisopct = lingisopct / totalpop;
                                      demoindex = demoindex / totalpop;

                                      demoObject.over64 = over64;
                                      demoObject.unemployed = Math.round(unemployed * 100).toFixed(
                                        2
                                      );
                                      demoObject.under5 = under5;
                                      demoObject.lowincpct = Math.round(lowincpct * 100).toFixed(2);
                                      demoObject.lesshspct = Math.round(lesshspct * 100).toFixed(2);
                                      demoObject.peopcolorp = Math.round(peopcolorp * 100).toFixed(
                                        2
                                      );
                                      demoObject.lingisopct = Math.round(lingisopct * 100).toFixed(
                                        2
                                      );
                                      demoObject.demoindex = Math.round(demoindex * 100).toFixed(2);

                                      breadObj.demoObject = demoObject;
                                      setDemographicObject(demoObject);
                                    });

                                  map.fitBounds(bounds);
                                  setValidState(true);
                                  selectedGeoType = {
                                    name: "Zip Code",
                                    api: `${mainURL}/geom?table=zip_code&field=zip_code&value=`,
                                  };
                                  geoType = "Zip Code";
                                  setGeoTypeOption(selectedGeoType);
                                  setGeoTypeHandler(null, selectedGeoType);

                                  endpointUrl = `${mainURL}/geom?table=zip_code&value=${zip_code}&field=zip_code`;
                                  //console.log(zip_code);
                                  map.data.setStyle(function (feature) {
                                    if (
                                      feature.getProperty("zip_code") &&
                                      !feature.getProperty("gid")
                                    ) {
                                      if (zip_code !== feature.getProperty("zip_code")) {
                                        return {
                                          visible: false,
                                        };
                                      } else {
                                        // const zipCodeStyle = getZipCodeStyle(
                                        //   zipCodeObsData,
                                        //   feature.getProperty("zip_code")
                                        // );

                                        //return zipCodeStyle;
                                        return {
                                          strokeWeight: 5,
                                          fillOpacity: 0,
                                          strokeColor: "red",
                                          visible: true,
                                          zIndex: 2,
                                        };
                                      }
                                    } else if (feature.getProperty("gid")) {
                                      // return {
                                      //   fillColor: getDemoIndexColor(feature.getProperty("p_demogidx")), // Set the fill color for the polygons
                                      //   fillOpacity: 0.5, // Set the fill opacity
                                      //   strokeWeight: 1, // Set the stroke weight
                                      //   strokeColor: "black", // Set the stroke color
                                      //   zIndex: 1,
                                      // };
                                      return {
                                        visible: false,
                                      };
                                    } else {
                                      return {
                                        visible: false,
                                      };
                                    }
                                  });
                                  if (feature.getProperty("state") === "VI") {
                                    summaryObjStore.splice(0, summaryObjStore.length);
                                  }
                                }
                              } else if (gid) {
                                setTabValue(0);
                                resetMap();
                                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                                setValidState(true);
                                selectedGeoType = {
                                  name: "Census Tract",
                                  api: ``,
                                };
                                geoType = "Census Tract";
                                setGeoType(geoType);
                                setGeoTypeOption(selectedGeoType);
                                setGeoTypeHandler(null, selectedGeoType);

                                var geometry = feature.getGeometry();

                                // Calculate the bounds of the clicked feature
                                var bounds = new google.maps.LatLngBounds();
                                geometry.forEachLatLng(function (latlng) {
                                  bounds.extend(latlng);
                                });

                                // Retrieve the latitude and longitude values from the bounds
                                var swLatLng = bounds.getSouthWest();
                                var neLatLng = bounds.getNorthEast();

                                // var swLat = swLatLng.lat();
                                // var swLng = swLatLng.lng();
                                // var neLat = neLatLng.lat();
                                // var neLng = neLatLng.lng();

                                //let urlEx = `https://umissis.org/bluegap/tract_demography?table=county&x1=${swLng}&y1=${swLat}&x2=${neLng}&y2=${neLat}`;
                                let urlEx = `https://umissis.org/bluegap/tract_demography3?table=tract&value=${gid}`;
                                //console.log(urlEx, "url");
                                let demoObject = {};

                                fetch(urlEx)
                                  .then((res) => res.json())
                                  .then((data) => {
                                    // let over64 = 0;
                                    // let unemployed = 0;
                                    // let under5 = 0;
                                    // let lowincpct = 0;
                                    // let lesshspct = 0;
                                    // let peopcolorp = 0;
                                    // let lingisopct = 0;
                                    // let demoindex = 0;

                                    // let tempDemograph = data.features.filter(
                                    //   (feature) => feature.properties.gid == gid
                                    // );
                                    //console.log("demographic", tempDemograph);

                                    // tempDemograph.forEach((item) => {
                                    //   over64 += item.properties.over64;
                                    //   unemployed += item.properties.unemppct;
                                    //   under5 += item.properties.under5;
                                    //   lowincpct += item.properties.lowincpct;
                                    //   lesshspct += item.properties.lesshspct;
                                    //   peopcolorp += item.properties.peopcolorp;
                                    //   lingisopct += item.properties.lingisopct;
                                    //   demoindex += item.properties.p_demogidx;
                                    // });

                                    demoObject.over64 = data[0].over64;
                                    demoObject.unemployed = (
                                      Math.round(data[0].unemppct * 100) / 100
                                    ).toFixed(2);
                                    demoObject.under5 = data[0].under5;
                                    demoObject.lowincpct = (
                                      Math.round(data[0].lowincpct * 100) / 100
                                    ).toFixed(2);
                                    demoObject.lesshspct = (
                                      Math.round(data[0].lesshspct * 100) / 100
                                    ).toFixed(2);
                                    demoObject.peopcolorp = (
                                      Math.round(data[0].peopcolorp * 100) / 100
                                    ).toFixed(2);
                                    demoObject.lingisopct = (
                                      Math.round(data[0].lingisopct * 100) / 100
                                    ).toFixed(2);
                                    demoObject.demoindex = (
                                      Math.round(data[0].p_demogidx * 100) / 100
                                    ).toFixed(2);
                                    //console.log("demoObject", demoObject);

                                    breadObj.demoObject = demoObject;

                                    breadObj.id = gid;
                                    breadObj.name = gid;
                                    breadObj.summaryObject =
                                      summaryObjStore[summaryObjStore.length - 1];
                                    breadObj.type = "Census";
                                    setBreadCrumbsList((prevList) => {
                                      const isItemExist = prevList.some(
                                        (bread) => bread.id === breadObj.id
                                      ); // Assuming each bread object has an 'id' property

                                      if (!isItemExist) {
                                        return [...prevList, breadObj];
                                      }

                                      return prevList;
                                    });
                                    setDemographicObject(demoObject);
                                  });

                                map.fitBounds(bounds);
                                //console.log(zip_code);
                                map.data.setStyle(function (feature) {
                                  if (feature.getProperty("gid")) {
                                    if (gid !== feature.getProperty("gid")) {
                                      return {
                                        visible: false,
                                      };
                                    } else {
                                      return {
                                        strokeWeight: 5,
                                        fillOpacity: 0.5,
                                        strokeColor: "red",
                                        visible: true,
                                        fillColor: "lightgray",
                                      };
                                    }
                                  } else {
                                    return {
                                      visible: false,
                                    };
                                  }
                                });
                              } else {
                                showNotification("Data not available yet!", "error");
                                return;
                              }

                              if (endpointUrl) {
                                fetch(endpointUrl)
                                  .then((res) => res.json())
                                  .then((data) => {
                                    const processedObj = getDataList(featureObs);

                                    let summaryObj = getSummaryObject(
                                      processedObj,
                                      featureObs,
                                      geoType,
                                      parsedJSONData
                                        ? parsedJSONData.state
                                        : searchParams.get("state")
                                    );

                                    if (fips) {
                                      breadObj.id = fips;
                                      summaryObj.name =
                                        data.features[0].properties.name +
                                        ", " +
                                        data.features[0].properties.state_name;
                                      summaryObj.id = fips;
                                    } else if (zip_code) {
                                      breadObj.id = zip_code;
                                      breadObj.state = parsedJSONData
                                        ? parsedJSONData.state
                                        : searchParams.get("state");
                                      summaryObj.id = zip_code;
                                      summaryObj.name = data.features[0].properties.zip_code;
                                      summaryObj.search = data.features[0].properties.zip_code;
                                      summaryObj.po_name = data.features[0].properties.po_name;
                                      summaryObj.zip_code = data.features[0].properties.zip_code;
                                    } else {
                                      breadObj.id = place_fips;
                                      summaryObj.id = place_fips;
                                      summaryObj.name =
                                        data.features[0].properties.name +
                                        ", " +
                                        data.features[0].properties.st;
                                    }

                                    setGeoValue(breadObj.id);
                                    breadObj.type = geoType;
                                    breadObj.name = summaryObj.name;
                                    breadObj.summaryObject = summaryObjStore[0];
                                    // setBreadCrumbsList((prevList) => [...prevList, breadObj]);

                                    setBreadCrumbsList((prevList) => {
                                      const isItemExist = prevList.some(
                                        (bread) => bread.id === breadObj.id
                                      ); // Assuming each bread object has an 'id' property

                                      if (!isItemExist) {
                                        return [...prevList, breadObj];
                                      }

                                      return prevList;
                                    });

                                    //console.log(summaryObj);
                                    setGeoType(geoType);
                                    setSummaryObject(summaryObj);
                                    setRegionValue(summaryObj);
                                    summaryObjStore.push(summaryObj);
                                    //sensor data present and on click of geoms didnt remove them so.
                                    map.data.forEach((feature) => {
                                      if (feature.getGeometry().getType() === "Point") {
                                        map.data.remove(feature);
                                      }
                                    });

                                    setGeomLoading(false);
                                  });
                              } else {
                                //This is for the case where the zip/gid are not visible and the user clicks on the shown region.
                                setGeomLoading(false);
                              }
                            }
                          }
                        });
                        setMapGlobal(map);
                        setLoadingIndicator(false);
                        //This case if for when the user navigates back to the home page and comes back in the map page.
                        return () => {
                          //console.log("destroy");

                          resetDataSource();

                          setGeomObject(dispatch, null);

                          setOpenPanel(false);
                        };
                      })
                      .catch((error) => {
                        showNotification("Server error. Please try again later.", "error");
                      });
                  })
                  .catch((error) => {
                    showNotification("Server error. Please try again later.", "error");
                  });
              });
            })
            .catch((error) => {
              showNotification("Server error. Please try again later.", "error");
            });
        });
      })
      .catch((error) => {
        showNotification("Server error. Please try again later.", "error");
      });
  }, []);

  const [value, setTabValue] = useState(1);

  function resetDataSource() {
    setValidState(false);
    setDataSourceLoaded(dispatch, {
      iwqis: false,
      storet: false,
      nwisdv: false,
      nwisiv: false,
      pwts: false,
      sdwa: false,
      waterCommunity: false,
      iasnapst: false,
      iowagw: false,
      nondnr: false,
      iowa21: false,
      groundWater: false,
      drinkingWater: false,
    });

    setDataSourceState(dispatch, {
      iwqis: false,
      storet: false,
      nwisdv: false,
      nwisiv: false,
      pwts: false,
      sdwa: false,
      waterCommunity: false,
      iasnapst: false,
      iowagw: false,
      nondnr: false,
      iowa21: false,
      groundWater: false,
      drinkingWater: false,
      recWatersOcean: false,
    });
  }

  const handleBreadcrumbClick = (data) => {
    // console.log("data", data);
    setTabValue(1);
    // lockZipCode = false;
    sessionStorage.setItem("isLocked", false);
    //console.log(lockZipCode);
    if (data.type == "Census") {
      setState({
        zip_code: true,
        census: true,
      });
    }
    //console.log("pop data", data);
    //console.log(zipCodeObsData);
    //console.log(data.summaryObject);

    resetDataSource();

    setBreadCrumbsList((prevList) => {
      const updatedList = [...prevList];
      updatedList.pop(); // Remove the last element from the list
      let idx = updatedList.findIndex((item) => item.type == "Zip Code");
      //This is for the case when the user clicks on zip code while on the census level.
      if (idx != -1) {
        if (data.type == "Zip Code") {
          updatedList.pop();
        }
      }
      return updatedList;
    });
    mapGlobal.data.forEach((feature) => {
      if (feature.getGeometry().getType() === "Point") {
        mapGlobal.data.remove(feature);
      }

      switch (data.type) {
        case "Zip Code":
          let state = parsedJSONData ? parsedJSONData.state : searchParams.get("state");
          state == "VI"
            ? mapGlobal.setZoom(
                parsedJSONData ? parsedJSONData.zoomValue : parseInt(searchParams.get("zoom"))
              )
            : mapGlobal.setZoom(9);
          let selectedGeoType = {
            name: "County",
            api: `${mainURL}/geom?table=counties&field=fips&value=`,
          };
          if (data.summaryObject) {
            setGeoTypeHandler(null, selectedGeoType);
            setGeoTypeOption(selectedGeoType);
            setRegionValue(data.summaryObject);
            if (feature.getProperty("gid")) {
              mapGlobal.data.remove(feature);
            }
          } else {
            mapGlobal.setZoom(12);
            setGeoTypeOption(null);
            setGeoTypeValue(null);
            setRegionValue("");
          }
          break;
        case "County":
          mapGlobal.setZoom(
            parsedJSONData ? parsedJSONData.zoomValue : parseInt(searchParams.get("zoom"))
          );
          setGeoTypeOption(null);
          setGeoTypeValue(null);
          setRegionValue("");
          setBreadCrumbsList([]);
          if (feature.getProperty("zip_code") || feature.getProperty("gid")) {
            mapGlobal.data.remove(feature);
          }
          break;
        case "Census":
          mapGlobal.setZoom(12);
          let selectedGeoType1 = {
            name: "Zip Code",
            api: `${mainURL}/geom?table=zip_code&field=zip_code&value=`,
          };
          setGeoTypeHandler(null, selectedGeoType1);
          setGeoTypeOption(selectedGeoType1);
          setRegionValue(data.summaryObject);
          break;
        default:
          if (feature.getProperty("fips")) {
            mapGlobal.data.remove(feature);
          }
      }
    });
    mapGlobal.data.setStyle(function (feature) {
      switch (data.type) {
        case "Zip Code":
          if (feature.getProperty("zip_code")) {
            let zipCodeStyle = getZipCodeStyle(
              zipCodeObsData,
              feature.getProperty("zip_code"),
              parsedJSONData ? parsedJSONData.state : searchParams.get("state")
            );
            zipCodeStyle.strokeColor = "black";
            zipCodeStyle.strokeWeight = 1;
            return zipCodeStyle;
          } else {
            if (data.summaryObject.id !== feature.getProperty("fips")) {
              return {
                visible: false,
              };
            } else {
              return {
                strokeWeight: 5,
                fillOpacity: 0,
                strokeColor: "red",
                visible: true,
                zIndex: 1,
              };
            }
          }
        case "Census":
          if (feature.getProperty("gid")) {
            return {
              fillColor: getDemoIndexColor(feature.getProperty("p_demogidx")), // Set the fill color for the polygons
              fillOpacity: 0.5, // Set the fill opacity
              strokeWeight: 1, // Set the stroke weight
              strokeColor: "black", // Set the stroke color
              zIndex: 1,
            };
          } else {
            if (data.summaryObject.id !== feature.getProperty("zip_code")) {
              return {
                visible: false,
              };
            } else {
              return {
                strokeWeight: 5,
                fillOpacity: 0,
                strokeColor: "red",
                visible: true,
                zIndex: 0,
              };
            }
          }
        default:
          console.log("break");
          break;
      }
    });
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

    if (data.summaryObject) {
      setValidState(true);
      setGeoType(
        data.summaryObject.geoType == "" ? searchParams.get("geotype") : data.summaryObject.geoType
      );
      setSummaryObject(data.summaryObject);
      data.type == "Census"
        ? setDemographicObject(breadCrumbsList[1].demoObject)
        : setDemographicObject(breadCrumbsList[0].demoObject);
      setGeoValue(data.summaryObject.id);
    } else {
      // console.log("here");
      clearSearch();
      setGeoType("");
      setValidState(false);
      setSummaryObject(null);
      setGeoValue(data.id);
    }
  };

  const [state, setState] = useState({
    zip_code: true,
    census: false,
  });

  function handleClickSnapBtn() {
    console.log(geoType);
    if (selectedSensorInstance == null) {
      if (geoType == "Zip Code") {
        let tempURL = `${window.location.origin}${
          window.location.pathname
        }?geotype=${encodeURIComponent(geoType)}&value=${breadCrumbsList[0].id}&url=${
          parsedJSONData
            ? encodeURIComponent(parsedJSONData.boundaryURL)
            : encodeURIComponent(searchParams.get("url"))
        }&lat=${parsedJSONData ? parsedJSONData.latitude : searchParams.get("lat")}&lng=${
          parsedJSONData ? parsedJSONData.longitude : searchParams.get("lng")
        }&state=${
          parsedJSONData ? parsedJSONData.state : searchParams.get("state")
        }&bounds=${encodeURIComponent(boundState)}&dataSource=${encodeURIComponent(
          JSON.stringify(dataSourceState)
        )}&zoom=${
          parsedJSONData ? parsedJSONData.zoomValue : searchParams.get("zoom")
        }&zip=true&zip_code=${breadCrumbsList[1].id}&breadcrumb=${encodeURIComponent(
          JSON.stringify(breadCrumbsList)
        )}`;
        console.log(tempURL);
        navigator.clipboard.writeText(tempURL);
      } else if (geoType == "Census Tract") {
        let tempURL = `${window.location.origin}${
          window.location.pathname
        }?geotype=${encodeURIComponent(geoType)}&value=${breadCrumbsList[0].id}&url=${
          parsedJSONData
            ? encodeURIComponent(parsedJSONData.boundaryURL)
            : encodeURIComponent(searchParams.get("url"))
        }&lat=${parsedJSONData ? parsedJSONData.latitude : searchParams.get("lat")}&lng=${
          parsedJSONData ? parsedJSONData.longitude : searchParams.get("lng")
        }&state=${
          parsedJSONData ? parsedJSONData.state : searchParams.get("state")
        }&bounds=${encodeURIComponent(boundState)}&dataSource=${encodeURIComponent(
          JSON.stringify(dataSourceState)
        )}&zoom=${
          parsedJSONData ? parsedJSONData.zoomValue : searchParams.get("zoom")
        }&zip=true&zip_code=${breadCrumbsList[1].id}&census=true&gid=${
          breadCrumbsList[2].id
        }&breadcrumb=${encodeURIComponent(JSON.stringify(breadCrumbsList))}`;
        navigator.clipboard.writeText(tempURL);
      } else {
        let tempUrl = `${window.location.origin}${
          window.location.pathname
        }?geotype=${geoType}&value=${breadCrumbsList[breadCrumbsList.length - 1].id}&url=${
          parsedJSONData
            ? encodeURIComponent(parsedJSONData.boundaryURL)
            : encodeURIComponent(searchParams.get("url"))
        }&lat=${parsedJSONData ? parsedJSONData.latitude : searchParams.get("lat")}&lng=${
          parsedJSONData ? parsedJSONData.longitude : searchParams.get("lng")
        }&zoom=${parsedJSONData ? parsedJSONData.zoomValue : searchParams.get("zoom")}&state=${
          parsedJSONData ? parsedJSONData.state : searchParams.get("state")
        }&bounds=${encodeURIComponent(boundState)}&dataSource=${encodeURIComponent(
          JSON.stringify(dataSourceState)
        )}&dataSourceLoaded=${encodeURIComponent(JSON.stringify(dataSourceLoaded))}`;
        console.log(tempUrl, encodeURIComponent(JSON.stringify(dataSourceLoaded)));
        navigator.clipboard.writeText(tempUrl);
      }
    } else {
      if (geoType == "Zip Code") {
        let tempURL = `${window.location.origin}${
          window.location.pathname
        }?geotype=${encodeURIComponent(geoType)}&value=${breadCrumbsList[0].id}&url=${
          parsedJSONData
            ? encodeURIComponent(parsedJSONData.boundaryURL)
            : encodeURIComponent(searchParams.get("url"))
        }&lat=${parsedJSONData ? parsedJSONData.latitude : searchParams.get("lat")}&lng=${
          parsedJSONData ? parsedJSONData.longitude : searchParams.get("lng")
        }&state=${
          parsedJSONData ? parsedJSONData.state : searchParams.get("state")
        }&bounds=${encodeURIComponent(boundState)}&dataSource=${encodeURIComponent(
          JSON.stringify(dataSourceState)
        )}&zoom=${
          parsedJSONData ? parsedJSONData.zoomValue : searchParams.get("zoom")
        }&zip=true&zip_code=${breadCrumbsList[1].id}&breadcrumb=${encodeURIComponent(
          JSON.stringify(breadCrumbsList)
        )}&data=${encodeURIComponent(JSON.stringify(selectedSensorInstance))}`;
        console.log(tempURL);
        // setSnapShotURL(tempURL);
        navigator.clipboard.writeText(tempURL);
      } else {
        let tempUrl = `${window.location.origin}${
          window.location.pathname
        }?geotype=${geoType}&value=${breadCrumbsList[breadCrumbsList.length - 1].id}&url=${
          parsedJSONData
            ? encodeURIComponent(parsedJSONData.boundaryURL)
            : encodeURIComponent(searchParams.get("url"))
        }&lat=${parsedJSONData ? parsedJSONData.latitude : searchParams.get("lat")}&lng=${
          parsedJSONData ? parsedJSONData.longitude : searchParams.get("lng")
        }&zoom=${parsedJSONData ? parsedJSONData.zoomValue : searchParams.get("zoom")}&state=${
          parsedJSONData ? parsedJSONData.state : searchParams.get("state")
        }&bounds=${encodeURIComponent(boundState)}&dataSource=${encodeURIComponent(
          JSON.stringify(dataSourceState)
        )}&data=${encodeURIComponent(JSON.stringify(selectedSensorInstance))}`;
        console.log(tempUrl);
        // setSnapShotURL(tempUrl);
        navigator.clipboard.writeText(tempUrl);
      }
    }

    showNotification("Snapshot URL has been copied to clipboard.", "info");
  }

  const handleCheckbox = (type, checked) => {
    if (type === "zip_code") {
      if (!checked) {
        //console.log("hide zip code");
        // mapGlobal.data.overrideStyle(function (feature) {
        //   if (
        //     feature.getProperty("zip_code")
        //   ) {
        //     return {
        //       visible: false,
        //     };
        //   }
        // });
        sessionStorage.setItem("isLocked", true);
        mapGlobal.data.setStyle(function (feature) {
          if (feature.getGeometry().getType() === "Point") {
            if (feature.getProperty("icon_color")) {
              return {
                icon: {
                  url: require("assets/images/markers/sensorMarkerSmall_" +
                    feature.getProperty("icon_color") +
                    ".png"),
                  scaledSize: new window.google.maps.Size(15, 15),
                },
              };
            }
          } else {
            if (feature.getProperty("zip_code")) {
              return {
                visible: false,
              };
            } else {
              if (summaryObject.id !== feature.getProperty("fips")) {
                return {
                  visible: false,
                };
              } else {
                return {
                  strokeWeight: 5,
                  fillOpacity: 0,
                  strokeColor: "red",
                  visible: true,
                  zIndex: 1,
                };
              }
            }
          }
        });
      } else {
        //console.log("show zip code");
        sessionStorage.setItem("isLocked", false);
        mapGlobal.data.setStyle(function (feature) {
          if (feature.getGeometry().getType() === "Point") {
            if (feature.getProperty("icon_color")) {
              return {
                icon: {
                  url: require("assets/images/markers/sensorMarkerSmall_" +
                    feature.getProperty("icon_color") +
                    ".png"),
                  scaledSize: new window.google.maps.Size(15, 15),
                },
              };
            }
          } else {
            if (feature.getProperty("zip_code")) {
              let zipCodeStyle = getZipCodeStyle(
                zipCodeObsData,
                feature.getProperty("zip_code"),
                parsedJSONData ? parsedJSONData.state : searchParams.get("state")
              );
              zipCodeStyle.strokeColor = "black";
              zipCodeStyle.strokeWeight = 1;
              return zipCodeStyle;
            } else {
              if (summaryObject.id !== feature.getProperty("fips")) {
                return {
                  visible: false,
                };
              } else {
                return {
                  strokeWeight: 5,
                  fillOpacity: 0,
                  strokeColor: "red",
                  visible: true,
                  zIndex: 1,
                };
              }
            }
          }
        });
      }
    } else {
      if (!checked) {
        //console.log("hide census");
        mapGlobal.data.setStyle(function (feature) {
          if (feature.getProperty("gid")) {
            return {
              visible: false,
            };
          } else if (feature.getGeometry().getType() === "Point") {
            if (feature.getProperty("icon_color")) {
              return {
                icon: {
                  url: require("assets/images/markers/sensorMarkerSmall_" +
                    feature.getProperty("icon_color") +
                    ".png"),
                  scaledSize: new window.google.maps.Size(15, 15),
                },
              };
            }
          } else {
            if (summaryObject.id !== feature.getProperty("zip_code")) {
              return {
                visible: false,
              };
            } else {
              return {
                strokeWeight: 5,
                fillOpacity: 0,
                strokeColor: "red",
                visible: true,
                zIndex: 1,
              };
            }
          }
        });
      } else {
        //console.log("show census");
        mapGlobal.data.setStyle(function (feature) {
          if (feature.getProperty("gid")) {
            return {
              fillColor: getDemoIndexColor(feature.getProperty("p_demogidx")), // Set the fill color for the polygons
              fillOpacity: 0.5, // Set the fill opacity
              strokeWeight: 1, // Set the stroke weight
              strokeColor: "black", // Set the stroke color
              zIndex: 2,
            };
          } else if (feature.getGeometry().getType() === "Point") {
            if (feature.getProperty("icon_color")) {
              return {
                icon: {
                  url: require("assets/images/markers/sensorMarkerSmall_" +
                    feature.getProperty("icon_color") +
                    ".png"),
                  scaledSize: new window.google.maps.Size(15, 15),
                },
              };
            }
          } else {
            if (summaryObject.id !== feature.getProperty("zip_code")) {
              return {
                visible: false,
              };
            } else {
              return {
                strokeWeight: 5,
                fillOpacity: 0,
                strokeColor: "red",
                visible: true,
                zIndex: 1,
              };
            }
          }
        });
      }
    }
  };

  useEffect(() => {
    //console.log("2"); old flow by selecting.
    if (geomObject != null) {
      setBreadCrumbsList([]);
      setTabValue(1);
      sessionStorage.setItem("old", "yes");
      //sessionStorage.setItem("is_old_flow", true);
      let regionGeometry = geomObject;
      // console.log("geomObject in map", geomObject.features[0].properties);
      let urlEx = "";
      if (geomObject.features[0].properties.zip_code) {
        urlEx = `https://umissis.org/bluegap/tract_demography3?table=zip_code&value=${geomObject.features[0].properties.zip_code}`;
        sessionStorage.setItem("is_old_flow", true);
      } else {
        urlEx = `https://umissis.org/bluegap/tract_demography2?table=county&value=${geomObject.features[0].properties.fips}`;
        sessionStorage.setItem("is_old_flow", false);
        sessionStorage.setItem("isLocked", false);
      }

      //This is for removing the old map data.
      mapGlobal?.data.forEach(function (feature) {
        mapGlobal.data.remove(feature);
      });

      if (geomObject.type == "FeatureCollection" && geomObject.features.length !== 0) {
        regionGeometry = geomObject.features[0];
      }

      if (geomObject.type == "FeatureCollection" && geomObject.features.length !== 0) {
        regionGeometry = geomObject.features[0];
      }

      mapGlobal?.data.addGeoJson(regionGeometry);

      // Adjust zoom level to show entire region on map.
      var bounds = new window.google.maps.LatLngBounds();

      mapGlobal?.data.forEach(function (feature) {
        feature.getGeometry().forEachLatLng(function (latlng) {
          bounds.extend(latlng);
        });
      });

      // Retrieve the latitude and longitude values from the bounds
      var swLatLng = bounds.getSouthWest();
      var neLatLng = bounds.getNorthEast();

      var swLat = swLatLng.lat();
      var swLng = swLatLng.lng();
      var neLat = neLatLng.lat();
      var neLng = neLatLng.lng();

      let demoObject = {};

      demoObject.lat = (swLat + neLat) / 2;
      demoObject.lng = (swLng + neLng) / 2;

      fetch(urlEx)
        .then((res) => res.json())
        .then((data) => {
          let over64 = 0;
          let unemployed = 0;
          let under5 = 0;
          let lowincpct = 0;
          let lesshspct = 0;
          let peopcolorp = 0;
          let lingisopct = 0;
          let demoindex = 0;
          let totalpop = 0;

          let tempDemograph = data.features;
          //console.log("demographic", tempDemograph);

          tempDemograph.forEach((item) => {
            totalpop += item.properties.acstotpop;
            over64 += item.properties.over64;
            unemployed += item.properties.unemployed;
            under5 += item.properties.under5;
            lowincpct += item.properties.lowincome;
            lesshspct += item.properties.lesshs;
            peopcolorp += item.properties.peopcolor;
            lingisopct += item.properties.lingiso;
            demoindex += item.properties.p_demogidx;
          });

          unemployed = unemployed / totalpop;
          peopcolorp = peopcolorp / totalpop;
          lowincpct = lowincpct / totalpop;
          lesshspct = lesshspct / totalpop;
          lingisopct = lingisopct / totalpop;
          demoindex = demoindex / totalpop;

          demoObject.over64 = over64;
          demoObject.unemployed = Math.round(unemployed * 100).toFixed(2);
          demoObject.under5 = under5;
          demoObject.lowincpct = Math.round(lowincpct * 100).toFixed(2);
          demoObject.lesshspct = Math.round(lesshspct * 100).toFixed(2);
          demoObject.peopcolorp = Math.round(peopcolorp * 100).toFixed(2);
          demoObject.lingisopct = Math.round(lingisopct * 100).toFixed(2);
          demoObject.demoindex = Math.round(demoindex * 100).toFixed(2);
          setDemographicObject(demoObject);
        });

      mapGlobal?.data.setStyle(function (feature) {
        if (feature.getProperty("fips")) {
          let countyStyle = getCountyStyle(
            countyObsData,
            feature.getProperty("fips"),
            parsedJSONData.state
          );
          countyStyle.strokeColor = "red";
          countyStyle.strokeWeight = 5;
          return countyStyle;
        } else {
          let zipCodeStyle = getZipCodeStyle(
            zipCodeObsData,
            feature.getProperty("zip_code"),
            parsedJSONData.state
          );

          return zipCodeStyle;
        }
      });

      mapGlobal?.fitBounds(bounds);
    }
  }, [geomObject]);

  useEffect(() => {
    // Add mouseover and mouseout event listeners to the marker
    mapGlobal?.data.addListener("mouseover", function (event) {
      let tempData = event.feature;

      if (tempData.getGeometry().getType() !== "Point") {
        if (sessionStorage.getItem("hoverFeature") == "true") {
          let fips = tempData.getProperty("fips");
          // console.log("hover feature", fips);

          if (fips != 12049) {
            setTabValue(1);
            var geometry = tempData.getGeometry();

            // Calculate the bounds of the clicked feature
            var bounds = new google.maps.LatLngBounds();
            geometry.forEachLatLng(function (latlng) {
              bounds.extend(latlng);
            });

            //demographic part
            // Retrieve the latitude and longitude values from the bounds
            // let demoData = [];
            // if (parsedJSONData ? parsedJSONData.state : searchParams.get("state") == "IA") {
            //   demoData = iowaDemoData;
            // } else if (parsedJSONData ? parsedJSONData.state : searchParams.get("state") == "FL") {
            //   demoData = tampaDemoData;
            // }

            setDemographicObject(getDemographicData("County", "hover", bounds, fips, null, []));
            //

            // Highlight the selected marker on map
            mapGlobal.data.overrideStyle(tempData, {
              strokeWeight: 5,
              fillOpacity: 0,
              strokeColor: "red",
            });

            let featureObs = getCountyObsData(countyObsData, fips);
            //console.log(featureObs);
            if (featureObs) {
              const processedObj = getDataList(featureObs);

              let tempObj = {};

              tempObj = getSummaryObject(
                processedObj,
                featureObs,
                "County",
                parsedJSONData ? parsedJSONData.state : searchParams.get("state")
              );

              if (fips) {
                tempObj.name =
                  tempData.getProperty("name") + ", " + tempData.getProperty("state_name");

                tempObj.id = fips;
              }

              tempObj.geoType = "County";
              // console.log(tempObj);
              setGeoType("County");
              setSummaryObject(tempObj);
              setValidState(true);
            } else {
              showNotification("No data found!!", "error");
            }
          } else {
            showNotification("No Data Found.", "error");
          }
        }
      }
    });

    mapGlobal?.data.addListener("mouseout", function (event) {
      let tempData = event.feature;
      if (tempData.getGeometry().getType() !== "Point") {
        if (sessionStorage.getItem("hoverFeature") == "true") {
          if (tempData.getProperty("fips")) {
            // Highlight the selected marker on map
            mapGlobal.data.revertStyle();
            setGeoType(null);
            setSummaryObject(null);
            setValidState(false);
          }
        }
      }
    });
  }, [mapGlobal]);

  const checkUndefined = (value) => {
    return value ? value : 0;
  };

  const naIfNull = (value) => {
    return value === null || value === undefined || value === "null" || value === "NULL"
      ? "N/A"
      : value;
  };

  function noSitesNotifier(sensorClassName) {
    if (sensorClassName == "sdwa" || sensorClassName == "pws") {
      showNotification(`Drinking water (public) data cannot be found for this location.`, "error");
    } else if (sensorClassName == "pwts" || sensorClassName == "tbw") {
      showNotification(`Drinking water (private) data cannot be found for this location.`, "error");
    } else if (sensorClassName == "recWatersOcean") {
      showNotification(
        `Recreational Waters (Oceans) data cannot be found for this location.`,
        "error"
      );
    } else {
      showNotification(`Recreational Waters data cannot be found for this location.`, "error");
    }
  }

  function geoScopeBroadNotifier(sensorClassName) {
    if (sensorClassName == "sdwa" || sensorClassName == "pws") {
      showNotification(
        `Geoscope is too broad. Drinking water (public) data cannot be visualized; please select more specific geo-context to visualize sensors`,
        "error"
      );
    } else if (sensorClassName == "pwts" || sensorClassName == "tbw") {
      showNotification(
        `Geoscope is too broad. Drinking water (private) data cannot be visualized; please select more specific geo-context to visualize sensors`,
        "error"
      );
    } else {
      showNotification(
        `Geoscope is too broad. Recreational Waters data cannot be visualized; please select more specific geo-context to visualize sensors`,
        "error"
      );
    }
  }

  useEffect(() => {
    //console.log("3");
    // console.log("load sendsotr data", sensorObject);
    if (sensorObject != null && mapGlobal != null) {
      //console.log(sensorObject);
      if (sensorObject.Notice !== undefined) {
        // No sensors found for this location.
        noSitesNotifier(sensorClassName);

        setDataSourceLoaded(dispatch, {
          ...dataSourceLoaded,
          [sensorClassName.toLowerCase()]: false,
        });
        setDataSourceState(dispatch, {
          ...dataSourceState,
          [sensorClassName.toLowerCase()]: false,
        });
        return;
      }
      // TODO: Race condition in setting sensorObject (i.e. all data sources are racing to set it)
      if (sensorObject.features === null) {
        // No sensors found for this location.
        noSitesNotifier(sensorClassName);

        if (sensorClassName === "pwts") {
          setDataSourceState(dispatch, {
            ...dataSourceState,
            ["groundWater"]: false,
            [sensorClassName.toLowerCase()]: false,
          });
          return;
        } else if (sensorClassName === "sdwa") {
          setDataSourceState(dispatch, {
            ...dataSourceState,
            ["drinkingWater"]: false,
            [sensorClassName.toLowerCase()]: false,
          });
          return;
        } else if (sensorClassName === "waterCommunity") {
          setDataSourceState(dispatch, {
            ...dataSourceState,
            ["waterCommunity"]: false,
          });
          return;
        } else if (sensorClassName === "recWatersOcean") {
          setDataSourceState(dispatch, {
            ...dataSourceState,
            ["recWatersOcean"]: false,
          });
          return;
        }

        setDataSourceLoaded(dispatch, {
          ...dataSourceLoaded,
          [sensorClassName.toLowerCase()]: false,
        });
        setDataSourceState(dispatch, {
          ...dataSourceState,
          [sensorClassName.toLowerCase()]: false,
        });
        return;
      }
      // //console.log(sensorData);
      let actualMapObject = mapGlobal;

      if (sensorObject.type == "FeatureCollection") {
        // check if we have way too much sensors to visualize
        if (sensorObject.features.length > g_maxNumberOfSensorsOnMap) {
          geoScopeBroadNotifier(sensorClassName);
          setDataSourceLoaded(dispatch, {
            ...dataSourceLoaded,
            [sensorClassName.toLowerCase()]: false,
          });
          setDataSourceState(dispatch, {
            ...dataSourceState,
            [sensorClassName.toLowerCase()]: false,
          });
          return;
          //sensorObject.features = sensorObject.features.slice(0, 50); //Get the first 50 point only.
        }
      }
      let tempStateValue = parsedJSONData ? parsedJSONData.state : searchParams.get("state");
      // Before we add the layer, annotate them with its source, e.g. Storet
      sensorObject?.features.forEach(function (feature) {
        // console.log(feature.properties.site == "23000002" ? feature.properties : null);
        if (tempStateValue == "VI" || feature.properties["agency"] == "TBB") {
          feature.properties["data_source"] = "recWatersOcean";
        } else {
          feature.properties["data_source"] = feature.properties["agency"].toLowerCase();
        }

        feature.properties["icon_color"] = getColorForProvider(
          feature.properties["agency"].toLowerCase()
        );
      });
      // console.log("sens", sensorObject);

      actualMapObject.data.addGeoJson(sensorObject);
      let dataToShow = {
        siteid: null,
        sitename: null,
        statecode: null,
        stateabbr: null,
        provider: null,
        lat: null,
        lng: null,
        desc: null,
        agency: null,
        tableData: null,
      };

      actualMapObject.data.addListener("click", function (event) {
        // setShowObservationsPanel(dispatch, false);
        //debugger;
        if (event.feature.getGeometry().getType() === "Point") {
          setOpenPanel(false);
          let clickedSensorData = event.feature;
          // console.log(clickedSensorData);
          let clickedSensorClass = clickedSensorData.getProperty("agency");
          // console.log(clickedSensorClass.toLowerCase());
          switch (clickedSensorClass.toLowerCase()) {
            case "storet":
            case "usvi":
              dataToShow.provider = "STORET";
              dataToShow.desc = clickedSensorData.getProperty("sitedesc");
              dataToShow.agency = clickedSensorData.getProperty("agency");
              break;
            case "iwqis":
              dataToShow.provider = "IWQIS";
              dataToShow.desc = clickedSensorData.getProperty("sitedesc");
              dataToShow.agency = "IWQIS";
              break;
            case "sdwa":
              dataToShow.provider = "SDWA";
              dataToShow.agency = "SDWA";
              break;
            case "tbw":
              dataToShow.provider = "TBW";
              dataToShow.agency = "TBW";
              break;
            case "pws":
              dataToShow.provider = "PWS";
              dataToShow.agency = "PWS";
              break;
            case "pwts":
              dataToShow.provider = "PWTS";
              dataToShow.agency = "PWTS";
              break;
            case "iadnr":
              dataToShow.provider = "IADNR";
              dataToShow.agency = "IADNR";
              dataToShow.extentVal = clickedSensorData.getProperty("extentval").toLowerCase();
              break;
            case "iowa21":
              dataToShow.provider = "IADNR";
              dataToShow.agency = "21iowa";
              break;
            case "iowagw":
              dataToShow.provider = "IADNR";
              dataToShow.agency = "iowagw";
              break;
            case "iasnapst":
              dataToShow.provider = "IADNR";
              dataToShow.agency = "iasnapst";
              break;
            case "nondnr":
              dataToShow.provider = "IADNR";
              dataToShow.agency = "nondnr";
              break;
            case "tbb":
              dataToShow.provider = "TBB";
              dataToShow.agency = "TBB";
              break;
            default:
              dataToShow.provider = "NWISDV";
              dataToShow.agency = "NWIS";
              break;
          }
          dataToShow.fips = clickedSensorData.getProperty("fips");
          dataToShow.statecode = clickedSensorData.getProperty("state_fips");
          dataToShow.sitename = clickedSensorData.getProperty("huc12name");
          dataToShow.stateabbr = clickedSensorData.getProperty("state_abbr")
            ? clickedSensorData.getProperty("state_abbr")
            : parsedJSONData
            ? parsedJSONData.state
            : searchParams.get("state");
          dataToShow.siteid = String(clickedSensorData.getProperty("site"));
          dataToShow.lat = checkUndefined(clickedSensorData.getGeometry().get().lat());
          dataToShow.lng = checkUndefined(clickedSensorData.getGeometry().get().lng());

          //console.log(dataToShow);
          if (
            clickedSensorData.getProperty("agency") == "PWTS" ||
            clickedSensorData.getProperty("agency") == "SDWA" ||
            clickedSensorData.getProperty("agency") == "PWS"
          ) {
            fetch(
              `https://umissis.org/bluegap/${dataToShow.agency.toLowerCase()}_metadata?site=${
                dataToShow.siteid
              }`
            )
              .then((res) => res.json())
              .then((data) => {
                // console.log(
                //   `https://umissis.org/bluegap/${dataToShow.agency.toLowerCase()}_metadata?site=${
                //     dataToShow.siteid
                //   }`,
                //   data[0] ? data[0][0] : null
                // );
                switch (clickedSensorData.getProperty("agency")) {
                  case "SDWA":
                    dataToShow.tableData = [
                      [["Facility:", naIfNull(data[0] ? data[0][0].facName : null)]],
                      [["Agency:", naIfNull(dataToShow.agency)]],
                      [["Site:", naIfNull(dataToShow.siteid)]],
                      [["Address:", naIfNull(data[0] ? data[0][0].LocAddress : null)]],
                      [["City:", naIfNull(clickedSensorData.getProperty("po_name"))]],
                      [["County:", naIfNull(clickedSensorData.getProperty("county_name"))]],
                      [["River:", naIfNull(dataToShow.sitename)]],
                    ];
                    break;
                  case "PWTS":
                    dataToShow.tableData = [
                      [["Agency:", naIfNull(dataToShow.agency)]],
                      [["Site:", naIfNull(dataToShow.siteid)]],
                      [["Place:", naIfNull(clickedSensorData.getProperty("po_name"))]],
                      [["County:", naIfNull(clickedSensorData.getProperty("county_name"))]],
                      [["Owner:", naIfNull(data[0] ? data[0][0].OWNER_NAME : null)]],
                      [["Well Number:", naIfNull(data[0] ? data[0][0].WELLNMBR : null)]],
                      [["Cnstr. Method:", naIfNull(data[0] ? data[0][0].CNSTR_MTHD : null)]],
                      [["Well Usage:", naIfNull(data[0] ? data[0][0].LocAddress : null)]],
                      [["River:", naIfNull(dataToShow.sitename)]],
                    ];
                    break;
                  default:
                    dataToShow.tableData = [
                      [["Agency:", naIfNull(dataToShow.agency)]],
                      [["Site:", naIfNull(dataToShow.siteid)]],
                      [["Place:", naIfNull(clickedSensorData.getProperty("po_name"))]],
                      [["County:", naIfNull(clickedSensorData.getProperty("county_name"))]],
                      [["Type:", naIfNull(data[0] ? data[0][0].PWS_TYPE : null)]],
                      [["Operator:", naIfNull(data[0] ? data[0][0].PWS_OPERATOR : null)]],
                      [["Plant Name:", naIfNull(data[0] ? data[0][0].PLANT_NAME : null)]],
                      [
                        [
                          "Pri Service Area:",
                          naIfNull(data[0] ? data[0][0].PWS_PRIMARY_SERVICE_AREA : null),
                        ],
                      ],
                      [["River:", naIfNull(dataToShow.sitename)]],
                    ];
                    break;
                }
                setSelectedSensorInstance(dataToShow);
                setSensorDataBox(dispatch, dataToShow);

                //console.log("data", clickedSensorData.j);
                //console.log("class", clickedSensorClass);
                if (clickedSensorData.getGeometry().getType() === "Point") {
                  // Highlight the selected marker on map
                  actualMapObject.data.revertStyle();
                  actualMapObject.data.overrideStyle(clickedSensorData, {
                    icon: {
                      url: require("assets/images/markers/sensorMarkerSmall_selected.png"),
                      scaledSize: new window.google.maps.Size(15, 15),
                    },
                  });
                }
              });
          } else {
            dataToShow.tableData = [
              [["Site:", naIfNull(dataToShow.siteid)]],
              [["Agency:", naIfNull(dataToShow.agency)]],
              [["County:", naIfNull(clickedSensorData.getProperty("county_name"))]],
              [["City:", naIfNull(clickedSensorData.getProperty("po_name"))]],
              [["River:", naIfNull(dataToShow.sitename)]],
            ];

            // debugger;
            setSelectedSensorInstance(dataToShow);
            setSensorDataBox(dispatch, dataToShow);

            //console.log("data", clickedSensorData.j);
            //console.log("class", clickedSensorClass);
            if (clickedSensorData.getGeometry().getType() === "Point") {
              // Highlight the selected marker on map
              actualMapObject.data.revertStyle();
              actualMapObject.data.overrideStyle(clickedSensorData, {
                icon: {
                  url: require("assets/images/markers/sensorMarkerSmall_selected.png"),
                  scaledSize: new window.google.maps.Size(15, 15),
                },
              });
            }
          }
        }
      });

      //console.log("bbLength", breadCrumbsList.length);
      //console.log("bb", breadCrumbsList);
      // actualMapObject.data.forEach((feature) => {
      //   if (feature.getGeometry().getType() === "Point") {
      //     if (feature.getProperty("icon_color")) {
      //       actualMapObject.data.overrideStyle(feature, {
      //         icon: {
      //           url: require("assets/images/markers/sensorMarkerSmall_" +
      //             feature.getProperty("icon_color") +
      //             ".png"),
      //           scaledSize: new window.google.maps.Size(15, 15),
      //         },
      //       });
      //     }
      //   }
      // });
      actualMapObject.data.setStyle(function (feature) {
        if (feature.getGeometry().getType() === "Point") {
          // debugger;
          if (feature.getProperty("icon_color")) {
            return {
              icon: {
                url: require("assets/images/markers/sensorMarkerSmall_" +
                  feature.getProperty("icon_color") +
                  ".png"),
                scaledSize: new window.google.maps.Size(15, 15),
              },
            };
          }
        } else {
          if (breadCrumbsList?.length === 1 && breadCrumbsList[0].state != "VI") {
            //tesko zipcodes dekha
            if (feature.getProperty("zip_code")) {
              if (state.zip_code) {
                let zipCodeStyle = getZipCodeStyle(
                  zipCodeObsData,
                  feature.getProperty("zip_code"),
                  parsedJSONData ? parsedJSONData.state : searchParams.get("state")
                );
                zipCodeStyle.strokeColor = "black";
                zipCodeStyle.strokeWeight = 1;
                return zipCodeStyle;
              } else {
                return {
                  visible: false,
                };
              }
            } else {
              if (feature.getProperty("fips") === breadCrumbsList[0].id) {
                return {
                  strokeWeight: 5,
                  fillOpacity: 0,
                  strokeColor: "red",
                  visible: true,
                  zIndex: 1,
                };
              } else {
                return {
                  visible: false,
                };
              }
            }
          } else if (breadCrumbsList.length === 2 || breadCrumbsList[0]?.state == "VI") {
            //place
            if (
              feature.getProperty("zip_code") === breadCrumbsList[breadCrumbsList.length - 1].id &&
              !feature.getProperty("gid")
            ) {
              return {
                strokeWeight: 5,
                fillOpacity: 0,
                strokeColor: "red",
                visible: true,
                zIndex: 1,
              };
            } else if (feature.getProperty("gid")) {
              if (state.census) {
                return {
                  fillColor: getDemoIndexColor(feature.getProperty("p_demogidx")), // Set the fill color for the polygons
                  fillOpacity: 0.5, // Set the fill opacity
                  strokeWeight: 1, // Set the stroke weight
                  strokeColor: "black", // Set the stroke color
                  zIndex: 1,
                };
              } else {
                return {
                  visible: false,
                };
              }
            } else {
              return {
                visible: false,
              };
            }
          } else {
            //Old flow comes here.
            let color = "";
            let tempData = [];
            let sum_c = 0;

            if (feature.getProperty("fips")) {
              tempData = getCountyObsData(countyObsData, feature.getProperty("fips"));
              //console.log("tempo", tempData);
            } else {
              tempData = getZipCodeObsData(zipCodeObsData, feature.getProperty("zip_code"));
              //console.log("tempo", tempData);
            }

            for (const key in tempData) {
              if (key.includes("_c")) {
                const value = tempData[key];
                if (typeof value === "number") {
                  sum_c += value;
                }
              }
            }
            color = getColor(
              sum_c,
              parsedJSONData ? parsedJSONData.state : searchParams.get("state"),
              geoType == "County" ? false : true
            );

            return {
              visible: true,
              fillColor: color,
              fillOpacity: 0.5,
              strokeWeight: 5,
              strokeColor: "red",
            };
          }
        }
      });

      //we only need this below code to run when the user loads the url link first time.
      if (!isFirstSnapShotRender.current) {
        return;
      }

      if (decodeURIComponent(searchParams.get("data")) !== "null") {
        console.log(JSON.parse(decodeURIComponent(searchParams.get("data"))));
        setSelectedSensorInstance(JSON.parse(decodeURIComponent(searchParams.get("data"))));
        setSensorDataBox(dispatch, JSON.parse(decodeURIComponent(searchParams.get("data"))));
        // actualMapObject.data.revertStyle();
        actualMapObject.data.forEach((feature) => {
          if (feature.getGeometry().getType() === "Point") {
            if (
              feature.getProperty("site") ==
              JSON.parse(decodeURIComponent(searchParams.get("data"))).siteid
            ) {
              actualMapObject.data.overrideStyle(feature, {
                icon: {
                  url: require("assets/images/markers/sensorMarkerSmall_selected.png"),
                  scaledSize: new window.google.maps.Size(15, 15),
                },
              });
            }
          }
        });
      }

      isFirstSnapShotRender.current = false;
    }
  }, [sensorObject]);

  //To prevent run in the first render.
  const firstRender = useRef(true);

  //refactored one.
  useEffect(() => {
    // Avoid running the effect on the first render
    //console.log("4");
    // debugger;
    if (!firstRender.current) {
      const layersToRemove = [];

      if (!dataSourceState.sdwa && drinkingWaterLoaded) {
        layersToRemove.push("sdwa", "pws");
      }

      if (!dataSourceState.waterCommunity && waterCommunityLoaded) {
        layersToRemove.push(
          "waterCommunity",
          "iasnapst",
          "iowa21",
          "iowagw",
          "nondnr",
          "nwisdv",
          "storet",
          "iwqis",
          "nwis",
          "iadnr"
        );
      }

      if (!dataSourceState.pwts && groundWaterLoaded) {
        layersToRemove.push("pwts", "tbw");
      }

      if (!dataSourceState.recWatersOcean && recreationalWaterOceanLoaded) {
        layersToRemove.push("recWatersOcean");
      }

      if (layersToRemove.length > 0) {
        // setDataSourceLoaded(dispatch, {
        //   ...dataSourceLoaded,
        //   ...Object.fromEntries(layersToRemove.map((layer) => [layer, false])),
        // });
        layersToRemove.forEach((item) => {
          switch (item) {
            case "sdwa":
            case "pws":
              setDrinkingWaterLoaded(false);
              break;
            case "pwts":
            case "tbw":
              setGroundWaterLoaded(false);
              break;
            case "recWatersOcean":
              setRecreationalWaterOceanLoaded(false);
              break;
            default:
              setWaterCommunityLoaded(false);
              break;
          }
        });
        layersToRemove.forEach((layer) => removeSensorDataLayer(mapGlobal, layer));
      }
    } else {
      firstRender.current = false;
    }
  }, [dataSourceState]);

  const getColorForProvider = (provider) => {
    switch (provider) {
      case "pwts":
      case "tbw":
        // return "#9c27b0"; // purple
        return "pwts";
      case "sdwa":
      case "pws":
        // return "#9c27b0"; // purple
        return "sdwa";
      case "recWatersOcean":
        // return "#9c27b0"; // purple
        return "purple";
      default:
        // NWIS
        // return "#4caf50"; // green
        return "green";
    }
  };

  const removeSensorDataLayer = (map, dataSource) => {
    // debugger;
    map.data.forEach(function (feature) {
      if (feature.getProperty("data_source") == dataSource) {
        map.data.remove(feature);
      }
    });
  };

  const handleDataSourceLoaded = (source, isLoaded) => {
    setDataSourceLoaded(dispatch, { ...dataSourceLoaded, [source]: isLoaded });
  };

  function resetMap() {
    sessionStorage.setItem("is_old_flow", false);
    setState({
      zip_code: true,
      census: false,
    });
    setOpenPanel(false);
    setGeoType("");
    setGeoTypeOption(null);
    setGeomObject(dispatch, null);
    setSelectedSensorInstance(null);
    setSensorDataBox(dispatch, null);
    resetDataSource();

    // mapGlobal?.data.forEach(function (feature) {
    //   mapGlobal.data.remove(feature);
    // });
    mapGlobal?.data.forEach((feature) => {
      if (!feature.getProperty("fips") || feature.getGeometry().getType() === "Point") {
        mapGlobal.data.remove(feature);
      }
    });

    mapGlobal?.data.setStyle(function (feature) {
      // console.log("herer", feature.getProperty("fips"));
      if (feature.getProperty("fips")) {
        let countyStyle = getCountyStyle(
          countyObsData,
          feature.getProperty("fips"),
          parsedJSONData ? parsedJSONData.state : searchParams.get("state")
        );

        return countyStyle;
      } else {
        return {
          visible: false,
        };
      }
    });
  }

  function clearSearch() {
    resetMap();
    mapGlobal?.data.forEach((feature) => {
      mapGlobal.data.remove(feature);
    });
    lockZipCode = false;
    sessionStorage.setItem("isLocked", false);
    sessionStorage.setItem("hoverFeature", true);
    fetch(parsedJSONData ? parsedJSONData.boundaryURL : searchParams.get("url"))
      .then((res) => res.json())
      .then((result) => {
        //console.log("result", result);
        mapGlobal?.data.addGeoJson(result);
        //parsedJSONData.state != "VI" ? mapGlobal?.setZoom(7) : mapGlobal?.setZoom(12);
        switch (parsedJSONData ? parsedJSONData.state : searchParams.get("state")) {
          case "IA":
            mapGlobal?.setZoom(7);
            break;
          case "FL":
            mapGlobal?.setZoom(8);
            break;
          default:
            mapGlobal?.setZoom(
              parsedJSONData ? parsedJSONData.zoomValue : parseInt(searchParams.get("zoom"))
            );
            break;
        }
        setBreadCrumbsList([]);
      });
  }

  function loadNewMap(region, requestURL, geoType) {
    //console.log("load new map", region);
    setGeoType(geoType);
    setSelectedSensorInstance(null);
    setSensorDataBox(dispatch, null);
    resetDataSource();
    if (region != null && region.url) {
      const replacedURL = region.url.replace("http://128.255.26.71:9999", mainURL);
      fetch(replacedURL)
        .then((res) => res.json())
        .then(
          (result) => {
            sessionStorage.setItem("hoverFeature", false);
            // console.log(region);
            // var geometry = result.features[0].geometry.getGeometry();

            // // Calculate the bounds of the clicked feature
            // var bounds = new google.maps.LatLngBounds();
            // geometry.forEachLatLng(function (latlng) {
            //   bounds.extend(latlng);
            // });

            // console.log("bounds", bounds);
            let featureObs = {};

            if (result.features[0].properties.fips) {
              featureObs = getCountyObsData(countyObsData, result.features[0].properties.fips);
              setGeoValue(result.features[0].properties.fips);
            }

            if (result.features[0].properties.zip_code) {
              featureObs = getZipCodeObsData(
                zipCodeObsData,
                result.features[0].properties.zip_code
              );
              setGeoValue(result.features[0].properties.zip_code);
            }

            const processedObj = getDataList(featureObs);

            let summaryObj = getSummaryObject(
              processedObj,
              featureObs,
              geoType,
              parsedJSONData.state
            );

            switch (geoType) {
              case "County":
                summaryObj.id = result.features[0].properties.fips;
                summaryObj.name =
                  result.features[0].properties.name +
                  ", " +
                  result.features[0].properties.state_name;
                break;
              case "City":
                summaryObj.name = result.features[0].properties.ua_label;
                break;
              case "Zip Code":
                summaryObj.id = result.features[0].properties.zip_code;
                summaryObj.name = result.features[0].properties.zip_code;
                summaryObj.search = result.features[0].properties.zip_code;
                summaryObj.po_name = result.features[0].properties.po_name;
                summaryObj.zip_code = result.features[0].properties.zip_code;
                break;
              default:
                summaryObj.id = place_fips;
                summaryObj.name =
                  result.features[0].properties.name + ", " + result.features[0].properties.st;
            }

            setGeomObject(dispatch, result);
            setValidState(true);
            setRegionValue(summaryObj);
            setSummaryObject(summaryObj);
          },
          (error) => {
            //console.log(error);
          }
        );
    } else {
      setGeomObject(dispatch, null);
      setValidState(false);
    }
  }

  async function loadSensorData(geoType, region) {
    const queryParams = getQueryParams(geoType, region);

    // const dataSourcesToLoad = Object.entries(dataSourceState).filter(
    //   ([key, value]) => value && !dataSourceLoaded[key]
    // );
    const dataSourcesToLoad = Object.entries(dataSourceState).filter(([key, value]) => {
      switch (key) {
        case "sdwa":
        case "pws":
          return value && !drinkingWaterLoaded;
        case "pwts":
        case "tbw":
          return value && !groundWaterLoaded;
        case "waterCommunity":
          return value && !waterCommunityLoaded;
        case "recWatersOcean":
          return value && !recreationalWaterOceanLoaded;
        default:
          return false;
      }
    });
    for (const [key] of dataSourcesToLoad) {
      setSensorClassName(dispatch, key);

      let endpointURL;
      let sensorDataResult;

      switch (key) {
        case "pwts":
          endpointURL = pwtsEndpoints[geoType] + queryParams;
          sensorDataResult = await retrieveSensorData(endpointURL, "pwts");
          setSensorObject(dispatch, sensorDataResult);
          // handleDataSourceLoaded(key, true);
          setGroundWaterLoaded(true);
          break;

        case "sdwa":
          endpointURL = sdwaEndpoints[geoType] + queryParams;
          sensorDataResult = await retrieveSensorData(endpointURL, "sdwa");
          setSensorObject(dispatch, sensorDataResult);
          // handleDataSourceLoaded(key, true);
          setDrinkingWaterLoaded(true);
          break;

        case "waterCommunity":
          endpointURL = waterCommunityEndpoints[geoType] + queryParams;
          sensorDataResult = await retrieveSensorData(endpointURL, "waterCommunity");
          setSensorObject(dispatch, sensorDataResult);
          // handleDataSourceLoaded(key, true);
          setWaterCommunityLoaded(true);
          break;

        case "recWatersOcean":
          endpointURL = recreationalWaterOceanEndpoints[geoType] + queryParams;
          sensorDataResult = await retrieveSensorData(endpointURL, "recWatersOceans");
          setSensorObject(dispatch, sensorDataResult);
          // handleDataSourceLoaded(key, true);
          setRecreationalWaterOceanLoaded(true);
          break;

        default:
          break;
      }
    }

    setLoadingIndicator(false);
  }

  function getQueryParams(geoType, region) {
    //console.log(region);
    switch (geoType) {
      case "State":
        return region.state_fips;
      case "County":
        return region.id;
      case "Place":
        return region?.name?.split(",")[0];
      case "City":
        return region.ua_label;
      case "Zip Code":
        return region.zip_code;
      case "Watershed":
        const url = new URL(region.url);
        const searchParams = new URLSearchParams(url.search);

        const value = searchParams.get("value"); // "070101"
        const field = searchParams.get("field"); // "huc6"
        return `${field}&value=${value}`;
      default:
        return "";
    }
  }

  const retrieveSensorData = async (endpointURL, type) => {
    return fetch(endpointURL)
      .then((res) => res.json())
      .then(
        (result) => {
          // //console.log(result);
          // return result;
          //console.log(type);
          if (type == "waterCommunity" || "pwts") {
            return result;
          } else {
            let tempData = result[0];
            return tempData[0];
          }
        },
        (error) => {
          // showNotification("There was an error retrieving the data. Please try again later.");
          //console.log(error);
          return null;
        }
      );
  };

  const handleDataSourceChange = (event) => {
    // debugger;
    //console.log(event.target.name + ": ", event.target.checked);
    setSensorDataBox(dispatch, null);
    setLoadingIndicator(true);
    if (event.target.name == "waterCommunity") {
      setDataSourceState(dispatch, {
        ...dataSourceState,
        [event.target.name]: event.target.checked,
        ["iasnapst"]: false,
        ["iowa21"]: false,
        ["iowagw"]: false,
        ["nondnr"]: false,
        ["nwisdv"]: false,
        ["storet"]: false,
        ["iwqis"]: false,
      });
      // setWaterCommunity(dispatch, event.target.checked);
    } else if (event.target.name == "drinkingWater") {
      setDataSourceState(dispatch, {
        ...dataSourceState,
        [event.target.name]: event.target.checked,
        ["sdwa"]: event.target.checked,
      });
      // setDrikingWater(dispatch, event.target.checked);
    } else if (event.target.name == "groundWater") {
      setDataSourceState(dispatch, {
        ...dataSourceState,
        [event.target.name]: event.target.checked,
        ["pwts"]: event.target.checked,
      });

      // setGroundWater(dispatch, event.target.checked);
    } else {
      setDataSourceState(dispatch, {
        ...dataSourceState,
        [event.target.name]: event.target.checked,
      });
      //setRecreationalWater(dispatch, event.target.checked);
    }
  };

  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const handleLoadingIndicatorClose = () => {
    setLoadingIndicator(false);
    setOpenPanel(false);
  };

  const [openPanel, setOpenPanel] = useState(false);

  function setOpenHandler(bool) {
    //console.log(openPanel);
    setOpenPanel(bool);
  }

  const [openComparePanel, setComparePanel] = useState(false);

  function setOpenComparePanelHandler(bool) {
    //console.log(openComparePanel);
    //console.log(bool);
    // if (!bool) {
    //   setSelectedSensorInstances_CompareView([]);
    // }
    setComparePanel(bool);
  }

  const [selectedSensorInstance, setSelectedSensorInstance] = useState(null);

  const [selectedSensorInstances_CompareView, setSelectedSensorInstances_CompareView] = useState(
    []
  );
  const sensorCompareViewRef = useRef();
  sensorCompareViewRef.current = selectedSensorInstances_CompareView;

  const addToCompareList = () => {
    if (selectedSensorInstances_CompareView.length < 2) {
      localStorage.setItem("first_data", JSON.stringify(selectedSensorInstance));
      const oldData = JSON.parse(localStorage.getItem("first_data"));
      let element = sensorCompareViewRef.current.find((obj) => obj.siteid == oldData.siteid);

      element == undefined
        ? setSelectedSensorInstances_CompareView([
            ...selectedSensorInstances_CompareView,
            JSON.parse(localStorage.getItem("first_data")),
          ])
        : showNotification("Are you sure you want to compare same sensors?", "warning");

      localStorage.removeItem("first_data");
    } else {
      showNotification("Only two sensors can be compared at once.", "error");
    }
  };

  const removeSensor_CompareView = (p_siteid) => {
    let element = sensorCompareViewRef.current.find((obj) => obj.siteid == p_siteid);
    if (element) {
      var stateClone = [...sensorCompareViewRef.current];
      let index = stateClone.indexOf(element);
      stateClone.splice(index, 1);
      setSelectedSensorInstances_CompareView(stateClone);
    }
  };

  function checkItemIsOnListHandler(siteId) {
    // debugger;
    return sensorCompareViewRef.current.some((item) => item.siteid === siteId); //returns true if one of the condition specified is matched in the function.
  }

  const [showDataReport, setShowDataReport] = useState(false);

  const [showDataExpert, setShowDataExpert] = useState(false);
  const [expertURL, setExpertURL] = useState("");

  function handleCloseActionAI() {
    setShowDataExpert(false);
  }

  const [showTakeAction, setShowTakeAction] = useState(false);

  function handCloseTakeAction() {
    setShowTakeAction(false);
  }

  const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const [showAIHub, setShowAIHub] = useState(false);

  return (
    <>
      <Backdrop style={{ backgroundColor: "rgba(0, 0, 0, 0.75)", zIndex: 99 }} open={geomLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div style={{ display: "flex", height: "100vh" }}>
        <div style={{ flex: "none", width: "285px" }}>
          <Configurator
            setShowAIHub={setShowAIHub}
            loadNewMap={loadNewMap}
            handleDataSourceChange={handleDataSourceChange}
            dataSourceState={dataSourceState}
            loadSensorData={loadSensorData}
            resetMap={resetMap}
            clearSearch={clearSearch}
            geoTypeValue={geoTypeValue}
            geoTypeUrl={geoTypeUrl}
            setGeoTypeHandler={setGeoTypeHandler}
            regionValue={regionValue}
            setRegionValueHandler={setRegionValueHandler}
            regionInputValue={regionInputValue}
            setRegionInputValue={setRegionInputValue}
            regionOptions={regionOptions}
            setRegionOptions={setRegionOptions}
            geoTypeOption={geoTypeOption}
            parsedJSONData={parsedJSONData}
            validState={validState}
            setShowDataExpert={setShowDataExpert}
            setExpertURL={setExpertURL}
            breadCrumbsList={breadCrumbsList}
            handleClickSnapBtn={handleClickSnapBtn}
            searchParams={searchParams}
          />
        </div>
        <div style={{ flex: 1 }}>
          {openPanel === true ? (
            <div className={classes.observationPanel}>
              <SensorDataObservation
                data={selectedSensorInstance}
                setLoadingIndicator={setLoadingIndicator}
                setOpenPanel={setOpenHandler}
                showNotification={showNotification}
                openPanel={openPanel}
                showCloseBtnStatus={{}}
                setShowDataExpert={setShowDataExpert}
                setExpertURL={setExpertURL}
                geoType={geoType}
                breadCrumbsList={breadCrumbsList}
                dataSourceState={dataSourceState}
                parsedJSONData={parsedJSONData}
                boundState={boundState}
              />
            </div>
          ) : null}
          {showDataReport ? (
            <div style={{ position: "absolute", zIndex: "1", left: "35%" }}>
              <DataReportBox
                countyObsData3Year={countyObsData3Year}
                zipCodeObsData3Year={zipCodeObsData3Year}
                setShowDataReport={setShowDataReport}
                geoType={geoType}
                parsedJSONData={parsedJSONData}
                summaryObject={summaryObject}
                geoValue={geoValue}
                state={parsedJSONData ? parsedJSONData.state : searchParams.get("state")}
                showNotification={showNotification}
                searchParams={searchParams}
              />
            </div>
          ) : null}
          {(!showDataExpert || showTakeAction) &&
          (showDataExpert || !showTakeAction) &&
          !openPanel &&
          !showDataReport ? (
            <LegendBox
              geoType={geoType}
              demographicObject={demographicObject}
              censusTract={state.census}
              zipCode={state.zip_code}
            />
          ) : null}
          {/* <LegendBox /> */}
          <div style={{ height: `100vh` }} ref={mapRef}>
            <AnimatePresence initial={false} mode="wait" onExitComplete={() => null}>
              {selectedSensorInstance !== null ? (
                <SensorDataInfoBox
                  checkItemIsOnListHandler={checkItemIsOnListHandler}
                  setLoadingIndicator={setLoadingIndicator}
                  data={selectedSensorInstance}
                  setSelectedSensorInstance={setSelectedSensorInstance}
                  setOpenPanel={setOpenHandler}
                  addToCompareList={addToCompareList}
                  geoType={geoType}
                  breadCrumbsList={breadCrumbsList}
                  dataSourceState={dataSourceState}
                  parsedJSONData={parsedJSONData}
                  boundState={boundState}
                  searchParams={searchParams}
                  showNotification={showNotification}
                />
              ) : null}
            </AnimatePresence>
            {validState && selectedSensorInstance === null ? (
              <SummaryBox
                geoType={geoType}
                summaryObject={summaryObject}
                breadCrumbsList={breadCrumbsList}
                handleBreadcrumbClick={handleBreadcrumbClick}
                value={value}
                setTabValue={setTabValue}
                setShowDataReport={setShowDataReport}
                demographicObject={demographicObject}
                setShowTakeAction={setShowTakeAction}
                parsedJSONData={parsedJSONData}
                handleCheckbox={handleCheckbox}
                state={state}
                setState={setState}
                dataSourceState={dataSourceState}
                dataSourceLoaded={dataSourceLoaded}
                boundState={boundState}
                showNotification={showNotification}
                searchParams={searchParams}
                mapGlobal={mapGlobal}
              />
            ) : null}

            {/* {validState && selectedSensorInstance === null && demographicObject ? (
              <DemographicReportBox demographicObject={demographicObject} />
            ) : null} */}
            {selectedSensorInstances_CompareView.length === 0 ? null : (
              <ComparisonTable
                lastSelectedSensorData={selectedSensorInstance}
                data={selectedSensorInstances_CompareView}
                removeSensor_CompareView={removeSensor_CompareView}
                showComparisonObservationWindow={setOpenComparePanelHandler}
                setLoadingIndicator={setLoadingIndicator}
              />
            )}
            {selectedSensorInstances_CompareView.length !== 0 && openComparePanel == true ? (
              <div className={classes.observationWindowContainer}>
                <Box display="flex" justifyContent="center" flexWrap="nowrap">
                  {selectedSensorInstances_CompareView.map((instance, key) => {
                    return (
                      <Box p={1} className={classes.observationWindowItem}>
                        <SensorDataObservation
                          data={instance}
                          key={key}
                          setLoadingIndicator={setLoadingIndicator}
                          setOpenPanel={setOpenComparePanelHandler}
                          showNotification={showNotification}
                          showCloseBtnStatus={
                            selectedSensorInstances_CompareView.length == key + 1 ? {} : null
                          }
                        />
                      </Box>
                    );
                  })}
                </Box>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {showDataExpert ? (
        <Card
          sx={{
            position: "absolute",
            right: "25%",
            top: "4%",
            width: "50%",
            height: "92%",
            zIndex: 5,
          }}
        >
          <MDBox sx={{ width: "100%", height: "100%" }}>
            <iframe
              src={expertURL}
              style={{ width: "100%", height: "98%", borderRadius: "10px" }}
            ></iframe>
          </MDBox>

          <MDBox sx={{ margin: "0px 5px 5px 5px" }}>
            <MDButton
              onClick={handleCloseActionAI}
              sx={{ float: "right" }}
              size="small"
              color="error"
            >
              Close
            </MDButton>
          </MDBox>
        </Card>
      ) : null}

      {showTakeAction ? (
        <Card
          sx={{
            position: "absolute",
            right: "25%",
            top: "4%",
            width: "50%",
            height: "92%",
            zIndex: 5,
          }}
        >
          <MDBox sx={{ width: "100%", height: "100%" }}>
            <iframe
              src={`/chat/app/?expert=actionexpert`}
              style={{ width: "100%", height: "98%", borderRadius: "10px" }}
            ></iframe>
          </MDBox>

          <MDBox sx={{ margin: "0px 5px 5px 5px" }}>
            <MDButton
              onClick={handCloseTakeAction}
              sx={{ float: "right" }}
              size="small"
              color="error"
            >
              Close
            </MDButton>
          </MDBox>
        </Card>
      ) : null}

      {showAIHub ? (
        <Dialog
          open={showAIHub}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            setShowAIHub(false);
          }}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Select an AI Expert"}</DialogTitle>
          <DialogContent style={{ width: "350px" }}>
            <List>
              <ListItem disableGutters>
                <ListItemButton
                  onClick={() => {
                    setShowAIHub(false);
                    setShowDataExpert(true);
                    setExpertURL("/chat/app/?expert=dataexpert");
                  }}
                >
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                      <PersonIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={"Data Expert"} />
                </ListItemButton>
              </ListItem>
              <ListItem disableGutters>
                <ListItemButton
                  onClick={() => {
                    setShowAIHub(false);
                    setShowDataExpert(true);
                    setExpertURL("/chat/app/?expert=expert");
                  }}
                >
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                      <PersonIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={"Water Quality Expert"} />
                </ListItemButton>
              </ListItem>
            </List>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setShowAIHub(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}

      <Backdrop
        style={{ backgroundColor: "rgba(0, 0, 0, 0.75)", zIndex: 99 }}
        open={loadingIndicator}
        onClick={handleLoadingIndicatorClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Backdrop
        style={{ backgroundColor: "rgba(0, 0, 0, 0.75)" }}
        open={openComparePanel || openPanel || showDataExpert || showTakeAction || showDataReport}
        onClick={handleLoadingIndicatorClose}
      ></Backdrop>

      <MDSnackbar
        color={snackbarType}
        icon={
          snackbarType == "success"
            ? "check"
            : snackbarType == "error"
            ? "warning"
            : "notifications"
        }
        title="Dashboard"
        content={snackbarErrorMessage}
        dateTime="just now"
        open={tc}
        onClose={() => setTC(false)}
        close={() => setTC(false)}
        // closeNotification={() => setTC(false)}
        bgWhite
      />

      {/* ICON Credits */}
      <span style={{ display: "none" }}>
        <a href="https://www.flaticon.com/free-icons/map" title="map icons">
          Map icons created by Freepik - Flaticon
        </a>
      </span>
    </>
  );
}

export default DashboardMap;
