const homeStyle = {
    customMarker: {
        border: '2px solid black !important',
        borderRadius: '50% !important',
    },
    container: {
        display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        height: "91%",
        backgroundColor: "#fff"
    },
    box: {
        flexGrow: "1",
        height: "95%",
        marginLeft: "13px",
        marginRight: "13px",
        boxSizing: "border-box"
    },
    labelSite: {
        fontSize: "24px",
        fontWeight: "bold",
        textAlign: "center",
        color: "white",
        position: "absolute",
        top: "25px", right: "0",
        zIndex: "1"
    },
    labelWQ: {
        position: "absolute",
        top: "0px",
        left: "10px",
        fontSize: "24px",
        fontWeight: "bold",
        textAlign: "center",
        zIndex: 1,
        color: "white"
    },
    mapBox: {
        height: "100%",
        width: "100%",
        borderRadius: "0.75rem !important",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    cardCss: {
        height: "100%"
    },
    buttonCss: {
        backgroundColor: "green",
        color: "white",
        margin: "5px"
    },
    overlappingBox: {
        position: "absolute",
        top: "50px",
        left: "170px",
        width: "80%",
        height: "65%",
        color: "white",
        zIndex: 1
    },
    overlappingFormBox: {
        position: "absolute",
        top: "20%",
        right: "35%",
        width: "25%",
        height: "65%",
        color: "white",
        zIndex: 2
    },
    icon: {
        height: "18px !important",
        width: "2em !important"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        bottom: "30px",
        height: "60px",
        width: "100%"
    },

    button: {
        transition: 'transform 0.2s',
        '&:hover': {
            // transform: 'translate(0,2px)',
        },
        fontSize: "2rem",
        fontWeight: "bold",
        cursor: "pointer",
        boxShadow: '0 4px 20px 0 rgb(0 0 0 / 60%), 0 7px 10px -5px rgb(76 175 80 / 50%)',
    },
    chipLabel: {
        height: '55px !important',
        width: "100px",
        backgroundColor: 'rgba(33, 150, 243, 1) !important',
        boxShadow: 'rgb(0 0 0 / 30%) 0 1px 4px -1px !important',
        '& .MuiChip-label': {
            display: 'block !important',
            whiteSpace: 'normal !important',
            fontSize: "34px !important",
            color: "white !important",
            fontWeight: "normal"
        },
        borderRadius: "0px !important",
        fontFamily:"PFDinTextCompProMedium !important"
    },
    chipSideLabel: {
        width: "5.5em !important",
        height: "3em !important",
        backgroundColor: 'rgba(255, 255, 255, 1) !important',
        '& .MuiChip-label': {
            display: 'block',
            whiteSpace: 'normal',
            fontSize: "18px",
            color: "black !important"
        },
        borderRadius: "0px !important",
        boxShadow: 'rgb(0 0 0 / 30%) 0 1px 4px -1px',
        fontFamily:"PFDinTextCompProMedium !important"
    }

}

export default homeStyle;