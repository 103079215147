/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import DashboardMap from "layouts/dashboardMap";
import Profile from "layouts/profile";
import API from "layouts/api";

import Cookies from "universal-cookie";

const cookies = new Cookies();

// @mui icons
import Icon from "@mui/material/Icon";

import { DOMAINROOT, PLATFORM_PATH, HOMEPAGE } from "./constants";
import Home from "layouts/home/home";
import InterviewDetailBox from "layouts/interview-page/InterviewDetailBox";
import SimpleSlide from "layouts/test/testdemo";
import ActionPage from "layouts/action-page/ActionPage";
import AboutUs from "layouts/about-us/AboutUs";

const routePrefix = PLATFORM_PATH;

const routes = [
  {
    type: "collapse",
    name: "map",
    key: "map",
    icon: <Icon fontSize="small">dashboard</Icon>,
    // route: routePrefix+"/dashboard",
    route: routePrefix + "/map",
    component: <DashboardMap />,
  },
  {
    type: "collapse",
    name: "map",
    key: "map",
    icon: <Icon fontSize="small">dashboard</Icon>,
    // route: routePrefix+"/dashboard",
    route: routePrefix + "/story/map",
    component: <DashboardMap />,
  },
  {
    type: "collapse",
    name: "Platform",
    key: "platform",
    icon: <Icon fontSize="small">dashboard</Icon>,
    // route: routePrefix+"/dashboard",
    route: routePrefix + "",
    component: <Home />,
  },
  {
    type: "collapse",
    name: "Interview",
    key: "interview",
    icon: <Icon fontSize="small">interview</Icon>,
    // route: routePrefix+"/dashboard",
    route: routePrefix + "/story",
    component: <InterviewDetailBox />,
  },
  {
    type: "collapse",
    name: "Action",
    key: "action",
    icon: <Icon fontSize="small">action</Icon>,
    // route: routePrefix+"/dashboard",
    route: routePrefix + "/action",
    component: <ActionPage />,
  },
  {
    type: "collapse",
    name: "AboutUs",
    key: "aboutus",
    icon: <Icon fontSize="small">action</Icon>,
    // route: routePrefix+"/dashboard",
    route: routePrefix + "/aboutus",
    component: <AboutUs />,
  },
  {
    type: "collapse",
    name: "test",
    key: "test",
    icon: <Icon fontSize="small">interview</Icon>,
    // route: routePrefix+"/dashboard",
    route: routePrefix + "/test",
    component: <SimpleSlide />,
  },
  {
    type: "collapse",
    name: "API",
    key: "api",
    icon: <Icon fontSize="small">content_paste</Icon>,
    route: routePrefix + "/api",
    component: <API />,
  },

  // HYDROSHARE MENU BLOCK
  {
    type: "divider",
    key: "login_divider",
  },
  {
    type: "title",
    title: "My Workspace",
    key: "login_panel",
  },
  {
    type: "collapse",
    name: "My workspace",
    key: "workspace",
    icon: <Icon fontSize="small">person</Icon>,
    route: routePrefix + "",
    route: routePrefix + "/workspace",
    component: <Profile />,
    component: null,
  },
];

export { routes, routePrefix };
