export default `
precision highp float;
precision mediump sampler2D;

varying vec2 vUv;
uniform sampler2D uTexture;
uniform float value;

void main () {
  vec4 color = texture2D(uTexture, vUv);
  color = vec4(1.0, 1.0, 1.0, 1.0); // Set the color to white
  color *= value;
  color.a = 0.0; // Set the alpha channel to zero to make the surface transparent
  gl_FragColor = color;
}
`
