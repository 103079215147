/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Images
import data_placeholder from "assets/images/data_placeholder.jpeg";

export default [
  {
    image: data_placeholder,
    name: "Composite Resource #1",
    description: "Watershed boundaries, hydrography, stream gage locations, and impervious surface area shapefiles for Swift Creek watershed, Wake County, NC, USA",
    action: {
      type: "internal",
      route: "/pages/profile/profile-overview",
      color: "info",
      label: "load",
    },
  },
  {
    image: data_placeholder,
    name: "Composite Resource #2",
    description: "Watershed boundaries, hydrography, stream gage locations, and impervious surface area shapefiles for Difficult Run watershed in Fairfax County, VA, USA",
    action: {
      type: "internal",
      route: "/pages/profile/profile-overview",
      color: "info",
      label: "load",
    },
  },
  {
    image: data_placeholder,
    name: "Composite Resource #3",
    description: "United States Aquifer Database",
    action: {
      type: "internal",
      route: "/pages/profile/profile-overview",
      color: "info",
      label: "load",
    },
  }
];
