/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-github-btn

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material//FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { spring, useVariants } from "../../config/particlesConfig";
import { motion } from "framer-motion";

// @mui icons

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Custom styles for the Configurator

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import { useRef } from "react";
// import FluidAnimation from "@usertive/react-fluid-animation";
import ReactFluidAnimation from "./dist/index";

import "./styles/configuratorStyles.css";
import { setGeomObject } from "context";
import { mainURL } from "constants";
import { useNavigate, useSearchParams } from "react-router-dom";

function Configurator({
  setShowAIHub,
  loadNewMap,
  handleDataSourceChange,
  dataSourceState,
  loadSensorData,
  resetMap,
  geoTypeValue,
  geoTypeUrl,
  setGeoTypeHandler,
  regionValue,
  setRegionValueHandler,
  geoTypeOption,
  regionInputValue,
  setRegionInputValue,
  regionOptions,
  setRegionOptions,
  parsedJSONData,
  validState,
  setShowDataExpert,
  setExpertURL,
  breadCrumbsList,
  handleClickSnapBtn,
  searchParams,
}) {
  // const { color, logo, image, logoText, selectedRegionGeometry } = props;
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const {
    sidenavColor,
    geomObject,
    waterCommunity,
    drinkingWater,
    groundWater,
    recreationalWater,
  } = controller;

  const [cursorText, setCursorText] = useState("");
  const [cursorVariant, setCursorVariant] = useState("default");

  const ref = useRef(null);
  const variants = useVariants(ref);

  // const [geoTypeValue, setGeoTypeValue] = useState("");
  // const [geoTypeUrl, setGeoTypeUrl] = useState("");
  // const setGeoTypeHandler = (event, selectedGeoType) => {
  //   setRegionValue("");
  //   setRegionOptions([]);
  //   // props.setGlobalRegionValue(null);
  //   //console.log("geotype changed");
  //   (selectedGeoType != null && Object.keys(selectedGeoType).includes('name') && Object.keys(selectedGeoType).includes('api'))
  //     ? (setGeoTypeValue(selectedGeoType.name), setGeoTypeUrl(selectedGeoType.api))
  //     : (setGeoTypeValue(""), setGeoTypeUrl(""), resetMap())
  // }

  // Set region (with state variables)
  // Dropdown 2
  // const [regionValue, setRegionValue] = useState("");
  // const setRegionValueHandler = (value) => {
  //   setRegionValue(value);
  //   //console.log(value);
  //   if (value == null) {
  //     //console.log("reset");
  //     resetMap();
  //   }
  //   else {
  //     loadNewMap(value, geoTypeUrl, geoTypeValue);
  //   }
  //   // loadGeodata(value);
  // }

  // Autocomplete variables
  // const [regionInputValue, setRegionInputValue] = useState('');
  // const [regionOptions, setRegionOptions] = useState([]);
  // const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let active = true;

    if (regionInputValue === "") {
      // setRegionOptions(regionValue ? [regionValue] : []);
      setRegionOptions([]);
      return undefined;
    }

    (async () => {
      // add parameter
      var geomParam = "all";
      switch (geoTypeValue) {
        case "Watershed":
          geomParam = "huc";
          break;
        case "City":
          geomParam = "urban";
          break;
        case "Zip Code":
          geomParam = "zip_code";
          break;
        case "Place":
          geomParam = "place";
          break;
        default:
          geomParam = geoTypeValue.toLowerCase();
          break;
      }

      let responseOK = true;
      const response_auto = await fetch(
        `${mainURL}/auto?instr=${regionInputValue}&geomtype=${geomParam}`
      ).catch((error) => {
        //console.log(error);
        responseOK = false;
      });

      // if(!responseOK){
      //   props.showNotification(`An error occured while fetching the autocomplete results.`);
      //   return;
      // }

      const results_auto = await response_auto.json();
      // console.log("res", results_auto);
      let tempData = [];
      if (results_auto[0]) {
        results_auto[0].forEach((item) => {
          if (
            item.state_fips ==
              (parsedJSONData ? parsedJSONData.state_fips : searchParams.get("value")) ||
            (item.state &&
              item.state == (parsedJSONData ? parsedJSONData.state : searchParams.get("state")))
          ) {
            tempData.push(item);
          }
        });

        if (
          active &&
          results_auto.Error === undefined &&
          results_auto.Notice === undefined &&
          results_auto[0] !== undefined &&
          results_auto[0][0] !== undefined &&
          results_auto[0][0] !== null &&
          typeof results_auto[0][0] !== "string"
        ) {
          let newOptions = [];

          // if (regionValue) {
          //   newOptions = [regionValue];
          // }
          // //console.log(results_auto);
          if (results_auto) {
            newOptions = [...newOptions, ...tempData];
          }

          setRegionOptions(newOptions);

          active = false;
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [regionInputValue]);

  // const geoTypes = [
  //   { name: 'State', api: 'https://umissis.org/umisapi/states?state_abbr=' },
  //   { name: 'County', api: 'https://umissis.org/umisapi/counties?param=name&value=' },
  //   { name: 'City', api: 'https://umissis.org/umisapi/urban?param=ua_id&value=' },
  //   { name: 'Watershed', api: 'https://umissis.org/umisapi/huc?value=' }
  // ];

  const geoTypes = [
    // { name: 'State', api: 'mainURL/geom?table=states&field=state_fips&value=' },
    { name: "County", api: `${mainURL}/geom?table=counties&field=fips&value=` },
    // { name: 'City', api: 'mainURL/geom?table=urban&field=ua_label&value=' },
    {
      name: "Zip Code",
      api: `${mainURL}/geom?table=zip_code&field=zip_code&value=`,
    },
    // { name: 'Place', api: 'mainURL/geom?table=place&field=placefips&value=' },
    // { name: 'Watershed', api: 'mainURL/geom?table=huc&value=' }
  ];

  // HELPER FUNCTIONS
  const formatWatershedAutocompleteOption = (option) => {
    if (option.geomtype.includes("huc")) return [option["search"], option["name"]].join(", ");
  };

  const formatZipCodeAutocompleteOption = (option) => {
    console.log(option);
    if (typeof option === "string") {
      return [option["search"], option["po_name"]].join(", ");
    } else {
      return "";
    }
  };

  function onClickLoadGeoData() {
    //console.log("loadgeodata");
    //console.log(regionValue);
    loadNewMap(regionValue, geoTypeUrl, geoTypeValue);
  }

  // function handleDataSourceChange() {
  //   //console.log("switch changed");
  // }
  function onChangeDataSource() {
    //console.log("switch changed");
    handleDataSourceChange(event);
  }

  useEffect(() => {
    loadSensorData(geoTypeValue, regionValue);
  }, [dataSourceState]);

  function greenEnter() {
    if (regionInputValue === "") {
      setCursorText("❌");
      setCursorVariant("red");
    } else {
      setCursorText("✅");
      setCursorVariant("green");
    }
  }

  function greenLeave() {
    setCursorText("");
    setCursorVariant("default");
  }

  const defaultConfig = {
    textureDownsample: 2,
    densityDissipation: 0.98,
    velocityDissipation: 0.99,
    pressureDissipation: 0.8,
    pressureIterations: 25,
    curl: 30,
    splatRadius: 0.005,
    colorsPool: ["#0096FF", "#0818A8"],
    backgroundColor: "#fff",
  };

  return (
    <div className="sidebar" ref={ref}>
      {/* <Particles height="100vh" width="100vw" params={particlesConfig} /> */}
      {/* <motion.div
        variants={variants}
        style={{
          position: "fixed",
          zIndex: "100",
          display: "flex",
          flexFlow: "row",
          alignContent: "center",
          justifyContent: "center",
          top: 0,
          left: 0,
          height: "10px",
          width: "10px",
          backgroundColor: "#D44343",
          borderRadius: "200px",
          pointerEvents: "none",
          color: "#fff",
          textAlign: "center",
          fontSize: "16px"
        }}
        animate={cursorVariant}
        transition={spring}
      >
        <span style={{
          flex: "auto",
          fontSize: "inherit",
          pointerEvents: "none",
          margin: "auto"
        }}>{cursorText}</span>
      </motion.div> */}

      {/* <ReactFluidAnimation
        config={defaultConfig}
        style={{ height: '100vh', position: "absolute" }}
      /> */}

      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={1}
        pb={0.5}
        px={3}
        style={{ position: "relative", zIndex: "0", cursor: "pointer" }}
        onClick={() => {
          navigate("/");
        }}
      >
        <img
          style={{ width: "200px" }}
          src={require("assets/images/logos/bluegaplogo.png")}
          alt="image"
        />
      </MDBox>

      <MDBox pt={1} pb={1} px={3}>
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography
              gutterBottom
              variant="h5"
              style={{
                color: "black",
                fontFamily: "PFDinTextCompProMedium",
                marginLeft: "10px",
                fontSize: "25px",
              }}
            >
              Explore a Community
            </Typography>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox pb={1.5} px={3}>
        <MDBox>
          <Autocomplete
            disablePortal
            id="geo-selector"
            size="small"
            options={geoTypes}
            clearOnEscape
            getOptionLabel={(option) => option.name}
            // sx={{ width: 300 }}
            renderInput={({ inputProps, ...params }) => (
              <TextField
                {...params}
                label="Select Scope"
                inputProps={{ ...inputProps, readOnly: true }}
              />
            )}
            onChange={setGeoTypeHandler}
            value={geoTypeOption}
          />
        </MDBox>
        {geoTypeValue === "State" ? (
          <MDBox pt={1.5}>
            <Autocomplete
              id="state-selector"
              size="small"
              options={regionOptions}
              renderInput={(params) => (
                <TextField {...params} label="Select State" variant="outlined" />
              )}
              onChange={(event, newValue) => {
                // setRegionOptions(newValue ? [newValue, ...regionOptions] : regionOptions);
                setRegionValueHandler(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                setRegionInputValue(newInputValue);
              }}
              getOptionLabel={(option) => (typeof option === "string" ? option : option.state_name)}
              autoComplete
            />
          </MDBox>
        ) : null}
        {geoTypeValue === "County" ? (
          <MDBox pt={1.5}>
            <Autocomplete
              id="county-selector"
              size="small"
              freeSolo
              value={regionValue}
              options={regionOptions}
              style={{}}
              renderInput={(params) => (
                <TextField {...params} label="Select County" variant="outlined" />
              )}
              onChange={(event, newValue) => {
                // setRegionOptions(newValue ? [newValue, ...regionOptions] : regionOptions);
                setRegionValueHandler(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                setRegionInputValue(newInputValue);
              }}
              getOptionLabel={(option) => (typeof option === "string" ? option : option.name)}
              autoComplete
            />
          </MDBox>
        ) : null}

        {geoTypeValue === "Zip Code" ? (
          <MDBox pt={1.5}>
            <Autocomplete
              id="city-selector"
              size="small"
              freeSolo
              value={regionValue}
              options={regionOptions}
              style={{}}
              renderInput={(params) => (
                <TextField {...params} label="Select Zip Code" variant="outlined" />
              )}
              onChange={(event, newValue) => {
                // setRegionOptions(newValue ? [newValue, ...regionOptions] : regionOptions);
                setRegionValueHandler(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                setRegionInputValue(newInputValue);
              }}
              getOptionLabel={(option) =>
                typeof option === "string"
                  ? option
                  : [option["search"], option["po_name"]].join(", ")
              }
              autoComplete
            />
          </MDBox>
        ) : null}

        {geoTypeValue === "City" ? (
          <MDBox pt={1.5}>
            <Autocomplete
              id="city-selector"
              size="small"
              freeSolo
              options={regionOptions}
              value={regionInputValue}
              style={{}}
              renderInput={(params) => (
                <TextField {...params} label="Select City" variant="outlined" />
              )}
              onChange={(event, newValue) => {
                // setRegionOptions(newValue ? [newValue, ...regionOptions] : regionOptions);
                setRegionValueHandler(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                setRegionInputValue(newInputValue);
              }}
              getOptionLabel={(option) => (typeof option === "string" ? option : option.search)}
              autoComplete
            />
          </MDBox>
        ) : null}

        {geoTypeValue === "Place" ? (
          <MDBox pt={1.5}>
            <Autocomplete
              id="place-selector"
              size="small"
              freeSolo
              options={regionOptions}
              style={{}}
              renderInput={(params) => (
                <TextField {...params} label="Select Place" variant="outlined" />
              )}
              onChange={(event, newValue) => {
                // setRegionOptions(newValue ? [newValue, ...regionOptions] : regionOptions);
                setRegionValueHandler(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                setRegionInputValue(newInputValue);
              }}
              getOptionLabel={(option) => (typeof option === "string" ? option : option.search)}
              autoComplete
            />
          </MDBox>
        ) : null}

        {geoTypeValue === "Watershed" ? (
          <MDBox pt={1.5}>
            <Autocomplete
              id="watershed-selector"
              size="small"
              freeSolo
              options={regionOptions}
              style={{}}
              renderInput={(params) => (
                <TextField {...params} label="Enter HUC Code" variant="outlined" />
              )}
              onChange={(event, newValue) => {
                // setRegionOptions(newValue ? [newValue, ...regionOptions] : regionOptions);
                setRegionValueHandler(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                setRegionInputValue(newInputValue);
              }}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : formatWatershedAutocompleteOption(option)
              }
              autoComplete
            />
          </MDBox>
        ) : null}

        {/* {<MDBox pt={1.5}>
          <MDButton
            disabled={geoTypeValue === ""}
            // target="_blank"
            rel="noreferrer"
            variant="gradient"
            color={sidenavColor}
            fullWidth
            onClick={onClickLoadGeoData}
            onMouseEnter={greenEnter}
            onMouseLeave={greenLeave}
          >
            Select Region
          </MDButton>
        </MDBox>} */}

        {regionValue != "" ? (
          <MDBox mt={2} lineHeight={1}>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography
                  gutterBottom
                  variant="h5"
                  style={{
                    color: "black",
                    fontSize: "1.4rem",
                    fontFamily: "PFDinTextCompProMedium",
                  }}
                >
                  Data Sources
                </Typography>
              </Grid>
            </Grid>
            <FormControl component="fieldset" disabled={!validState}>
              <FormGroup>
                {/* {dataSourceState.drinkingWater ? <MDBox ml={3}>
                  <FormControlLabel
                    control={<Switch checked={dataSourceState.sdwa} onChange={onChangeDataSource} name="sdwa" />}
                    label="SDWA"
                  />
                </MDBox> : null} */}

                {(parsedJSONData
                  ? parsedJSONData.state == "IA"
                  : searchParams.get("state") == "IA") ||
                (parsedJSONData
                  ? parsedJSONData.state == "FL"
                  : searchParams.get("state") == "FL") ? (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={dataSourceState.waterCommunity}
                        onChange={onChangeDataSource}
                        name="waterCommunity"
                      />

                      // <Switch
                      //   checked={waterCommunity}
                      //   onChange={onChangeDataSource}
                      //   name="waterCommunity"
                      // />
                    }
                    label={
                      <p
                        style={{
                          fontFamily: "PFDinTextCompProMedium",
                          fontSize: "1.1rem",
                          fontWeight: "normal",
                          color: "black",
                        }}
                      >
                        Recreational Water (stream)
                      </p>
                    }
                  />
                ) : null}

                {(parsedJSONData
                  ? parsedJSONData.state == "VI"
                  : searchParams.get("state") == "VI") ||
                (parsedJSONData
                  ? parsedJSONData.state == "FL"
                  : searchParams.get("state") == "FL") ? (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={dataSourceState.recWatersOcean}
                        onChange={onChangeDataSource}
                        name="recWatersOcean"
                      />

                      // <Switch
                      //   checked={groundWater}
                      //   onChange={onChangeDataSource}
                      //   name="groundWater"
                      // />
                    }
                    label={
                      <p
                        style={{
                          fontFamily: "PFDinTextCompProMedium",
                          fontSize: "1.1rem",
                          fontWeight: "normal",
                          color: "black",
                        }}
                      >
                        Recreational Water (ocean)
                      </p>
                    }
                    style={{ marginRight: "0px" }}
                  />
                ) : null}

                {(parsedJSONData
                  ? parsedJSONData.state == "IA"
                  : searchParams.get("state") == "IA") ||
                (parsedJSONData
                  ? parsedJSONData.state == "FL"
                  : searchParams.get("state") == "FL") ? (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={dataSourceState.sdwa}
                        onChange={onChangeDataSource}
                        name="sdwa"
                      />
                      // <Switch
                      //   checked={drinkingWater}
                      //   onChange={onChangeDataSource}
                      //   name="drinkingWater"
                      // />
                    }
                    label={
                      <p
                        style={{
                          fontFamily: "PFDinTextCompProMedium",
                          fontSize: "1.1rem",
                          fontWeight: "normal",
                          color: "black",
                        }}
                      >
                        Drinking Water (public)
                      </p>
                    }
                    style={{ marginRight: "0px" }}
                  />
                ) : null}

                {(parsedJSONData
                  ? parsedJSONData.state == "IA"
                  : searchParams.get("state") == "IA") ||
                (parsedJSONData
                  ? parsedJSONData.state == "FL"
                  : searchParams.get("state") == "FL") ? (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={dataSourceState.pwts}
                        onChange={onChangeDataSource}
                        name="pwts"
                      />

                      // <Switch
                      //   checked={groundWater}
                      //   onChange={onChangeDataSource}
                      //   name="groundWater"
                      // />
                    }
                    label={
                      <p
                        style={{
                          fontFamily: "PFDinTextCompProMedium",
                          fontSize: "1.1rem",
                          fontWeight: "normal",
                          color: "black",
                        }}
                      >
                        Drinking Water (private)
                      </p>
                    }
                    style={{ marginRight: "0px" }}
                  />
                ) : null}

                {/* {dataSourceState.waterCommunity ? <MDBox ml={3}>
                  <FormControlLabel
                    control={<Switch checked={dataSourceState.nwisdv} onChange={onChangeDataSource} name="nwisdv" />}
                    label="NWIS"
                  />
                  <FormControlLabel
                    control={<Switch checked={dataSourceState.storet} onChange={onChangeDataSource} name="storet" />}
                    label="STORET"
                  />
                  <FormControlLabel
                    control={<Switch checked={dataSourceState.iwqis} onChange={onChangeDataSource} name="iwqis" />}
                    label="IWQIS"
                  />
                  <FormControlLabel
                    control={<Switch checked={dataSourceState.iasnapst} onChange={onChangeDataSource} name="iasnapst" />}
                    label="IASNAPST"
                  />
                  <FormControlLabel
                    control={<Switch checked={dataSourceState.iowa21} onChange={onChangeDataSource} name="iowa21" />}
                    label="21IOWA"
                  />
                  <FormControlLabel
                    control={<Switch checked={dataSourceState.iowagw} onChange={onChangeDataSource} name="iowagw" />}
                    label="IOWAGW"
                  />
                  <FormControlLabel
                    control={<Switch checked={dataSourceState.nondnr} onChange={onChangeDataSource} name="nondnr" />}
                    label="NONDNR"
                  />
                </MDBox> :
                  null
                } */}
                {/* <FormControlLabel
                control={<Switch checked={dataSourceState.groundWater} onChange={onChangeDataSource} name="groundWater" />}
                label="Ground Water"
              />
              {dataSourceState.groundWater ? <MDBox ml={3}>
                <FormControlLabel
                  control={<Switch checked={dataSourceState.pwts} onChange={onChangeDataSource} name="pwts" />}
                  label="PWTS"
                />
              </MDBox> : null} */}

                {/* <FormControlLabel
                control={<Switch checked={props.dataSourceState.nwisiv} onChange={props.handleDataSourceChange} name="nwisiv" disabled/>}
                label="NWIS - Instant Value"
                className={classes.dataSwitchLabel}
              /> */}
              </FormGroup>
            </FormControl>
          </MDBox>
        ) : null}
        {/* <MDButton
          style={{
            backgroundColor: `#d2dde4`,
            color: "#000",
            boxShadow: "0 7px 0 #b2bdc4",
            position: "absolute",
            bottom: "80px",
            left: "25px",
            width: "200px",
          }}
          onClick={() => {
            setShowDataExpert(true);
            setExpertURL(
              "/chat/app/?expert=dataexpert"
            );
          }}
        >
          Data Expert
        </MDButton>

        <MDButton
          style={{
            backgroundColor: `#d2dde4`,
            color: "#000",
            boxShadow: "0 7px 0 #b2bdc4",
            position: "absolute",
            bottom: "20px",
            left: "25px",
            width: "200px",
          }}
          variant="contained"
          fullWidth
          onClick={() => {
            setShowDataExpert(true);
            setExpertURL("/chat/app/?expert=expert");
          }}
        >
          Water Quality Expert
        </MDButton> */}
      </MDBox>
      <div
        style={{
          position: "fixed",
          bottom: "20px",
          left: "40px",
          // textAlign: "center",
          // marginTop: "auto",
          // marginBottom: "0.8em",
        }}
      >
        {/* <button
            className="bluegap-ai-btn"
            onClick={() => {
              setShowAI(true);
            }}
          >
            AI HUB
          </button> */}
        <img
          style={{ height: "70px", cursor: "pointer" }}
          src={require("assets/images/chatbot.png")}
          onClick={() => {
            setShowAIHub(true);
          }}
        />
      </div>

      <div
        style={{
          position: "fixed",
          bottom: "130px",
          left: "40px",
          // textAlign: "center",
          // marginTop: "auto",
          // marginBottom: "0.8em",
        }}
      >
        <button
          className="feedback-button"
          onClick={() => {
            window.open(
              "https://docs.google.com/forms/d/e/1FAIpQLSeBSL4BpDkZhxXDkftWvMl5N4TtRSKvPgCbrcpT6PGgbyWnVA/viewform",
              "_blank"
            );
          }}
        >
          Feedback
        </button>
      </div>

      {breadCrumbsList.length > 0 ? (
        <div
          style={{
            position: "fixed",
            bottom: "22px",
            left: "140px",
            // textAlign: "center",
            // marginTop: "auto",
            // marginBottom: "0.8em",
          }}
        >
          <MDButton
            style={{
              margin: "10px 10px 15px 10px",
              padding: "0% 4% 0% 4%",
              backgroundColor: `#d2dde4`,
              color: "#000",
              boxShadow: "0 7px 0 #b2bdc4",
            }}
            variant="contained"
            onClick={handleClickSnapBtn}
          >
            Snap
          </MDButton>
        </div>
      ) : null}
    </div>
  );
}

export default Configurator;
