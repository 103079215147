const SensorDataObservationStyle = {
  chartCss: {
    height: "200px",
    // border: '1px solid grey'
  },
  cardCss: {
    position: "absolute",
    top: 20,
    right: 330,
    width: 550,
  },
  cardTitle: {
    color: "gray",
    marginTop: "50px",
    marginBottom: "10px",
    minHeight: "auto",
    fontWeight: "400",
    fontSize: "1.325em",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    // marginBottom: "1em",
    textDecoration: "none",
    textAlign: "center",
    "& small": {
      color: "gray",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  stats: {
    color: "gray",
    // display: "inline-flex",
    fontSize: "16px",
    lineHeight: "22px",
    paddingTop: "0.40625rem",
    paddingBottom: "0.40625rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "50%",
    flexGrow: "1",
    fontFamily: "PFDinTextCompProMedium",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
  },
  statsBtnContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  tableBodyRow: {
    height: "34px",
    color: "inherit",
    display: "table-row",
    outline: "none",
  },
  tableSummaryCell: {
    padding: "0px 0px 5px 5px !important", // used to be 0px 8px
    verticalAlign: "middle",
    borderBottom: "none !important",
    fontWeight: "400",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  dataTitle: {
    color: "inherit",
    fontWeight: "400",
    fontSize: "0.9rem",
  },
};

export default SensorDataObservationStyle;
