/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import Cookies from 'universal-cookie';
const cookies = new Cookies();

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// Material Dashboard 2 React components

// Material Dashboard 2 React example components
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
import { routes, routePrefix } from "routes";
import { useSearchParams } from "react-router-dom";

// Material Dashboard 2 React contexts
import {
  useMaterialUIController, setMiniSidenav, setDataSourceState, 
  setSensorDataBox
} from "context";

import './App.css';

export default function App() {
  // Hydroshare login
  const [searchParams, setSearchParams] = useSearchParams();
  const [hsCode, setHSCode] = useState(searchParams.get("code"));


  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    // transparentSidenav,
    // whiteSidenav,
    darkMode,
    dataSourceState,
    dataSourceLoaded
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // useEffect(() => {
  //   if(cookies.get('hsCode') === null || cookies.get('hsCode') === undefined){
  //     if (hsCode !== null || hsCode !== ""){
  //       setHSCode(null);
  //     }
  //   }
  // });

  // // Work only when hydroshare code is changed
  // useEffect(() => {
  //   // //console.log(process.env.REACT_APP_HS_CLIENT_SECRET);
  //   // //console.log(hsCode);
  //   if(hsCode === null || hsCode === "" || cookies.get('hs_access_token') !== undefined){
  //     return;
  //   }

  //   cookies.set('hsCode', hsCode, { path: '/' });
  //   // //console.log(cookies.get('hs_access_token'));

  //   fetch("https://www.hydroshare.org/o/token/", {method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  //     },
  //     body: new URLSearchParams({
  //       'grant_type': 'authorization_code',
  //       'code': hsCode,
  //       'client_id': process.env.REACT_APP_HS_CLIENT_ID,
  //       'client_secret': process.env.REACT_APP_HS_CLIENT_SECRET,
  //       'redirect_uri': HOMEPAGE,
  //     }),
  //   }).then(res => res.json())
  //   .then(
  //     async (result) => {
  //       //console.log(result);
  //       cookies.set('hs_access_token', result.access_token, { path: '/' });
  //       cookies.set('hs_refresh_token', result.refresh_token, { path: '/' });
  //       getAndSaveUserInfo();
  //     },
  //     (error) => {
  //       //console.log(error);
  //     }
  //   )

  // }, [hsCode]);

  // // Retrieves and saves the logged-in user's info
  // const getAndSaveUserInfo = () => {
  //   //console.log("getting user info");
  //   fetch("https://www.hydroshare.org/hsapi/userInfo/", {method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
  //       'Authorization': 'Bearer '+cookies.get('hs_access_token')
  //     }
  //   }).then(res => res.json())
  //   .then(
  //     async (result) => {
  //       //console.log(result);
  //       cookies.set('hs_user_info', result, { path: '/' });
  //       window.location.reload();
  //     },
  //     (error) => {
  //       //console.log(error);
  //     }
  //   )

  // }

  // // Change the openConfigurator state
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const handleLoadingIndicatorClose = () => {
    setLoadingIndicator(false);
  };

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            {/* <Sidenav
              color={sidenavColor}
              // brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="BlueGap"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
              loadNewMap={loadNewMap}
              handleDataSourceChange={handleDataSourceChange}
              dataSourceState={dataSourceState}
              loadSensorData={loadSensorData}
            /> */}
            {/*<Configurator />*/}
            {/*{configsButton}*/}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to={routePrefix + ""} />} />
        </Routes>
        <Backdrop open={loadingIndicator} onClick={handleLoadingIndicatorClose}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          {/* <Sidenav
            color={sidenavColor}
            // brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="BlueGap"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            loadNewMap={loadNewMap}
            handleDataSourceChange={handleDataSourceChange}
            dataSourceState={dataSourceState}
            loadSensorData={loadSensorData}
          /> */}
          {/*<Configurator />*/}
          {/*{configsButton}*/}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to={routePrefix + ""} />} />
      </Routes>
      <Backdrop open={loadingIndicator} onClick={handleLoadingIndicatorClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </ThemeProvider>
  );
}
