import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function TeamCard({ image, name, title }) {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "visible",
      }}
    >
      <MDBox position="relative" width="100%" height="100%" shadow="xl" borderRadius="xl">
        <CardMedia
          src={image}
          component="img"
          title={title}
          sx={{
            width: "100%",
            height: "100%",
            margin: 0,
            boxShadow: ({ boxShadows: { md } }) => md,
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      </MDBox>
      <MDBox mt={3} mx={0.5}>
        <MDBox>
          <MDTypography variant="h5" color="black" textTransform="capitalize">
            {name}
          </MDTypography>
        </MDBox>
        <MDTypography variant="button" fontWeight="regular" color="black">
          {title}
        </MDTypography>
        {/* <MDBox mb={3} lineHeight={0}>
          <MDTypography variant="button" fontWeight="light" color="black">
            {description}
          </MDTypography>
        </MDBox> */}
      </MDBox>
    </Card>
  );
}

export default TeamCard;
