/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Cookies from 'universal-cookie';
const cookies = new Cookies();
import { useEffect, useState } from "react";
import {useNavigate} from 'react-router-dom';

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";
import ProfileInfoCard from "layouts/profile/components/ProfileInfoCard";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

import profile_placeholder from "assets/images/profile_placeholder.jpeg";
import hs_resource_1 from "assets/images/hs-resource-1.png";
import hs_resource_2 from "assets/images/hs-resource-2.png";
import hs_resource_3 from "assets/images/hs-resource-3.png";
import hs_resource_4 from "assets/images/hs-resource-4.png";

import {DOMAINROOT, PLATFORM_PATH, HOMEPAGE} from "../../constants";

function Overview() {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(cookies.get("hs_user_info"));

  const hsLogout = () => {
    cookies.remove('hs_access_token', { path: '/' });
    cookies.remove('hs_refresh_token', { path: '/' });
    cookies.remove('hs_user_info', { path: '/' });
    //console.log("logging out");
    navigate(PLATFORM_PATH);
    window.location.reload();
    return;
  }
  
  useEffect(() => {
    //console.log("checking userInfo...");
    if(userInfo === undefined){
      navigate("/");
      return;
    }

  },[]);


  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      {/* <MDBox mb={2} /> */}
      {userInfo !== undefined ? (
      <Header hsLogout={hsLogout} userInfo={userInfo}>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={4}>
              <PlatformSettings />
            </Grid>
            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="profile information"
                description="Profile information retrieved from HydroShare can be seen below."
                info={{
                  userName: userInfo.username,
                  email: userInfo.email,
                  location: userInfo.state + ", " + userInfo.country,
                  userType: userInfo.user_type,
                }}
                social={[
                  {
                    link: "https://www.facebook.com/CreativeTim/",
                    icon: <FacebookIcon />,
                    color: "facebook",
                  },
                  {
                    link: "https://twitter.com/creativetim",
                    icon: <TwitterIcon />,
                    color: "twitter",
                  },
                  {
                    link: "https://www.instagram.com/creativetimofficial/",
                    icon: <InstagramIcon />,
                    color: "instagram",
                  },
                ]}
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
            <Grid item xs={12} xl={4}>
              <ProfilesList title="My Resources" profiles={profilesListData} shadow={false} />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="medium">
            {"BlueGap"} Resources
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="button" color="text">
              Publicly Available {"BlueGap"}-tagged resources
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox p={2}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={hs_resource_1}
                label="#data"
                title="Composite Resource #1"
                description="Watershed delineation for Herrington sampling location"
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: profile_placeholder, name: "Barbara Bellows" }
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={hs_resource_2}
                label="#data"
                title="Composite Resource #2"
                description="United States Aquifer Database"
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: profile_placeholder, name: "Merhawi GebreEgziabher" },
                  { image: profile_placeholder, name: "Scott Jasechko" },
                  { image: profile_placeholder, name: "Debra Perrone" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={hs_resource_3}
                label="#data"
                title="Composite Resource #3"
                description="Watershed boundaries, hydrography, stream gage locations, and impervious surface area shapefiles for Difficult Run watershed in Fairfax County, VA, USA"
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: profile_placeholder, name: "John J. Lagrosa" },
                  { image: profile_placeholder, name: "Claire Welty" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={hs_resource_4}
                label="#data"
                title="Composite Resource #4"
                description="Watershed boundaries, hydrography, stream gage locations, and impervious surface area shapefiles for Swift Creek watershed, Wake County, NC, USA"
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: profile_placeholder, name: "John J. Lagrosa" },
                  { image: profile_placeholder, name: "Claire Welty" },
                ]}
              />
            </Grid>
          </Grid>
        </MDBox>
      </Header>
      ) : null}
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Overview;
