import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { Chip } from "@mui/material";
import MDButton from "components/MDButton";
import { makeStyles } from "@mui/styles";
import interviewDetailBoxStyle from "./styles/interviewDetailBoxStyle";
import { forwardRef, useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import MDBox from "components/MDBox";
import { useLocation, useNavigate } from "react-router-dom";

// @mui icons

// Material Dashboard 2 React example components

// Overview page components

// Data
import MDTypography from "components/MDTypography";
import "../home/styles/homeStyle";
import YouTubePlaylist from "@codesweetly/react-youtube-playlist";
// import iowaGeoJSON from "../../assets/iowa_huc8_v2.json";
// import tampaGeoJSON from "../../assets/tampa_bay_huc8.json";
// import usviGeoJSON from "../../assets/uvsi_huc12_v2.json";

const useStyles = makeStyles(interviewDetailBoxStyle);
import "./styles/customStyles.css";
import ChampionCard from "layouts/home/components/ChampionCard";
import Backdrop from "@mui/material/Backdrop";
import { mainURL } from "constants";
import { HOMEPAGE } from "constants";
import MDSnackbar from "components/MDSnackbar";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import NavigationIcon from "@mui/icons-material/Navigation";
import Slide from "@mui/material/Slide";
import PersonIcon from "@mui/icons-material/Person";
import { blue } from "@mui/material/colors";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import pdf from "assets/pdf/homepdf.pdf";
import conniepdf from "assets/pdf/connie.pdf";
import dingdarlingpdf from "assets/pdf/dingdarling.pdf";
import craigpdf from "assets/pdf/craig.pdf";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function InterviewDetailBox() {
  const [youtubePlaylist, setYouTubePlaylist] = useState(null);
  const [championsList, setChampionsList] = useState([]);
  const [membersList, setMembersList] = useState([]);
  const [artDesignList, setArtDesignList] = useState([]);
  const [placesList, setPlacesList] = useState([]);
  const [showStoryMapDialog, setShowStoryMapDialog] = useState(false);
  const [storyMapURL, setStoryMapURL] = useState("");
  const [tc, setTC] = useState(false);
  const [snackbarErrorMessage, setSnackbarErrorMessage] = useState("Something went wrong.");
  const [snackbarType, setSnackbarType] = useState("success");
  const showNotification = (message, type = "") => {
    if (!tc) {
      setSnackbarErrorMessage(message);
      setSnackbarType(type);
      setTC(true);
      setTimeout(function () {
        setTC(false);
      }, 4000);
    }
  };

  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const parsedJSONData = JSON.parse(location.state);
  //console.log("localtion state", parsedJSONData);
  const lat = parsedJSONData.latitude;
  const lng = parsedJSONData.longitude;
  const clickedSensorData = parsedJSONData.clickedSensorData;
  const currentZoom = parsedJSONData.currentZoom;
  const allDataList = parsedJSONData.allDataList;
  const allCommunityMembersList = parsedJSONData.allCommunityMembersList;
  const allPlacesList = parsedJSONData.allPlacesList;

  const [currentData, setCurrentData] = useState(clickedSensorData);

  const [mapGlobal, setMapGlobal] = useState(null);
  const mapRef = useRef(null);

  function buildContent(property) {
    const content = document.createElement("div");

    content.classList.add("property");
    content.innerHTML = `
      <div class="customMarker ${property.type}">
      <img class="image" src=${property.img} alt="profile-image" />
      </div>
      <div class="details">
          <div class="name">${property.name}</div>
          <div class="site">${property.expertise}</div>
          <div class="expertise">${property.site}</div>
          <div class="features">
          <div class="action">
              <span>Read Story</span>
          </div>
          </div>
      </div>
      `;
    return content;
  }

  function highlight(markerView, property) {
    markerView.content.classList.add("highlight");
    markerView.element.style.zIndex = 1;
  }

  function unhighlight(markerView, property) {
    markerView.content.classList.remove("highlight");
    markerView.element.style.zIndex = "";
  }

  const intersectionObserver = new IntersectionObserver((entries) => {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        entry.target.classList.add("drop");
        intersectionObserver.unobserve(entry.target);
      }
    }
  });

  function fetchYouTubePlaylistData(playlistId, callback) {
    if (playlistId === null) {
      callback(null);
      return;
    }

    // console.log(`https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=${playlistId}&key=AIzaSyBgvH0dBGFTYNKfBXMRcQ9ms9RKPqF-dqc`);
    fetch(
      `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=${playlistId}&key=AIzaSyBgvH0dBGFTYNKfBXMRcQ9ms9RKPqF-dqc`
    )
      .then((res) => res.json())
      .then((data) => {
        callback(data);
      });
  }

  useEffect(() => {
    //console.log("map");

    fetch("iowa_huc8_v2.json")
      .then((response1) => {
        response1.json().then((iowaGeoJson) => {
          fetch("tampa_bay_huc8.json").then((response2) => {
            response2.json().then((tampaGeoJson) => {
              fetch("uvsi_huc12_v2.json").then((response3) => {
                response3.json().then((usviGeoJson) => {
                  // Corrected from response2 to response3
                  document.title = "BlueGAP Story Platform";
                  let google = window.google;
                  let map = mapRef.current;
                  const latLng = new window.google.maps.LatLng(lat, lng);
                  const mapOptions = {
                    tilt: 0,
                    zoom: currentZoom,
                    center: latLng,
                    scrollwheel: true,
                    zoomControl: true,
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                    mapId: "cff1476c907ac09b",
                  };

                  map = new google.maps.Map(map, mapOptions);
                  // console.log(allDataList);
                  let tempChampions = [];
                  let tempMembers = [];
                  let tempPlaces = [];
                  let tempArtDesign = [];

                  for (const property of allDataList) {
                    if (property.type == "blue") {
                      tempChampions.push(property);
                    } else if (property.type == "orange") {
                      tempMembers.push(property);
                    } else if (property.type == "green") {
                      tempArtDesign.push(property);
                    }
                    // else {
                    //   tempPlaces.push(property);
                    // }
                    const advancedMarkerView = new google.maps.marker.AdvancedMarkerView({
                      map,
                      content: buildContent(property),
                      position: property.position,
                      title: property.site,
                    });
                    const element = advancedMarkerView.element;

                    const content = advancedMarkerView.content;

                    content.style.opacity = "0";
                    content.addEventListener("animationend", () => {
                      content.classList.remove("drop");
                      content.style.opacity = "1";
                    });

                    const time = 0.5 + Math.random(); // 2s delay for easy to see the animation

                    content.style.setProperty("--delay-time", time + "s");
                    intersectionObserver.observe(content);

                    ["focus", "pointerenter"].forEach((event) => {
                      element.addEventListener(event, () => {
                        highlight(advancedMarkerView, property);
                      });
                    });
                    ["blur", "pointerleave"].forEach((event) => {
                      element.addEventListener(event, () => {
                        unhighlight(advancedMarkerView, property);
                      });
                    });
                    advancedMarkerView.addListener("click", () => {
                      //console.log(property);
                      const zoomLevel = getCurrentZoom(property.data.state);
                      unhighlight(advancedMarkerView, property);
                      //console.log("here", property);

                      map.setCenter(property.position);

                      fetchYouTubePlaylistData(property.data.embedId, (data) => {
                        setAutoplay(0);
                        //console.log(property.data);
                        //console.log("youtube", data.items);
                        setYouTubePlaylist(data);
                        //console.log(map.getZoom());
                        if (map.getZoom() <= 7) {
                          setCurrentData(property);
                        } else {
                          setCurrentData(property);
                          //handleShowStoryBox();
                        }
                        map.setZoom(zoomLevel);
                      });
                    });
                  }

                  setChampionsList(tempChampions);
                  setMembersList(tempMembers);
                  setArtDesignList(tempArtDesign);
                  setPlacesList(tempPlaces);

                  if (clickedSensorData) {
                    fetchYouTubePlaylistData(clickedSensorData.data.embedId, (data) => {
                      setAutoplay(0);
                      // console.log("youtube", data.items);
                      setYouTubePlaylist(data);
                    });
                  }

                  if (parsedJSONData.location == "IOWA") {
                    map.data.addGeoJson(iowaGeoJson);
                  } else if (parsedJSONData.location == "TAMPA") {
                    map.data.addGeoJson(tampaGeoJson);
                  } else {
                    map.data.addGeoJson(usviGeoJson);
                  }
                  map.data.setStyle(function (feature) {
                    return {
                      fillColor: "#D3D3D3", // Example: Red fill color
                      strokeWeight: 2, // Example: Border weight of 2 pixels
                      strokeColor: "#000", // Example: White border color
                      strokeOpacity: 0.7, // Example: Opacity of 0.7
                    };
                  });

                  setMapGlobal(map);
                });
              });
            });
          });
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [mapRef]);

  function getCurrentZoom(state) {
    let zoomLevel = 11;
    switch (state) {
      case "FL":
        zoomLevel = 13;
        break;
      case null:
        zoomLevel = 15;
        break;
      default:
        break;
    }

    return zoomLevel;
  }

  function revertZoom() {
    if (currentData) {
      let zoomLevel = 7;

      switch (currentData.data.state) {
        case "FL":
          zoomLevel = 9;
          break;
        case null:
          zoomLevel = 11;
          break;
        default:
          break;
      }
      mapGlobal?.setZoom(zoomLevel);
      setCurrentData(null);
    }
  }

  const [autoplay, setAutoplay] = useState(0);

  const [showStoryBox, setShowStoryBox] = useState(false);

  function handleCloseStoryBox() {
    setShowStoryBox(false);
  }

  function handleShowStoryBox() {
    if (currentData.type == "green") {
      // console.log("cire", currentData);
      if (currentData.data.image) {
        setShowStoryBox(true);
      } else {
        if (currentData.videoId) {
          setChampionVideoLink(currentData.videoId);
          setShowChampionVideo(true);
        } else {
          showNotification("Story video coming soon!", "info");
        }
      }
    } else {
      if (currentData.data.longSummary1) {
        setShowStoryBox(true);
      } else {
        showNotification("Story Details coming soon!", "info");
      }
    }
  }

  const [showChampionVideo, setShowChampionVideo] = useState(false);

  function handleCloseChampionVideo() {
    setShowChampionVideo(false);
  }

  function handleShowChampionVideo() {
    setShowChampionVideo(true);
  }

  const descriptionElementRef = useRef(null);

  function handleExploreDataBtnClick() {
    let lng = 0;
    let lat = 0;
    let zoomValue = 12;
    let state = "VI";
    let boundaryURL = `${mainURL}/allgeom?table=zip_code&x1=-65.08316619836198&y1=17.679370591195905&x2=-64.57707574535745&y2=18.38465859717597`;
    switch (currentData.data.state) {
      case "IA":
        lat = 42.0267;
        lng = -93.6465;
        zoomValue = 7;
        state = "IA";
        boundaryURL = `${mainURL}/allgeom?table=county&x1=-96.63306039630396&y1=40.37830479583795&x2=-90.14002756307562&y2=43.50012771146711`;
        break;
      case "FL":
        lat = 27.9506;
        lng = -82.4572;
        zoomValue = 8;
        state = "FL";
        boundaryURL = `${mainURL}/allgeom?table=county&x1=-87.63470035600356&y1=24.51490854927549&x2=-80.03257567895679&y2=31.000809213282125`;
        break;
      default:
        lat = 17.7373691;
        lng = -64.771487;
        break;
    }

    navigate(`map`, {
      state: JSON.stringify({
        longitude: lng,
        latitude: lat,
        zoomValue: zoomValue,
        boundaryURL: boundaryURL,
        state: state,
      }),
    });
  }

  function handleVirtualSethBtnClick() {
    // window.open(
    //   "/chat/app/?expert=championsiowa",
    //   "_blank",
    //   "noreferrer"
    // );
    currentData.site.includes("IA")
      ? setVirtualChampURL("/chat/app/?expert=championsiowa")
      : setVirtualChampURL("/chat/app/?expert=championstampa");
    setShowAI(false);
    setShowVirtualSeth(true);
  }

  const [showTakeActionDialog, setShowTakeActionDialog] = useState(false);
  const [currentVideoDetails, setCurrentVideoDetails] = useState(null);

  function handleTakeActionBtnClick() {
    //console.log(currentData.video.filter((item) => item.videoID == championVideoLink));
    let filteredVideoDetail = currentData.video.filter((item) => item.videoID == championVideoLink);
    if (filteredVideoDetail.length == 0) {
      showNotification("Information Coming Soon!", "info");
    } else {
      setCurrentVideoDetails(filteredVideoDetail[0]);
      setShowTakeActionDialog(true);
    }
  }

  function handleActionAIBtnClick() {
    setShowAI(false);
    setshowActionAI(true);
  }

  const [championVideoLink, setChampionVideoLink] = useState("Cu26XrlpDXM");
  const [virtualChampURL, setVirtualChampURL] = useState("");

  const [showVirtualSeth, setShowVirtualSeth] = useState(false);

  function handleCloseVirtualSeth() {
    setShowVirtualSeth(false);
  }

  const [showActionAI, setshowActionAI] = useState(false);

  function handleCloseActionAI() {
    setshowActionAI(false);
  }

  const [showAI, setShowAI] = useState(false);

  function handleCloseAI() {
    setShowAI(false);
  }

  function handleStoryMapBtnClick() {
    currentData.data.expertise == "Rivers and Risks"
      ? setStoryMapURL("https://storymaps.arcgis.com/stories/64211237ea99456ab8b2de09aa9a35f9")
      : setStoryMapURL("https://storymaps.arcgis.com/stories/71f0eca141c148a885494a16169aa16b");
    setShowStoryMapDialog(true);
  }

  function handleVisualStoryBtnClick() {
    // console.log("pdf", currentData);
    if (currentData.name == "Craig Just") {
      setStoryMapURL("https://app.blue-gap.org" + craigpdf);
    } else if (currentData.name == "Connie Mutel") {
      setStoryMapURL("https://app.blue-gap.org" + conniepdf);
    } else if (currentData.name == "Ding Darling") {
      setStoryMapURL("https://app.blue-gap.org" + dingdarlingpdf);
    } else {
      setStoryMapURL("https://app.blue-gap.org" + pdf);
    }

    setShowStoryMapDialog(true);
  }

  return (
    <Card className={classes.cardCss}>
      {/* <div className={classes.btnDiv}>
        <MDButton style={{ backgroundColor: "red", color: "white", width: "55px", margin: "5px" }} onClick={() => { navigate("/platform/dashboard") }}>Back</MDButton>
      </div> */}
      <div className={classes.mapAndSideCss}>
        <div
          style={{
            position: "absolute",
            zIndex: "1",
            top: "2.4%",
            left: "0px",
            boxShadow: "rgb(0 0 0 / 40%) 2px 2px 5px 2px",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <img
            style={{ width: "270px" }}
            src={require("assets/images/logos/bluegaplogo.png")}
            alt="image"
          />
        </div>
        <div className={classes.mapCss} ref={mapRef}></div>

        {showChampionVideo ? (
          <Card
            sx={{
              position: "absolute",
              right: "30%",
              top: "10%",
              width: "45%",
              height: "65%",
              zIndex: 5,
            }}
          >
            <MDBox sx={{ height: "100%" }}>
              <iframe
                id="youtube-player"
                width="100%"
                height="99%"
                src={`https://www.youtube.com/embed/${championVideoLink}?autoplay=${autoplay}`}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
            </MDBox>

            <MDBox sx={{ margin: "0px 5px 5px 5px" }}>
              <MDButton
                onClick={handleCloseChampionVideo}
                sx={{ float: "right" }}
                size="small"
                color="error"
              >
                Close
              </MDButton>
              <MDButton
                onClick={handleTakeActionBtnClick}
                sx={{ float: "left" }}
                size="small"
                color="info"
                disabled={currentData?.data.longSummary1 == null ? true : false}
              >
                Take Action
              </MDButton>
            </MDBox>
          </Card>
        ) : null}
        <Dialog
          open={showStoryBox}
          onClose={handleCloseStoryBox}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          fullWidth
          maxWidth={"md"}
          sx={{ height: "85%", margin: "3% 0% 0% 0%", zIndex: "2" }}
        >
          <DialogTitle id="scroll-dialog-title" style={{ color: "#000" }}>
            {currentData?.data.title ? currentData.data.title : currentData?.name + " Story Detail"}
          </DialogTitle>
          <DialogContent dividers={true}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <Grid item xl={12} md={12}>
                <List>
                  <ListItem sx={{ paddingBottom: "10px" }}>
                    <ListItemText
                      style={{ color: "#000" }}
                      secondary={currentData?.data.longSummary1}
                    />
                  </ListItem>
                  {currentData?.data.image ? (
                    currentData.data.image[0] ? (
                      <ListItem sx={{ paddingBottom: "10px", textAlign: "center" }}>
                        {" "}
                        {/* Added textAlign: center here */}
                        <img
                          height="350px"
                          src={currentData.data.image[0].src}
                          alt="Description of image"
                          style={{ maxWidth: "100%", height: "auto" }}
                        ></img>
                      </ListItem>
                    ) : null
                  ) : null}
                  <ListItem sx={{ paddingBottom: "10px" }}>
                    <ListItemText
                      style={{ color: "#000" }}
                      secondary={currentData?.data.longSummary2}
                    />
                  </ListItem>
                  {currentData?.data.image ? (
                    currentData.data.image[1] ? (
                      <ListItem sx={{ paddingBottom: "10px" }}>
                        <img
                          height="350px"
                          src={currentData.data.image[1].src}
                          style={{ maxWidth: "100%", height: "auto" }}
                        ></img>
                      </ListItem>
                    ) : null
                  ) : null}
                  <ListItem sx={{ paddingBottom: "10px" }}>
                    <ListItemText
                      style={{ color: "#000" }}
                      secondary={currentData?.data.longSummary3}
                    />
                  </ListItem>
                  {currentData?.data.image ? (
                    currentData.data.image[2] ? (
                      <ListItem sx={{ paddingBottom: "10px" }}>
                        <img
                          height="350px"
                          src={currentData.data.image[2].src}
                          style={{ maxWidth: "100%", height: "auto" }}
                        ></img>
                      </ListItem>
                    ) : null
                  ) : null}
                  <ListItem sx={{ paddingBottom: "10px" }}>
                    <ListItemText
                      style={{ color: "#000" }}
                      secondary={currentData?.data.longSummary4}
                    />
                  </ListItem>
                  <ListItem sx={{ paddingBottom: "10px" }}>
                    <ListItemText
                      style={{ color: "#000" }}
                      secondary={currentData?.data.longSummary5}
                    />
                  </ListItem>
                  <ListItem sx={{ paddingBottom: "10px" }}>
                    <ListItemText
                      style={{ color: "#000" }}
                      secondary={currentData?.data.longSummary6}
                    />
                  </ListItem>

                  {currentData?.data.audio
                    ? currentData.data.audio.map((item, index) => (
                        <>
                          <div>
                            <Typography style={{ color: "black" }}>{`Interview ${
                              index + 1
                            }`}</Typography>
                          </div>
                          <ListItem sx={{ paddingBottom: "30px" }}>
                            <AudioPlayer src={item.src} onPlay={(e) => console.log("onPlay")} />
                          </ListItem>
                        </>
                      ))
                    : null}
                </List>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={handleCloseStoryBox} color="error">
              Close
            </MDButton>
          </DialogActions>
        </Dialog>

        <Dialog
          open={showTakeActionDialog}
          onClose={() => {
            setShowTakeActionDialog(false);
          }}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          sx={{ height: "80%", margin: "3% 0% 0% 0%", zIndex: "10" }}
        >
          <DialogTitle id="scroll-dialog-title" style={{ color: "#000" }}>
            {currentVideoDetails?.videoTitle}
          </DialogTitle>
          <DialogContent dividers={true}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <Grid item xl={12} md={12}>
                <List>
                  <ListItem sx={{ paddingBottom: "10px" }}>
                    <ListItemText
                      style={{ color: "#000" }}
                      secondary={currentVideoDetails?.intro1}
                    />
                  </ListItem>
                  <ListItem sx={{ paddingBottom: "10px" }}>
                    <ListItemText primary={currentVideoDetails?.title1} style={{ color: "#000" }} />
                  </ListItem>
                  <ListItem sx={{ paddingBottom: "10px" }}>
                    <ListItemText
                      style={{ color: "#000" }}
                      secondary={currentVideoDetails?.summary1}
                    />
                  </ListItem>
                  <ListItem sx={{ paddingBottom: "10px" }}>
                    <ListItemText primary={currentVideoDetails?.title2} style={{ color: "#000" }} />
                  </ListItem>
                  <ListItem sx={{ paddingBottom: "10px" }}>
                    <ListItemText
                      style={{ color: "#000" }}
                      secondary={currentVideoDetails?.summary2}
                    />
                  </ListItem>
                  <ListItem sx={{ paddingBottom: "10px" }}>
                    <ListItemText primary={currentVideoDetails?.title3} style={{ color: "#000" }} />
                  </ListItem>
                  <ListItem sx={{ paddingBottom: "10px" }}>
                    <ListItemText
                      style={{ color: "#000" }}
                      secondary={currentVideoDetails?.summary3}
                    />
                  </ListItem>
                  <ListItem sx={{ paddingBottom: "10px" }}>
                    <ListItemText primary={currentVideoDetails?.title4} style={{ color: "#000" }} />
                  </ListItem>
                  <ListItem sx={{ paddingBottom: "10px" }}>
                    <ListItemText
                      style={{ color: "#000" }}
                      secondary={currentVideoDetails?.summary4}
                    />
                  </ListItem>
                  {currentVideoDetails?.title5 ? (
                    <>
                      <ListItem sx={{ paddingBottom: "10px" }}>
                        <ListItemText
                          primary={currentVideoDetails?.title5}
                          style={{ color: "#000" }}
                        />
                      </ListItem>
                      <ListItem sx={{ paddingBottom: "10px" }}>
                        <ListItemText
                          style={{ color: "#000" }}
                          secondary={currentVideoDetails?.summary5}
                        />
                      </ListItem>
                      <ListItem sx={{ paddingBottom: "10px" }}>
                        <ListItemText
                          primary={currentVideoDetails?.title6}
                          style={{ color: "#000" }}
                        />
                      </ListItem>
                      <ListItem sx={{ paddingBottom: "10px" }}>
                        <ListItemText
                          style={{ color: "#000" }}
                          secondary={currentVideoDetails?.summary6}
                        />
                      </ListItem>
                    </>
                  ) : null}

                  <ListItem sx={{ paddingBottom: "10px" }}>
                    <ListItemText
                      style={{ color: "#000" }}
                      secondary={currentVideoDetails?.footer}
                    />
                  </ListItem>
                </List>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton
              onClick={() => {
                setShowTakeActionDialog(false);
              }}
              color="error"
            >
              Close
            </MDButton>
          </DialogActions>
        </Dialog>

        {showVirtualSeth ? (
          <Card
            sx={{
              position: "absolute",
              right: "25%",
              top: "4%",
              width: "50%",
              height: "92%",
              zIndex: 5,
            }}
          >
            <MDBox sx={{ width: "100%", height: "100%" }}>
              <iframe
                src={virtualChampURL}
                style={{ width: "100%", height: "98%", borderRadius: "10px" }}
              ></iframe>
            </MDBox>

            <MDBox sx={{ margin: "0px 5px 5px 5px" }}>
              <MDButton
                onClick={handleCloseVirtualSeth}
                sx={{ float: "right" }}
                size="small"
                color="error"
              >
                Close
              </MDButton>
            </MDBox>
          </Card>
        ) : null}

        {showActionAI ? (
          <Card
            sx={{
              position: "absolute",
              right: "25%",
              top: "4%",
              width: "50%",
              height: "92%",
              zIndex: 5,
            }}
          >
            <MDBox sx={{ width: "100%", height: "100%" }}>
              <iframe
                src={`/chat/app/?expert=actionexpert`}
                style={{ width: "100%", height: "98%", borderRadius: "10px" }}
              ></iframe>
            </MDBox>

            <MDBox sx={{ margin: "0px 5px 5px 5px" }}>
              <MDButton
                onClick={handleCloseActionAI}
                sx={{ float: "right" }}
                size="small"
                color="error"
              >
                Close
              </MDButton>
            </MDBox>
          </Card>
        ) : null}

        {showStoryMapDialog ? (
          <Card
            sx={{
              position: "absolute",
              right: "5%",
              top: "4%",
              width: "90%",
              height: "92%",
              zIndex: 5,
            }}
          >
            <MDBox sx={{ width: "100%", height: "100%" }}>
              <iframe
                src={storyMapURL}
                style={{ width: "100%", height: "98%", borderRadius: "10px" }}
              ></iframe>
            </MDBox>

            <MDBox sx={{ margin: "0px 5px 5px 5px" }}>
              <MDButton
                onClick={() => {
                  navigator.clipboard.writeText(storyMapURL);
                  showNotification("Link copied to clipboard!", "info");
                }}
                sx={{ float: "left" }}
                size="small"
                color="success"
              >
                Share
              </MDButton>
              <MDButton
                onClick={() => {
                  setShowStoryMapDialog(false);
                }}
                sx={{ float: "right" }}
                size="small"
                color="error"
              >
                Close
              </MDButton>
            </MDBox>
          </Card>
        ) : null}

        {showAI ? (
          <Dialog
            open={showAI}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseAI}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"AI Selection Menu"}</DialogTitle>
            <DialogContent style={{ width: "350px" }}>
              <List>
                <ListItem disableGutters>
                  <ListItemButton onClick={handleVirtualSethBtnClick}>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                        <PersonIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={"Virtual Champion"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disableGutters>
                  <ListItemButton onClick={handleActionAIBtnClick}>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                        <PersonIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={"Action AI"} />
                  </ListItemButton>
                </ListItem>
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAI}>Close</Button>
            </DialogActions>
          </Dialog>
        ) : null}

        <div className={classes.sideBar}>
          <Card style={{ background: "lightgrey", height: "100%" }}>
            <div>
              <Chip
                label="Watershed Stories"
                onClick={revertZoom}
                style={{
                  width: "70%",
                  fontSize: "30px",
                  fontWeight: "bold",
                  font: "Tw Cen MT",
                  color: "white",
                  height: "70px",
                  cursor: currentData ? "pointer" : "default",
                  paddingRight: "130px",
                  backgroundColor: "rgba(33, 150, 243, 1)",
                  boxShadow: "none",
                  ".MuiChipLabel": {
                    display: "block",
                    whiteSpace: "normal",
                    color: "white",
                  },
                  borderRadius: "0px",
                  fontFamily: "PFDinTextCompProMedium",
                }}
              />
              <Chip
                label={parsedJSONData.location}
                style={{
                  width: "30%",
                  fontSize: "30px",
                  fontWeight: "bold",
                  textAlign: "right",
                  font: "Tw Cen MT",
                  color: "white",
                  height: "70px",
                  paddingLeft: "50px",
                  backgroundColor: "rgba(33, 150, 243, 1)",
                  boxShadow: "none",
                  "& .MuiChipLabel": {
                    display: "block",
                    whiteSpace: "normal",
                    color: "white",
                  },
                  borderRadius: "0px",
                  fontFamily: "PFDinTextCompProMedium",
                }}
              />
            </div>

            {currentData != null ? (
              <div className={classes.cardSmallAndBig}>
                <ChampionCard
                  image={currentData.img}
                  name={currentData.name}
                  expertise={currentData.expertise}
                  site={currentData.site}
                  currentData={currentData}
                  setYouTubePlaylist={setYouTubePlaylist}
                />
                {/* <div className={classes.cardImgShortDesc}>
                  <div className={classes.imageSection}>
                    <img className={classes.image} src={currentData.img} alt="image" />
                  </div>
                  <div style={{ marginTop: "5px" }}>
                    <TableRow>
                      <TableCell className={classes.tableTitle}>Name</TableCell>
                      <TableCell className={classes.tableDescription} align="left">{currentData.name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableTitle}>Location</TableCell>
                      <TableCell className={classes.tableDescription} align="left">{currentData.site}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableTitle}>Expertise</TableCell>
                      <TableCell className={classes.tableDescription} align="left">{currentData.data.expertise}</TableCell>
                    </TableRow>
                  </div>
                </div> */}
                {currentData.name ? (
                  <div className={classes.bigDesc}>
                    <MDTypography
                      variant="button"
                      fontWeight="bold"
                      textTransform="capitalize"
                      style={{
                        color: "black",
                        marginLeft: "10px",
                        marginTop: "5px",
                        marginBottom: "5px",
                        fontSize: "1.2rem",
                        fontFamily: "PFDinTextCompProMedium",
                      }}
                    >
                      Summary
                    </MDTypography>

                    <div style={{ margin: "0px 10px 10px 10px" }}>
                      <p
                        style={{
                          fontWeight: "300",
                          fontSize: "16px",
                          color: "black",
                          fontFamily: "Gill Sans MT",
                        }}
                      >
                        {currentData.data.briefSummary}
                      </p>
                    </div>
                    {currentData?.type === "blue" ? null : (
                      <MDBox style={{ margin: "0px 10px 10px 10px" }}>
                        <p
                          style={{
                            fontWeight: "300",
                            fontSize: "16px",
                            color: "black",
                            fontFamily: "Gill Sans MT",
                          }}
                        >
                          <b>Note:</b> The following is a BlueGAP supported graduate student project
                          from 2023/2024 cohorts of 12 University of Iowa graduate students were
                          tasked with producing individual, independent projects that represents a
                          range of approaches to storytelling, and provides an aesthetic connection
                          to the problems of nitrogen pollution.
                        </p>
                      </MDBox>
                    )}

                    <Grid container sx={{ paddingBottom: "10px" }}>
                      <Grid item xs={12} sm={12} md={4}>
                        <MDButton
                          onClick={handleShowStoryBox}
                          color="info"
                          style={{ marginLeft: "10px" }}
                        >
                          Story Detail
                        </MDButton>
                      </Grid>
                      {currentData.type == "blue" ? (
                        <Grid item xs={12} sm={12} md={4}>
                          <MDButton
                            style={{ marginLeft: "10px" }}
                            onClick={handleExploreDataBtnClick}
                            color="info"
                          >
                            Explore Data
                          </MDButton>
                        </Grid>
                      ) : null}

                      {currentData.map ? (
                        <Grid item xs={12} sm={12} md={4}>
                          {currentData.map == "story" ? (
                            <MDButton
                              style={{ marginLeft: "10px" }}
                              onClick={handleStoryMapBtnClick}
                              color="info"
                            >
                              Story Map
                            </MDButton>
                          ) : (
                            <MDButton
                              style={{ marginLeft: "10px" }}
                              onClick={handleVisualStoryBtnClick}
                              color="info"
                            >
                              Visual Story
                            </MDButton>
                          )}
                        </Grid>
                      ) : null}

                      {/* <Grid item xs={12} sm={12} md={4}>
                        <MDButton
                          style={{ marginLeft: "10px" }}
                          onClick={handleTakeActionBtnClick}
                          color="info"
                        >
                          Take Action
                        </MDButton>
                      </Grid> */}
                      {/* <Grid item xs={12} sm={6} md={3}>
                        <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                          <MDButton size="small" onClick={handleVirtualSethBtnClick} color="info">
                            Virtual Seth
                          </MDButton>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <div style={{ paddingLeft: "10px", paddingRight: "15px" }}>
                          <MDButton size="small" onClick={handleTakeActionBtnClick} color="info">
                            Take Action
                          </MDButton>
                        </div>
                      </Grid> */}
                      {/* Add more buttons within Grid items */}
                    </Grid>
                    {currentData.type == "blue" ? (
                      <>
                        <MDTypography
                          variant="button"
                          fontWeight="bold"
                          textTransform="capitalize"
                          style={{
                            color: "black",
                            marginLeft: "10px",
                            fontSize: "1.2rem",
                            fontFamily: "PFDinTextCompProMedium",
                          }}
                        >
                          Video Playlist
                        </MDTypography>
                        {youtubePlaylist ? (
                          <div className={classes.videoResponsive}>
                            <div className="playlist-gallery-div">
                              {youtubePlaylist?.items.map(({ id, snippet = {} }) => {
                                const { title, thumbnails = {}, resourceId = {} } = snippet;
                                const { medium } = thumbnails;
                                return (
                                  <figure className="youtube-video-figure" key={id}>
                                    {/* <a href={`https://www.youtube.com/watch?v=${resourceId.videoId}`}> */}
                                    <img
                                      src={medium?.url}
                                      className="youtube-video-image"
                                      alt=""
                                      onClick={() => {
                                        //console.log("video ID", resourceId.videoId);
                                        setChampionVideoLink(resourceId.videoId);
                                        setShowChampionVideo(true);
                                      }}
                                    />
                                    <figcaption>{title}</figcaption>
                                    {/* </a> */}
                                  </figure>
                                );
                              })}
                            </div>
                          </div>
                        ) : (
                          <MDTypography
                            variant="button"
                            fontWeight="bold"
                            textTransform="capitalize"
                            style={{
                              color: "black",
                              marginLeft: "10px",
                              marginTop: "10px",
                              marginBottom: "5px",
                              fontSize: "1.2rem",
                              fontFamily: "PFDinTextCompProMedium",
                            }}
                          >
                            Video Coming Soon.
                          </MDTypography>
                        )}
                      </>
                    ) : null}
                  </div>
                ) : (
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    textTransform="capitalize"
                    style={{
                      color: "black",
                      marginLeft: "20px",
                      marginTop: "15px",
                      marginBottom: "5px",
                      fontSize: "1.2rem",
                      fontFamily: "PFDinTextCompProMedium",
                    }}
                  >
                    Information Coming Soon.
                  </MDTypography>
                )}
              </div>
            ) : (
              <div>
                <Accordion defaultExpanded sx={{ marginBottom: "15px" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    Community Champions
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: "0px !important" }}>
                    {championsList?.map((user, index) => (
                      <div
                        key={index}
                        // style={{
                        //   boxShadow:
                        //     "0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(76 175 80 / 40%)",
                        // }}
                        className={classes.cardSmallAndBig2}
                      >
                        <ChampionCard
                          image={user.img}
                          name={user.name}
                          expertise={user.data.expertise}
                          site={user.site}
                          setAutoplay={setAutoplay}
                          setCurrentData={setCurrentData}
                          mapGlobal={mapGlobal}
                          user={user}
                          getCurrentZoom={getCurrentZoom}
                          currentData={currentData}
                          setYouTubePlaylist={setYouTubePlaylist}
                        />
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
                {membersList.length > 0 ? (
                  <Accordion sx={{ marginBottom: "15px" }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      People, Places & Voices
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: "0px !important" }}>
                      {membersList?.map((user, index) => (
                        <div
                          key={index}
                          // style={{
                          //   boxShadow:
                          //     "0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(76 175 80 / 40%)",
                          // }}
                          className={classes.cardSmallAndBig2}
                        >
                          <ChampionCard
                            image={user.img}
                            name={user.name}
                            expertise={user.data.expertise}
                            site={user.site}
                            setAutoplay={setAutoplay}
                            setCurrentData={setCurrentData}
                            mapGlobal={mapGlobal}
                            user={user}
                            getCurrentZoom={getCurrentZoom}
                            currentData={currentData}
                            setYouTubePlaylist={setYouTubePlaylist}
                          />
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ) : null}

                {artDesignList.length > 0 ? (
                  <Accordion sx={{ marginBottom: "15px" }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      Art & Media
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: "0px !important" }}>
                      {artDesignList?.map((user, index) => (
                        <div
                          key={index}
                          // style={{
                          //   boxShadow:
                          //     "0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(76 175 80 / 40%)",
                          // }}
                          className={classes.cardSmallAndBig2}
                        >
                          <ChampionCard
                            image={user.img}
                            name={user.name}
                            expertise={user.data.expertise}
                            site={user.site}
                            setAutoplay={setAutoplay}
                            setCurrentData={setCurrentData}
                            mapGlobal={mapGlobal}
                            user={user}
                            getCurrentZoom={getCurrentZoom}
                            currentData={currentData}
                            setYouTubePlaylist={setYouTubePlaylist}
                          />
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ) : null}

                {/* <Accordion sx={{ marginBottom: "15px" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    Places
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: "0px !important" }}>
                    {placesList?.map((user, index) => (
                      <div
                        key={index}
                        // style={{
                        //   boxShadow:
                        //     "0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(76 175 80 / 40%)",
                        // }}
                        className={classes.cardSmallAndBig2}
                      >
                        <ChampionCard
                          image={user.img}
                          name={user.name}
                          expertise={user.data.expertise}
                          site={user.site}
                          setAutoplay={setAutoplay}
                          setCurrentData={setCurrentData}
                          mapGlobal={mapGlobal}
                          user={user}
                          getCurrentZoom={getCurrentZoom}
                          currentData={currentData}
                          setYouTubePlaylist={setYouTubePlaylist}
                        />
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion> */}
              </div>
            )}
          </Card>
        </div>

        <MDSnackbar
          color={"warning"}
          icon={
            snackbarType == "success"
              ? "check"
              : snackbarType == "error"
              ? "warning"
              : "notifications"
          }
          title="BlueGAP Information System"
          content={snackbarErrorMessage}
          dateTime="just now"
          open={tc}
          onClose={() => setTC(false)}
          close={() => setTC(false)}
          // closeNotification={() => setTC(false)}
          bgWhite={false}
        />
        <Backdrop
          style={{ zIndex: 2, backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          open={
            showStoryBox ||
            showChampionVideo ||
            showActionAI ||
            showVirtualSeth ||
            showAI ||
            showStoryMapDialog ||
            showTakeActionDialog
          }
          onClick={handleCloseStoryBox}
        ></Backdrop>
      </div>
      {/* <Box sx={{ position: "absolute", bottom: "30px", left: "30px" }}>
        <Fab
          variant="extended"
          color="primary"
          onClick={() => {
            setShowAI(true);
          }}
        >
          <NavigationIcon sx={{ mr: 1 }} />
          AI HUB
        </Fab>
      </Box> */}
      <div
        style={{
          position: "fixed",
          bottom: "130px",
          left: "40px",
          textAlign: "center",
        }}
      >
        <button
          className="feedback-button"
          onClick={() => {
            window.open(
              "https://docs.google.com/forms/d/e/1FAIpQLSeBSL4BpDkZhxXDkftWvMl5N4TtRSKvPgCbrcpT6PGgbyWnVA/viewform",
              "_blank"
            );
          }}
        >
          Feedback
        </button>
      </div>
      {currentData ? (
        <div
          style={{
            position: "fixed",
            bottom: "30px",
            left: "40px",
            textAlign: "center",
          }}
        >
          {/* <button
            className="bluegap-ai-btn"
            onClick={() => {
              setShowAI(true);
            }}
          >
            AI HUB
          </button> */}
          <img
            style={{ height: "75px", cursor: "pointer" }}
            src={require("assets/images/chatbot.png")}
            onClick={() => {
              handleVirtualSethBtnClick();
            }}
          />
        </div>
      ) : null}
    </Card>
  );
}

export default InterviewDetailBox;
