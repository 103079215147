import { Card } from "@mui/material";
import MDTypography from "components/MDTypography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DemographicReportBoxStyle from "../styles/DemographicReportBoxStyle";
import { makeStyles } from "@mui/styles";
import Divider from "@mui/material/Divider";

const useStyles = makeStyles(DemographicReportBoxStyle);

function DemographicReportBox({demographicObject}) {
  const classes = useStyles();

  return (
    <Card
      style={{
        position: "absolute",
        bottom: 10,
        left: '30%',
        width: "37%",
        maxWidth: "690px",
        minWidth: "620px",
        contain: "content",
        padding: "4px",
      }}
    >
      <MDTypography
          style={{
            fontFamily: "PFDinTextCompProMedium",
            color: "black",
            fontWeight: "500",
            fontSize: "20px",
            background: "lightskyblue",
            paddingLeft: "10px",
            borderRadius: "7px"
          }}
        >
          Demographic Data
        </MDTypography>

      <Table className={classes.table}>
        <TableBody>
          <TableRow className={classes.tableBodyRow}>
            <TableCell className={classes.tableSummaryCell} style={{width:"40px"}}>
              <span style={{ color: `blue` }} className={classes.dataTitle}>
                Over 64
              </span>
            </TableCell>
            <TableCell className={classes.tableSummaryCell} style={{ textAlign: "left", width:"35px" }}>
              <span style={{ color: `blue` }} className={classes.dataTitle}>
                Under 5
              </span>
            </TableCell>
            <TableCell className={classes.tableSummaryCell} style={{ textAlign: "left", width:"50px" }}>
              <span style={{ color: `blue` }} className={classes.dataTitle}>
                Unemployed
              </span>
            </TableCell>
            <TableCell className={classes.tableSummaryCell} style={{ textAlign: "left", width:"60px" }}>
              <span style={{ color: `blue` }} className={classes.dataTitle}>
                Low Income %
              </span>
            </TableCell>
            <TableCell className={classes.tableSummaryCell} style={{ textAlign: "left", width:"60px" }}>
              <span style={{ color: `blue` }} className={classes.dataTitle}>
                People of Color
              </span>
            </TableCell>
            <TableCell className={classes.tableSummaryCell} style={{ textAlign: "left", width:"60px" }}>
              <span style={{ color: `blue` }} className={classes.dataTitle}>
                Less than HS Edu.
              </span>
            </TableCell>
            <TableCell className={classes.tableSummaryCell} style={{ textAlign: "left", width:"60px" }}>
              <span style={{ color: `blue` }} className={classes.dataTitle}>
                English Speaking
              </span>
            </TableCell>
          </TableRow>
        </TableBody>
        <TableBody>
          <TableRow className={classes.tableBodyRow}>
            <TableCell className={classes.tableSummaryCell}>
              <span className={classes.dataTitle}>{demographicObject?.over64}</span>
            </TableCell>
            <TableCell className={classes.tableSummaryCell} style={{ textAlign: "left" }}>
              <span className={classes.dataTitle}>{demographicObject?.under5}</span>
            </TableCell>
            <TableCell className={classes.tableSummaryCell} style={{ textAlign: "left" }}>
              <span className={classes.dataTitle}>{demographicObject?.unemployed} %</span>
            </TableCell>
            <TableCell className={classes.tableSummaryCell} style={{ textAlign: "left" }}>
              <span className={classes.dataTitle}>{demographicObject?.lowincpct} %</span>
            </TableCell>
            <TableCell className={classes.tableSummaryCell} style={{ textAlign: "left" }}>
              <span className={classes.dataTitle}>{demographicObject?.peopcolorp} %</span>
            </TableCell>
            <TableCell className={classes.tableSummaryCell} style={{ textAlign: "left" }}>
              <span className={classes.dataTitle}>{demographicObject?.lesshspct} %</span>
            </TableCell>
            <TableCell className={classes.tableSummaryCell} style={{ textAlign: "left" }}>
              <span className={classes.dataTitle}>{demographicObject?.lingisopct} %</span>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {/* <MDTypography
        style={{
          fontFamily: "PFDinTextCompProMedium",
          marginLeft: "5px",
          color: "black",
          fontWeight: "500",
          fontSize: "20px",
        }}
      >
        Transportation
      </MDTypography>

      <Table className={classes.table}>
        <TableBody>
          <TableRow className={classes.tableBodyRow}>
            <TableCell className={classes.tableSummaryCell} style={{width: "55px"}}>
              <span style={{ color: `blue` }} className={classes.dataTitle}>
                Ozone
              </span>
            </TableCell>
            <TableCell
              className={classes.tableSummaryCell}
              style={{ textAlign: "left", width: "50px" }}
            >
              <span style={{ color: `blue` }} className={classes.dataTitle}>
              Diesel %
              </span>
            </TableCell>
            <TableCell className={classes.tableSummaryCell} style={{ textAlign: "left" }}>
              <span style={{ color: `blue`, width: "150px" }} className={classes.dataTitle}>
                Land Area (sq.m)
              </span>
            </TableCell>
            <TableCell className={classes.tableSummaryCell} style={{ textAlign: "left" }}>
              <span style={{ color: `blue`, width: "90px" }} className={classes.dataTitle}>
                Traffic Proximity
              </span>
            </TableCell>
          </TableRow>
        </TableBody>
        <TableBody>
          <TableRow className={classes.tableBodyRow}>
            <TableCell className={classes.tableSummaryCell}>
              <span className={classes.dataTitle}>{demographicObject?.ozone}</span>
            </TableCell>
            <TableCell className={classes.tableSummaryCell} style={{ textAlign: "left" }}>
              <span className={classes.dataTitle}>{demographicObject?.dslpm}</span>
            </TableCell>
            <TableCell className={classes.tableSummaryCell} style={{ textAlign: "left" }}>
              <span className={classes.dataTitle}>{demographicObject?.landarea}</span>
            </TableCell>
            <TableCell className={classes.tableSummaryCell} style={{ textAlign: "left" }}>
              <span className={classes.dataTitle}>{demographicObject?.ptraf}</span>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table> */}

      {/* <MDTypography
        style={{
          fontFamily: "PFDinTextCompProMedium",
          marginLeft: "5px",
          color: "black",
          fontWeight: "500",
          fontSize: "20px",
        }}
      >
        Worker Industry
      </MDTypography>

      <Table className={classes.table}>
        <TableBody>
          <TableRow className={classes.tableBodyRow}>
            <TableCell className={classes.tableSummaryCell}>
              <span style={{ color: `blue` }} className={classes.dataTitle}>
                Travel Time
              </span>
            </TableCell>
            <TableCell className={classes.tableSummaryCell} style={{ textAlign: "left" }}>
              <span style={{ color: `blue` }} className={classes.dataTitle}>
                Total Households
              </span>
            </TableCell>
            <TableCell className={classes.tableSummaryCell} style={{ textAlign: "left" }}>
              <span style={{ color: `blue` }} className={classes.dataTitle}>
                Total Families
              </span>
            </TableCell>
          </TableRow>
        </TableBody>
        <TableBody>
          <TableRow className={classes.tableBodyRow}>
            <TableCell className={classes.tableSummaryCell}>
              <span className={classes.dataTitle}>2 Hours</span>
            </TableCell>
            <TableCell className={classes.tableSummaryCell} style={{ textAlign: "left" }}>
              <span className={classes.dataTitle}>2</span>
            </TableCell>
            <TableCell className={classes.tableSummaryCell} style={{ textAlign: "left" }}>
              <span className={classes.dataTitle}>1</span>
            </TableCell>
          </TableRow>
          <TableRow className={classes.tableBodyRow}>
            <TableCell className={classes.tableSummaryCell}>
              <span className={classes.dataTitle}>30 Minutes</span>
            </TableCell>
            <TableCell className={classes.tableSummaryCell} style={{ textAlign: "left" }}>
              <span className={classes.dataTitle}>3</span>
            </TableCell>
            <TableCell className={classes.tableSummaryCell} style={{ textAlign: "left" }}>
              <span className={classes.dataTitle}>4</span>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table> */}
    </Card>
  );
}

export default DemographicReportBox;
