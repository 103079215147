import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import MDTypography from "components/MDTypography";

function LegendBox({ geoType, demographicObject, censusTract, zipCode }) {
  return geoType == "Census Tract" ? null : (
    <Card
      style={{
        position: "absolute",
        bottom: "1%",
        left: "320px",
        width: "120px",
        contain: "content",
        padding: "4px",
        zIndex: 1,
        borderRadius: "0px",
      }}
    >
      {demographicObject ? (
        <>
          {geoType == "Zip Code" ? (
            censusTract ? (
              <MDTypography
                style={{
                  color: "black",
                  fontSize: "16px",
                  fontWeight: "normal",
                  fontFamily: "PFDinTextCompProMedium",
                  textAlign: "center",
                }}
              >
                Demographic Index
              </MDTypography>
            ) : null
          ) : zipCode ? (
            <MDTypography
              style={{
                color: "black",
                fontSize: "16px",
                fontWeight: "normal",
                fontFamily: "PFDinTextCompProMedium",
                textAlign: "center",
              }}
            >
              Data Availability
            </MDTypography>
          ) : null}

          {geoType == "Zip Code" ? (
            censusTract ? (
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ padding: "6px 6px !important" }}>
                      <Box
                        sx={{
                          width: 15,
                          height: 15,
                          backgroundColor: "rgb(217, 217, 217)",
                          border: "2px solid black",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "0.84rem",
                        padding: "0px 6px 0px 6px",
                        fontFamily: "PFDinTextCompProMedium",
                      }}
                      align="left"
                    >
                      {"<"} 50
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "6px 6px !important" }}>
                      <Box
                        sx={{
                          width: 15,
                          height: 15,
                          backgroundColor: "rgb(181, 181, 181)",
                          border: "2px solid black",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "0.84rem",
                        padding: "0px 6px 0px 6px",
                        fontFamily: "PFDinTextCompProMedium",
                      }}
                      align="left"
                    >
                      50-60
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "6px 6px !important" }}>
                      <Box
                        sx={{
                          width: 15,
                          height: 15,
                          backgroundColor: "rgb(145, 145, 145)",
                          border: "2px solid black",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "0.84rem",
                        padding: "0px 6px 0px 6px",
                        fontFamily: "PFDinTextCompProMedium",
                      }}
                      align="left"
                    >
                      60-70
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "6px 6px !important" }}>
                      <Box
                        sx={{
                          width: 15,
                          height: 15,
                          backgroundColor: "rgb(107, 107, 107)",
                          border: "2px solid black",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "0.84rem",
                        padding: "0px 6px 0px 6px",
                        fontFamily: "PFDinTextCompProMedium",
                      }}
                      align="left"
                    >
                      70-80
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "6px 6px !important" }}>
                      <Box
                        sx={{
                          width: 15,
                          height: 15,
                          backgroundColor: "rgb(255, 255, 115)",
                          border: "2px solid black",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "0.84rem",
                        padding: "0px 6px 0px 6px",
                        fontFamily: "PFDinTextCompProMedium",
                      }}
                      align="left"
                    >
                      80-90
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "6px 6px !important" }}>
                      <Box
                        sx={{
                          width: 15,
                          height: 15,
                          backgroundColor: "rgb(230, 152, 0)",
                          border: "2px solid black",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "0.84rem",
                        padding: "0px 6px 0px 6px",
                        fontFamily: "PFDinTextCompProMedium",
                      }}
                      align="left"
                    >
                      90-95
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ padding: "6px 6px !important" }}>
                      <Box
                        sx={{
                          width: 15,
                          height: 15,
                          backgroundColor: "rgb(168, 0, 0)",
                          border: "2px solid black",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "0.84rem",
                        padding: "0px 6px 0px 6px",
                        fontFamily: "PFDinTextCompProMedium",
                      }}
                      align="left"
                    >
                      95-100
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            ) : null
          ) : zipCode ? (
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell sx={{ padding: "6px 6px !important" }}>
                    <Box
                      sx={{
                        width: 15,
                        height: 15,
                        backgroundColor: "green",
                        border: "2px solid black",
                      }}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "0.84rem",
                      padding: "0px 6px 0px 6px",
                      fontFamily: "PFDinTextCompProMedium",
                    }}
                    align="left"
                  >
                    Good
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: "6px 6px !important" }}>
                    <Box
                      sx={{
                        width: 15,
                        height: 15,
                        backgroundColor: "yellow",
                        border: "2px solid black",
                      }}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "0.84rem",
                      padding: "0px 6px 0px 6px",
                      fontFamily: "PFDinTextCompProMedium",
                    }}
                    align="left"
                  >
                    Average
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ padding: "6px 6px !important" }}>
                    <Box
                      sx={{
                        width: 15,
                        height: 15,
                        backgroundColor: "#FA5B3D",
                        border: "2px solid black",
                      }}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "0.84rem",
                      padding: "0px 6px 0px 6px",
                      fontFamily: "PFDinTextCompProMedium",
                    }}
                    align="left"
                  >
                    Poor
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ) : null}
        </>
      ) : (
        <>
          <MDTypography
            style={{
              color: "black",
              fontSize: "16px",
              fontWeight: "normal",
              fontFamily: "PFDinTextCompProMedium",
              textAlign: "center",
            }}
          >
            Data Availability
          </MDTypography>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell sx={{ padding: "6px 6px !important" }}>
                  <Box
                    sx={{
                      width: 15,
                      height: 15,
                      backgroundColor: "green",
                      border: "2px solid black",
                    }}
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "0.84rem",
                    padding: "0px 6px 0px 6px",
                    fontFamily: "PFDinTextCompProMedium",
                  }}
                  align="left"
                >
                  Good
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: "6px 6px !important" }}>
                  <Box
                    sx={{
                      width: 15,
                      height: 15,
                      backgroundColor: "yellow",
                      border: "2px solid black",
                    }}
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "0.84rem",
                    padding: "0px 6px 0px 6px",
                    fontFamily: "PFDinTextCompProMedium",
                  }}
                  align="left"
                >
                  Average
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: "6px 6px !important" }}>
                  <Box
                    sx={{
                      width: 15,
                      height: 15,
                      backgroundColor: "#FA5B3D",
                      border: "2px solid black",
                    }}
                  />
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "0.84rem",
                    padding: "0px 6px 0px 6px",
                    fontFamily: "PFDinTextCompProMedium",
                  }}
                  align="left"
                >
                  Poor
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </>
      )}
    </Card>
  );
}

export default LegendBox;
