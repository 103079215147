/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React base styles

// Images
import backgroundImage from "assets/images/river-background-2.jpg";
import { AppBar, Box, Grid, Toolbar, Typography } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";

// ICONS

function Header() {
  let navigate = useNavigate();
  return (
    <AppBar position="static" sx={{ backgroundColor: "#fff", height: "100% !important" }}>
      <Toolbar sx={{ paddingRight: "10px !important" }}>
        <img
          onClick={() => {
            navigate(-1);
          }}
          style={{ width: "270px", cursor: "pointer" }}
          src={require("assets/images/logos/bluegaplogo.png")}
          alt="image"
        />

        <Typography
          variant="h6"
          noWrap
          sx={{
            ml: 2,
            display: { xs: "none", md: "flex" },
            fontFamily: "PFDinTextCompProMedium",
            fontSize: "45px",
            color: "#000 !important",
            textDecoration: "none",
          }}
        >
          Information System
        </Typography>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "none", md: "flex" },
            justifyContent: "flex-end", // Align items to the right
          }}
        >
          <MDBox
            variant="gradient"
            bgColor="success"
            color="white"
            coloredShadow="success"
            borderRadius="md"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="3.2rem"
            m={1}
            p={2}
            style={{
              fontSize: "1.5vw",
              fontFamily: "PFDinTextCompProMedium",
              fontWeight: "normal",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            Home
          </MDBox>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
