import "./styles/ActionPage.css";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { Button, Card, CardActions, Chip, CircularProgress, Link, Paper } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import solutionsJSON from "../../assets/solutions.json";
import actualProblemsJSON from "../../assets/actual_problems.json";
import problemAreasJSON from "../../assets/problem_areas.json";
import tampaSolutionsJSON from "../../assets/planner-data/tampaSolutions.json";
import tampaActualProblemsJSON from "../../assets/planner-data/tampaProbs.json";
import tampaProblemDomainsJSON from "../../assets/planner-data/tampaDomain.json";

import iowaSolutionsJSON from "../../assets/planner-data/iowaSolutions.json";
import iowaActualProblemsJSON from "../../assets/planner-data/iowaProbs.json";
import iowaProblemDomainsJSON from "../../assets/planner-data/iowaDomain.json";

import usviSolutionsJSON from "../../assets/planner-data/usviSolutions.json";
import usviActualProblemsJSON from "../../assets/planner-data/usviProbs.json";
import usviProblemDomainsJSON from "../../assets/planner-data/usviDomain.json";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import MDSnackbar from "components/MDSnackbar";
import { useLocation, useNavigate } from "react-router-dom";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid rgba(0, 0, 0, 0.12)`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: 1,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 2,
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function ActionPage() {
  const locationJSON = useLocation();
  const parsedJSONData = JSON.parse(locationJSON.state);

  const [tc, setTC] = useState(false);
  const [snackbarErrorMessage, setSnackbarErrorMessage] = useState("Something went wrong.");
  const [snackbarType, setSnackbarType] = useState("success");
  const showNotification = (message, type = "") => {
    if (!tc) {
      setSnackbarErrorMessage(message);
      setSnackbarType(type);
      setTC(true);
      setTimeout(function () {
        setTC(false);
      }, 4000);
    }
  };

  const [expanded, setExpanded] = useState(["panel3"]);

  const locations = [
    {
      id: 1,
      name: "Iowa",
    },
    {
      id: 2,
      name: "Tampa",
    },
  ];

  const roles = [
    {
      id: 1,
      name: "Farmer",
    },
    {
      id: 2,
      name: "Environment Protection Commission Personnel",
    },
    {
      id: 3,
      name: "Environmental Scientist",
    },
    {
      id: 4,
      name: "Environmental Manager",
    },
    {
      id: 5,
      name: "Attorney",
    },
    {
      id: 6,
      name: "Citizen",
    },
  ];

  const problemDomains =
    parsedJSONData.location == "IOWA"
      ? iowaProblemDomainsJSON
      : parsedJSONData.location == "TAMPA"
      ? tampaProblemDomainsJSON
      : usviProblemDomainsJSON;

  const currentIssues =
    parsedJSONData.location == "IOWA"
      ? iowaActualProblemsJSON
      : parsedJSONData.location == "TAMPA"
      ? tampaActualProblemsJSON
      : usviActualProblemsJSON;

  const solutions =
    parsedJSONData.location == "IOWA"
      ? iowaSolutionsJSON
      : parsedJSONData.location == "TAMPA"
      ? tampaSolutionsJSON
      : usviSolutionsJSON;

  const [solutionDetail, setSolutionDetail] = useState(null);
  const [actualProblem, setActualProblem] = useState(null);
  const [problemArea, setProblemArea] = useState(null);
  const [role, setRole] = useState(null);
  const [location, setLocation] = useState(null);
  const [locationList, setLocationList] = useState(locations);
  const [roleList, setRoleList] = useState(roles);
  const [problemAreaList, setProblemAreaList] = useState([]);
  const [actualProblemList, setActualProblemList] = useState([]);
  const [solutionList, setSolutionList] = useState([]);
  const [gptLoaded, setGPTLoaded] = useState(false);
  const [gptSolution, setGPTSolution] = useState(null);
  const [showGPTSolution, setShowGPTSolution] = useState(false);
  const [breadCrumbList, setBreadCrumbList] = useState([]);
  //   const [selectedLocation, setSelectedLocation] = useState(null);

  useEffect(() => {
    document.title = "BlueGAP Action Platform";
    const sortedProblemDomains = [...problemDomains].sort((a, b) => a.name.localeCompare(b.name));

    setProblemAreaList(sortedProblemDomains);
  }, []);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded((prevExpanded) => {
      if (newExpanded) {
        return [...prevExpanded, panel];
      } else {
        return prevExpanded.filter((item) => item !== panel);
      }
    });
  };

  function handleClickRoleBtn(roleid) {
    let selectedRole = roles.find((item) => item.id == roleid);
    setSolutionDetail(null);
    setLocation(null);
    setActualProblem(null);
    setActualProblemList([]);
    setProblemArea(null);
    setProblemAreaList([]);
    setSolutionList([]);
    setRole(selectedRole);
    // setProblemAreaList(problemAreas.filter((item) => item.roleid == roleid));
    //fetch to fill data for the problem areas.
    setExpanded(["panel2"]);
    setBreadCrumbList((prevList) => {
      const newList = [...prevList];
      const indexToUpdate = newList.findIndex((item) => item.type === "role");
      // If indexToUpdate is provided, replace the item at that index
      if (indexToUpdate !== undefined && indexToUpdate >= 0 && indexToUpdate < newList.length) {
        // Create a new array with elements up to the specified index
        const updatedList = newList.slice(0, indexToUpdate);
        updatedList.push({ type: "role", name: selectedRole.name });
        return updatedList;
      } else {
        // Otherwise, add the new item to the end of the list
        newList.push({ type: "role", name: selectedRole.name });
        return newList;
      }
    });
  }

  function handleClickLocationBtn(locationid) {
    let selectedLocation = locationList.find((item) => item.id == locationid);
    setSolutionDetail(null);
    setProblemAreaList([]);
    setActualProblemList([]);
    setSolutionList([]);
    setProblemArea(null);
    setActualProblem(null);
    setLocation(selectedLocation);
    //location == "IA" ? setSelectedLocation(1): setSelectedLocation(2);
    //fetch to fill data for the roles.
    setRoleList(roles);
    setExpanded(["panel3"]);
    if (problemDomains.filter((item) => item.locationID == locationid).length == 0) {
      showNotification("Data coming soon!", "info");
    }
    setProblemAreaList(problemDomains.filter((item) => item.locationID == locationid));
    setBreadCrumbList((prevList) => {
      const newList = [...prevList];
      const indexToUpdate = newList.findIndex((item) => item.type === "location");
      // If indexToUpdate is provided, replace the item at that index
      if (indexToUpdate !== undefined && indexToUpdate >= 0 && indexToUpdate < newList.length) {
        // Create a new array with elements up to the specified index
        const updatedList = newList.slice(0, indexToUpdate);
        updatedList.push({ type: "location", name: selectedLocation.name });
        return updatedList;
      } else {
        // Otherwise, add the new item to the end of the list
        newList.push({ type: "location", name: selectedLocation.name });
        return newList;
      }
    });
  }

  function handleClickProblemAreasBtn(problemAreaId) {
    let selectedProblemDomain = problemDomains.find((item) => item.id == problemAreaId);
    setSolutionDetail(null);
    setSolutionList([]);
    setProblemArea(selectedProblemDomain);
    setActualProblem(null);

    const sortedIssues = currentIssues
      .filter((item) => {
        const itemDomainID = Array.isArray(item.domainID) ? item.domainID : [item.domainID];
        return itemDomainID.includes(problemAreaId);
      })
      .sort((a, b) => a.name.localeCompare(b.name));

    setActualProblemList(sortedIssues);
    setExpanded(["panel4"]);
    setBreadCrumbList((prevList) => {
      const newList = [...prevList];
      const indexToUpdate = newList.findIndex((item) => item.type === "domain");
      // If indexToUpdate is provided, replace the item at that index
      if (indexToUpdate !== undefined && indexToUpdate >= 0 && indexToUpdate < newList.length) {
        // Create a new array with elements up to the specified index
        const updatedList = newList.slice(0, indexToUpdate);
        updatedList.push({ type: "domain", name: selectedProblemDomain.name });
        return updatedList;
      } else {
        // Otherwise, add the new item to the end of the list
        newList.push({ type: "domain", name: selectedProblemDomain.name });
        return newList;
      }
    });
  }

  function handleClickActualProblemsBtn(actualProblemId) {
    let selectedActualProblem = currentIssues.find((item) => item.id == actualProblemId);
    setSolutionDetail(null);
    setActualProblem(selectedActualProblem);
    const sortedSolutions = solutions.sort((a, b) => a.name.localeCompare(b.name));

    const filteredSolutions = sortedSolutions.filter((item) => item.problemID === actualProblemId);

    setSolutionList(filteredSolutions);
    setExpanded(["panel5"]);
    setBreadCrumbList((prevList) => {
      const newList = [...prevList];
      const indexToUpdate = newList.findIndex((item) => item.type === "issue");
      // If indexToUpdate is provided, replace the item at that index
      if (indexToUpdate !== undefined && indexToUpdate >= 0 && indexToUpdate < newList.length) {
        // Create a new array with elements up to the specified index
        const updatedList = newList.slice(0, indexToUpdate);
        updatedList.push({ type: "issue", name: selectedActualProblem.name });
        return updatedList;
      } else {
        // Otherwise, add the new item to the end of the list
        newList.push({ type: "issue", name: selectedActualProblem.name });
        return newList;
      }
    });
  }

  function handleClickSolutionsBtn(solutionId) {
    // let encodedParam = encodeURI(
    //   `role=${role.name}&location=${location.name}&problem_area=${
    //     problemArea.name + ":" + problemArea.details
    //   }&actual_problem=${actualProblem.details}`
    // );
    // let fetchURL = `/chat/app?expert=actionexpert?${encodedParam}`;
    // console.log(fetchURL);
    setShowGPTSolution(false);
    setGPTLoaded(false);
    let tempSolutionData = solutions.find((item) => item.id == solutionId);
    setSolutionDetail(tempSolutionData);
    // fetch(fetchURL)
    //   .then((res) => res.json())
    //   .then((result) => {
    //     setGPTLoaded(true);
    //     setGPTSolution(result.data);
    //   });
  }

  function handleClickLearnMoreBtn() {
    setGPTLoaded(false);
    setShowGPTSolution(true);
    // console.log({
    //   role: role.name,
    //   location: location.name,
    //   problem_area: problemArea.name + ":" + problemArea.details,
    //   actual_problem: actualProblem.name + ":" + actualProblem.details,
    //   solution: solutionDetail.name + ":" + solutionDetail.details,
    // });

    fetch("/ai/planmaker", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        problem_area: problemArea.name + " " + problemArea.details,
        actual_problem: actualProblem.name + " " + actualProblem.details,
        solution: solutionDetail.name + " " + solutionDetail.details,
        location: parsedJSONData.location,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        // console.log("result", result);
        setGPTLoaded(true);
        setGPTSolution(result.data);
      })
      .catch((e) => {
        setGPTLoaded(true);
        setGPTSolution("An error occurred while fetching the plan data. Please try again.");
        console.log("error", e);
      });
  }

  const navigate = useNavigate();

  return (
    <div className="container">
      <div className="left-side">
        <div
          style={{
            cursor: "pointer",
            textAlign: "center",
          }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <img
            style={{ minWidth: "150px", maxWidth: "230px", width: "100%" }}
            src={require("assets/images/logos/bluegaplogo.png")}
            alt="image"
          />
        </div>
        <Paper style={{ padding: 10, boxShadow: "none" }}>
          <Chip
            label={parsedJSONData?.location + " Action Planner"}
            style={{
              width: "100%",
              fontSize: "30px",
              fontWeight: "bold",
              font: "Tw Cen MT",
              color: "white",
              height: "50px",
              backgroundColor: "rgba(33, 150, 243, 1)",
              boxShadow: "none",
              ".MuiChipLabel": {
                display: "block",
                whiteSpace: "normal",
                color: "white",
              },
              borderRadius: "0px",
              fontFamily: "PFDinTextCompProMedium",
            }}
          />
          {/* <Accordion expanded={expanded.includes("panel1")} onChange={handleChange("panel1")}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              <Typography className="title">Select Role/Occupation</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List className="text">
                {roleList?.map((item) => (
                  <ListItem key={item.id} disablePadding>
                    <ListItemButton
                      sx={{
                        "&.Mui-selected": {
                          background: "lightblue !important",
                        },
                      }}
                      onClick={() => {
                        handleClickRoleBtn(item.id);
                      }}
                      selected={role?.id === item.id}
                    >
                      <Typography
                        sx={{
                          fontSize: "17px !important",
                          marginLeft: "30px !important",
                        }}
                      >
                        {item.name}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded.includes("panel2")} onChange={handleChange("panel2")}>
            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
              <Typography className="title">Select Location</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List className="text">
                {locationList.map((item) => (
                  <ListItem key={item.id} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        handleClickLocationBtn(item.id);
                      }}
                      selected={location?.id === item.id}
                      sx={{
                        "&.Mui-selected": {
                          background: "lightblue !important",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "17px !important",
                          marginLeft: "30px !important",
                        }}
                      >
                        {item.name}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion> */}

          <Accordion expanded={expanded.includes("panel3")} onChange={handleChange("panel3")}>
            <AccordionSummary
              sx={{ backgroundColor: "#91b551", color: "white !important" }}
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography className="title">Problem Domains</Typography>
            </AccordionSummary>
            <List className="text">
              {problemAreaList.map((item) => (
                <ListItem key={item.id} disablePadding>
                  <ListItemButton
                    onClick={() => {
                      handleClickProblemAreasBtn(item.id);
                    }}
                    selected={problemArea?.id === item.id}
                    sx={{
                      "&.Mui-selected": {
                        background: "lightblue !important",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "17px !important",
                        marginLeft: "30px !important",
                      }}
                    >
                      {item.name}
                    </Typography>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Accordion>
          <Accordion expanded={expanded.includes("panel4")} onChange={handleChange("panel4")}>
            <AccordionSummary
              sx={{ backgroundColor: "#91b551", color: "white !important" }}
              aria-controls="panel4d-content"
              id="panel4d-header"
            >
              <Typography className="title">Current Issues</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List className="text">
                {actualProblemList.map((item) => (
                  <ListItem key={item.id} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        handleClickActualProblemsBtn(item.id);
                      }}
                      selected={actualProblem?.id === item.id}
                      sx={{
                        "&.Mui-selected": {
                          background: "lightblue !important",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "17px !important",
                          marginLeft: "30px !important",
                        }}
                      >
                        {item.name}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded.includes("panel5")} onChange={handleChange("panel5")}>
            <AccordionSummary
              sx={{ backgroundColor: "#91b551", color: "white !important" }}
              aria-controls="panel5d-content"
              id="panel5d-header"
            >
              <Typography className="title">Solutions</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List className="text">
                {solutionList.map((item) => (
                  <ListItem key={item.id} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        handleClickSolutionsBtn(item.id);
                      }}
                      selected={solutionDetail?.id === item.id}
                      sx={{
                        "&.Mui-selected": {
                          background: "lightblue !important",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "17px !important",
                          marginLeft: "30px !important",
                        }}
                      >
                        {item.name}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        </Paper>
      </div>
      <Card style={{ overflow: "auto", padding: 10, background: "#F0EDED" }} className="right-side">
        <CardMedia
          component="img"
          height="150"
          image={require("assets/images/blue-gap-action.png")}
          alt="Action Planner"
        />
        <CardContent sx={{ padding: "0px 15px 0px 15px" }}>
          {/* <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {breadCrumbList.map((item, index) => (
              <Typography color="text.primary" variant="h6" key={index}>
                {item.name}
              </Typography>
            ))}
          </Breadcrumbs> */}
          {/* <div style={{ display: "flex" }}>
            {role ? (
              <>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  color="text.primary"
                  style={{ fontWeight: "bold", marginRight: "8px", lineHeight: "inherit" }}
                >
                  Role/Occupation:
                </Typography>
                <span style={{ marginRight: "20px" }}>{role.name}</span>
              </>
            ) : null}
            {location ? (
              <>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  color="text.primary"
                  style={{ fontWeight: "bold", marginRight: "8px", lineHeight: "inherit" }}
                >
                  Location:
                </Typography>
                <span>{location.name}</span>
              </>
            ) : null}
          </div> */}

          <div
            style={{
              position: "fixed",
              bottom: "30px",
              left: "40px",
              // textAlign: "center",
              // marginTop: "auto",
              // marginBottom: "0.8em",
            }}
          >
            {/* <button
            className="bluegap-ai-btn"
            onClick={() => {
              setShowAI(true);
            }}
          >
            AI HUB
          </button> */}
            <button
              className="feedback-button"
              onClick={() => {
                window.open(
                  "https://docs.google.com/forms/d/e/1FAIpQLSeBSL4BpDkZhxXDkftWvMl5N4TtRSKvPgCbrcpT6PGgbyWnVA/viewform",
                  "_blank"
                );
              }}
            >
              Feedback
            </button>
          </div>

          {problemArea ? (
            <Card sx={{ marginTop: 2 }}>
              <CardContent sx={{ padding: "20px !important" }}>
                <div style={{ display: "flex" }}>
                  {problemArea ? (
                    <>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        color="text.primary"
                        style={{ fontWeight: "bold", marginRight: "8px", lineHeight: "inherit" }}
                      >
                        Problem Domain:
                      </Typography>
                      <span>{problemArea.name}</span>
                    </>
                  ) : null}
                </div>
                <div style={{ display: "flex" }}>
                  {actualProblem ? (
                    <>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        color="text.primary"
                        style={{ fontWeight: "bold", marginRight: "8px", lineHeight: "inherit" }}
                      >
                        Current Issue:
                      </Typography>
                      <span>{actualProblem.name}</span>
                    </>
                  ) : null}
                </div>
                <div style={{ display: "flex" }}>
                  {solutionDetail ? (
                    <>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        color="text.primary"
                        style={{ fontWeight: "bold", marginRight: "8px", lineHeight: "inherit" }}
                      >
                        Solution:
                      </Typography>
                      <span>{solutionDetail.name}</span>
                    </>
                  ) : null}
                </div>
              </CardContent>
            </Card>
          ) : null}

          {solutionDetail ? (
            <>
              <div style={{ display: "flex" }}></div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Card sx={{ maxWidth: "50%", marginTop: 2, marginRight: 1 }}>
                  <CardContent sx={{ padding: "20px !important" }}>
                    <Typography gutterBottom variant="h4" component="div" color="text.primary">
                      Solution Description
                    </Typography>
                    <Typography variant="body2" color="text.primary">
                      {solutionDetail.details}
                    </Typography>

                    {solutionDetail.related_link ? (
                      <>
                        <Typography
                          sx={{ marginTop: "20px" }}
                          gutterBottom
                          variant="h5"
                          component="div"
                          color="text.primary"
                        >
                          Related Resources
                        </Typography>
                        {solutionDetail.related_link.map((item, index) => (
                          <Typography variant="body2" color="text.primary">
                            <List sx={{ listStyleType: "disc", paddingLeft: "25px" }}>
                              <ListItem sx={{ display: "list-item" }}>
                                {/* <Link rel="noopener noreferrer" target="_blank" href={item}>
                                  {item}
                                </Link> */}
                                <Typography variant="body2" color="text.primary">
                                  {item.title}
                                </Typography>
                                <Typography
                                  sx={{
                                    textDecoration: "underline",
                                    color: "blue",
                                    cursor: "pointer",
                                  }}
                                  variant="body2"
                                  color="text.primary"
                                  onClick={() => {
                                    window.open(item.link, "_blank");
                                  }}
                                >
                                  {item.link}
                                </Typography>
                                {/* <Link
                                  style={{ textDecoration: "underline !important", color: "blue" }}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  href={item.link}
                                >
                                  {item.link}
                                </Link> */}
                              </ListItem>
                            </List>
                          </Typography>
                        ))}
                      </>
                    ) : null}
                  </CardContent>
                </Card>

                <Card sx={{ minWidth: "50%", marginTop: 2 }}>
                  <CardContent
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "20px 20px 0px 20px",
                    }}
                  >
                    <Typography gutterBottom variant="h4" component="div" color="text.primary">
                      AI Generated Solution
                    </Typography>
                    <Button
                      sx={{ color: "white !important" }}
                      variant="contained"
                      onClick={handleClickLearnMoreBtn}
                    >
                      Generate
                    </Button>
                  </CardContent>
                  <CardContent sx={{ padding: "20px !important" }}>
                    <Typography variant="body2" color="text.primary">
                      {showGPTSolution ? (
                        <>
                          {gptLoaded ? (
                            <div className="gpt-section">
                              <Markdown rehypePlugins={rehypeRaw}>{gptSolution}</Markdown>
                            </div>
                          ) : (
                            <div style={{ textAlign: "center" }}>
                              <CircularProgress />
                            </div>
                          )}
                        </>
                      ) : (
                        <Typography variant="body2" color="text.primary">
                          Please click on the "Generate" button to get detailed AI generated plan
                          for the given solution.
                        </Typography>
                      )}
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </>
          ) : (
            <>
              <Typography sx={{ marginTop: "20px" }} variant="h3" color="text.primary">
                Please select your options to view the potential solutions.
              </Typography>
            </>
          )}
        </CardContent>
      </Card>
      <MDSnackbar
        color={snackbarType}
        icon={
          snackbarType == "success"
            ? "check"
            : snackbarType == "error"
            ? "warning"
            : "notifications"
        }
        title="Action Planner"
        content={snackbarErrorMessage}
        dateTime="just now"
        open={tc}
        onClose={() => setTC(false)}
        close={() => setTC(false)}
        // closeNotification={() => setTC(false)}
        bgWhite
      />
    </div>
  );
}

export default ActionPage;
