const dashboardMapStyle = {
  animate: {
    transition: 'all 0.3s ease-out',
    transform: 'scale(1.2)',
  },
  observationPanel: {
    zIndex: "1",
    position: "absolute !important",
    width:"550px",
    left:"35% !important",
    top:"5%"
  },
  observationWindowContainer: {
    top: "10px !important",
    left: "50% !important",
    transform: "translate(-50%, 0%)",
    zIndex: "1",
    position: "absolute !important",
    margin: "0 auto",
    contain: "content",
  },
  observationWindowItem: {
    flex: "1 1 0px",
    minWidth: "0",
  },
  tiltButton: {
    // backgroundColor: "#fff",
    // color: "#4e5257",
    border: "0",
    // borderRadius: "2px",
    // boxShadow: "0 1px 4px -1px rgba(0, 0, 0, 0.3)",
    margin: "5px",
    padding: "0 0.5em",
    font: "400 18px Roboto, Arial, sans-serif",
    overflow: "hidden",
    height: "35px",
    // cursor: "pointer",
    backgroundColor: "#344767",
    color: "#ffffff",
    borderRadius: "50%",
    boxShadow: "0rem 0.3125rem 0.625rem 0rem rgb(0 0 0 / 12%)",
    cursor: "pointer",
    width: "3.25rem",
    height: "3.25rem",
    marginBottom: "2rem !important",
    '&:hover': {
      background: "rgb(235, 235, 235)",
      color: "#000",
    }
  },
  enableLayerContainer: {
    background: "#fff",
    zIndex: "999999999",
    fontSize: "16px",
    color: "#333",
    padding: "16px 40px",
    fontWeight: "400",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "fixed",
    top: "40px",
    width: "50%",
    borderRadius: "8px",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    boxShadow: "0 13px 27px -5px rgb(50 50 93 / 25%), 0 8px 16px -8px rgb(0 0 0 / 30%), 0 -6px 16px -6px rgb(0 0 0 / 3%)",
  },
  // .ui-button:hover {
  //   background: rgb(235, 235, 235);
  // }
};

export default dashboardMapStyle;
