const SensorDataInfoBoxStyle = {
    cardCss: {
        position: "absolute",
        top: 20,
        right: 30,
        width: 250
    },
    cardIcon: {
        padding: "0px !important",
        marginTop: "-18px !important",
        marginLeft:"5px"
    },
    icon: {
        fontSize: "32px !important",
        width: "35px !important",
        height: "35px !important",
        lineHeight: "42px !important",
        color: "#fff !important",
    },
    cardCategory: {
        color: "#999",
        margin: "0",
        fontSize: "18px",
        marginTop: "0",
        marginBottom: "0",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        textAlign: "right",
        width: "100%",
        fontFamily:"PFDinTextCompProMedium"
    },
    cardTitle: {
        color: "black",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "400",
        fontSize: "20px",
        fontFamily: "'PFDinTextCompProMedium', 'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "black",
            fontWeight: "400",
            lineHeight: "1"
        },
        textAlign: "right",
        width:"100%"
    },
    infoboxContainer: {
        width: "20%",
        position: "absolute",
        maxWidth: "500px",
        minWidth: "250px",
        contain: "content",
        top: 20,
        right: 30,
        width: 250
    },
    // table: {
    //     marginBottom: "0 !important",
    //     // width: "unset",
    //     maxWidth: "100% !important",
    //     backgroundColor: "transparent !important",
    //     borderSpacing: "0 !important",
    //     borderCollapse: "collapse !important",
    //     tableLayout: "fixed !important",
    //   },
    //   tableResponsive: {
    //     width: "100% !important",
    //     marginTop: "10px !important",
    //     overflowX: "auto !important"
    //   },
    //   tableBodyRow: {
    //     height: "24px !important",
    //     color: "inherit !important",
    //     display: "table-row !important",
    //     outline: "none !important",
    //     verticalAlign: "middle !important"
    //   },
    //   tableCell: {
    //     lineHeight: "1.02857143 !important",
    //     padding: "7px !important", // used to be 0px 8px
    //     verticalAlign: "middle !important",
    //     fontSize: "0.8125rem !important",
    //     borderBottom: "none !important",
    //     fontWeight: "400 !important",
    //     whiteSpace: "nowrap !important",
    //     overflow: "hidden !important",
    //     textOverflow: "ellipsis !important",
    //     maxWidth: "70% !important",
    //   },
    //   dataTitle: {
    //     color: "inherit !important",
    //     fontWeight: "400 !important"
    //   }
    table: {
        // width: "unset",
        maxWidth: "100%",
        backgroundColor: "transparent",
        borderSpacing: "0",
        borderCollapse: "collapse",
        tableLayout: "fixed !important",
    },
    tableResponsive: {
        width: "100%",
        marginTop: "1px",
        // overflowX: "auto"
    },
    tableBodyRow: {
        height: "24px",
        color: "inherit",
        display: "table-row",
        outline: "none",
        verticalAlign: "middle"
    },
    tableCell: {
        // padding: "0px 10px 0px 10px !important", // used to be 0px 8px
        // verticalAlign: "middle !important",
        // borderBottom: "none !important",
        // fontWeight: "400 !important",
        // whiteSpace: "nowrap !important",
        // textOverflow: "ellipsis !important",
        // maxWidth: "70% !important",
            lineHeight: "1.02857143 !important",
        padding: "7px !important", // used to be 0px 8px
        verticalAlign: "middle !important",
        fontSize: "0.8125rem !important",
        borderBottom: "none !important",
        fontWeight: "400 !important",
        whiteSpace: "nowrap !important",
        overflow: "hidden !important",
        textOverflow: "ellipsis !important",
        maxWidth: "70% !important",
    },
    tableCell2: {
        padding: "0px 10px 5px 0px !important", // used to be 0px 8px
        verticalAlign: "middle !important",
        borderBottom: "none !important",
        fontWeight: "400 !important",
        whiteSpace: "nowrap !important",
        textOverflow: "ellipsis !important",
        maxWidth: "70% !important",
        textAlign: "right !important"
    },
    tableSummaryCell: {
        padding: "0px 0px 5px 5px !important", // used to be 0px 8px
        verticalAlign: "middle",
        borderBottom: "none !important",
        fontWeight: "400",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        maxWidth: "100%",
    },
    tableSummaryCellWQ: {
        padding: "0px 0px 5px 5px !important", // used to be 0px 8px
        verticalAlign: "middle",
        borderBottom: "none !important",
        fontWeight: "400",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    dataTitle: {
        color: "inherit",
        fontWeight: "600 !important",
        fontSize: "0.79rem !important"
    },
    dataTitleContent: {
        color: "inherit",
        fontWeight: "400 !important",
        fontSize: "0.79rem !important"
    }
}

export default SensorDataInfoBoxStyle;