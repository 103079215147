import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import ScreenshotIcon from "@mui/icons-material/Screenshot";
// @mui icons

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { makeStyles } from "@mui/styles";
import SensorDataInfoBoxStyle from "../styles/SensorDataInfoBoxStyle";
import { motion } from "framer-motion";
import { useCallback, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

const useStyles = makeStyles(SensorDataInfoBoxStyle);

function SensorDataInfoBox({
  data,
  setSelectedSensorInstance,
  setOpenPanel,
  setLoadingIndicator,
  openPanel,
  addToCompareList,
  checkItemIsOnListHandler,
  geoType,
  breadCrumbsList,
  dataSourceState,
  parsedJSONData,
  boundState,
  searchParams,
  showNotification,
}) {
  const classes = useStyles();
  const [controller, dispatch] = useMaterialUIController();
  const { showObservationsPanel } = controller;

  const dropIn = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 25,
        stiffness: 500,
      },
    },
    exit: {
      y: "100vh",
      opacity: 1,
    },
  };

  function handleCloseHandler() {
    setSelectedSensorInstance(null);
  }

  const constructGoogleMapURL = (lat, lon) => {
    return "https://maps.google.com?q=" + lat + "," + lon;
  };

  function showObservationsHandler() {
    setLoadingIndicator(true);
    // setShowObservationsPanel(dispatch, true);
    setOpenPanel(true);
    //console.log(data);
  }

  const itemIsPresent = checkItemIsOnListHandler(data.siteid);

  const getColorForProvider = (dataInstance) => {
    // if (dataInstance.provider === undefined){
    //   return "success";
    // }
    switch (dataInstance.provider) {
      case "IWQIS":
        return "#F9C74F"; // blue
      case "STORET":
        return "#F94144"; // purple
      case "SDWA":
        return "rgb(17, 4, 204)"; // pink
      case "PWS":
      case "TBW":
        return "#b97a56"; // orange
      case "IADNR":
        return "#F8961E"; // orange
      default:
        // NWIS
        return "#F3722C"; // green
    }
  };

  // function handleSnapshotClickBtn() {
  //   console.log(geoType);
  //   console.log(breadCrumbsList);
  //   console.log(dataSourceState);
  //   console.log(
  //     window.location.href +
  //       `?geotype=${geoType}&value=${
  //         breadCrumbsList[breadCrumbsList.length - 1].id
  //       }&url=${encodeURIComponent(parsedJSONData.boundaryURL)}&lat=${
  //         parsedJSONData.latitude
  //       }&lng=${parsedJSONData.longitude}&zoom=${parsedJSONData.zoomValue}&state=${
  //         parsedJSONData.state
  //       }&bounds=${encodeURIComponent(boundState)}&dataSource=${encodeURIComponent(
  //         JSON.stringify(dataSourceState)
  //       )}&zoom=${parsedJSONData.zoomValue}&data=${encodeURIComponent(JSON.stringify(data))}`
  //   );
  // }

  const [snapshotURL, setSnapShotURL] = useState(null);
  const [copied, setCopied] = useState(false);

  function handleSnapshotClickBtn() {
    setCopied(false);
    setSnapShotURL(null);
    // console.log(geoType);
    // console.log(breadCrumbsList);
    // console.log(dataSourceState);
    if (geoType == "Zip Code") {
      let tempURL = `${window.location.origin}${
        window.location.pathname
      }?geotype=${encodeURIComponent(geoType)}&value=${breadCrumbsList[0].id}&url=${
        parsedJSONData
          ? encodeURIComponent(parsedJSONData.boundaryURL)
          : encodeURIComponent(searchParams.get("url"))
      }&lat=${parsedJSONData ? parsedJSONData.latitude : searchParams.get("lat")}&lng=${
        parsedJSONData ? parsedJSONData.longitude : searchParams.get("lng")
      }&state=${
        parsedJSONData ? parsedJSONData.state : searchParams.get("state")
      }&bounds=${encodeURIComponent(boundState)}&dataSource=${encodeURIComponent(
        JSON.stringify(dataSourceState)
      )}&zoom=${
        parsedJSONData ? parsedJSONData.zoomValue : searchParams.get("zoom")
      }&zip=true&zip_code=${breadCrumbsList[1].id}&breadcrumb=${encodeURIComponent(
        JSON.stringify(breadCrumbsList)
      )}&data=${encodeURIComponent(JSON.stringify(data))}`;
      console.log(tempURL);
      setSnapShotURL(tempURL);
      navigator.clipboard.writeText(tempURL);
    } else {
      let tempUrl = `${window.location.origin}${
        window.location.pathname
      }?geotype=${geoType}&value=${breadCrumbsList[breadCrumbsList.length - 1].id}&url=${
        parsedJSONData
          ? encodeURIComponent(parsedJSONData.boundaryURL)
          : encodeURIComponent(searchParams.get("url"))
      }&lat=${parsedJSONData ? parsedJSONData.latitude : searchParams.get("lat")}&lng=${
        parsedJSONData ? parsedJSONData.longitude : searchParams.get("lng")
      }&zoom=${parsedJSONData ? parsedJSONData.zoomValue : searchParams.get("zoom")}&state=${
        parsedJSONData ? parsedJSONData.state : searchParams.get("state")
      }&bounds=${encodeURIComponent(boundState)}&dataSource=${encodeURIComponent(
        JSON.stringify(dataSourceState)
      )}&data=${encodeURIComponent(JSON.stringify(data))}`;
      console.log(tempUrl);
      setSnapShotURL(tempUrl);
      navigator.clipboard.writeText(tempUrl);
    }

    showNotification("Snapshot URL has been copied to clipboard.", "info");
  }

  // Function to handle text copy
  const handleCopy = () => {
    setCopied(true);
  };

  return (
    <motion.div variants={dropIn} initial="hidden" animate="visible" exit="exit">
      <Card
        style={{
          position: "absolute",
          top: 20,
          right: 30,
          width: "20%",
          maxWidth: "400px",
          minWidth: "250px",
          contain: "content",
          padding: "4px",
        }}
      >
        <MDBox display="flex" justifyContent="space-between" alignItems="baseline" pt={1} pb={0.5}>
          <MDBox className={classes.cardIcon}>
            <IconButton
              aria-label="add"
              className={classes.icon}
              style={{ backgroundColor: `${getColorForProvider(data)}`, borderRadius: "5px" }}
              onClick={addToCompareList}
            >
              {itemIsPresent ? <PlaylistAddCheckIcon /> : <PlaylistAddIcon />}
            </IconButton>
          </MDBox>
          <MDBox style={{ width: "100%" }}>
            {/* <p className={classes.cardCategory}>
              {(data.agency == "NWIS" ? data.agency : data.provider) + " - " + data.siteid}
            </p> */}
            {/* <h6 className={classes.cardTitle}>
              {data.sitename != "NULL" ? data.sitename : data.siteid}
            </h6> */}
          </MDBox>

          <Icon
            sx={({ typography: { size }, palette: { dark, white } }) => ({
              fontSize: `${size.lg} !important`,
              color: "white",
              stroke: "currentColor",
              strokeWidth: "2px",
              cursor: "pointer",
              transform: "translateY(-10px)",
              paddingTop: "4px",
            })}
            onClick={handleCloseHandler}
          >
            close
          </Icon>
        </MDBox>
        {/* <div className={classes.tableResponsive}>
          <Table className={classes.table}>
            <TableBody>
              {data.tableData.map((row, key) => {
                return (
                  <TableRow key={key} className={classes.tableBodyRow}>
                    {row.map((cell, key2) => {
                      return cell.length >= 2 ? (
                        <TableCell
                          key={key2}
                          className={key2 % 2 == 0 ? classes.tableCell : classes.tableCell2}
                        >
                          <span style={{ color: `black` }} className={classes.dataTitle}>
                            {cell[0]} &nbsp;
                          </span>
                          <span className={classes.dataTitle}>
                            {cell[1] == "storet" ? "EPA STORET" : cell[1]}
                          </span>
                        </TableCell>
                      ) : null;
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div> */}
        <div className={classes.tableResponsive}>
          <Table className={classes.table}>
            {/*tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null*/}
            <TableBody>
              {data.tableData.map((row, key) => {
                return (
                  <TableRow key={key} className={classes.tableBodyRow}>
                    {row.map((cell, key2) => {
                      return cell.length >= 2 ? (
                        <TableCell key={key2} className={classes.tableCell}>
                          <span className={classes.dataTitle}>{cell[0]} &nbsp;</span>
                          <span className={classes.dataTitleContent}>{cell[1]}</span>
                        </TableCell>
                      ) : null;
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        {/* <MDButton
          style={{
            fontFamily: "PFDinTextCompProMedium",
            fontSize: "1.1rem",
            margin: "0px 10px 10px 10px",
            backgroundColor: `${getColorForProvider(data)}`,
            color: "white",
          }}
          variant="contained"
          onClick={showObservationsHandler}
          disabled={openPanel}
        >
          Show Observations
        </MDButton> */}
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <IconButton
            aria-label="add"
            className={classes.icon}
            style={{ backgroundColor: `${getColorForProvider(data)}`, borderRadius: "5px" }}
            onClick={handleSnapshotClickBtn}
          >
            <ScreenshotIcon />
          </IconButton> */}

          <MDButton
            style={{
              flex: 1,
              margin: "10px 10px 15px 10px",
              padding: "0% 4% 0% 4%",
              backgroundColor: `#d2dde4`,
              color: "#000",
              boxShadow: "0 7px 0 #b2bdc4",
            }}
            variant="contained"
            onClick={showObservationsHandler}
            disabled={openPanel}
          >
            Show Observations
          </MDButton>

          {/* <MDButton
            style={{
              margin: "10px 10px 15px 10px",
              padding: "0% 4% 0% 4%",
              backgroundColor: `#d2dde4`,
              color: "#000",
              boxShadow: "0 7px 0 #b2bdc4",
            }}
            variant="contained"
            onClick={handleSnapshotClickBtn}
            disabled={openPanel}
          >
            Snap
          </MDButton> */}
        </div>
      </Card>
    </motion.div>
  );
}

export default SensorDataInfoBox;
