import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabs: {
    "& .MuiTab-root": {
      minWidth: "auto",
      backgroundColor: "darkgrey",
      color: "black !important",
    },
    "& .Mui-selected": {
      backgroundColor: ({ activeColor }) => activeColor,
      color: "white !important",
    },
    "& .Mui-disabled": {
      pointerEvents: "all !important",
      cursor: "not-allowed !important",
    },
  },
}));

function DataResolutionTab({
  dataResolutionValue,
  handleDataResolutionChange,
  tabs,
  activeColor,
  ...props
}) {
  const classes = useStyles({ activeColor });

  return tabs.length > 1 ? (
    <Tabs
      value={dataResolutionValue}
      onChange={handleDataResolutionChange}
      variant="scrollable"
      scrollButtons="auto"
      className={classes.tabs}
      {...props}
    >
      {tabs.map((prop, key) => {
        var icon = {};
        if (prop.tabIcon) {
          icon = {
            icon: <prop.tabIcon style={{ height: "12px" }} />,
          };
        }
        return (
          <Tab
            key={key}
            label={prop.tabName}
            disabled={prop.isDisabled}
            {...icon}
            style={{ marginRight: "5px" }}
          />
        );
      })}
    </Tabs>
  ) : null;
}

export default DataResolutionTab;
