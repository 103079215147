import { Card, Divider, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Header from "./components/Header";
import TeamCard from "./components/TeamCard";
import hs_resource_0 from "assets/images/ibrahim_demir.jpg";
import hs_resource_1 from "assets/images/jerry.jpg";
import hs_resource_2 from "assets/images/yusuf-sermet.jpg";
import hs_resource_3 from "assets/images/gabriel.jpg";
import hs_resource_4 from "assets/images/samrat.jpg";
import david_cwiertny from "assets/images/david-cwiertny.jpg";
import marcus from "assets/images/marcus-beck.jpg";
import steven from "assets/images/steven-meyers.jpg";
import frank from "assets/images/frank-muller.jpg";
import omer from "assets/images/omer.jpg";
import jackson from "assets/images/jackson.jpg";
import maya from "assets/images/MayaTrotz.jpg";
import ana from "assets/images/anaperalta.jpg";
import zarger from "assets/images/Zarger.jpg";
import eric from "assets/images/eric.jpg";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  item: {
    flexGrow: 1,
    maxWidth: "20%", // 100% / 5 elements per row = 20%
    flexBasis: "20%", // Ensures that the basis before growing or shrinking is also 20%
  },
  emptyItem: {
    flexGrow: 1,
    maxWidth: "20%",
    flexBasis: "20%",
    backgroundColor: "transparent", // Optional, for visual debugging
  },
}));

function AboutUs() {
  const classes = useStyles();
  return (
    <MDBox sx={{ background: "white" }}>
      <Header />
      <MDBox m={2} sx={{ background: "white" }}>
        <MDBox mb={2} lineHeight={1}>
          <MDTypography variant="button" fontSize="19px" color="black" fontWeight="regular">
            The BlueGAP Information System (BlueGAP-IS) is our virtual community for sustained and
            continued engagement with stories and data about nitrogen pollution. Visitors to the
            BlueGAP-IS can explore additional content from our Alliance members, explore past
            Academy events, and hear from other members of the BlueGAP community. Users can explore
            water quality data in their own watershed, and use the BlueGAP Action Planner to
            identify actions they can take to start improving water quality in their community.
          </MDTypography>
        </MDBox>
        <MDBox>
          <MDTypography mb={2} variant="h3" color="black" fontWeight="medium">
            Developer Team
          </MDTypography>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6} xl className={classes.item}>
              <TeamCard
                image={hs_resource_4}
                title="Graduate Research Assistant, University of Iowa"
                name="Samrat Shrestha"
              />
            </Grid>
            <Grid item xs={12} md={6} xl className={classes.item}>
              <TeamCard
                image={hs_resource_3}
                title="Graduate Research Assistant, University of Iowa"
                name="Gabriel Vald"
              />
            </Grid>
            <Grid item xs={12} md={6} xl className={classes.item}>
              <TeamCard
                image={hs_resource_1}
                title="Research Scientist, University of Iowa"
                name="Jerry Mount"
              />
            </Grid>
            <Grid item xs={12} md={6} xl className={classes.item}>
              <TeamCard
                image={hs_resource_2}
                title="Research Engineer, University of Iowa"
                name="Yusuf Sermet"
              />
            </Grid>
            {/* <Grid item xs={12} md={6} xl={3}>
              <TeamCard
                image={jackson}
                title="Postdoctoral Research Scholar, University of Iowa"
                name="Dinesh Jackson Samuel"
                // description="Watershed delineation for Herrington sampling location"
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <TeamCard
                image={omer}
                title="Senior Researcher, University of Iowa"
                name="Omer Mermer"
                // description="Watershed delineation for Herrington sampling location"
              />
            </Grid> */}
            <Grid item xs={12} md={6} xl className={classes.item}>
              <TeamCard
                image={hs_resource_0}
                title="Department of Civil & Environmental Engineering, University of Iowa"
                name="Ibrahim Demir"
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={3}>
          <MDTypography variant="h3" mb={2} color="black" fontWeight="medium">
            Advisory Team
          </MDTypography>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6} xl className={classes.item}>
              <TeamCard
                image={david_cwiertny}
                title="Department of Civil & Environmental Engineering, University of Iowa"
                name="David Cwiertny"
              />
            </Grid>
            <Grid item xs={12} md={6} xl className={classes.item}>
              <TeamCard image={marcus} title="Program Scientist, TBEP" name="Marcus Beck" />
            </Grid>
            <Grid item xs={12} md={6} xl className={classes.item}>
              <TeamCard
                image={steven}
                title="Department of Marine Science, University of South Florida"
                name="Steven Meyers"
              />
            </Grid>
            <Grid item xs={12} md={6} xl className={classes.item}>
              <TeamCard
                image={frank}
                title="Department of Marine Science, University of South Florida"
                name="Frank Muller-Karger"
              />
            </Grid>
            <Grid item xs={12} md={6} xl className={classes.item}>
              {/* This is an empty grid item to maintain layout consistency */}
              <TeamCard
                image={eric}
                title="College of Liberal Arts and Sciences, University of Iowa"
                name="Eric Gidal"
              />
            </Grid>
          </Grid>
          <Grid container spacing={6} mt={2}>
            <Grid item xs={12} md={6} xl className={classes.item}>
              {/* This is an empty grid item to maintain layout consistency */}
              <TeamCard
                image={ana}
                title="Department of Marine Science, University of South Florida"
                name="Ana Carolina Peralta"
              />
            </Grid>
            <Grid item xs={12} md={6} xl className={classes.item}>
              {/* This is an empty grid item to maintain layout consistency */}
              <TeamCard
                image={zarger}
                title="Department of Anthropology, University of South Florida"
                name="Rebecca Zarger"
              />
            </Grid>
            <Grid item xs={12} md={6} xl className={classes.item}>
              {/* This is an empty grid item to maintain layout consistency */}
              <TeamCard
                image={maya}
                title="Department of Civil and Environmental Engineering, University of South Florida"
                name="Maya Trotz"
              />
            </Grid>
            <Grid item xs={12} md={6} xl className={classes.emptyItem}>
              {/* This is an empty grid item to maintain layout consistency */}
            </Grid>
            <Grid item xs={12} md={6} xl className={classes.emptyItem}>
              {/* This is an empty grid item to maintain layout consistency */}
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default AboutUs;
