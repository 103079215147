import Icon from "@mui/material/Icon";
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
// @mui icons

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import ButtonBase from "@mui/material/ButtonBase";
import Tooltip from "@mui/material/Tooltip";
import Grid from '@mui/material/Grid';
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { useContext } from "react";
import ComparisonTableStyle from "../styles/ComparisonTableStyle";
import { useMaterialUIController } from "context";
import { useCallback } from "react";
import { useEffect } from "react";

const useStyles = makeStyles(ComparisonTableStyle);

function ComparisonTable({ data, lastSelectedSensorData, removeSensor_CompareView, showComparisonObservationWindow, setLoadingIndicator }) {
    const classes = useStyles();
    const [controller, dispatch, sensorDataList] = useMaterialUIController();
    const {
        sensorDataBox,
        showObservationsPanel
    } = controller;

    const getColorForProvider = (dataInstance) => {
        // if (dataInstance.provider === undefined){
        //   return "success";
        // }
        switch (dataInstance.provider) {
            case "IWQIS":
                return "#00acc1"; // blue
            case "STORET":
                return "#9c27b0"; // purple
            default:
                // NWIS
                return "#4caf50"; // green
        }
    }

    const naIfNull = (value) => {
        return value === "NULL" ? "N/A" : value;
    }

    const tableHead = ["ID", "Provider", "Name"];

    const tableData = data.map((instance, key) => {
        return [naIfNull(instance.siteid), naIfNull(instance.agency), naIfNull(instance.sitename)];
    });

    const onRemoveItem = useCallback(
        (siteid) => () => {
            removeSensor_CompareView(siteid);
        },
        [],
    );

    function onClickCompareHandler() {
        setLoadingIndicator(true);
        showComparisonObservationWindow(true);
    }

    return (
        <>
            <div className={classes.container}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <Card style={{
                            border: "0",
                            marginBottom: "30px",
                            marginTop: "30px",
                            borderRadius: "6px",
                            width: "100%",
                            position: "relative",
                            display: "flex",
                            flexDirection: "column",
                            minWidth: "0",
                            wordWrap: "break-word",
                            fontSize: ".875rem"
                        }}>
                            <Card style={{
                                backgroundColor: `${getColorForProvider(lastSelectedSensorData)}`,
                                borderRadius: "3px", padding: "15px", width: "90%", marginLeft: "14px"
                            }}>
                                <ButtonBase focusRipple={true} variant="text" onClick={onClickCompareHandler} style={{ justifyContent: "flex-start" }}><h4 className={classes.cardTitleWhite}>Compare Observations</h4></ButtonBase>
                                <p className={classes.cardCategoryWhite}>
                                    Select sensors on the map to enable side-by-side data view.
                                </p>
                            </Card>

                            <MDBox
                                display="flex"
                                justifyContent="space-between"
                                alignItems="baseline"
                                pt={2}
                                pb={0.5}
                                px={3}
                            >
                                <MDBox className={classes.tableResponsive}>
                                    <Table className={classes.table}>
                                        {tableHead !== undefined ? (
                                            <TableHead>
                                                <TableRow className={classes.tableBodyRow}>
                                                    {tableHead.map((prop, key) => {
                                                        return key == 0 ? null : (
                                                            <TableCell
                                                                key={key}
                                                                style={{ color: `${getColorForProvider(lastSelectedSensorData)}` }}
                                                                className={classes.tableHeadCell}>
                                                                {prop}
                                                            </TableCell>
                                                        );
                                                    })}
                                                    <TableCell
                                                        className={classes.tableCell + " " + classes.tableHeadCell}
                                                    >
                                                        {""}
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                        ) : null}
                                        <TableBody>
                                            {tableData.map((prop, key) => {
                                                return (
                                                    <TableRow key={key} className={classes.tableBodyRow}>
                                                        {prop.map((prop2, key) => {
                                                            return key == 0 ? null :
                                                                (
                                                                    <TableCell className={classes.tableCell} key={key}>
                                                                        {prop2}
                                                                    </TableCell>
                                                                );
                                                        })}
                                                        <TableCell className={classes.tableCell}>
                                                            <Tooltip
                                                                id="tooltip-top-start"
                                                                title="Remove"
                                                                placement="top"
                                                            >
                                                                <IconButton className={classes.tableActionButton}
                                                                    aria-label="Close" onClick={onRemoveItem(prop[0])}>
                                                                    <Close className={
                                                                        classes.tableActionButtonIcon + " " + classes.close
                                                                    } />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </MDBox>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </div>

        </>
    );

}

export default ComparisonTable;