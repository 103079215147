const DemographicReportBoxStyle = {
    table: {
      // width: "unset",
      maxWidth: "100%",
      backgroundColor: "transparent",
      borderSpacing: "0",
      borderCollapse: "collapse",
      tableLayout: "fixed !important"
    },
    tableResponsive: {
      width: "100%",
      marginTop: "1px",
      // overflowX: "auto"
    },
    tableBodyRow: {
      height: "24px",
      color: "inherit",
      display: "table-row",
      outline: "none",
      verticalAlign: "middle",
    },
    tableCell: {
      padding: "0px 0px 5px 10px !important", // used to be 0px 8px
      verticalAlign: "middle",
      borderBottom: "none !important",
      fontWeight: "400",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: "100%",
    },
    tableSummaryCell: {
      padding: "0px 0px 5px 5px !important", // used to be 0px 8px
      verticalAlign: "middle",
      borderBottom: "none !important",
      fontWeight: "400",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: "100%",
    },
    tableSummaryCellWQ: {
      padding: "0px 0px 5px 5px !important", // used to be 0px 8px
      verticalAlign: "middle",
      borderBottom: "none !important",
      fontWeight: "400",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    dataTitle: {
      color: "inherit",
      fontWeight: "400",
      fontSize: "0.76rem",
    },
    statusText: {
      fontWeight: "500",
      fontSize: "29px !important",
      position: "absolute",
      color: "black !important",
      top: "35%",
      left: "42%",
      zIndex: "1",
      fontFamily: "PFDinTextCompProMedium !important",
      "@media (min-width: 2134px)": {
        left: "39%", // Change the left position for screens with a width greater than or equal to 1920px
      },
    },
    demographicText: {
      fontWeight: "100",
      fontSize: "18px !important",
      position: "absolute",
      color: "green !important",
      top: "44%",
      left: "2%",
      zIndex: "1",
      fontFamily: "PFDinTextCompProMedium !important"
    },
  };
  
  export default DemographicReportBoxStyle;
  