/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useContext, useReducer } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// The Soft UI Dashboard PRO Material main context
const MaterialUI = createContext();

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";

// Material Dashboard 2 PRO React reducer
function reducer(state, action) {
  // debugger;
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    case "GEOM": {
      return { ...state, geomObject: action.value };
    }
    case "DATA_SOURCE": {
      return { ...state, dataSourceState: action.value };
    }
    case "SENSOR": {
      return { ...state, sensorObject: action.value };
    }
    case "SENSOR_CLASS": {
      return { ...state, sensorClassName: action.value };
    }
    case "DATA_SOURCE_LOADED": {
      return { ...state, dataSourceLoaded: action.value };
    }
    case "SENSOR_DATA_BOX": {
      return { ...state, sensorDataBox: action.value };
    }
    case "OBSERVATION_PANEL": {
      return { ...state, showObservationsPanel: action.value };
    }
    case "OBSERVATION": {
      return { ...state, selectedObservations: action.value };
    }
    case "ADD_SENSOR": {
      return { ...state, sensorDataList: [...state.sensorDataList, action.value] };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Material Dashboard 2 PRO React context provider
function MaterialUIControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    darkMode: false,
    geomObject: null,
    dataSourceState: {
      iwqis: false,
      storet: false,
      nwisdv: false,
      nwisiv: false,
      pwts: false,
      sdwa: false,
      drinkingWater: false,
      groundWater: false,
      waterCommunity: false,
      iasnapst: false,
      iowagw: false,
      nondnr: false,
      iowa21: false,
      recWatersOcean: false
    },
    sensorObject: null,
    sensorClassName: "NWISDV",
    dataSourceLoaded: {
      iwqis: false,
      storet: false,
      nwisdv: false,
      nwisiv: false,
      pwts: false,
      sdwa: false,
      drinkingWater: false,
      groundWater: false,
      waterCommunity: false,
      iasnapst: false,
      iowagw: false,
      nondnr: false,
      iowa21: false,
      recWatersOcean: false
    },
    sensorDataBox: null,
    showObservationsPanel: false,
    selectedObservations: null,
    sensorDataList: [],
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  return <MaterialUI.Provider value={[controller, dispatch]}>{children}</MaterialUI.Provider>;
}

// Material Dashboard 2 PRO React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });
const setGeomObject = (dispatch, value) => dispatch({ type: "GEOM", value });
const setDataSourceState = (dispatch, value) => dispatch({ type: "DATA_SOURCE", value });
const setSensorObject = (dispatch, value) => dispatch({ type: "SENSOR", value });
const setSensorClassName = (dispatch, value) => dispatch({ type: "SENSOR_CLASS", value });
const setDataSourceLoaded = (dispatch, value) => dispatch({ type: "DATA_SOURCE_LOADED", value });
const setSensorDataBox = (dispatch, value) => dispatch({ type: "SENSOR_DATA_BOX", value });
const setShowObservationsPanel = (dispatch, value) =>
  dispatch({ type: "OBSERVATION_PANEL", value });
const setSelectedObservations = (dispatch, value) => dispatch({ type: "OBSERVATION", value });
const addSensorList = (dispatch, value) => dispatch({ type: "ADD_SENSOR", value });

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
  setGeomObject,
  setDataSourceState,
  setSensorObject,
  setSensorClassName,
  setDataSourceLoaded,
  setSensorDataBox,
  setShowObservationsPanel,
  setSelectedObservations,
  addSensorList,
};
