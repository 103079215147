const DataReportBoxStyle = {
  table: {
    // width: "unset",
    backgroundColor: "transparent",
    borderSpacing: "0",
    borderCollapse: "collapse",
    tableLayout: "fixed !important",
  },
  tableBodyRow: {
    height: "24px",
    color: "inherit",
    display: "table-row",
    outline: "none",
    verticalAlign: "middle",
  },
  tableCell: {
    padding: "0px 0px 5px 10px !important", // used to be 0px 8px
    verticalAlign: "middle",
    borderBottom: "none !important",
    fontWeight: "400",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  tableSummaryCell: {
    padding: "0px 0px 5px 5px !important", // used to be 0px 8px
    verticalAlign: "middle",
    borderBottom: "none !important",
    fontWeight: "400",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  tableSummaryCellWQ: {
    padding: "0px 0px 5px 5px !important", // used to be 0px 8px
    verticalAlign: "middle",
    borderBottom: "none !important",
    fontWeight: "400",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "70px"
  },
  dataTitle: {
    color: "inherit",
    fontWeight: "400",
    fontSize: "0.76rem",
  },
};

export default DataReportBoxStyle;
