import { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { useMaterialUIController } from "context";

function HistogramChart({
  data,
  provider,
  startDate,
  endDate,
  selectedVariableAbbr,
  selectedVariableName,
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { sensorDataBox } = controller;
  const [stateData, setSetData] = useState({
    selectedVariableName: "Select a variable",
    data: [{ x: null, y: null }],
    startDate: new Date(),
    endDate: new Date(),
  });

  useEffect(() => {
    //console.log(data);
    //console.log(provider);
    //console.log(startDate);
    //console.log(endDate);
    //console.log(selectedVariableAbbr);
    //console.log(selectedVariableName);
    setSetData({
      data: formatObservations(data, provider),
      selectedVariableAbbr: selectedVariableAbbr,
      selectedVariableName: selectedVariableName,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
    });
  }, [data]);

  function formatObservations(observations, provider) {
    // console.log("objeara",observations);
    if (observations != null) {
      // Make sure data is actual observation and not noise.
      const helper_isLegitData = (data) => {
        if (data !== null && data !== "null" && data !== "None") {
          if (parseFloat(data) >= 0) {
            return true;
          }
        }
        return false;
      };

      const helper_nullifyMissingData = (data, doesRequireParseFloat = false) => {
        if (data === null || data === "null" || data === "None") {
          return null;
        }
        let data_numeric = doesRequireParseFloat ? parseFloat(data) : data;
        if (data_numeric < 0) {
          return null;
        }
        return data_numeric;
      };

      let labeledData = [];

      if (Array.isArray(observations) && Array.isArray(observations[0])) {
        observations[0].forEach(function (observation) {
          let dataRaw = {};
          if (selectedVariableAbbr == "wc") {
            dataRaw = observation?.obs_count;
          } else if (selectedVariableAbbr == "dw") {
            dataRaw = observation?.obs_count_dw;
          } else if (selectedVariableAbbr == "rw") {
            dataRaw = observation?.obs_count_rw;
          } else {
            dataRaw = observation?.obs_count_gw;
          }
          let dateRaw = observation?.["dt"];
          if (helper_isLegitData(dataRaw)) {
            let dateInstance = new Date(dateRaw);
            let dataProcessed = helper_nullifyMissingData(dataRaw, true);
            labeledData.push({ x: dateInstance, y: dataRaw });
          }
        });
      }

      //console.log(labeledData);
      return labeledData;
    }
    return null;
  }

  return (
    <>
      {data !== null ? (
        <Plot
          data={[
            {
              x: stateData.data.map((obj) => {
                return obj.x;
              }),
              y: stateData.data.map((obj) => {
                return obj.y;
              }),
              type: "bar",
              marker: { color: "#2989E3" },
            },
          ]}
          layout={{
            autosize: true,
            margin: {
              l: 50,
              r: 40,
              b: 10,
              t: 10,
              pad: 10,
            },
            // width: 350,
            // height: 200,
            // title: 'A Fancy Plot',
            xaxis: {
              autorange: true,
              range: [stateData.startDate, stateData.endDate],
              rangeslider: { range: [stateData.startDate, stateData.endDate] },
              type: "date",
            },
            yaxis: {
              autorange: true,
              range: [0, 60],
              type: "linear",
              rangemode: "tozero",
            },
          }}
          style={{ width: "100%", height: "100%", overflow: "hidden" }}
          config={{ responsive: true, displayModeBar: false }}
        />
      ) : null}
    </>
  );
}

export default HistogramChart;
